'use strict';

/**
 * @name StoreConfirmationController - Confirmation Component Controller
 * @param {Object} HoogyService 
 * @param {Object} AuthService 
 * @param {Object} NotificationService 
 * @param {Object} Angularytics 
 */
function StoreConfirmationController($state, $timeout, HoogyService, AuthService, NotificationService, UIService, StateService, ActionCreator, Angularytics) {


    var vm = this;
    
    /**
     * @name $onInit 
     */
    this.$onInit = function(){
        this.model = {}; //initialization
        this.screen = UIService.getStoreConfirmationScreen({component: 'store:confirmation'});//initialization
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        this.init($state.params);
    };
    
    /**
     * @name subscribe - Subscribes for Model Updates.
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = AuthService.getModel();
            vm.screen = UIService.getStoreConfirmationScreen(vm.screen);
            vm.model.notifications = NotificationService.getModel();                                                 
        });
    };

    /**
     * @name _handleException - used for Signup Request
     * @param {Object} error 
     * @return {Object} error 
     */
    this._handleException = function (error) {
        vm.screen.waiting = false;
        var notice = NotificationService.formatConfirmErrorMessage(error);
        Angularytics.trackEvent('Store Confirmation Error', notice.message);
        NotificationService.report(notice);             
        return error;
    };

    /**
     * @name _signupRequestHandler - handles signup response.
     * @param {Object} response
     * @return {Object} response
     */
    this._signupRequestHandler = function (response) {
        var message = response.data || response || {};
        message = message.message || message;
        message = NotificationService.formatConfirmSuccessMessage(message);
        Angularytics.trackEvent('Store Confirmation', message.message);
        NotificationService.notify(message);
        $timeout(function () { 
            $state.go('settings.profile'); 
        }, 2000);
        return response;
    };

    /**
     * @name init - Fetches invitation details from /api/confirmation/:key
     * @param  {String} params.key - invitation key
     * @return {Promise} promise
     */
    this.init = function (options) {
        var params = options || $state.params;
        if (params && !params.invitation && params.key) {
            params.invitation = params.key;
        }
        if (!params || !(params.key || params.invitation)) {
            throw 'Confirmation initializer should have token key';
        }
        Angularytics.trackEvent('Store Invitation', 'Initialized');
        return HoogyService.confirmation(params).then(function (response) {
            vm.model = HoogyService.invitation;
            Angularytics.trackEvent('Store Invitation', 'Success');
            return response;
        }).catch(function (response) {
            var data = HoogyService.invitationError;
            if (!data.title) { data.title = 'Invitation Confirmation'; }
            Angularytics.trackEvent('Store Invitation Error', 'Failed');
            NotificationService.notify(data);
            return response;
        });
    };


    /**
     * Save function will be used to send data back to server.
     * @param {Form} form - validates and saves the form
     */
    this.save = function (form) {
        if (!form || !form.$valid || form.$invalid) {
            NotificationService.notify(NotificationService.formatConfirmErrorMessage());
            throw 'Invitation confirmation form should be available and valid';
        }
        if (AuthService.isAuthenticated()) {
            throw 'Confirmation not allowed on authenticated people';
        }
        if (!this.model.email) { throw 'Email missing exception'; }

        Angularytics.trackEvent('Store Confirmation', 'Initialized');
        return AuthService.signup(this.model).then(this._signupRequestHandler).catch(this._handleException);
    };


    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };

}
StoreConfirmationController.$inject = ['$state', '$timeout', 'HoogyService', 'AuthService', 'NotificationService', 'UIService', 'StateService', 'ActionCreator', 'Angularytics'];
angular.module('hoogy').component('confirmation', {
    controllerAs: 'vm',
    controller: StoreConfirmationController,
    templateUrl: 'js/store/view/confirmation.html'
});
