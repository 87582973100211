/*global _:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/*global angular:true*/
/*global window:true*/
/**
 * PubSub
 * @link http://eburley.github.io/2013/01/31/angularjs-watch-pub-sub-best-practices.html
 */
function NotificationService($log, StateService, ActionCreator) {
    'use strict';
    (function () {
        if (window && typeof window.onerror === 'object') {
            window.onerror = function (msg, url, line, column, error) {
                $log.log('Catching the error', msg, url, line, column, error);
                return false;
            };
        }
    })();

    var context = false;
    var store = StateService.getState();
    var model = {
        body: '',
        type: '',
        category: 'notice',
        priority: -1,
        title: 'default',
        description: 'default',
        details: 'default',
        created: new Date(),
        context: 'application',
        actions: [{ title: '', url: 'workspace' }]
    };

    var SUCCESS = 200;
    var FAILURE = 500;

    /**
     * This event will be broadcasted, if user tries to use a secured area,
     * And user is not authorized.
     * @type {string}
     */
    var UNAUTHORIZED = 'unauthorized';
    var SIGNIN_SUCCEED = 'authentication::success';
    var SIGNUP_SUCCEED = 'authentication::success';
    var AUTHENTICATION_SUCCEED = 'authentication::success';
    var LOGOUT_SUCCEED = 'lougout::success';

    //contexts
    var PROVIDER_CONTEXT = 'provider:context';
    var ORDER_CONTEXT = 'order:context';
    var CUSTOMER_CONTEXT = 'customer:context';
    var SYSTEM_CONTEXT = 'system:context';
    var SYSTEM_ERROR_CONTEXT = 'system:error:context';
    var SYSTEM_FE_CONTEXT = 'system:fe:context';
    var SYSTEM_BE_CONTEXT = 'system:be:context';
    var CUSTOMER_SETTINGS_CONTEXT = 'customer:settings:context';


    var service = {
        SUCCESS: SUCCESS,
        FAILURE: FAILURE,
        UNAUTHORIZED: UNAUTHORIZED,
        SIGNIN_SUCCEED: SIGNIN_SUCCEED,
        SIGNUP_SUCCEED: SIGNUP_SUCCEED,
        AUTHENTICATION_SUCCEED: AUTHENTICATION_SUCCEED,
        LOGOUT_SUCCEED: LOGOUT_SUCCEED,
        //Notification Context Constants
        PROVIDER_CONTEXT: PROVIDER_CONTEXT,
        ORDER_CONTEXT: ORDER_CONTEXT,
        CUSTOMER_CONTEXT: CUSTOMER_CONTEXT,
        SYSTEM_CONTEXT: SYSTEM_CONTEXT,
        SYSTEM_ERROR_CONTEXT: SYSTEM_ERROR_CONTEXT,
        SYSTEM_FE_CONTEXT: SYSTEM_FE_CONTEXT,
        SYSTEM_BE_CONTEXT: SYSTEM_BE_CONTEXT,
        CUSTOMER_SETTINGS_CONTEXT: CUSTOMER_SETTINGS_CONTEXT,

        setContext: setContext,
        unauthorized: unauthorized,


        //abstraction of store operations
        add: add,
        report: add,
        reset: reset,
        notify: add,
        remove: remove,
        getNotification: getNotification,
        getNotifications: getNotifications,
        formatMessage: createNotificationMessage,

        //operations on a store
        getModel: getModel, 
        
        //Utility to Format New Messages. 
        messageExtractor: messageExtractor,    
        createNotification: createNotification,
        createNotificationMessage: createNotificationMessage,
        getNotificationByContext: getNotificationByContext,

        //notifications about authentication
        formatSigninMessage: formatSigninMessage,
        formatSignupMessage: formatSignupMessage,
        formatRecoverMessage: formatRecoverMessage,
        formatAuthenticateMessage: formatAuthenticateMessage,
        formatAuthFailureMessage: formatAuthFailureMessage,

        formatHooggyComponentFailure: formatHooggyComponentFailure,
        formatCreateAccountSuccessMessage: formatCreateAccountSuccessMessage,
        formatCreateAccountFailureMessage: formatCreateAccountFailureMessage,

        formatConfirmErrorMessage: formatConfirmErrorMessage,
        formatConfirmSuccessMessage: formatConfirmSuccessMessage,

        formatUnlinkSettingsSuccessMessage: formatUnlinkSettingsSuccessMessage,
        formatUnlinkSettingsFailureMessage: formatUnlinkSettingsFailureMessage,

        formatSettingsSuccessMessage: formatSettingsSuccessMessage,
        formatSettingsFailureMessage: formatSettingsFailureMessage,

        formatLinkSettingsSuccessMessage: formatLinkSettingsSuccessMessage,
        formatLinkSettingsFailureMessage: formatLinkSettingsFailureMessage,

        formatNotifySuccessMessage: formatNotifySuccessMessage,

        formatWorkspaceExceptionMessage: formatWorkspaceExceptionMessage,
        formatOrderQueryErrorMessage: formatOrderQueryErrorMessage, 

        formatPeersRequestException: formatPeersRequestException,
        
        formatReviewsFailureMessage: formatReviewsFailureMessage,
        formatReviewFailureMessage: formatReviewFailureMessage,
        formatTalkWidgetFailureMessage: formatTalkWidgetFailureMessage,

        formatCheckoutSuccessMessage: formatCheckoutSuccessMessage,
        formatCheckoutFailureMessage: formatCheckoutFailureMessage,

        formatStoreFailureMessage: formatStoreFailureMessage, 
        formatCardsWidgetFailureMessage: formatCardsWidgetFailureMessage
        
    };

    return service;


    /**
     * @name getModel - Getting Model of Notification
     * @param {String} which 
     * @returns model - returns current model + other things. 
     */
    function getModel(which){
        var notification = StateService.getState().notification;
        return Object.assign({},model, notification);
    }


    /**
     * @name messageExtractor - Transforms Object's Values into Human Readable Messages  
     * @param {Object<Response|Error>} params  
     */
    function messageExtractor(params){
        if(!params || _.isEmpty(params)) return false; // returns false 

        var obj = params.data || params.message || params;
        if(typeof obj === 'string') obj = {message: obj};
        var values = Object.values(obj) || [];
        return (values.map(function(item){
            return typeof item === 'string' ? item.substring(0, 140) : '';
        }) || []).join(' ');
    }

    /**
     * @name formatTalkWidgetFailureMessage - Format Error Message
     * @param {Object<Error>} error 
     */
    function formatTalkWidgetFailureMessage(error){
        var custom = service.messageExtractor(error);
        return {
            type: FAILURE,
            title: 'Messenger - Talk Widget',
            message: custom || 'Failed to Fetch Message Details, due to unknown reasons - Server may be down, or you don\'t have access to these messages'
        };
    }
    

    /**
     * @name formatReviewsFailureMessage 
     * @param {Object<Error>} error 
     */
    function formatReviewFailureMessage(error){
        var custom = service.messageExtractor(error);
        return {
            type: FAILURE,
            title: 'Review List',
            message: custom || 'Failed to Fetch Your Review, due to unknown reasons - Server may be down, or you don\'t have access to this Review'
        };
    }
        

    /**
     * @name formatReviewsFailureMessage 
     * @param {Object<Error>} error 
     */
    function formatReviewsFailureMessage(error){
        var custom = service.messageExtractor(error);
        return {
            type: FAILURE,
            title: 'Reviews List',
            message: custom || 'Failed to Fetch Your Reviews, due to unknown reasons - Server may be down, or you haven\'t added Reviews Yet'
        };
    }
    

    /**
     * @name formatPeersRequestException 
     * @param {Object<Error>} error 
     */
    function formatPeersRequestException(error){
        var custom = service.messageExtractor(error);
        return {
            type: FAILURE,
            title: 'Hooggy Peers Search Error',
            message: custom || 'Failed to Fetch Your Friends, due to unknown reasons - Server may be down, or you haven\'t added Friends Yet'
        };
    }
    
    /**
     * @uses messageExtractor 
     * @name formatHooggyComponentFailure used to notify about failure to fetch cards
     * @param {String} custom 
     */
    function formatHooggyComponentFailure(response){
        var custom = service.messageExtractor(response);
        return {
            type: FAILURE,
            title: 'Hooggy Initialization Error',
            message: custom || 'Failed to Fetch Initialization Items, due to unknown reasons - Server may be down, or you returned incorrect values'
        };
    }

    /**
     * @name formatCardsWidgetFailureMessage used to notify about failure to fetch cards
     * @param {String} custom 
     */
    function formatCardsWidgetFailureMessage(response){
        var custom = service.messageExtractor(response);
        return {
            type: FAILURE,
            title: 'Cards Widget Fetch Cards Exception',
            message: custom || 'Failed to Fetch Cards, due to unknown reasons - Server may be down, or you haven\'t added cards yet'
        };
    }


    /**
     * @name formatStoreFailureMessage
     * @param {String} custom 
     */
    function formatStoreFailureMessage(response){
        var custom = service.messageExtractor(response);
        return {
            type: FAILURE,
            title: 'Store Item Adder Exception',
            message: custom || 'Adding Item Failed, more more details available for this Exception.'
        };
    }

    /**
     * @name formatCheckoutSuccessMessage
     * @param {Object<Response>} response 
     */
    function formatCheckoutSuccessMessage(response){
        var custom = service.messageExtractor(response);        
        return {
            type: SUCCESS,
            title: 'Item Rental - Checkout process',
            message: custom || 'Step executed successfully.'
        };
    }

    /**
     * @name formatCheckoutFailureMessage 
     * @param {Object<Response>} response 
     */
    function formatCheckoutFailureMessage(response){
        var custom = service.messageExtractor(response);
        return {
            type: FAILURE,
            title: 'Item Rental - Checkout process',
            message: custom || 'Execution of step failed.'
        };
    }

    /**
     * @name formatWorkspaceExceptionMessage- Formats Workspace Message Exception
     * @return {Type, Title, Message}
     * @param {String} custom 
     */
    function formatWorkspaceExceptionMessage(response){
        var custom = service.messageExtractor(response);
        return {
            type: FAILURE,
            title: 'Workspace Error',
            message: custom || 'Something embarrasing happened, but I have no idea what it is :-( '
        };
    }


    /**
     * @name formatOrderQueryErrorMessage - Formats Order Message.
     * @param {String} custom 
     * @return {Type, Title, Message}
     */
    function formatOrderQueryErrorMessage(response){
        var custom = service.messageExtractor(response);
        return {
            type: FAILURE,
            title: 'Order ID is required',
            message: custom || 'Order ID is required'
        };
    }

    /**
     * @param {String} custom - Success Message Parameter.
     * @name formatNotifySuccessMessage - formats notify message on success
     * @return {Type, Title, Message}
     */
    function formatNotifySuccessMessage(response) {
        var custom = service.messageExtractor(response);
        return {
            type: SUCCESS,
            title: 'Invite friends',
            message: custom || 'Message details not Available. But everything worked :-) '
        };
    }

    /**
     * @name formatSettingsSuccessMessage - Formats Settings Success Message
     * @param {String} custom 
     * @return {Title, Type, Message}
     */
    function formatSettingsSuccessMessage(response) {
        var custom = service.messageExtractor(response);
        return {
            title: 'Updating personal settings',
            message: ['You have successfully updated settings', custom || ''].join(''),
            type: SUCCESS
        };
    }

    /**
     * @name formatSettingsFailureMessage - Formats Settings Failure Message
     * @param {String} custom 
     * @return {Title, Type, Message}
     */
    function formatSettingsFailureMessage(response) {
        var custom = service.messageExtractor(response);
        return {
            title: 'Updating personal settings',
            message: ['Updating your personal settings failed', custom || ''].join(''),
            type: FAILURE
        };
    }

    /**
     * @param {String} custom 
     * @return {Title, Type, Message}
     */
    function formatUnlinkSettingsSuccessMessage(response) {
        var custom = service.messageExtractor(response);
        return {
            title: 'Profile Unlink Account',
            message: ['Profile Unlink Account Failure', custom || ''].join(''),
            type: SUCCESS
        };
    }

    /**
     * 
     * @param {String} custom 
     * @return {Title, Type, Message}
     */
    function formatUnlinkSettingsFailureMessage(response) {
        var custom = service.messageExtractor(response);
        return {
            title: 'Profile Unlink Account',
            message: ['You have successfully unlinked account ', custom || ''].join(''),
            type: FAILURE
        };
    }
    /**
     * @param {String} custom 
     * @return {Title, Type, Message}
     */
    function formatLinkSettingsSuccessMessage(response) {
        var custom = service.messageExtractor(response);
        return {
            title: 'Profile Link Account',
            message: ['You have successfully linked account ', custom || ''].join(''),
            type: SUCCESS
        };
    }

    /**
     * 
     * @param {String} custom 
     * @return {Title, Type, Message}
     */
    function formatLinkSettingsFailureMessage(response) {
        var custom = service.messageExtractor(response);
        return {
            title: 'Profile Link Account',
            message: ['Profile Link Account Failed', custom || ''].join(''),
            type: FAILURE
        };
    }


    /**
     * @return {Title, Type, Message}
     * @name formatCreateAccountFailureMessage
     * @param {String} custom 
     */
    function formatCreateAccountFailureMessage(response) {
        var custom = service.messageExtractor(response);
        return {
            type: FAILURE,
            title: 'Wallet statistics',
            message: custom || 'Wallet Request Exception'
        };
    }


    /**
     * @name formatCreateAccountSuccessMessage  
     * @return {Type, Message}
     * @param {String} custom 
     */
    function formatCreateAccountSuccessMessage(response) {
        var custom = service.messageExtractor(response);
        return {
            type: SUCCESS,
            title: 'Wallet statistics',
            message: custom || 'Identity Verification passed.'
        }
    }


    /**
     * @param {String} custom - custom message, replaces the default message. 
     * @name formatConfirmSuccessMessage - creates message model used with Confirmation Component. 
     */
    function formatConfirmSuccessMessage(response) {
        var custom = service.messageExtractor(response);
        return {
            title: 'Invitation confirmation',
            type: SUCCESS,
            message: custom || 'Signup completed. Message not available.'
        };
    }

    /**
     * @param {String} custom - custom message, replaces the default message. 
     * @name formatConfirmErrorMessage - creates message model used with Conform Component.
     */
    function formatConfirmErrorMessage(response) {
        var custom = service.messageExtractor(response);
        return {
            title: 'Invitation confirmation',
            type: FAILURE,
            message: custom || 'Invalid form - cannot be saved'
        };
    }


    /**
     * @name formatAuthFailureMessage - Format Authentication Failure message.
     * @param {Object|String} params 
     * @return {Object} {title, message, type}
     */
    function formatAuthFailureMessage(params) {
        var _message = 'Something went wrong';
        var custom = service.messageExtractor(params);
        return {
            title: 'Authentication',
            message: custom || _message,
            type: FAILURE
        };
    }

    /**
     * @name formatSignupMessage - Function that formats signup response message, 
     * And creates a notify-able message 
     * @param {Object<Response>} response 
     */
    function formatSignupMessage(response) {
        var custom = service.messageExtractor(response);
        return {
            message: custom,
            type: SUCCESS
        };
    };



    /**
     * @name formatAuthenticateMessage - Function to format notification messages. 
     */
    function formatAuthenticateMessage(response) {
        var custom = service.messageExtractor(response);
        return {
            message: custom || 'Logged in',
            type: SUCCESS
        };
    }
    /**
     * Format Message for Signin Success 
     */
    function formatSigninMessage(response) {
        var custom = service.messageExtractor(response);
        return {
            message: custom || 'Logged in',
            type: SUCCESS
        };
    }
    /**
     * @param {Object<Response>} response
     * @name formatRecoverMessage - Format Message for Recover Success 
     */
    function formatRecoverMessage(response) {
        var custom = service.messageExtractor(response);
        return {
            message: custom,
            type: SUCCESS
        };
    }

    /**
     * multipurpose message passing agent
     * @use NotificationService#report
     * @param {Object} add - adds notification after formatting the message. 
     * NotificationService::report is more clear than NotificationService::add
     * @param  {Object} notice 
     * @return {Object}        
     */
    function add(notice) {
        var payload;
        if (notice && notice.conversation) {
            notice.id = notice.conversation._id || notice.conversation.id || notice.conversation || false;
            payload = createNotificationMessage(notice);
        } else {
            payload = createNotification(notice);
        }
        StateService.dispatch(ActionCreator.addNotification(payload));
        $log.log('NotificationService::add [hasContext, options]');
    }



    /**
     * @name createNotification - creates notification 
     * @param  {Object} params 
     * @return {Object}        
     */
    function createNotification(params) {
        var title = params.title || params.text || 'Oops! something went wrong.';
        var description = params.description || params.message || params.text || params;
        if(_.isString(params)) { 
            title = description = params;
        }
        var type = params.type || 'add:notification';
        var category = params.category || 'notice';
        var context = params.context || 'application';
        var id = params.id || [type, context, category].join(':');
        var actions = params.actions || [{ title: 'View All', url: 'workspace.notifications' }];
        return Object.assign({},{
            id: params.id || params._id || id,
            type: type,
            priority: -1,
            title: title,
            category: category,
            text: description,
            description: description,
            created: params.created || new Date(),
            details: params.details || 'default',
            context: context,
            actions: actions
        });
    }
    /**
     * @name createNotificationMessage - Creates notifiation for a message
     * @param  {Object} params - Message Parameter
     * @throws {Error<string>} Error Expediter Not Found
     * @return {Object}  
     */
    function createNotificationMessage(params) {
        if(!params){ throw 'Message Parameter Not Found @createNotificationMessage'; }
        if(!params.expediter && !(params.expediter||{})._id && !(params.expediter||{}).id){
            throw 'Expediter Not Found';
        }
        
        var title = params.title || params.text || 'You have message';
        if (params.user && (params.user.name || params.user.first)) {
            var name = (params.user.name || params.user.first);
            title = [name, 'sent you a message'].join(' ');
        }
        var description = params.description|| params.message  || params.text || params;
        if(_.isString(params)) { 
            description = params;
        }
        var context = params.context || 'message';
        var type = params.type || 'add:notification';
        var category = params.category || 'message';
        var id = params._id || params.id || [type, category, context].join(':');
        var actions = params.actions || [{
            title: 'Reply', url: {
                text: 'messenger.talk',
                id: params.expediter._id || params.expediter.id || params.expediter
            }
        }];
        return Object.assign({}, {
            id: id,
            type: type,
            priority: 1,
            title: title,
            category: category,
            expediter: params.expediter || {},
            created: params.created || new Date(),
            details: params.text || params.message || "",
            description: description,
            context: context,
            actions: actions
        });
    }

    /**
     * @name reset - removes all messages from data store
     * @uses StateService.dispatch
     * @uses ActionCrator.removeAllNotifications
     */
    function reset(){
        StateService.dispatch(ActionCreator.removeAllNotifications());
    }

    /**
     * @name remove - Removing Note from Notifications. 
     * @param {Object|String} notice 
     */
    function remove(notice) {
        var payload; 
        var index;
        if(['string', 'number'].indexOf(typeof notice) >= 0){
            index = [notice.type, notice.category, notice.context].join(':').trim();//make it a string.
            payload = {index: index, id: index, _id: index};
        }else if(notice && typeof notice === 'object'){
            //more processing shoult be added to initialize the payload
            payload = notice;
        } 
        if(!notice || !payload) throw '@NotificationService:remove requires Index';
        StateService.dispatch(ActionCreator.removeNotification(payload));
    }

    /**
     * @name getNotificationByContext - Finds A Notification Given The Context. 
     * @param {Object<Context>} context 
     * @returns {Object<Notification>}
     */
    function getNotificationByContext(context){
        if(_.isEmpty(context)) throw "Notification Context is required @getNotificationByContext";
        if(!context.type) throw '@getNotificationByContext requires Notification Type';
        if(!context.category) throw '@getNotificationByContext requires Notification Category';
        if(!context.context) throw '@getNotificationByContext requires Context'; 
        var hash = Object.values(_.pick(context, 'type', 'category', 'context')).join(':').trim();
        var entities = Object.values(StateService.getState().notification.entities || {});
        var _hash;
        return _.find(entities, function(entity){
            _hash = Object.values(_.pick(entity, 'type', 'category', 'context')).join(':').trim();
            return _hash === hash;
        }) || {};
    }

    /**
     * @name getNotification - search a particular Notification in the store 
     * @param {Object} params 
     */
    function getNotification(params) {
        if(!params || _.isEmpty(params)) throw "Notification Parameters required @getNotification";
        if(!params.id) throw 'Notification ID is required @getNotification';
        var notifications = StateService.getState().notification.entities || {};
        return _.find(notifications, function (notice) {
            return notice.id === params.id;
        }) || {};
    }


    


    /**
     * @name getNotifications - Returns Entities of Notifications.
     */
    function getNotifications() {
        return StateService.getState().notification.entities || [];
    }

    /**
     *
     * @deprecated - this function was replaced by Injection of $rootScope
     * @param  {Object} ctxt 
     * @return {Object}      
     */
    function setContext(ctxt) {
        context = ctxt;
    }

    

    /**
     * @name unauthorized - Used to Notify All Components about Auth Expiration
     */
    function unauthorized() {
        var payload = {token: null};
        return StateService.dispatch(ActionCreator.removeAuth(payload));
    }

}
NotificationService.$inject = ['$log', 'StateService',  'ActionCreator'];
angular.module('hoogy').service('NotificationService', NotificationService);