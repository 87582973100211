'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * Utility directive used to format a Date of birth.
 */
function Dob() {
    function link(scope, element, attrs, ctrl) {
        var date;
        element.on('keyup', function () {
            scope.$apply(function () {
                date = moment($(element).val(), 'DD-MM-YYYY');
                ctrl.$setValidity('dob', date.isValid() && $(element).val().length === 10);
            });
        });

        /**
         * Cleanup - try to destroy memory leak substible 
         */
        function cleanup() {
            if (angular.isElement(this)) { return scope.$destroy(); }
            if (typeof angular.element(element).off === 'function') { angular.element(element).off(); }
            if (element && typeof $(element).off === 'function') {
                $(element).off();
            }
            if (element && typeof element.off === 'function') { element.off(); }
            date = null;
        }
        scope.$on('$destroy', cleanup);
        element.on('$destroy', cleanup);
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        link: link
    };

}
angular.module('hoogy').directive('dob', Dob);
