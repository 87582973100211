'use strict';

/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/

/**
 * @todo --- Salvage this Component for ItemEditor Top Level Component used with Inventory 
 * @name HoogyEditorController 
 */
function EditorWidgetController($log, $location, $state, HoogyService, AuthService, StuffService, StateService, ActionCreator, UIService, Angularytics, NotificationService) {

    var vm = this;
    this.model = {};
    //@todo check if Needed or remove it.
    this.status = {};
    
    //@todo - Check if needed or remove it
    this.gravatar = HoogyService.gravatar;
    
    /**
     * @name $onInit - Initialization of Editor.
     */
    this.$onInit = function () {
        if(!this.screen) throw '@EditorWidgetController:$onInit requires Screen Object';
        this.model.item = Object.assign({}, StuffService.getModel('item'));
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.model = StuffService.getModel();
        this.unsubscribe = this.subscribe();
        //can be used in create/update modes
        if($state.params && $state.params.id){
            this.init($state.params);
        } 
    };

    /**
     * @name init initialization of editor.
     * @name init - this function is called to initialize form element.
     */
    this.init = function (options) {
        var params = options || $state.params;
        Angularytics.trackEvent('Store Item Adder Initialization', +1);
        if (!this.isAuthenticated()) {
            Angularytics.trackEvent('Store Item Adder Init - unauthorized', +1);
            return false;
        }
        Angularytics.trackEvent('Store Item Adder - Edit', +1);
        this.screen.waiting = true;
        return HoogyService.getItem(params.id).then(function (response) {
                vm.screen.waiting = false;
                StateService.dispatch(ActionCreator.addStuff(HoogyService.inventoryItem));
                Angularytics.trackEvent('Store Item Adder - Edit', vm.model.item.title);
                return response;
            }).catch(this._handleException);
    };

    /**
     * @todo Cherry pick which models have to be updated and ones that have to stay the same way.
     * @name subscribe - Subscribes for Model Updates.
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false : StateService.subscribe(function () {
            vm.model.item = StuffService.getModel('item');
            vm.screen = UIService.getEditStuffScreen(vm.screen);
            vm.model.notification = {}; //@todo check notification to be added in this place
            vm.model.notifications = NotificationService.getModel();
        });
    };

    /**
     * @name isAuthenticated 
     */
    this.isAuthenticated = function () {
        return AuthService.isAuthenticated(true);
    };

    /**
     * @todo --- use StuffService.upload().then(initialize/replace item)
     * This function handles data dropped in an area defined by droppableDirective
     * This section couples, and limits the overall usability of this controller.
     * it is a quick fix ( huh ), but should be refactored
     */
    this.payload = {};
    this.drop = this.select = function (payload) {
        var owner = HoogyService.getUserId();
        var data = Object.assign({}, payload, {owner: owner});
        $log.log('Drop::Select [data, vm.payload]', data, vm.payload);
        if (_.isEmpty(payload)) {
            throw '@EditStuff::drop Requires File to be Dropped.';
        }

        if (vm.model && vm.model.item && vm.model.item._id) {
            data.itemid = vm.model.item._id || vm.model.item.id;
        }else if($state.params && $state.params.id){
            data.itemid = $state.params.id;
        }
        Angularytics.trackEvent('Store Item Adder -Select/Drop', +1);

        vm.screen.waiting = true;
        var notice = {
            message: 'File uploaded!',
            type: NotificationService.SUCCESS
        };
        return HoogyService.sync(data).then(function (response) {
            vm.screen.waiting = false;
            var photo = data.payload;
            var inventory = HoogyService.inventoryItem;//initialized after sync return
            var item = Object.assign({},{photo: photo}, inventory);
            StateService.dispatch(ActionCreator.addStuff(item));
            NotificationService.notify(notice);
            Angularytics.trackEvent('Store Item Adder - Uploaded Image', +1);
            return response;
        }).catch(vm._handleException);
    };


    /**
     * @name save - Editing Stuff 
     * @throws ItemMissingException
     * @throws UserNotAuthenticatedException
     * @returns {Object<Promise>}
     */
    this.save = function (item) {
        if (!item || _.isEmpty(item)) {
            throw 'EditStuff::save - Item should not be empty';
        }

        if (!this.isAuthenticated()) {
            Angularytics.trackEvent('Store Item Adder - Unauthorized', +1);
            throw 'You should be authenticated to save an item';
        }

        //stop double clicks while waiting for response
        if (this.screen.waiting) {
            Angularytics.trackEvent('Store Item Adder - Save Invalid', +1);
            $log.log('Detected another saving operation');
            return false;
        }

        this.screen.waiting = true;

        Angularytics.trackEvent('Store Item Adder - Save', +1);
        return HoogyService.saveItem(item).then(function (response) {
            vm.screen.waiting = false;
            vm.model.item = HoogyService.inventoryItem;
            NotificationService.notify({
                message: 'Item created.'
            });
            StateService.dispatch(ActionCreator.addStuff(HoogyService.inventoryItem));
            Angularytics.trackEvent('Store Item Adder - Save Success', vm.model.item.title);
            $state.go('workspace.notify', {
                itemid: vm.model.item._id
            });
            return response;
        }).catch(this._handleException);
    };



    /**
     * @deprecated - moved to AddStuffController(StuffEditor Component).
     * @name removItem - function used to delete an item.
     * @required {Component} HoogyItem 
     * This function removes cancels, and deletes an item in current edit mode.
     * It Works with model.equipment instead of model.item
     */
    this.removeItem = function () {
        Angularytics.trackEvent('Store Item Adder', +1);
        return HoogyService.removeItem(vm.model.item).then(function (response) {
            var msg = {
                message: response.message || 'Item removal next!'
            };
            NotificationService.notify(msg);
            Angularytics.trackEvent('Store Item Adder - Remove', +1);
            return response;
        });
    };


    /**
     * @name doneUpdatingPricing - Callback used by Price editor
     * @param  {Object<Number>} pricing 
     * @return {void}
     */
    this.doneUpdatingPricing = function (pricing) {
        vm.model.item.pricing = pricing;
        StateService.dispatch(ActionCreator.updateStuff(vm.model.item));
    };



    /**
     * @name _handleException  - Utility to handle exceptions from server. 
     * @param {Object<Error>} error 
     * @return {Promise<Error>}
     */
    this._handleException = function (error) {
        vm.screen.waiting = false;
        var notice = NotificationService.formatStoreFailureMessage(error);
        NotificationService.report(notice);
        Angularytics.trackEvent('Store Item Adder Exception', notice.message);
        return error;
    };


    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };


}
EditorWidgetController.$inject = ['$log','$location', '$state', 'HoogyService', 'AuthService', 'StuffService', 'StateService', 'ActionCreator', 'UIService', 'Angularytics', 'NotificationService'];
/**
 * This directive will be used to edit an item.
 * It will be used by users who whish to edit some items in the inventory.
 * It replaces pop-ups while editing an item.
 */
angular.module('hoogy').component('editorWidget', {
    controllerAs: 'vm',
    controller: EditorWidgetController,
    templateUrl: 'js/common/components/view/editorwidget.html',
    bindings: {
        next: '&?',
        status: '=', 
        screen :'='
    }
});