'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * Usage
 * 	- input having this directive will be set to  uppercase as user types
 * 	@link http://stackoverflow.com/a/16388643/132610
 * 	@link http://stackoverflow.com/a/25047323/132610
 * 	@link http://stackoverflow.com/a/15253892/132610
 */

function Uppercase() {
    function link(scope, element, attrs, ctrl) {
        /**
         * Capitalization is used to uppercase anything typed in associated input.
         */
        function capitalize(inputValue) {
            if (inputValue === undefined) { inputValue = ''; }
            var capitalized = inputValue.toUpperCase();
            if (capitalized !== inputValue) {
                ctrl.$setViewValue(capitalized);
                ctrl.$render();
            }
            return capitalized;
        }

        ctrl.$parsers.push(capitalize);
        capitalize(scope[attrs.ngModel]);  // capitalize initial value

        /**
         * Cleanup - try to destroy memory leak substible 
         */
        function cleanup() {
            if (angular.isElement(this)) { return scope.$destroy(); }
            if (element && typeof element.off === 'function') {
                element.off();
            }
              if($(element) && typeof $(element).off === 'function'){
                $(element).off();
            }
        }
        scope.$on('$destroy', cleanup);
        element.on('$destroy', cleanup);

    }

    return {
        restrict: 'A',
        require: 'ngModel',
        link: link
    };

}
angular.module('hoogy').directive('uppercase', Uppercase);
