'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * @todo - remove scope dependency. 
 * @deprecated - this directive will be replaced with notification
 * @type {Object}
 */
function HoogyAnnounceController(HoogyService, UIService, StateService, ActionCreator,  NotificationService) {
    var vm = this;

    /**
     * @name $onInit 
     */
    this.$onInit = function(){
        this.screen = UIService.getAnnounceScreen({component: 'announce'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));        
        this.init(); 
    };
    /**
     * @name init - Initialization 
     */
    this.init = function() {
        if (!this.model) { 
            throw 'Announce requires a model to be initialized'; 
        }
    };


    /**
     * Sends announcement and closes the alert box.
     * @requires form - validated form. 
     * @name save - creating a new announcement. 
     */
    this.save = function (form) {
        if(!form || form.$invalid || !form.$valid){ throw 'Valid form is required'; }
        this.model.announcer = HoogyService.getUserId();
        if (vm.model.announcement && _.size(vm.model.announcement) > 0) {
            HoogyService.announce(vm.model).then(function (response) {
                vm.screen.save = false;
                vm.screen.edit = false;
                                         
                NotificationService.notify(response.data || response);
            });
        }
    };
    /**
     * @name cancel - cancelling a new edit announcement.
     */
    this.cancel = function (form) {
        if(form && typeof form.$setPristine === 'function'){
            form.$setPristine();
        }
        this.screen.save = false;
        this.screen.edit = false;
        this.model.announcement = '';
    };

    /**
     * @name enable - starting new announcement from search text. 
     */
    this.enable = function () {
        this.screen.save = true;
        this.screen.edit = true;
        if (this.model.search && this.model.search.text) {
            this.model.announcement = this.model.search.text;
        }
        
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
       
    };

}
HoogyAnnounceController.$inject = ['HoogyService', 'UIService', 'StateService', 'ActionCreator','NotificationService'];
angular.module('hoogy').component('hoogyAnnounce', {
    controllerAs: 'vm',
    controller: HoogyAnnounceController,
    bindings: { model: '=' },
    templateUrl: 'js/common/components/view/announce.html'
});