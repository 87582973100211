'use strict';

function FacebookSettingsController(HoogyService, AuthService, UIService, PeopleService, NotificationService, StateService, ActionCreator, Angularytics) {
    
    var vm = this;
    
    /**
     * @name $onInit 
     */
    this.$onInit = function(){
        this.model = PeopleService.getMe();
        this.screen = UIService.getSettingsScreen({component: 'facebook:settings'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        this.init();
    };

    /**
     * @name subscribe - Subscribe to PeopleService
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = PeopleService.getMe();
            vm.model.notifications = NotificationService.getModel();                            
            vm.screen = UIService.getSettingsScreen(vm.screen);
        });
    };
    
    /**
     * @name init - initizalization of facebook component. 
     */
    this.init = function () {
        this.screen.FACEBOOK = 'facebook';
        this.screen.link =false; 
        this.screen.unlink =false; 
    };


    /**
    * @name link - Link third-party provider
    * @todo replace $auth with AutService::link + AuthService.unlink 
    */
    this.link = function (provider) {
        if(!provider) throw 'Link requires a Provider';
        this.screen.link = true;
        Angularytics.trackEvent(['Profile Link - Initialized - ', provider].join(''), +1);
        return AuthService.link(provider).then(this._settingsRequestCallback).catch(this._handleException);
    };

    /**
    * @name unlink - Unlink third-party provider.
    * @todo replace $auth with AutService::link + AuthService.unlink 
    */
    this.unlink = function (provider) {
        if(!provider) throw 'Unlink requires a Provider';
        this.screen.unlink = true;
        Angularytics.trackEvent('Profile Unlink - Initialized', +1);
        return AuthService.unlink(provider).then(this._settingsRequestCallback).catch(this._handleException);
    };
    

    /**
     * @name _handleException - Error handler
     * @param {Object<Response>} response
     * @param {Object<Status>} status 
     * @returns {Promise}
     */
    this._handleException = function (error) {
        vm.enableSave = false;
        vm.screen.waiting = false;
        var message = error.data || error || false;
        var notice = NotificationService.formatSettingsFailureMessage(message);
        if (vm.screen.unlink) {
            vm.screen.unlink = false;
            notice = NotificationService.formatUnlinkSettingsFailureMessage(error.data.message);
        }
        if (vm.screen.link) {
            vm.screen.link = false;
            notice = NotificationService.formatLinkSettingsFailureMessage(error.data.message);
        }
        NotificationService.report(notice);
                         
        Angularytics.trackEvent('Settings Exception ', notice.message);
        return error;
    };


    /**
     * @name _settingsRequestCallback - Private callback used by final promise
     * @param  {Object} response - Response for requests  
     * @return {Promise} 
     */
    this._settingsRequestCallback = function (response) {
        vm.screen.waiting = false;
        var message = response.message || response.data || response;
        var notice = NotificationService.formatSettingsSuccessMessage(message);
        if (vm.screen.unlink) {
            vm.screen.unlink = false;
            notice = NotificationService.formatUnlinkSettingsSuccessMessage(message);
        }
        if (vm.screen.link) {
            vm.screen.link = false;
            notice = NotificationService.formatLinkSettingsSuccessMessage(message);
        }
        NotificationService.notify(notice);
                         
        Angularytics.trackEvent(message, +1);
        vm.init();
        return response;
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}
FacebookSettingsController.$inject = ['HoogyService','AuthService', 'UIService', 'PeopleService', 'NotificationService', 'StateService', 'ActionCreator', 'Angularytics'];
angular.module('hoogy').component('facebookSettings', {
    controllerAs: 'vm',
    templateUrl: 'js/settings/view/facebook.html',
    controller: FacebookSettingsController
});