'use strict';
/**
 * @name GoogleSettingsController
 * @param {Object} AuthService 
 * @param {Object} HoogyService 
 * @param {Object} UIService 
 * @param {Object} PeopleService 
 * @param {Object} NotificationService 
 * @param {Object} Angularytics 
 */
function GoogleSettingsController(AuthService, HoogyService, UIService, PeopleService, NotificationService, StateService, ActionCreator, Angularytics) {
    
    var vm = this;
    
    /**
     * @name $onInit - Initialization Of Google Settings. 
     */
    this.$onInit = function(){
        
        this.model = PeopleService.getMe();
        this.screen = UIService.getSettingsScreen({component: 'google:settings', link: false, unlink: false});
        this.screen.GOOGLE = 'google';
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        this.init(); 
    };

    /**
     * @name subscribe - Subscribe to PeopleService
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = PeopleService.getMe();
            vm.model.notifications = NotificationService.getModel();                            
            vm.screen = UIService.getSettingsScreen(vm.screen);
        });
    };

    /**
     * @name init - Initialiazation
     */
    this.init = function () {
    };

   /**
    * @todo replace $auth with AutService::link + AuthService.unlink 
    * @name link - Link third-party provider
    */
    this.link = function (provider) {
        if(!provider) throw 'Link requires a Provider';
        this.screen.link = true;
        Angularytics.trackEvent(['Profile Link - Initialized - ', provider].join(''), +1);
        return AuthService.link(provider).then(this._settingsRequestCallback).catch(this._handleException);
    };

    /**
    * @todo replace $auth with AutService::link + AuthService.unlink 
    * @name unlink - Unlink third-party provider.
    */
    this.unlink = function (provider) {
        if(!provider) throw 'Unlink requires a Provider';
        this.screen.unlink = true;
        Angularytics.trackEvent('Profile Unlink - Initialized', +1);
        return AuthService.unlink(provider).then(this._settingsRequestCallback).catch(this._handleException);
    };

    /**
     * @name _handleException - Error handler
     * @param {Object<Response>} response
     * @param {Object<Status>} status 
     * @returns {Promise}
     */
    this._handleException = function (error) {
        vm.screen.waiting = false;
        var notice = NotificationService.formatSettingsFailureMessage(error);
        if (vm.screen.unlink) {
            vm.screen.unlink = false;
            notice = NotificationService.formatUnlinkSettingsFailureMessage(error);
        }
        if (vm.screen.link) {
            vm.screen.link = false;
            notice = NotificationService.formatLinkSettingsFailureMessage(error);
        }

        NotificationService.report(notice);
                         
        Angularytics.trackEvent('Settings Exception ', notice.message);
        return error;
    };


    /**
     * @name _settingsRequestCallback - Private callback used by final promise
     * @param  {Object} response - Response for requests  
     * @return {Promise} 
     */
     this._settingsRequestCallback = function (response) {
        vm.screen.waiting = false;
        var message = response.message || response.data || response;
        var notice = NotificationService.formatSettingsSuccessMessage(message);
        if (vm.screen.unlink) {
            vm.screen.unlink = false;
            notice = NotificationService.formatUnlinkSettingsSuccessMessage(message);
        }
        if (vm.screen.link) {
            vm.screen.link = false;
            notice = NotificationService.formatLinkSettingsSuccessMessage(message);
        }
        vm.init();
        NotificationService.notify(notice);
                         
        Angularytics.trackEvent(message, +1);
        return response;
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}
GoogleSettingsController.$inject = ['AuthService', 'HoogyService', 'UIService', 'PeopleService', 'NotificationService', 'StateService', 'ActionCreator', 'Angularytics'];
angular.module('hoogy').component('googleSettings', {
    controllerAs: 'vm',
    templateUrl: 'js/settings/view/google.html',
    controller: GoogleSettingsController
});