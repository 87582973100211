'use strict';
/**
 * @todo bring in here all logic from NotificationService here. 
 * @todo - decouple activity stream from Notifications.
 * @todo - updates+adding+removing+listing all are next here.
 * @todo - implement this feature in weekend.
 */ 
function ActivityService($http, $filter, $q, $log, HoogyService, StateService, ActionCreator) {
    /**
     * @type {Object}
     */
    var model = { messages: [], system: [], notifications: []};
    var service = {getModel: getModel};
   
    return service;

    
    /**
     * @todo --- Updated the  activity reducer with messages|notifications from other reduced stores.
     * @param {String|Optional} which - indicates a model to return
     * @name getModel - Helper to access to a specific model. 
     */
    function getModel(which) {
        var activity = StateService.getState().activity;
        var state = Object.assign({}, model, { 
            messages: activity.entities.messages || model.messages,
            system: activity.system || model.system,
            notifications: activity.entities.notifications || model.notifications
        });
        return state[which] || state;
    }

}
ActivityService.$inject = ['$http', '$filter', '$q', '$log', 'HoogyService', 'StateService', 'ActionCreator'];
angular.module('hoogy').factory('ActivityService', ActivityService);
