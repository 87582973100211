'use strict';

/**
 * @name PhotoSettingsController
 * @param {*} HoogyService 
 * @param {*} UIService 
 * @param {*} PeopleService 
 * @param {*} NotificationService 
 * @param {*} Angularytics 
 */
function PhotoSettingsController(HoogyService, UIService, PeopleService, NotificationService, StateService, ActionCreator, Angularytics) {
    var vm = this;
   
   /**
    * @name $onInit 
    */
    this.$onInit = function(){
        
        this.model = PeopleService.getMe();
        this.screen = UIService.getSettingsScreen({component: 'photo:settings', active: 'photo'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));        
        this.unsubscribe = this.subscribe();
        this.init(); 
    };
    
    /**
     * @name subscribe - Subscribe to PeopleService
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = PeopleService.getMe();
            vm.model.notifications = NotificationService.getModel();                            
            vm.screen = UIService.getSettingsScreen(vm.screen);
        });
    };
    
    /**
     * @name init 
     */
    this.init = function () {
         Angularytics.trackEvent('Settings Photo', +1);
    };

    /**
     * @name dropHandler
     * @name fileSelectorHandler
     */
    this.dropHandler = this.fileSelectorHandler = function (data) {
        if (!data) { return false; }
        this.enableSave = true;
        if (data && data.payload && _.size(data.payload) > 0) {
            this.model.photo = data;
        }
        Angularytics.trackEvent('Settings Image Update', +1);
    };

     /**
      * @name save 
     * @deprecated - Move all save settings into dependent components. 
     * @todo all settings will be sent to server using this function
     * @todo use HoogyService::setSettings([options], next);
     */
    this.save = function (form) {
        if(!form || form.$invalid || !form.$valid){
            throw 'Photo Settings Requires a Valid Form';
        }
        var settings = { action: form.$name, data: this.model.photo};
        if (!form || !form.$valid || form.$invalid) { throw 'Update Settings requires a valid form'; }
        return HoogyService.updateSettings(settings).then(this._settingsRequestCallback)
            .catch(this._handleException);
    };

    /**
     * @name reset - resets a form 
     * @throws ResetFormRequiresAForm 
     */
    this.reset = function(form){
        if(!form || typeof form.$setPristine !== 'function'){
            throw 'Reset Requires a Form.';
        }
        form.$setPristine();
    };

    /**
     * @param response 
     * @name _settingsRequestCallback - Callback on settings update success.
     * @return {Promise}
     */
    this._settingsRequestCallback = function (response) {
        vm.enableSave = false;
        var message = response.data || response || false;
        if(message && message.message){message = message.message; }
        var notice = NotificationService.formatSettingsSuccessMessage(message);
        Angularytics.trackEvent(notice.title, +1);
        NotificationService.notify(notice);
        if(typeof vm.next === 'function'){ vm.next(response);}
        return response;
    };

    /**
     * @name _handleException 
     * @param {Object<Error>}
     */
    this._handleException = function (error) {
        vm.enableSave = false;
        vm.screen.waiting = false;
        var notice = NotificationService.formatSettingsFailureMessage(error);
        NotificationService.report(notice);
        Angularytics.trackEvent('Settings Exception ', notice.message);           
        return error; 
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}
PhotoSettingsController.$inject = ['HoogyService', 'UIService', 'PeopleService', 'NotificationService', 'StateService', 'ActionCreator', 'Angularytics'];
angular.module('hoogy').component('photoSettings', {
    controllerAs: 'vm',
    templateUrl: 'js/settings/view/photo.html',
    controller: PhotoSettingsController
});