'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
//Intent directive
function IntentController(MessengerService, NotificationService, StateService) {
    var vm = this;

    /**
     * @name $onInit - Initialization Hook Handler 
     */
    this.$onInit = function () {
        this.model = MessengerService.getModel();
        this.unsubscribe = this.subscribe();
    };

    /**
     * @name subscribe - subscribes to message updates
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.model = MessengerService.getModel();
            vm.model.notifications = NotificationService.getModel(); 
        });
    };

    this.action = function (name) {
        //@todo check the action from intent name
        //call proper intent action, from actions register
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };
}
IntentController.$inject = ['MessengerService', 'NotificationService', 'StateService'];


/**
 * The controller for message directive. 
 * A message can be displayed in chat context.
 * A message can also be used in Unread messages(Notifications context). 
 * [HoogyMessageController description]
 */
function HoogyMessageController($timeout, MessengerService, NotificationService, StateService) {
    var vm = this;

    /**
     * @name $onInit 
     */
    this.$onInit = function () {
        this.durl = MessengerService.AVATAR;
        this.model = MessengerService.getModel();
        this.unsubscribe = this.subscribe();

        this.css = { active: false };
        $timeout.cancel(this.handler);
        this.handler = $timeout(function(){ vm.css.active = true; }, 50);
    };

    /**
     * @name subscribe - subscribes to message updates
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.model = MessengerService.getModel();
            vm.model.notifications = NotificationService.getModel(); 
        });
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
        this.css = { active: false };
        $timeout.cancel(this.handler);
        this.handler = null;
    };
}

/**
 * This Directive will be used to create certain events based on portions
 * 	of selected area of selected events.
 */
HoogyMessageController.$inject = ['$timeout', 'MessengerService', 'NotificationService', 'StateService'];


/**
 * The controller for message directive
 * [HoogyMessageController description]
 */
TalkWidgetController.$inject = ['$filter', '$state', '$log', 'HoogyService', 'MessengerService', 'StateService', 'ActionCreator', 'NotificationService'];

function TalkWidgetController($filter, $state, $log, HoogyService, MessengerService, StateService, ActionCreator, NotificationService) {
    var vm = this;


    /**
     * @name $onInit - Initialization function. 
     */
    this.$onInit = function () {
        this.model = MessengerService.getModel();
        this.screen.active = 'messenger';
        this.screen.waiting = false;
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        this.init();
    };
    
    /**
     * @name subscribe - subscribes to message updates
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.model = MessengerService.getModel();
            vm.model.notifications = NotificationService.getModel();             
        });
    };

    /**
     * @uses NotificationService:formatWidgetFailureMessage 
     * @param {Object<Error>} error - Error Handler Function.
     * @name _handleException - handling initialization exception.
     */
    this._handleException = function (error) {
        vm.screen.waiting = false; 
        vm.model.message.text = '';
        $log.log("A Problem occurred - ", error);
        var notice = NotificationService.formatTalkWidgetFailureMessage(error);
        NotificationService.report(notice);
        return error;
    };

    /**
     * @uses MessengerService::markSent(message, false)
     * @param {Object<Error>} error - Error Handler Function
     * @name _handleSendException - handling send exception.
     */
    this._handleSendException = function (error) {
        console.log(" ====> error", error);
        vm.model.message.text = "";
        vm.screen.waiting = false; 
        MessengerService.markSent(vm.model.message, false);
        vm.model.messages = MessengerService.getModel().messages;
        return error;
    };

    /**
     * @param {Object} params 
     * @name init - Initialization of Messages 
     */
    this.init = function (params) {
        return MessengerService.getRecipient(params || $state.params).
        then(MessengerService.getIntents).
        then(function (response) {
            var _response = response.data || response;
            vm.model.user = MessengerService.getModel().user || _response.user;
            //@todo add this element on server side
            //@todo move response processing into services. 
            vm.model.recipient = MessengerService.getModel().recipient || _response.recipient || {};
            vm.model.discussions = MessengerService.getModel().discussions || _response.discussions || [];
            vm.model.feedbacks = MessengerService.getModel().feedback || _response.feedback || [];
            vm.model.orders = MessengerService.getModel().orders || _response.orders || [];
            if (_response.length && _response[0] && _response[0].conversation) {
                vm.conversation = _response[0].conversation;
                if (!_.isString(vm.conversation) && (vm.conversation.id || vm.conversation._id)) {
                    vm.conversation = vm.conversation.id || vm.conversation._id;
                }
            }
            vm.model.messages = MessengerService.getConversations(vm.conversation, true) || vm.model.messages;


            return response;
        }).catch(this._handleException);
    };

    /**
     * @name send - Function used to send a message.
     * @param  {Object} payload 
     * @return {Object<Promise>} 
     */
    vm.send = function (payload) {
        //preventing sending empty data
        if (_.isEmpty(payload)) throw 'Payload Message is Empty';
        if (!payload.text || _.isEmpty(payload.text)) throw 'Payload Message Miss Text Message';
        if (payload && payload.text) vm.model.message.payload = payload.text;
        if (!vm.model.message.text && payload.text) this.model.message.text = payload.text;
        vm.model.message.recipient = $state.params.recipient;
        return MessengerService.send(vm.model.message).then(function (response) {
            var _response = response.data || response;
            MessengerService.markSent(_response, true);
            vm.model.message = {
                text: ''
            };
            //@todo move these elements into subscribe()
            var conv = _response.conversation; //used to hold small values
            vm.model.messages = MessengerService.getConversations(conv, true) || vm.model.messages;
            return response;
        }).catch(this._handleSendException);
    };

    /**
     * @name onMessageEvent - Listens to incoming messages.
     * @name WebSocket receives, and alert everyone that there is an incoming message.
     * @todo -- great chances are received message doesn't belond to currrent conversation.
     *          Find messages based on conversations.
     * @param {Object<Event>} event
     * @param {Object<Payload>} payload
     */
    this.onMessageEvent = function (event, payload) {
        var _conversation = payload.conversation;
        if(!vm.conversation) vm.conversation = _conversation._id || _conversation.id || _conversation;
        if ((_conversation._id || _conversation.id || _conversation) === vm.conversation) {
            vm.model.messages = MessengerService.getConversations(_conversation, true) || vm.model.messages;
        }
    };
    
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}


angular
    .module('hoogy')
    .component('intent', {
        controller: IntentController,
        template: ''
    })
    .component('hoogyMessage', {
        controllerAs: 'vm',
        bindings: {
            message: '=',
            search: '&'
        },
        controller: HoogyMessageController,
        templateUrl: 'js/common/components/view/message.html'
    })
    .component('hoogyTalkWidget', {
        controllerAs: 'vm',
        bindings: {
            screen: '='
        },
        controller: TalkWidgetController,
        templateUrl: 'js/common/components/view/talkwidget.html'
    });