'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/

/**
 * @name HoogyAuthController - Used for to ensure authentication
 */
function HoogyAuthController($log, HoogyService, AuthService, NotificationService, StateService, ActionCreator, UIService, Angularytics) {

    var vm = this;

    /**
     * @name $onInit - Initializtion of Auth Directive.
     */
    this.$onInit = function () {
        this.SIGNIN = 'signin';
        this.SIGNUP = 'signup';
        this.RECOVER = 'recover';
        this.ERROR_INVALID_FORM = 'Authentication form is invalid';
        this.model = AuthService.getModel();
        this.screen.action = 'signin';
        this.screen.active = this.screen.action;
        StateService.dispatch(ActionCreator.addScreen(this.screen));        
        this.unsubscribe = this.subscribe();
        this.init();
    };

    /**
     * @name subscribe - Waiting for Model Change on AuthService.
     * @name subscribe - callback used with StateService.subscribe(next);
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.model = AuthService.getModel();
            vm.screen = UIService.getAuthScreen(vm.screen);
            vm.screen.authprompt = !AuthService.isAuthenticated();
        });
    };

    this.init = function () {
        this.screen.authprompt =  !AuthService.isAuthenticated();
        this.screen.waiting = false;
        this.screen.action = 'signin';
        this.screen.active = this.screen.action;
        Angularytics.trackEvent('Authentication Initialization', +1);
    };

    this.reset = function () {
        this.screen.waiting = false;
        this.screen.action = 'reset';
        Angularytics.trackEvent('Authentication - Reset', +1);
    };

    /**
     * @todo --- move error handling in doneXXX() function. 
     * @param {*} response 
     * @param {*} status 
     */
    function _exceptionHandler(response, status) {
        vm.reset();
        var notice = NotificationService.formatAuthFailureMessage(response);
        Angularytics.trackEvent(['Authentication Failure - ', notice.message].join(''), +1);
        NotificationService.notify(notice);
        return response;
    }

    /**
     * @name isAuthenticated - Makes sure we return whatever the service is handing to upperlayer.
     */
    this.isAuthenticated = function () {
        return AuthService.isAuthenticated();
    };


    this.auth = function () {
        return NotificationService.unauthorized();
    };

    /**
     * @name close - This function is designed to close the login modal.
     */
    this.close = function () {
        vm.screen.waiting = false;
        vm.screen.action = 'signin';
        HoogyService.showAuthentication = false;
        vm.screen.authprompt = false;           
        Angularytics.trackEvent('Authentication Exit', +1);
        this.next({params: {exit: true}});
        $log.log('hoogyAuth::close');
    };

    /**
     * Allows children to switch between actions. 
     * From signup to signin or recover. 
     */
    this.action = function (action) {
        this.screen.waiting = false;
        this.screen.action = action;
                
        return false;
    };

    /**
     * @name  authenticate - authenticates people based on facebook/google provieder. 
     */
    this.authenticate = function (provider) {
        return AuthService.authenticate(provider)
            .then(function (response) {
                Angularytics.trackEvent('Authentication Success', +1);
                NotificationService.notify(NotificationService.formatAuthenticateMessage());
                NotificationService.notify(NotificationService.AUTHENTICATION_SUCCEED);
                vm.close();
                vm.reset();
                return response;
            }).catch(_exceptionHandler);
    };



    //@todo --- change change signin to add where to redirect the directive after saving this items. 
    this.doneSignin = function (response) {
        vm.reset();
        if (response && response.error) {
            return _exceptionHandler(response);
        }
        vm.close();
    };
    this.doneRecover = function (response) {
        vm.reset();
        if (response && response.error) {
            return _exceptionHandler(response);
        }
        vm.close();
    };
    vm.doneSignup = function (response) {
        vm.reset();
        if (response && response.error) {
            return _exceptionHandler(response);
        }
        vm.close();
    };



    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}

HoogyAuthController.$inject = ['$log', 'HoogyService', 'AuthService', 'NotificationService', 'StateService', 'ActionCreator', 'UIService', 'Angularytics'];
angular.module('hoogy').component('hoogyAuth', {
    controllerAs: 'vm',
    bindings: {
        screen: '=',
        next: '&'
    },
    controller: HoogyAuthController,
    templateUrl: 'js/common/components/view/auth.html'
});

function AuthSigninController(AuthService, NotificationService, HoogyService, StateService, ActionCreator, UIService, Angularytics) {
    var vm = this;
    //
    this.ERROR_INVALID_FORM = 'Authentication form is invalid';
    this.model = AuthService.getModel();
    //possible actions
    this.ACTION_SIGNUP = 'signup';
    this.ACTION_RECOVER = 'recover';

    /**
     * @name $onInit 
     */
    this.$onInit = function () {
        this.unsubscribe = this.subscribe();
        this.screen.action = 'signin';
        this.model = AuthService.getModel();
        StateService.dispatch(ActionCreator.addScreen(this.screen));        
        this.init();
    };
    /**
     * @name subscribe - Subscribing to Auth Service Changes
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.model = AuthService.getModel();
            vm.screen = UIService.getAuthScreen(vm.screen);
        });
    };

    this.init = function () {
        this.screen.action = 'signin';
    };

    this.save = function (form) {
        var self = this;
        if (!form || form.$invalid) {
            throw self.ERROR_INVALID_FORM;
        }
        return AuthService.signin(this.model.signin).then(function (response) {
            NotificationService.notify(NotificationService.formatSigninMessage());
            NotificationService.notify(NotificationService.SIGNIN_SUCCEED);
            Angularytics.trackEvent('Authentication Signin', +1);
            self.next(response);
            return response;
        }).catch(self.next);
    };

    /**
     * The function that makes it possible to change the Views.
     */
    this.action = function (action) {
        this.signin.$setPristine();
        this.screen.action = action;
                 
        this.goto({
            action: action
        });
        return true;
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };
}
AuthSigninController.$inject = ['AuthService', 'NotificationService', 'HoogyService', 'StateService', 'ActionCreator', 'UIService', 'Angularytics'];
angular.module('hoogy').component('hgSignin', {
    controllerAs: 'vm',
    bindings: {
        screen: '=',
        next: '&',
        goto: '&'
    },
    controller: AuthSigninController,
    templateUrl: 'js/common/components/view/signin.html'
});

function AuthSignupController(AuthService, NotificationService, HoogyService, StateService, ActionCreator, UIService, Angularytics) {
    var vm = this;

    
    /**
     * @name $onInit - Initialization
     */
    this.$onInit = function () {
        //Error 
        this.ERROR_INVALID_FORM = 'Authentication form is invalid';
        //possible actions
        this.ACTION_SIGNIN = 'signin';
        this.ACTION_RECOVER = 'recover';
        //
        this.screen.action = 'signup';
        this.model = AuthService.getModel();
        this.unsubscribe = this.subscribe();
        StateService.dispatch(ActionCreator.addScreen(this.screen));                
        this.init();
    };
    /**
     * @name subscribe - Subscribing to Auth Service Changes
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.model = AuthService.getModel();
            vm.screen = UIService.getAuthScreen(vm.screen);
        });
    };
    /**
     * @name init - Re-Initialization
     */
    this.init = function () {        
        this.screen.action = 'signup';
    };

    this.save = function (form) {
        var self = this;
        if (!form || form.$invalid) throw self.ERROR_INVALID_FORM;
        this.screen.waiting = true;
                 
        return AuthService.signup(this.model.signup)
            .then(function (response) {
                self.screen.waiting = false;
                NotificationService.notify(NotificationService.formatSignupMessage(response));
                NotificationService.notify(NotificationService.SIGNUP_SUCCEED);
                Angularytics.trackEvent('Authentication Signup', +1);
                self.next(response);
                return response;
            }).catch(self.next);
    };

    /**
     * The function that makes it possible to change the Views.
     */
    this.action = function (action) {
        this.signup.$setPristine();
        this.screen.action = action;
                 
        this.goto({
            action: action
        });
        return true;
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };
}
AuthSignupController.$inject = ['AuthService', 'NotificationService', 'HoogyService', 'StateService', 'ActionCreator', 'UIService', 'Angularytics'];
angular.module('hoogy').component('hgSignup', {
    controllerAs: 'vm',
    bindings: {
        screen: '=',
        next: '&',
        goto: '&'
    },
    controller: AuthSignupController,
    templateUrl: 'js/common/components/view/signup.html'
});

function AuthRecoverController(AuthService, NotificationService, HoogyService, StateService, ActionCreator, UIService, Angularytics) {
    var vm = this;
    this.ERROR_INVALID_FORM = 'Authentication form is invalid';
    //possible actions
    this.ACTION_SIGNUP = 'signup';
    this.ACTION_SIGNIN = 'signin';

    this.model = AuthService.getModel();

    /**
     * @name $onInit - Initialization of AuthRecovery Component
     */
    this.$onInit = function () {
        this.screen.action = 'recover';
        this.unsubscribe = this.subscribe();
        StateService.dispatch(ActionCreator.addScreen(this.screen));

        this.model = AuthService.getModel();        
        this.init();
    };
    /**
     * @name subscribe - Subscribing to Auth Service Changes
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.model = AuthService.getModel();
            vm.screen = UIService.getAuthScreen(vm.screen);
        });
    };
    /**
     * @name init - Re-initialization
     */
    this.init = function () {
        this.screen.action = 'recover';
    };

    /**
     * @name save - Saving Auth
     * @param {Object<FormController>}
     */
    this.save = function (form) {
        var self = this;
        this.screen.waiting = true;
         
        
        if (!form || form.$invalid) throw self.ERROR_INVALID_FORM;
        return AuthService.recover(this.model.recover).then(function (response) {
            self.screen.waiting = false;
            NotificationService.notify(NotificationService.formatRecoverMessage(response));
            NotificationService.unauthorized();
            Angularytics.trackEvent('Authentication Recover', +1);
            self.next(response);
            return response;
        }).catch(self.next);
    };

    /**
     * @param {Object<Action>} action 
     * @name action - The function that makes it possible to change the Views.
     */
    this.action = function (action) {
        this.recover.$setPristine();
        this.screen.action = action;
         
        
        this.goto({
            action: action
        });
        return true;
    };
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}
AuthRecoverController.$inject = ['AuthService', 'NotificationService', 'HoogyService', 'StateService', 'ActionCreator', 'UIService', 'Angularytics'];
angular.module('hoogy').component('hgRecover', {
    controllerAs: 'vm',
    bindings: {
        screen: '=',
        next: '&',
        goto: '&'
    },
    controller: AuthRecoverController,
    templateUrl: 'js/common/components/view/recover.html'
});