'use strict';

/**
 * @name CheckoutContractController - Contract Signing Component 
 * @param {*}  
 * @param {*} UIService 
 * @param {*} CheckoutService 
 * @param {*} NotificationService 
 * @param {*} StateService 
 * @param {*} ActionCreator 
 * @param {*} Angularytics 
 */
function CheckoutContractController($state, UIService, CheckoutService, NotificationService, StateService, ActionCreator, Angularytics) {
    
    var vm = this;
    /**
     * @name $onInit  
     */
    this.$onInit = function(){
        this.screen = UIService.getCheckoutContractScreen({component: 'checkout'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));        
        this.unsubscribe = this.subscribe();
        this.init();
    }; 

    /**
     * @name subscribe - Subscription on state changes 
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = CheckoutService.getModel();
            vm.model.item = CheckoutService.getModel().item || {};
            vm.model.payment.cost = CheckoutService.getOrderTotal();
            vm.screen.notifications = NotificationService.getModel(); 
        });
    };
    
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };

    /**
     * @name init - Initializes Contract Screen 
     * @param {Object<Options>} options - Parameters 
     */
    this.init = function (options) {
        var params = options || $state.params;
        CheckoutService.fetchOrder(params).then(function(response){
            vm.model = CheckoutService.getModel();
            vm.model.item = CheckoutService.getModel().item;
        });
        Angularytics.trackEvent('Checkout - Started Contract Screen', +1);
    };

    /**
     * @name save - Saves Contract Form. After Successful save, the application redirects to /payment/:oid
     *  
     * @todo --- merge save+next into same function
    * Hack to initialize form on compile time.
    * Normally forms are initialized first time it is used. 
    */
    this.save = function (form) {
        if (this.screen.waiting) { return false; }

        //errors are being displayed in-form
        if (!form || !form.$name || form.$invalid || form.$hasErrors) {
            throw 'Checkout steps require a valid form';
        }
        return this.next(form);
    };


    /**
     * @todo -- merge with save(), to form a single function.
      * @todo replace this methods with simple functions.
   * @todo support previous step.
  * Save and go to next step next
  */
    this.next = function (form) {

        if(!form || !form.$valid || form.$invalid || !form.$name){
            throw 'Checkout Contract Step requires a Valid Form';
        }

        this.screen.waiting = true;
        var action = form.$name.split('vm.');
        if (action[1]) action = action[1];
        else if (action[0]) action = action[0];
        //updates local instance of Order + adds Contract Signing 
        var data = Object.assign({}, {order: this.model.order}, this.model.contract);
        var options = {action: action, data: this.model.contract};
        return CheckoutService.checkoutContract(options).then(this._signContractHandler).catch(this._handleException);
    };

    /**
     * @name _handleException - handles exceptions when server sends error code.
     * @param {Object} response - object passed along from request promise 
     */
    this._handleException = function (error) {
        vm.screen.waiting = false;
        var notice = NotificationService.formatCheckoutFailureMessage(error);
        NotificationService.report(notice);
        Angularytics.trackEvent('Checkout - Exception', notice.message);
        return error;
    };
    /**
     * @name _signContractHandler - handle contact signing.
     * @todo add next() to make redirection from the parent(caller)
     */
    this._signContractHandler = function (response) {
        var message = response.data || response;
        vm.screen.active = 'payment';
        vm.screen.waiting = false;
        if (message.enabled) {
            vm.model.payment.enabled = true;
        }
        Angularytics.trackEvent('Checkout - Completed Contract Step', +1);
        var order = CheckoutService.getModel();
        var orderId = order._id || order.id || order.oid || order.order;
        $state.go('checkout.payment', {oid: orderId, id: orderId});
        return response;
    };
}
CheckoutContractController.$inject = ['$state', 'UIService', 'CheckoutService', 'NotificationService', 'StateService', 'ActionCreator', 'Angularytics'];
angular.module('hoogy').component('contractCheckout', {
    controllerAs: 'vm',
    controller: CheckoutContractController,
    templateUrl: 'js/checkout/view/contract.html'
});