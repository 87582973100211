'use strict';

/**
 * @todo - Listen to rentable changes via Redux 
 * @name RentController 
 * @param {Object<State>} $state 
 * @param {Object} HoogyService 
 * @param {Object} UIService 
 * @param {Object} SearchService 
 * @param {Object} StuffService 
 * @param {Object} Angularytics 
 */
function RentController($state, HoogyService, NotificationService, UIService, SearchService, StuffService, RentingService, StateService, ActionCreator, Angularytics) {

    var vm = this;

    /**
     * @name $onInit - Initialization Handler.
     */
    this.$onInit = function () {
        this.model = RentingService.getModel();
        this.screen = UIService.getRentScreen({component: 'workspace:rent'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        StateService.dispatch(ActionCreator.showHideRootLoading({value: false}));        
        StateService.dispatch(ActionCreator.showHideSidebar({value: false}));  
        this.init($state.params);
        this.unsubscribe = this.subscribe();
    };

    /**
     * @name subscribe - Subscribes for Model Updates.
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.model = RentingService.getModel();
            vm.screen = UIService.getRentScreen(vm.screen);
            vm.model.notifications = NotificationService.getModel();                                                             
        });
    };

    /**
     * @name _handleException 
     * @param {Object<Promise>} error 
     * @return {Object<Promise>}
     */
    this._handleException = function (error) {
        vm.screen.waiting = false;
        var notice = NotificationService.formatWorkspaceExceptionMessage(error);
        NotificationService.report(notice);
        return error;
    };

    /**
     * @name _success - handles on Search Success 
     * @param  {Object<Response>} response 
     */
    this._success = function (response) {
        vm.screen.waiting = false;
                         
        return response;
    };

    /**
     * @name search - does initial search of 
     * @param  {String} subject - optional search query
     * @return {Promise} 
     */
    this.search = function (subject) {
        this.screen.waiting = true;
        this.screen.search.what = this.screen.active; //default should be orders
        if (!this.screen.search.text) {
            this.screen.search.text = ['#', this.screen.search.what].join('');
        }
        return SearchService
            .omnisearch(vm.screen.search)
            .then(vm._success)
            .catch(vm._handleException);
    };

    /**
     * @param {Object} params - parameters to initialize the inventory screen. 
     * @name rent - displays rent page, and search for inventory.
     * @return void - 
     */
    this.init = function (params) {
        this.omnibox = false; //shows the omnibox
        this._user = HoogyService.getUser();
        var smodel = SearchService.getModel();
        var params = params || $state.params || {};
        if (smodel.people && params.owner) {
            var owner = _.find(smodel.people, {
                _id: params.owner
            });
            if (owner && owner._id && owner.name) {
                StuffService.setProvider(owner);
            }
        }
        Angularytics.trackEvent('Workspace Rent - Initialized', +1);
        return this.search();
    };
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };
}
RentController.$inject = ['$state', 'HoogyService', 'NotificationService', 'UIService', 'SearchService', 'StuffService', 'RentingService', 'StateService', 'ActionCreator', 'Angularytics'];
angular.module('hoogy').component('rent', {
    controllerAs: 'vm',
    controller: RentController,
    templateUrl: 'js/workspace/view/rent.html'
});