'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/

function SharerWidgetController($log, $state, MessengerService, SearchService, HoogyService, StateService, ActionCreator, UIService, NotificationService, RentingService) {

  /**
   * @param 
   */
  var vm = this;
  /**
   * Used with Hoogy-Tile for List View feature
   */
  this.settings = {
    list: true
  };

  /**
   * @name $onInit - Initialization Function.
   */
  this.$onInit = function () {
    this.count = 0;
    this.durl = MessengerService.AVATAR;
    this.model = RentingService.getModel();
    this.unsubscribe = this.subscribe();

    //allows to Send Rent to an Aquitance instead
    this.screen.waiting = false;
    this.screen.rental = false;

    //re-initialization of screen|search Object,
    this.screen.search = Object.assign({}, this.screen.search || {}, {action: 'inventory'});
    this.screen.search.suggestions = [{id: 1, title: 'test'}];
    StateService.dispatch(ActionCreator.addScreen(this.screen));
    StateService.dispatch(ActionCreator.addRental({}));
  };




  /**
   * @name subscribe - Updates Items + Renting person Details. 
   */
  this.subscribe = function () {
    return typeof this.unsubscribe === 'function' ? false : StateService.subscribe(function () {
      vm.model.person = RentingService.getModel().person || vm.model.person;
      vm.model.people = RentingService.getModel().people;
      vm.model.items = RentingService.getModel().items;
      vm.model.item = RentingService.getModel().item;
      vm.model.order = RentingService.getModel().order;
      vm.model.notifications = NotificationService.getModel();
      vm.screen.sharerup = UIService.getSharerScreen(vm.screen).sharerup;


      //Which is the best way to search for items/people or invite 
      //if(vm.model.items && vm.screen.rental) 
      //if(vm.model.people && vm.screen.invite) ;
      if (!vm.model.person._id && !vm.model.item._id) vm.screen.search.suggestions = vm.model.invites;
      if (vm.model.person._id) vm.screen.search.suggestions = vm.model.items;
      if (vm.model.item._id) vm.screen.search.suggestions = vm.model.people;
    });
  };



  /**
   * @name $onDestroy - Cleans Objects when Components
   */
  this.$onDestroy = function () {
    if (typeof this.unsubscribe === 'function') {
      this.unsubscribe();
    }
    this.unsubscribe = null;
  };



  /**
   * @name _exception 
   * @param {Object<Promise>} error 
   * @return {Object<Promise>}
   */
  this._exception = function (error) {
    vm.screen.waiting = false;
    //@todo --- add report error+message
    //var notice = NotificationService.formatWorkspaceExceptionMessage(error);
    //NotificationService.report(notice);
    return error;
  };

  /**
   * @name _success - handles on Search Success 
   * @param  {Object<Response>} response 
   */
  this._success = function (response) {
    vm.screen.waiting = false;
    //@todo --- add report for success 
    return response;
  };

  /**
   * @name switchValues - Function designed to change values. 
   *                    - Alternative to $watch-ed values
   */
  this.switchValues = function(){
    this.screen.invite = !this.screen.rental; 
  };

  /**
   * @uses setItem 
   * @uses RentingService:rentItem 
   * @name rentOutTo - Allows to rent my stuff to a person -> Kiosk Mode. 
   * @returns {Object<Promise>}
   */
  this.rentOutTo = function () {
    vm.switchValues();
    this.setItem(this.model.item);
    if (!this.model.item) throw '@ShareWidget:rentOutTo requires Item';
    if (!this.model.person) throw '@ShareWidget:rentOutTo requires Person';
    return RentingService.rentItem(this.model.order).then(function (response) {
      vm.screen.waiting = false;
      $log.log("SharerWidgetController::rentOutTo", response.data, vm.model.order);
      return response;
    });
  };

  /**
   * @name setItem - Setting Items 
   * @param {Object<Item>} item - Item 
   */
  this.setItem = function (item) {
    vm.switchValues();
    this.model.item = Object.assign({}, item, {
      id: item._id || item.id
    });
    this.model.order = Object.assign({}, this.model.order, {
      item: this.model.item,
      owner: HoogyService.getUserId(),
      person: this.model.person
    });
    $log.log("RentalWidget::setItem", this.model.item);
  };

  /**
   * @name search - Searches for Inventory when Person is set.
   *              - Searches for People when Item is set. 
   *              - Sets Invite when neither Person nor Item is set.
   * @uses searchItems
   * @uses searchPeople 
   * @param {Object<Params>} params 
   */
  this.search = function (params) {
    vm.switchValues();
    if (!vm.model.person._id && !vm.model.item._id){
      if(HoogyService.checkEmail(params.text)){
        this.model.text = params.text;
        return this.invite();
      }
    } 
    if (vm.model.person._id) return this.searchItems();
    return this.searchPeople();
  };

  /**
   * @todo use existing data to populate the auto-complete list.
   * @name searchItems - Used with Search to find Items
   * @param {Object<Params>} params 
   */
  this.searchItems = function (params) {
    vm.switchValues();
    vm.screen.search = Object.assign({}, this.screen.search, params, {action: 'inventory'});
    return SearchService.omnisearch(vm.screen.search).then(function (response) {
      var payload = response.data || response; 
      if(payload[0] && payload[0].inventory) payload = payload[0].inventory;
      if(payload && payload.inventory) payload = payload.inventory; 
      if(payload && payload.items) payload = payload.items; 

      StateService.dispatch(ActionCreator.addRentalItems(payload));   
      return response;
    });
  };


  /**
   * @todo use existing data to populate the auto-complete list.
   * @name searchPeople - Used with Search to find People
   * @param {Object<Params>} params 
   */
  this.searchPeople = function (params) {
    vm.switchValues();
    //@todo validate this search. 
    vm.screen.search = Object.assign({}, vm.screen.search, params, {action: 'people'});
    return SearchService.omnisearch(vm.screen.search).then(function (response) {
      var payload = response.data || response; 
      if(payload.contacts) payload = payload.contacts; 
      StateService.dispatch(ActionCreator.addRentalItems(payload));
      return response;
    });
  };



  /**
   * @todo ---- this function may not be used, if not, please delete.
   * @name chengeRentalPeriod - After changing 5 days/1week or a month/, recalculate price
   *                            will be used to change rental period
   *                          - This function is a copy/carbon of CheckoutController::changedRentalPeriod
   *                          - Copied from RentalWidget 
   * @param {Object<Period>} period
   */
  this.changedRentalPeriod = function (period) {
    vm.switchValues();
    //user selects 10 Days, Item charges per day - total time is calculated as following
    HoogyService.checkout.total = HoogyService.calculatePrice(this.model.item, period);
    this.model.payment.cost = HoogyService.checkout.total;
    HoogyService.checkout.period = period;
    $log.log("RentalWidget::changedRentalPeriod", period, this.model.order);
  };

  /**
   * @name invite - Allows to use the alert to send an invitation to join the platform.
   * @returns {Object<Promise>}
   */
  this.invite = function () {
    vm.switchValues();
    this.model.email = RentingService.formatEmail(this.model.text);
    Angularytics.trackEvent('Sidebar Invite Succeded ');
    return HoogyService.invite({
      email: this.model.email
    }).then(this._success).catch(this._exception);
  };

  /**
   * @name share - Shares current item with an Aquitance without renting to the Acquitance.
   *             - This feature is used for discovebility matters. 
   * @todo use HoogyService.postReview|ReviewService.postReview (recommend) - for non-owners
   * @todo use HoogyService.sendNotification | Sends Notifications About a new item.
   * @returns {Object<Promise>}
   */
  this.share = function () {
    vm.switchValues();
    return RentingService.share(this.model).then(this._success).catch(this._exception);
  };


  /**
   * @name send - Designed to Rent Out, Share or Rent Item to an acquitance.
   * @uses rentOutTo()
   * @uses invite() 
   * @uses share()
   * @returns {Object<Promise>}
   */
  this.send = function () {
    vm.switchValues();
    if (this.screen.rental) return this.rentOutTo();
    else if (!this.screen.rental && this.screen.invite) return this.share();
    return this.invite();
  };

  /**
   * @name close - Close Function 
   */
  this.close = function () {
    vm.switchValues();
    if (typeof this.next === 'function') {
      return this.next(this.screen);
    }
    StateService.dispatch(ActionCreator.removeRental(this.screen));
    StateService.dispatch(ActionCreator.stopSharerScreen(this.screen));
    this.screen.sharerup = false;
  };
}
SharerWidgetController.$inject = ['$log', '$state', 'MessengerService', 'SearchService', 'HoogyService', 'StateService', 'ActionCreator', 'UIService', 'NotificationService', 'RentingService'];

angular.module('hoogy').component('sharerWidget', {
  controllerAs: 'vm',
  bindings: {
    screen: '='
  },
  controller: SharerWidgetController,
  template: '<div class="sharer-widget-wrapper" ng-show="vm.screen.sharerup">' +
    '   <div  class="popup-holder">' +
    '   <div class="popup">' +
    '      <div class="-close-item-editor no-margin-xs">' +
    '           <div class="pull-left">' +
    '             <input type="checkbox" name="toggle" class="sw" id="sharer-toggle" ng-true-value="true" ng-false-value="false" ng-model="vm.screen.rental"/>' +
    '             <label for="sharer-toggle">' +
    '               <span ng-show="vm.screen.rental">Renting Out</span>' +
    '               <span ng-show="!vm.screen.rental">Sharing</span>' +
    '             </label>' +
    '           </div>' +
    '           <button type="button" class="close pull-right" aria-label="Close" title="Exit edit mode" ng-click="vm.close()">' +
    '               <span class="-close -span-close" aria-hidden="true">&times;</span>' +
    '           </button>' +
    '      </div>' +
    '      <hoogy-omnibox screen="vm.screen" search="vm.search(params)"></hoogy-omnibox>' +
    '      <hoogy-person ng-show="!!vm.model.person._id" person="vm.model.person"></hoogy-person>' +
    '      <hoogy-tile ng-show="!!vm.model.item._id" settings="vm.settings" item="vm.model.item"></hoogy-tile>' +
    '      <em-order ng-show="vm.screen.visibleOrder" order="vm.model.order"></em-order>' +
    '      <div class="rental-wrapper" ng-show="vm.screen.rental">'+
    '         <div class="rental-wrapper-body item-rent"> Rent item from'+
    '           <hoogy-datetime class="starting" date="vm.model.order.starting"></hoogy-datetime> for'+
    '           <b model="vm.model.item" class="dropup" next="vm.changedRentalPeriod(period)" hoogy-period></b>'+
    '         </div>'+
    '      </div>'+
    '      <div class="btn-group">' +
    '           <button class="button orange-button pull-right" ng-class="{waiting: vm.screen.waiting}" ng-click="vm.send()">Send</button>' +
    '      </div>' +
    '   </div>' +
    '   <div class="overlay"></div>' +
    '   </div>' +
    '</div>'
});