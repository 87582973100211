
/**
 * @name EmProfileController 
 * @param $log 
 * @param MessengerService
 * @deprecated - replace this EmprofileController with ProfileController instead
 */
function EmProfileController($log, $timeout, MessengerService) {
    var vm = this;
    
    /**
     * @name $onInit - Initialization of Embedded Profile.
     */
    this.$onInit = function(){
      this.model = {person: this.person};
      this.model.person = MessengerService.formatUser(this.person);
      this.css = { active: false };
      $timeout.cancel(this.handler);
      this.handler = $timeout(function(){ vm.css.active = true; }, 50);
    };
    
    /**
     * @name init - This initializer may not be needed, same for this directive.
     * @todo Except the analytics
     */
    this.init = function () {
    };
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
      this.css = { active: false };
      $timeout.cancel(this.handler);
      this.handler = null;
    };
  }
  EmProfileController.$inject = ['$log', '$timeout', 'MessengerService'];
  
  /**
   * Person Profile. 
   * @deprecated - replace this with hoogy-profile
   * EM - Profile stands for Embedded Profile.
   * This profile will be used to show oval-head of a user.
   * On-Mouse over, user will see quick details about a person via a popup
   */
  angular.module('hoogy').component('hoogyEmprofile', {
    controllerAs: 'vm',
    bindings: {
      person: '='
    },
    controller: EmProfileController,
    template: 
      '<div ng-init="vm.init()" class="em-profile-wrapper" ng-class="{\'ng-show\': vm.css.active === true}"  ui-sref="workspace.profile({owner:vm.model.person.id, id:vm.model.person.id})">'+
      ' <b class="avatar-frame" style="background-image: url({{vm.model.person.url}});" title="{{vm.model.person.name}}"></b>'+
      '</div>'
  });