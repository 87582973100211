'use strict';

function WorkspaceController($auth, $state, HoogyService, StateService, ActionCreator, SearchService, RentingService, StripeService, StuffService, PeopleService, AuthService, NotificationService, MessengerService, SettingsService, Angularytics, UIService) {
    
    var vm = this;
    
    /**
     * @name $onInit - Initialization Handler.
     */
    this.$onInit = function () {
        //this element is going to be used with MediumEditor
        this.text = '';
        this.screen = UIService.getWorkspaceScreen({component: 'workspace'});
        this.screen.user = HoogyService.getUser();
        this.screen.search.query.requestor = HoogyService.getUserId();
        StateService.dispatch(ActionCreator.showHideRootLoading({value: false}));        
        StateService.dispatch(ActionCreator.showHideSidebar({value: false}));  
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.model = SearchService.getModel();
        this.unsubscribe = this.subscribe();
        
        this.intro = false;
        this.omnibox = true; //shows the omnibox  
    };

    /**
     * @name $onChange - Detects Changed Objects in Child Components 
     * @param {Object<Params>} params 
     */
    this.$onChange = function(params){
        //this.screen.active
    };
    /**
     * @name subscribe - Subscribes for Model Updates.
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.model = SearchService.getModel();
            vm.screen = UIService.getWorkspaceScreen(vm.screen);
            vm.screen.user = HoogyService.getUser();
            vm.screen.search.query.requestor = HoogyService.getUserId();
            vm.model.notifications = NotificationService.getModel();                                                 
        });
    };

  


    /**
     * @deprecated - this function will be used in child components. 
     * @todo --- has to test this function.
     * @todo this function has been copied from StoreController to WorkspaceController,
     *      It has to have all elements required by WorkspaceController to be usable in this context.
     *      It redirects to checkout/item section
     *
     * this function has to send an email to the owner of the item, with the owner
     * and together with telephone number.
     * If user has provided email, we send email
     * If user has provided telephone/mobile, we sent sms
     * If user has provided twitter ... we tweet the owner with request to check his account
     * @todo use the vm.screen && vm.screen.order models to make a reservation
     * @todo - send a message when user tries to use this section so many times (ClientMailer??)
     * @todo --- handle payment, if user has to pay, or reservation if user is logged in.
     */
    this.start = function (item) {
        var _log = [item.title, (item.id || item._id)].join(' - ');
        Angularytics.trackEvent('Authorized Item Rent - Like', _log);
        $state.go('checkout.item', {
            id: item.id || item._id
        });
    };

    /**
     * @name logout - logging out connected person. 
     * @deprecated - logout should be performed from one place.
     * This function will be called to perform logout across the application
     * @todo check if there is a way to identify the person who logs out, and send him/her a message.
     * @todo the message can be to say thanks, items s/he wished to find, etc
     */
    this.logout = function () {
        this.screen.waiting = false;
        Angularytics.trackEvent('Workspace Logout - Initiated', +1);
        return AuthService.logout().then(function (response) {
            vm.screen.waiting = false;
             
            $state.go('store.list');
            return response;
        });
    };

    /**
     * @todo --- move this functionality to Settings section. 
     * @deprecated - this function is not used anywhere
     * @return {void} 
     */
    this.saveAddress = function () {
        this.screen.waiting = true;
        this.screen.user._id = HoogyService.getUserId(); 
        HoogyService.saveAddress(vm.screen.user).then(function (data) {
            vm.screen.user.address = data.address;
            var msg = {
                message: data.message || 'Address saved!'
            };
            NotificationService.notify(msg);
            vm.screen.waiting = false;
             
            
        });
        Angularytics.trackEvent('Workspace SaveAddress - Initialized', +1);
    };

    /**
     * @deprecated - details is not used in this context.
     * @todo remove it
     * Finds and displays item details from the server.
     * The item MUST belong to currently connected user.
     * @param params
     */
    this.details = function (params) {
        this.omnibox = false; //hides the omnibox
        this.screen.item = {};
        this.screen.active = 'item';
        this.screen.waiting = true;
        HoogyService.getItem(params.itemid).then(function () {
                vm.screen.item = HoogyService.inventoryItem || {};
                vm.screen.waiting = false;
                                 
                Angularytics.trackEvent('Inventory Item Details ', vm.screen.item.title);
            });
        Angularytics.trackEvent('Workspace Item Details - Initialized', +1);
    };



    /**
     * @name close - This function closes currently editing object, and redirects to homepage
     */
    this.close = function () {
        $state.go('workspace.discover');
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}
/**
 * Injecting dependencies + initialization of controller
 */
WorkspaceController.$inject = ['$auth', '$state', 'HoogyService', 'StateService', 'ActionCreator', 'SearchService', 'RentingService', 'StripeService', 'StuffService', 'PeopleService', 'AuthService', 'NotificationService', 'MessengerService', 'SettingsService', 'Angularytics', 'UIService'];



/**
 * The workspace gives managerial features to registered users.
 *  col-md-offset-1 col-lg-offset-1
 */
angular.module('hoogy').component('workspace', {
    controllerAs: 'vm',
    controller: WorkspaceController,
    template: '<div class="content-area profile-content-section ng-cloak">' +
        '   <div class="global-wrap">' +
        '       <hoogy-sidebar screen="vm.screen" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 -workspace no-padding-xs"></hoogy-sidebar>' +
        '       <div class="container col-xs-12 col-sm-12 col-md-9 col-lg-9 no-padding-xs">' +
        '           <div class="col-xs-12 col-sm-12 col-lg-12 workspace-wrapper">' +
        '               <div class="workspace-container">' +
        '                   <div class="col-lg-12 workspace-header right hidden-xs hidden-sm">' +
        '                       <div class="pull-right ttl"> {{vm.screen.active || \'Workspace\' |capitalize }}</div>' +
        '                   </div>' +
        '               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding-xs" ng-class="{\'no-padding\': vm.screen.active === \'discover\'}" ui-view></div>' +
        '           </div>' +
        '       </div>' +
        '   </div>' +
        '   <div class="push"></div>' +
        '</div>'
});