'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
function AddressController() {
    /**
     * 1 Hacker Way, Montreal QC, H0H A1A, Canada, telephone
     * @param address
     * @returns {
     *      String - civic ,
     *      String - suite,
     *      String - city,
     *      String - state,
     *      String - country,
     *      String - zip,
     *      String - zip,
     *      String - latitude,
     *      String - longitude
     *   }
     */
    function parse(address) {

        if (typeof address !== 'string') return address;

        var _ret = {};
        var _address = address.split(',');
        var _city = (_address[1] || '').trim().split(' ')[0] || '';
        var _state = (_address[1] || '').trim().split(' ')[1] || '';
        _ret.civic = (_address[0] || '').trim();
        _ret.city = _city;
        _ret.state = _state;
        _ret.zip = (_address[2] || '').trim();
        _ret.country = (_address[3] || '').trim();
        if (_address[4]) {
            _ret.telephone = (_address[4]).trim();
        }

        return _ret;
    }
    var vm = this;
    /**
     * @name $onInit 
     * @todo --- Add the Service that is in charge of Address Editing Instead. 
     */
    this.$onInit = function () {
        this.init();
    };

    /**
     * @name init - initialization of address editor.
     */
    this.init = function () {
        vm.isRemovable = false;
        vm.edit = vm.editable || false;
        if (vm.address && typeof vm.address === 'string') {
            vm.address = Object.assign({},{
                query: ''
            }, vm.address || {});
        }
        //state is equivalent of PRS(Province/Region or State)
        if (vm.address && vm.address.prs && !vm.address.state) {
            vm.address.state = vm.address.prs;
        }
        if (vm.address && vm.address.civic && !vm.address.street) {
            vm.address.street = ['#', vm.address.suite, ' - ', vm.address.civic].join('');
        }
    };
    this.swap = function () {
        vm.edit = !vm.edit;
    };

    this.query = function () {
        //send request and wait for resutl set
    };

    /**
     * @param {Object} address - Selected Address Object.
     * @name select - This line makes sure we transfer the title to newly selected address.
     */
    this.select = function (address) {
        if (vm.address.title && !vm.address.title) {
            vm.address.title = vm.address.title;
        }
        vm.address = address || {}; ///this is current clicked address
    };

    /**
     * @todo --- move this onChangeEvent to a reducer.
     * @todo unbounce to wait for this change event.
     */
    this.onAdressQueryChange = function (current, old) {
        if (current && current !== old) {
            vm.address = Object.assign({},vm.address, parse(current));
        }
    };


    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        
    };
}

angular.module('hoogy').component('hoogyAddress', {
    controllerAs: 'vm',
    controller: AddressController,
    bindings: {
        address: '=',
        addresses: '=?',
        save: '&',
        remove: '&',
        editable: '='
    },
    templateUrl: 'js/common/components/view/address.html'
});


/**
 * @todo --- formatting should be sent to a Service. 
 * Hoogy Location is used to display a Location. 
 */
function LocationController() {
    var vm = this;

    this.$onInit = function () {
        this.init();
    };

    /**
     * @name Init - This function parses the object into an  array.
     */
    this.init = function () {
        if (_.isString(vm.address) && vm.address.length > 0) {
            vm.address = vm.address.split(',');
            if (vm.address[3]) {
                vm.zip = vm.address[3];
            }
            if (vm.address[1]) {
                vm.city = vm.address[1];
            }
            if (vm.address[2]) {
                vm.state = vm.address[2];
            }
            if (vm.address[0]) {
                vm.street = vm.address[0];
            }
            if (vm.address[4]) {
                vm.country = vm.address[4];
            }
        }

        vm.content = _.values(vm.address || {}) || '';
        if (vm.content && _.isArray(vm.content)) {
            vm.content = vm.content.join(', ');
        }
    };


    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        
    };
}
angular.module('hoogy').component('hoogyLocation', {
    controllerAs: 'vm',
    bindings: {
        address: '='
    },
    controller: LocationController,
    templateUrl: 'js/common/components/view/location.html'
});