'use strict';

/**
 * @name FormAddressController
 */
function FormAddressController() {

    
    var vm = this;
    /**
     * @name $onInit 
     */
    this.$onInit = function () {
        if(!this.addressModel) throw '@FormatAddressController:$onInit requires addressModel';
        this.model = {address: this.addressModel};
        this.init();
    };

    /**
     * @name init - initialization of the form.
     */
    this.init = function () {
        this.model.address.city = this.model.address.city || 'Montreal';
        this.model.address.prs = this.model.address.prs || 'Quebec';
        this.model.address.country = this.model.address.country || 'Canada';
    };

    /**
     * @name save - This function makes sure address is valid and correct before submitting to server.
     */
    this.save = function (form) {
        if (!form || !form.$valid || form.$invalid) {
            throw 'Settings Form Required';
        }
        if (typeof this.next === 'function') {
            this.next(this.model.address);
        }
    };

    /**
     * @name reset - resetting the form.
     */
    this.reset = function (form) {
        if (!form || typeof form.$setPristine !== 'function') {
            throw 'Reset Requires a Form.';
        }
        form.$setPristine();
    };
}

angular.module('hoogy').component('hoogyFaddress', {
    controllerAs: 'vm',
    bindings: {
        next: '&?', 
        addressModel: '='
    },
    templateUrl: 'js/settings/view/address-form.html',
    controller: FormAddressController
});



/**
 * @name AddressSettingsController
 * @param {Object<Service>} HoogyService 
 * @param {Object<Service>} NotificationService 
 * @param {Object<Service>} PeopleService 
 * @param {Object<Service>} UIService 
 * @param {Object<Service>} Angularytics 
 */
function AddressSettingsController(HoogyService, NotificationService, PeopleService, UIService, StateService, ActionCreator, Angularytics) {
    var vm = this;

    /**
     * @name $onInit 
     */
    this.$onInit = function () {        
        this.model = PeopleService.getMe();
        this.screen = UIService.getSettingsScreen({component: 'address:settings', active: 'address'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        this.init();
    };

    /**
     * @name subscribe - Subscribe to PeopleService
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.model = PeopleService.getMe();
            vm.model.notifications = NotificationService.getModel();                
            vm.screen = UIService.getSettingsScreen(vm.screen);
        });
    };
    
    /**
     * @name init - initialization of the address. 
     */
    this.init = function () {
        Angularytics.trackEvent('Settings Address', +1);
    };

    /**
     * @todo all settings will be sent to server using this function
     * @todo use HoogyService::setSettings([options], next);
     */
    this.save = function (form) {
        var settings = {
            action: form.$name,
            data: this.model.address
        };
        if (!form || !form.$valid || form.$invalid) {
            throw 'Settings Form Requires';
        }
        return HoogyService.updateSettings(settings).then(this._settingsRequestCallback)
            .catch(this._handleException);

    };

    /**
     * @param response 
     * @name _settingsRequestCallback - Callback on settings update success.
     * @return {Promise}
     */
    this._settingsRequestCallback = function (response) {
        vm.enableSave = false;
        var message = response.data || response || false;
        var notice = NotificationService.formatSettingsSuccessMessage(message);
        Angularytics.trackEvent(notice.title, +1);
        NotificationService.notify(notice);
        return response;
    };


    /**
     * @param {Object<Error>} error 
     * @name _handleException - Handling exception around failed request
     * @return {Promise<Error>}
     */
    this._handleException = function (error) {
        vm.enableSave = false;
        vm.screen.waiting = false;
        var notice = NotificationService.formatSettingsFailureMessage(error);
        Angularytics.trackEvent('Settings Exception ', notice.message);
        NotificationService.report(notice);              
        return error;
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };

}
AddressSettingsController.$inject = ['HoogyService', 'NotificationService', 'PeopleService', 'UIService', 'StateService', 'ActionCreator', 'Angularytics'];
angular.module('hoogy').component('addressSettings', {
    controllerAs: 'vm',
    controller: AddressSettingsController,
    template: '<div class="settings-address-container" ng-init="vm.init()">' +
        '   <hoogy-faddress address-model="vm.model.address" next="vm.save(form)"></hoogy-faddress>' +
        '</div>'
});