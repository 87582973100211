'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/  

/**
 * Displaying an item.
 */
function HoogyItemController($window, $document, $log, UIService, StateService, ActionCreator, NotificationService, Angularytics) {

    var vm = this;

    /**
     * @name $onInit 
     */
    this.$onInit = function(){
        this.item = this.screen.item;
        if(this.screen.order && this.screen.order.item) {
            this.item = this.screen.order.item;
        }
        this.unsubscribe = this.subscribe();
        this.init();
    };


    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.item = vm.screen.order.item || vm.screen.item;  
            vm.screen.notifications = NotificationService.getModel(); 
            
        });
    };

    /**
     * @name init 
     */
    this.init = function () {
    };

    /**
     * @name reservation 
     */
    vm.reservation = function reservation(item) {
        $log.log('Want to make reservation of this item ');
        vm.reserve(vm.item || item);
    };

    /**
     * @name suggestion - Local Likes( Heart/Preference )
     * @param {Object} item
     */
    vm.suggestion = function suggestion(item) {
        vm.suggest(vm.item || item);
        $log.log('I want to keep this item for later usage');
    };
    /**
     * @name share - sharing an item. 
     * @link http://jasonwatmore.com/post/2014/08/01/AngularJS-directives-for-social-sharing-buttons-Facebook-Like-GooglePlus-Twitter-and-Pinterest.aspx
     * @link http://www.michaelbromley.co.uk/blog/171/enable-rich-social-sharing-in-your-angularjs-app
     * @todo share current item on facebook.
     */
    vm.share = function share() {
        Angularytics.trackEvent('Facebook share - init', vm.item.title + ' - ' + vm.item._id);
        if (FB && FB.ui) {
            FB.ui({
                method: 'share',
                app_id: AppConfig.Config.facebook.appId,
                display: 'popup',
                href: AppConfig.Config.server + '/item/' + vm.item._id
            }, function (response) {
                Angularytics.trackEvent('Facebook share - finalize', vm.item.title + ' - ' + vm.item._id);
                $log.log(response);
            });
        }
    };

    /**
     * @name tweet - allows to tweet 
     * @link http://jasonwatmore.com/post/2014/08/01/AngularJS-directives-for-social-sharing-buttons-Facebook-Like-GooglePlus-Twitter-and-Pinterest.aspx
     * @link http://www.michaelbromley.co.uk/blog/171/enable-rich-social-sharing-in-your-angularjs-app
     * @todo share current item on twitter.
     */
    vm.tweet = function() {
        /**
         * @todo - share the url, when the Card is approved, people will only need to share the card.
         * Which has to include Photo and Text of current Card.
         * @link http://stackoverflow.com/questions/21478891/twitter-card-share-button
         */
        Angularytics.trackEvent('Twitter share - init', vm.item.title + ' - ' + vm.item._id);
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
       if(typeof this.unsubscribe === 'function') {
           this.unsubscribe();
        }
        this.unsubscribe = null;
    };

    
}
HoogyItemController.$inject = ['$window', '$document', '$log', 'UIService', 'StateService', 'ActionCreator', 'NotificationService', 'Angularytics'];

angular.module('hoogy').component('hoogyItem', {
    controllerAs: 'vm',
    controller: HoogyItemController,
    bindings: {screen: '=', reserve: '&', suggest: '&'},
    templateUrl: 'js/common/components/view/item.html'
});