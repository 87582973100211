'use strict';

/**
 * @name OrdersController - Used Workspace Orders Component 
 * @param {Object} $state 
 * @param {Object<Service>} HoogyService 
 * @param {Object<Service>} SearchService 
 * @param {Object<Service>} NotificationService 
 * @param {Object<Service>} UIService 
 * @param {Object<Service>} Angularytics 
 */
function OrdersController($state, HoogyService, DiagnoseService, SearchService, NotificationService, UIService, StateService, ActionCreator, Angularytics) {

    var vm = this;

    this.SIZE = 10; 
    
    /**
     * @name $onInit - Initialization of Order 
     */
    this.$onInit = function () {
        //Initialization
        this.model = SearchService.getModel();
        this.screen = UIService.getWorkspaceOrdersScreen({component: 'workspace:orders'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));        
        StateService.dispatch(ActionCreator.showHideRootLoading({value: false}));        
        StateService.dispatch(ActionCreator.showHideSidebar({value: false}));  
        this.unsubscribe = this.subscribe();
        //@todo --- remove this section, it looks like it have never been used.
        this._user = HoogyService.getUser();
        this.search();
        Angularytics.trackEvent('Workspace Orders - Initialized', +1);
    };

    /**
     * @name subscribe - Subscribes for Model Updates.
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
           vm._success(true);
            vm.model.notifications = NotificationService.getModel();        
            var context = {
                type: DiagnoseService.ADD_ORDERS,
                category: 'suggestion',
                context: DiagnoseService.SETTINGS_CONTEXT
            };
            var notification = NotificationService.getNotificationByContext(context);
            //@todo add additional notifications. 
            if(!_.isEmpty(notification)) vm.model.notification = notification;                                                      
        });
    };

    /**
     * @deprecated - Use Filter() Instead
     * @name hashtag - This function sets a tag, and relaunch a search.
     *                 This prevents a user from typing all the time, if taglist if available
     * @uses OrdersController::search() 
     * @returns {Object<Promise>} 
     */
    this.hashtag = function (tag) {
        this.screen.search.text = tag || this.screen.search.text;
        this.screen.search.tags = [tag] || this.screen.search.tags;
        //re-launches the search
        return this.search();
    };

    /**
     * @name filter - Faster change in old-hashtag function
     * @uses hashtag
     */
    this.filter = function(obj){
        if(!obj) throw '@filter - Filter Object is required';
        if(!obj.tags) throw '@filter - Filter Object Requires Tags parameter';
        return this.hashtag(obj.tags);
    };
    /**
     * @deprecated - Made available via Search Component. 
     * @todo - Move Search to its Own Component. 
     * @param  {String} subject - optional search query
     * @return {Promise} 
     */
    this.search = function (subject) {
        this.screen.waiting = true;
        this.screen.search.what = this.screen.active; //default should be orders
        this.screen.search.text = this.screen.search.text || subject || ['#', this.screen.search.what].join('');
        return SearchService
            .omnisearch(this.screen.search)
            .then(this._success)
            .catch(this._exception);
    };



    /**
     * @name more - More Orders
     * @uses search()
     */
    this.more = function(){
        //memorize the last element in existing collection
        var last = this.model.items[_.size(this.model.items) - 1] || {};

        var start = this.screen.step * this.SIZE;
        this.model.orders = Object.values(Object.assign({}, SearchService.getModel(this.screen.search.what)) || {}).filter((ord, index) => index >= start && index < start + vm.SIZE );
        this.screen.step = this.screen.step + 1;

        if (_.size(this.model.orders) <= 0){
            this.screen.step = 0;//resetting the step
            this.screen.search.starting = last._id || last.id;
            this.search(this.screen.search);
        }
    };

    
    /**
     * @name _success - Omnisearch Order Handler.  This function handles the response about orders
     * @return {Object<Response>}
     */
    this._success = function (response) {

        vm.screen.step = 1;
        vm.screen.waiting = false;
        vm.model = Object.assign({}, SearchService.getModel());        
        
        var start = this.screen.step * this.SIZE;
        this.model.orders = Object.values(Object.assign({}, SearchService.getModel(vm.screen.search.what)) || {}).filter((ord, index) => index >= start && index < start + vm.SIZE );
        this.screen.step = this.screen.step + 1;

        //DiagnoseService.diagnoseOrders(vm.model.orders);      

        vm._user = HoogyService.getUser();
        vm.screen.more = _.size(SearchService.getModel(vm.screen.search.what)) > vm.SIZE;
        var count = _.size(vm.model.items);
        var message = ['Workspace', vm.screen.action || 'Collection',' - Found Provider (', count, ') Orders'].join('');

        return response;
    };

    /**
     * @name _exception - Exception handler. 
     * @param {Object} error
     * @return {Promise<Error>}
     */
    this._exception = function (error) {
        vm.screen.waiting = false;
        var notice = NotificationService.formatWorkspaceExceptionMessage(error);      
        NotificationService.report(notice);
        return error;
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };
}
OrdersController.$inject = ['$state', 'HoogyService', 'DiagnoseService', 'SearchService', 'NotificationService', 'UIService', 'StateService', 'ActionCreator', 'Angularytics'];
/**
 * A Component that Displays list of orders. 
 */
angular.module('hoogy').component('orders', {
    controllerAs: 'vm',
    templateUrl: 'js/workspace/view/orders.html',
    controller: OrdersController
});