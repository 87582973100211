'use strict';


/**
 * Used for to ensure authentication
 * @param $filter
 */
function SearchController(SearchService, StateService, ActionCreator, NotificationService) {

    var vm = this;
    var self = this;

    /**
     * @name $onInit 
     */
    this.$onInit = function () { 
        vm.model = SearchService.getModel();
        this.unsubscribe = this.subscribe();
        StateService.dispatch(ActionCreator.addScreen(this.screen));        
        this.init(); 
    };

    /**
     * @name subscribe 
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.model = SearchService.getModel();
        });
    };
    
    /**
     * @name init 
     */
    this.init = function () {
    };
    /**
     * @todo needs retest.
     * @name search - searching using new component architecture. 
     * @return {Promise} 
     * @uses {Function} next
     */
    this.search = function () {
        this.screen.waiting = true;
        this.screen.search.what = this.screen.active;//default should be orders
        if (!this.screen.search.text) {
            this.screen.search.text = ['#', this.screen.search.what].join('');
        }
        return SearchService.omnisearch(vm.screen.search).then(function (response) {
                if (typeof vm.next === 'function') {
                    vm.next(response);
                }
                return response;
            });
    };



    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}
SearchController.$inject = ["SearchService", "StateService", "ActionCreator", "NotificationService"];
/**
 * Hoogy Footer Component declaration. 
 */
angular.module('hoogy').component('hgSearch', {
    controllerAs: 'vm',
    bindings: { screen: '=', next: '&?' },
    controller: SearchController,
    template:
    '<div class="col-xs-12 col-sm-6 col-md-10 col-lg-10 workspace-header" ng-init="vm.init()">' +
    '   <div ng-show="vm.omnibox !== false" class="cols-xs-12 col-sm-12 pull-left left" screen="vm.screen" search="vm.search(subject)" hoogy-omnibox></div>' +
    '   <div class="pull-right ttl right hidden-xs hidden-sm"> {{vm.screen.active || \'Checkout\' |capitalize }}</div>' +
    '</div>'
});