'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * WebSocketBus plays same role as HoogyService for WebSocket communication.
 * It uses Socket.IO to send, receive and notify other services message handling.
 *
 */
function RentingService($http, $filter, $q, $log, HoogyService, SearchService, StateService, ActionCreator) {
    var AVATAR = HoogyService.AVATAR; //

    //model data will be stored here
    var model = {
        items: [], //collection of my items
        item: {}, //current item I am renting out.
        order: {}, //order details about rented out item.
        person: {}, //person I am renting to.
        payment: {}, //payment object.
        search: {
            text: ''
        } //search model
    };

    var service = {

        share: share,
        search: search,
        rentItem: rentItem,
        
        formatUser: formatUser,
        formatRecipient: formatRecipient,
        formatEmail: formatEmail,
        getRecipient: getRecipient,
        //getModel 
        getModel: getModel
    };

    //using the reveal design pattern
    return service;


    /**
     * @name getModel 
     * @param {String} which 
     * @returns {Object<State>}
     */
    function getModel(which) {
        var renting = StateService.getState().renting;
        var items = model.items;
        var people = model.people;
        if(renting && renting.entities){ items = renting.entities.items; }
        if(renting && renting.entities){ people  = renting.entities.people;}
        var state = Object.assign({}, model, {
            items: items,
            people: people,
            item: renting.item || model.item , 
            order: renting.order || model.order, 
            person: renting.person || model.person,
            payment: renting.payment || model.payment,
            search: renting.search || model.search 
        });
        return state[which] || state;
    }



    /**
     * @name formatEmail - Formatting the Text for real Email 
     * @param {String} email 
     */
    function formatEmail(email){
        if(!email) throw '@RentingService:formatEmail requires an Email';
        if(email.indexOf('@') < 2) throw '@RentingService:formatEmail requires an Email'; 
        return email;
    }

    /**
     * @name share - Sharing an Item to a Person, Instead of Renting right away
     * @param {Object<Params>} params 
     */
    function share(params){

    //sendNotify ==== has to be item owner for the moment. 
    //@uses ==== lib/controller/invite/notify.js
    //@todo --- can also send it as as message(messenger message + metadata included.)
    //@todo --- there is also recommend: this can be used to recommend an item: 
    //      ===> works with review, but can be tweeked - lib/controller/review/post-review.js 
    //@todo --- re-use NotifyController::save
    //@todo --- re-use HoogyReviewController.save
    //return ReviewService.postReview(this.model.review);
      return HoogyService.sendNotification(params).then(function(response){
          //@todo adding the notification message 
      });
    }

    /**
     * @name search - Searches Rental Items
     * @param  {Object} params 
     * @return {Object}        
     */
    function search(params) {
        var transaction = Object.assign({}, service.getModel());
        var options = Object.assign({}, params || {});
            options.what = options.what || 'inventory';
        var haystack = options.action || 'inventory';
        return SearchService.omnisearch(options).then(function (response) {
            transaction.items = SearchService.getModel()[haystack];
            StateService.dispatch(ActionCreator.addRental(transaction));       
            return response;
        });
    }

    /**
     * @name getRecipient - Function to fetch recipient information.
     * @param  {Object<Params>} params 
     * @return {Object}        
     */
    function getRecipient(params) {
        //checking if chained to a promise first
        var options = params.data || params || {};
        options.what = 'order'; //find order recipient data.
        return HoogyService.getRecipient(options).then(function (response) {
            StateService.dispatch(ActionCreator.addRentalPerson(service.formatRecipient(response.data || response)));                        
            return response;
        });
    }

    /**
     * @name rentItem - Initialization of Item Rental from User.
                      - Owner uses this function to rent an item out to acquitances 
     * @param {Object<Order>} params 
     */
    function rentItem(params) {
        
        var order = Object.assign({}, params);
        if(order.recipient && !order.person) order.person = order.recipient; 
        if(order.item && order.item.owner && !order.owner) order.owner = order.item.owner;

        if (order && order.starting && order.starting.thing) {
            order.starting = order.starting.thing;

        } else {
            order.starting = params.starting;

        }

        if (order && order.ending && order.ending.thing) {
            order.ending = order.ending.thing;
        } else {
            order.ending = params.ending;
        }


        if(!order) throw '@RentingService:rentItem - Order is Required';
        if(!order.item) throw '@RentingService:rentItem - Order.Item is Required';
        if(!order.person) throw '@RentingService:rentItem - Order.Person is Required';
        if(!order.owner)throw '@RentingService:rentItem - Order.Owner is required';
        var transaction = Object.assign({}, service.getModel());
        return HoogyService.rentOutItem(order).then(function (response) {
            transaction.order = response.data || response;
            StateService.dispatch(ActionCreator.addRental(transaction));       
            return response;
        });
    }

    /**
     * @name formatRecipient - Functions copied from MessengerService:::
     * Next two functions may be grouped at HoogyService level instead.
     * [formatRecipient description]
     * @param  {Object} payload 
     * @return {Object}         
     */
    function formatRecipient(payload) {
        var data = payload.data || payload || {};
        if (data[0] && (data[0].facebook || data[0].local || data[0].google)) {
            data = data[0];
        }

        return service.formatUser(data || {});
    }

    /**
     * @name formatUser 
     * @param {Object} options 
     */
    function formatUser(options) {
        var user = HoogyService.formatUser(options || HoogyService.getUser());
        return Object.assign({}, user, {
            name: user.first || user.name || 'JD',
            username: ['@', user.first || user.name || 'JD'].join(''),
            url: user.photo || AVATAR,
            id: user.id || user._id || options.id || options._id || ''
        });
    }
}

RentingService.$inject = ['$http', '$filter', '$q', '$log', 'HoogyService', 'SearchService', 'StateService', 'ActionCreator'];
angular.module('hoogy').factory('RentingService', RentingService);