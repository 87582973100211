'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * order date time
 */
function HoogyOrderTime($log, dateFilter) {
    return {
        scope: { datetime: '=' },
        replace: true,
        restrict: 'EA',
        template: ['<div class=\'ordertime date-time\'>',
            '<div class=\'date\'>{{datetime|date:\'dd MMM\'}}</div>',
            '<div class=\'time\'>{{datetime|date:\'HH:mm\'}}</div>',
            '</div>'].join('')
    };
}
HoogyOrderTime.$inject = ['$log', 'dateFilter'];
angular.module('hoogy').directive('hoogyOrderTime', HoogyOrderTime);