'use strict';

/**
 * @name StoreListController
 * @param {Object}  
 * @param {Object} HoogyService 
 * @param {Object} PeopleService 
 * @param {Object} Angularytics 
 */
function StoreListController($state, HoogyService, NotificationService, AuthService, PeopleService, UIService, StateService, ActionCreator, Angularytics) {

    var vm = this;

    /**
     * @name $onInit - Initializes Listing Items. 
     */
    this.$onInit = function () {
        this.screen = UIService.getStoreListScreen({component: 'store:list'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        this.init();
    };

 
    /**
     * @name subscribe - Subscribes for Model Updates.
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.model = AuthService.getModel();
            vm.screen = UIService.getStoreListScreen(vm.screen);
            vm.model.notifications = NotificationService.getModel();                                                 
        });
    };

    /**
     * Redirects to /w/discover if user is authenticated
     * @return {Object} 
     */
    this.init = function () {
        if (AuthService.isAuthenticated()) {
            $state.go('workspace.discover');
        }
    };

    /**
     * Clicking on tag --- tells us what users want on landing page
     * @param  {Object} tag 
     * @return {Object}     
     */
    this.tag = function (tag) {
        Angularytics.trackEvent(['Store Frontend tags ', tag].join(' - '), +1);
    };


    /**
     * @name auth - Triggers authentication alert box. 
     */
    this.auth = function () {
        return NotificationService.unauthorized();
    };

    /**
     * @name isAuthenticated 
     * This function is used to test if user is authenticated or not.
     * It injects this functionality into current scope, so that we can
     * use this test wherever current scope arrives.
     * @returns {*}
     */
    this.isAuthenticated = function () {
        return AuthService.isAuthenticated(true);
    };

    /**
     * Start saving and earning
     * @name
     * @return {Object} 
     */
    this.startSavingEarning = function () {
        if (this.isAuthenticated()) {
            Angularytics.trackEvent('Start saving and earning', 1);
            $state.go('stuff.add');
            return true;
        }
        if (typeof this.auth === 'function') {
            this.auth();
        }
        Angularytics.trackEvent('Un-Authorized Start Earning and Saving', 1);
        return false;
    };

    /**
     * Validate + Send invitation if user has not received any invitation yet.
     * @param  {Object} tag 
     * @return {Object}     
     */
    this.invite = function () {
        //claim allows users to auto invite themselves
        this.screen.message = false;
        this.screen.success = false;
        Angularytics.trackEvent('Store Frontend invite ', +1);
        return PeopleService.claimInvite({
                email: this.screen.email,
                claim: true
            })
            .then(function (response) {
                var _response = response.data || response;
                vm.screen.success = true;
                vm.screen.message = _response.message || '';
                Angularytics.trackEvent('Store Frontend invite Success', +1);
                 
                
                return response;
            }).catch(function (error) {
                vm.screen.message = error.data && error.data.message ? error.data.message : '';
                 
                Angularytics.trackEvent('Store Frontend invite Failed', +1);
                return error;
            });
    };
    
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };
}

StoreListController.$inject = ['$state', 'HoogyService', 'NotificationService', 'AuthService', 'PeopleService', 'UIService', 'StateService', 'ActionCreator', 'Angularytics'];
angular.module('hoogy').component('list', {
    controllerAs: 'vm',
    controller: StoreListController,
    templateUrl: 'js/store/view/list.html'
});