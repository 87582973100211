'use strict';
/**
 * @name CollectionController 
 * @param {$state}  
 * @param {Object} HoogyService 
 * @param {Object} StateService 
 * @param {Object} ActionCreator 
 * @param {Object} SearchService 
 * @param {Object} StuffService 
 * @param {Object} NotificationService 
 * @param {Object} UIService 
 * @param {Object} Angularytics 
 */
function CollectionController($state, HoogyService, StateService, ActionCreator, SearchService, StuffService, NotificationService, UIService, Angularytics) {

    var vm = this;

    /**
     * @name $onInit 
     */
    this.$onInit = function () {
        
        this.model = {items:[]};//re-initialize items for the collection
        this.screen = UIService.getWorkspaceCollectionScreen({component: 'workspace:collection'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        StateService.dispatch(ActionCreator.showHideRootLoading({value: false}));        
        StateService.dispatch(ActionCreator.showHideSidebar({value: false}));  
        this.model.notification = {}; 
        this.unsubscribe = this.subscribe();
        this.init($state.params);
    };

    /**
     * @name subscribe - Subscribes for Model Updates.
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm._handleSuccess(false);
            vm.screen = UIService.getWorkspaceCollectionScreen(vm.screen);
            vm.model.notification = {}; 
            vm.model.notifications = NotificationService.getModel();                                                 
        });
    };

    /**
     * @name _handleSuccess
     * @param {Object<Response>}
     * @returns {Object<Promise>}
     */
    this._handleSuccess = function (response) {
        vm.screen.waiting = false;
        //these model are still valid at collection level.s
        vm.model.owner = StuffService.getModel().owner || {};
        vm.model.items = StuffService.getModel().items || [];
        var count = _.size(vm.model.items);
        var message = ['Workspace Collection - Found Provider (', count, ') Items'].join('');
                         
        Angularytics.trackEvent(message, +1);
        return response;
    };

    /**
     * @name init - Displays items an Owner has for rent.
     * Initialization of Collection Component.
     * @param {Object} params - passing seaerch data into the function. 
     */
    this.init = function (options) {
        this.omnibox = false; //shows the omnibox
        var params = options || $state.params || {};
        this.screen.search.owner = params.owner || HoogyService.getUser();
        //parameter initializations
        //setting a person based on previous searches
        if (SearchService.getModel().people && params.owner) {
            var owner = _.find(SearchService.getModel().people, {
                _id: params.owner
            });
            if (owner && (owner._id || owner.id)) {
                StuffService.setProvider(owner);
            }
        }
                 
        Angularytics.trackEvent('Workspace Collection - Initialized', +1);
        return StuffService.getProviderCollection(this.screen.search)
            .then(this._handleSuccess).catch(this._handleException);

    };



    /**
     * @name _handleException - Exception handler. 
     * @param {Object} error - Error returned as a response.
     * @return {Promise<Error>} 
     */
    this._handleException = function (error) {
        vm.screen.waiting = false;
        var notice = NotificationService.formatWorkspaceExceptionMessage(error);
        NotificationService.report(notice);      
        return error;
    };


  

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}
CollectionController.$inject = ['$state', 'HoogyService', 'StateService', 'ActionCreator', 'SearchService', 'StuffService', 'NotificationService', 'UIService', 'Angularytics'];
angular.module('hoogy').component('collection', {
    controllerAs: 'vm',
    templateUrl: 'js/workspace/view/collection.html',
    controller: CollectionController
});