'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/

/**
 * @todo this directive has to show person image + basic information about a user.
 * [$inject description]
 * @type {Array}
 */
function HoogyPersonController($timeout, StateService, ActionCreator) {
  var vm = this;

  //activates/de-activates person card 
  this.$onInit = function () {
    this.active = false;
    
    this.css = { active: false };
    $timeout.cancel(this.handler);
    this.handler = $timeout(function(){ vm.css.active = true; }, 50);

  };

  /**
   * @name init - Initialization 
   */
  this.init = function () {};

  /**
   * @name sendInvite - sends invitation to a person
   */
  this.sendInvite = function (person) {
    if (!person || !this.person) throw 'Invite Requires Person to be Available';
    if (typeof this.invite === 'function') {
      this.invite({
        person: person || this.person
      });
    }
  };

  /**
   * @name share - Lanches Sharer UI
   *             - Owner or Non-Owner can Share the item
   * @param {Object<Person>} person - Optional Item Object
   */
  this.share = function (person) {
    var payload = {
      person: person
    };
    StateService.dispatch(ActionCreator.addRentalPerson(payload.person));
    StateService.dispatch(ActionCreator.startSharerScreen(payload));
  };

  /**
   * @name $onDestroy - cleans objects when components dies.
   */
  this.$onDestroy = function () {
    if (typeof this.unsubscribe === 'function') {
      this.unsubscribe();
      this.unsubscribe = null;
    }
    this.css = { active: false };
    $timeout.cancel(this.handler);
    this.handler = null;
  };
}
HoogyPersonController.$inject = ['$timeout','StateService', 'ActionCreator'];
angular.module('hoogy').component('hoogyPerson', {
  controllerAs: 'vm',
  bindings: {
    person: '=',
    invite: '&'
  },
  controller: HoogyPersonController,
  templateUrl: 'js/common/components/view/person.html'
});