'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/  

function CardsWidgetController(StripeService, NotificationService, StateService, ActionCreator, UIService) {
    var vm = this;


    /**
     * @name $onInit - Initialization of Component.
     */
    this.$onInit = function(){
        this.screen = UIService.getCardsWidgetScreen({component: 'cards-widget'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        
        this.model = {cards: this.cards, card: {}};
        this.unsubscribe = this.subscribe();
        this.init();
    };
    
    /**
     * @name subscribe - Subscribing to Auth Service Changes
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.model = StripeService.getModel();
            vm.screen = UIService.getCardsWidgetScreen(vm.screen);
            vm.screen.notifications = NotificationService.getModel(); 
        });
    };
    

    /**
     * @name _handleException - handles exception from initialization 
     * @param {Object<Error>} error - Error Object.
     */
    this._handleException = function (error) {
        vm.screen.waiting = false; 
        var notice = NotificationService.formatCardsWidgetFailureMessage(error);
        NotificationService.report(notice);
        return error; 
    };

    /**
     * @name init - Initialization of The CardWidget 
     */
    this.init = function () {
        this.screen.waiting = false;
        return StripeService.getUserCards().then(function (response) {
            vm.model.cards = StripeService.getModel().cards;
            return response; 
        }).catch(this._handleException);
    };

    /**
     * @name edit - Editing a Card.
     * @param {Object<Card>}
     */
    this.edit = function (card) {
        //sets card to edit
        vm.model.card = card;
        vm.model.card.remove = false;
    };

    /**
     * @name remove - Removing a Card
     * @param {Object<Card>} card
     */
    this.remove = function (card) {
        vm.model.card = card;
        //flag that will needed to confirm removal
        vm.model.card.remove = true;
    };

    /**
     * @name more - Listing more Items.
     */
    this.more = function () {
        //@todo listing when we have more than 10 elements.
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };

}
CardsWidgetController.$inject = ['StripeService', 'NotificationService', 'StateService', 'ActionCreator', 'UIService'];

/**
 * Business Logic of Credit Card widget
 */
function CardWidgetController($timeout, StripeService, NotificationService, StateService, ActionCreator, UIService) {
    var vm = this;
    
    /**
     * @name _handleException - handles exception post save operation.
     */
    this._handleException = function (error) {
        var message = error.message || error.data || false;
        vm.model.error.message = message.error ? message.error.message : message;
        $timeout(function () { vm.model.error = {}; }, 60000);
        vm.screen.waiting = false;
        var notice = NotificationService.formatCardsWidgetFailureMessage(error);
        NotificationService.report(notice);                 
        return error;
    };

    /**
     * @name $onInit - Initialization of CardWidget Component.
     */
    this.$onInit = function(){
        this.model = { card : this.cardModel, error: {}};        
        this.screen = UIService.getCardWidgetScreen({component: 'card-widget'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));        
        this.unsubscribe = this.subscribe();
        this.init();
    };
    
    /**
     * @name subscribe - Subscribing to Auth Service Changes
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.screen = UIService.getCardWidgetScreen(vm.screen);
            vm.screen.notifications = NotificationService.getModel();             
            vm.model = StripeService.getModel();
        });
    };
      
    /**
     * @name init - Card Widget Initialization
     */
    this.init = function () {};



    /**
     * @name save - function may return Promise to Unit testing purposes.
     * @param  {Object} form - Description
     * @return {void}
     */
    this.save = function (form) {
        if (!form || form.$invalid || !form.$valid) {
            throw 'Credit Card Widget Requires a Valid Form.'
        }
        if(vm.screen.waiting){ 
            throw 'There is an undergoing request. Please wait';
        }

        this.model.error = {};
        this.screen.waiting = true;
        return StripeService.editCustomerCard(this.model.card).then(function (response) {
            vm.model.card = {};
            vm.model.cards = StripeService.getModel().cards || vm.model.cards || [];
            vm.screen.waiting = false;
            return response;
        }).catch(this._handleException);
    };

    this.edit = function (card) {
        this.model.card = card;
    };
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };
}
CardWidgetController.$inject = ['$timeout', 'StripeService', 'NotificationService', 'StateService', 'ActionCreator', 'UIService'];

/**
 * @name HoogyCardController - This function transfers jobs to parent function.
 */
function HoogyCardController(UIService, NotificationService, StateService, ActionCreator) {
    var vm = this;
    
    /**
     * @name $onInit 
     */
    this.$onInit = function(){
        this.model = {card: this.card};
        this.screen = UIService.getCardScreen({component: 'card'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        this.init();
    };
    
    /**
     * @name init - initialization of the component.
     */
    this.init = function () { };


    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false : StateService.subscribe(function(){
            vm.screen = UIService.getCardScreen(vm.screen);
            vm.screen.notifications = NotificationService.getModel();             
        });
    };

    /**
     * @name edit - function transfers responsibility to caller
     * @param  {Object<Card>} card 
     * @return {void}
     */
    this.edit = function (card) {
        if (!this.model.card) {
            this.model.card = card;
        }
        if (typeof this.onEdit === 'function') {
            this.onEdit(this.model.card);
        }
    };

    /**
     * @name remove - Remove function transfers responsibility to caller.
     * @return {void} Description
     */
    this.remove = function (card) {
        if (typeof this.onRemove === 'function' && (this.screen.confirm === true)) {
            this.onRemove(this.model.card || card);
        } else {
            this.screen.confirm = !this.screen.confirm;
        }
    };
}
HoogyCardController.$inject = ['UIService', 'NotificationService', 'StateService', 'ActionCreator'];

/**
 * Registering directives of Card 
 */
angular.module('hoogy')
    .component('hoogyCard', {
        controllerAs: 'vm',
        controller: HoogyCardController,
        bindings: {card: '=', onEdit: '&', onRemove: '&'},
        templateUrl: 'js/common/components/view/card.html'
    })
    .component('cardsWidget', {
        controllerAs: 'vm',
        bindings: {cards: '='},
        controller: CardsWidgetController,
        template: 
        '<div class="cardswidget" ng-init="vm.init()">'+
        '   <hoogy-card class="cardwidget-wrapper" ng-repeat="card in vm.model.cards track by $index" on-edit="vm.edit(card)" on-remove="vm.remove(card)" card="card"></hoogy-card>'+
        '   <card-widget class="cardwidget-footer" card-model="vm.model.card"></card-widget>'+
        '</div>'
    })
    .component('cardWidget', {
        bindings: {cardModel: '='},
        controllerAs: 'vm',
        controller: CardWidgetController,
        templateUrl: 'js/common/components/view/cardwidget.html'
    });