'use strict';

/**
 * @name CheckoutPaymentController 
 * @param {Object} CheckoutService 
 * @param {Object} HoogyService 
 * @param {Object} Angularytics 
 */
function CheckoutPaymentController($log, $state, NotificationService, UIService, CheckoutService, HoogyService, StateService, ActionCreator, Angularytics) {

    var vm = this;
    /**
     * @name $onInit - Component based Event for initialization.
     */
    this.$onInit = function(){
        this.screen = UIService.getPaymentCheckoutScreen({component: 'checkout'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));        
        this.model = CheckoutService.getModel();
        this.unsubscribe = this.subscribe();
        this.init(); 
    };

    /**
     * @name subscribe - Subscription on state
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = CheckoutService.getModel();
            vm.screen = UIService.getPaymentCheckoutScreen(vm.screen);
            vm.screen.notifications = NotificationService.getModel(); 
        });
    };

    /**
     * @name $onDestroy - Cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
    

    
    /**
     * @name next - used as callback in dependent components.
     */
    this.next = function(options){
        /**
         * @todo -- check if payment has been accomplished, and react accordingly. 
         */
        console.log(' === next called back === ', options);
    };

    /**
     * @name init - Initialization of Payment 
     */
    this.init = function () {
        if (HoogyService.userSettings && HoogyService.userSettings.profile) {
            this.model.checkout.email = HoogyService.userSettings.profile.email;
        }
        Angularytics.trackEvent('Checkout - Started Payment Screen', +1);
    };


    /**
     * @param form current form to save.  
     * @name save - Pay Function deleages payment functionality to CheckoutComponnet Controller.
     */
    this.save = function (form) {
        if (!form || form.$invalid || !form.$valid || form.$hasErrors) {
            throw 'Payment Requires a valid Form';
        }

        if (this.screen.waiting || !this.model.order) {
            throw this.screen.waiting ? 'A request is underway.' : 'Payment Requires a Valid Order';
        }

        this.pay();
    };

    /**
    * @name _handleException - handles exceptions when server sends error code.
    * @param {Object} response - object passed along from request promise 
    */
    this._handleException = function (error) {
        vm.screen.waiting = false;
        var notice = NotificationService.formatCheckoutFailureMessage(error);
        NotificationService.report(notice);                
        Angularytics.trackEvent('Checkout - Exception', notice.message);
        return error;
    };


    /**
     * @name _savePaymentHandler 
     */
    this._savePaymentHandler = function (response) {
        vm.screen.waiting = false;
        Angularytics.trackEvent('Payment Authorized', vm.model.item.title + ' - ' + vm.model.item._id);
        Angularytics.trackEvent('Checkout - Completed Payment Step', +1);
                         
        $state.go('workspace.orders');
        return response;
    };


    /**
     * @todo --- CheckoutService --- should sortout if we need: 
     *  - Payment when user has valid card + customerId. 
     *  - Payment when user has valid account, but adds a new card. 
     *  - Payment when user has no customer account, and no card. 
     * Making payment revamped.
    * @param  {Object} form - Payment Form
    */
    this.pay = function () {
        if(this.screen.waiting) throw 'A request is underway.';
        vm.screen.waiting = true;
        /**
         * @todo - asbtract all cases behing CheckoutService.checkout. 
         * @todo -- setup options, if there is:
         *  - customerId + credit card (user refused to re-use a card)
         *  - customerId only(re-use an existing card)
         *  - no CustomerId + Non-existing credit card. 
         */
        var options = Object.assign({},{ data: vm.model.payment }, vm.model.checkout);
        Angularytics.trackEvent('Checkout - Started Payment Step', +1);
                 
        return CheckoutService.checkout(options).then(vm._savePaymentHandler).catch(vm._handleException);
    };

}
CheckoutPaymentController.$inject = ['$log', '$state', 'NotificationService', 'UIService', 'CheckoutService', 'HoogyService', 'StateService', 'ActionCreator', 'Angularytics'];
angular.module('hoogy').component('paymentCheckout', {
    controllerAs: 'vm',
    controller: CheckoutPaymentController,
    templateUrl: 'js/checkout/view/payment.html'
});

/**
 * @name PaymentEditController - Adds Payment Details{email, pickup, dropoff dates and time.} 
 */
function PaymentEditController(CheckoutService, StateService, ActionCreator, UIService, NotificationService) {
    var vm = this; 
    this.$onInit = function(){
        this.unsubscribe = this.subscribe();
        this.screen = UIService.getPaymentCheckoutScreen({component: 'checkout:payment'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));        
        this.model = CheckoutService.getModel();
        this.init(); 
    };
    /**
     * @name subscribe - Subscription on state
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = CheckoutService.getModel();
            vm.screen = UIService.getPaymentCheckoutScreen(vm.screen);
            vm.screen.notifications = NotificationService.getModel(); 
        });
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
    
    /**
     * @name init - initialization of payment edit.
     */
    this.init = function () {
    };
    /**
     * @name save - saving payment editor form.
     * @todo --- it looks like the payment form doesn't use ng-click="vm.save(vm.payment)"
     */
    this.save = function () {
        throw 'Not Yet Implemented';
    };
}
PaymentEditController.$inject = ['CheckoutService', 'StateService', 'ActionCreator', 'UIService', 'NotificationService'];
angular.module('hoogy').component('hgEditPayment', {
    controllerAs: 'vm',
    templateUrl: 'js/checkout/view/payment-edit.html',
    controller: PaymentEditController
});

/**
 * @name ReuseCardController 
 *       - Reuse Card Controller.
 *       - This component allows a customer to pay using previously used cards. 
 */
function ReuseCardController(CheckoutService, NotificationService, StateService, ActionCreator, UIService) {
    
    var vm = this;
    this.REUSE_CARD_EXCEPTION = 'Reuse Credit Card form should be valid';

    /**
     * @name $onInit - plugs into Component initialization process 
     */
    this.$onInit = function(){
        this.model = CheckoutService.getModel();
        this.screen = UIService.getPaymentCheckoutScreen({component: 'checkout:reuse'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));        
        this.unsubscribe = this.subscribe();
        this.init(); 
    };
    
    /**
     * @name subscribe - Subscription on state
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = CheckoutService.getModel();
            vm.model.card = CheckoutService.getReuseCardModel();
            vm.screen = UIService.getPaymentCheckoutScreen(vm.screen);
            vm.screen.notifications = NotificationService.getModel(); 
        });
    };
    
    /**
     * @name init - initiaziation of ReuseCardController 
     */
    this.init = function () {
        //initialization on default model changes. 
        this.model.card = CheckoutService.getReuseCardModel();
    };
    this.save = function (form) {
        if (!form || form.$invalid || !form.$valid) {
            throw this.REUSE_CARD_EXCEPTION;
        }
        if( typeof this.next === 'function' ){
            return this.next(form);
        }
    };
    /**
     * @name $onDestroy - Destroying objects
     */
    this.$onDestroy = function(){
        if(typeof this.unsubscribe === 'function'){
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}
ReuseCardController.$inject = ['CheckoutService', 'NotificationService', 'StateService', 'ActionCreator', 'UIService'];
angular.module('hoogy').component('hgReuseCard', {
    controllerAs: 'vm',
    bindings: { next: '&?' },
    templateUrl: 'js/checkout/view/payment-reuse-card.html',
    controller: ReuseCardController
});

/**
 * @name PaymentCreditCardController - validates and process form submission.
 */
function PaymentCreditCardController(CheckoutService, NotificationService, StateService, ActionCreator, UIService) {
    var vm = this;
    this.PAYMENT_EXCEPTION = 'Payment Form should be available and valid';
    this.$onInit = function(){
        this.model = CheckoutService.getModel();
        this.screen = UIService.getPaymentCheckoutScreen({component: 'checkout:credit-card'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));        
        this.unsubscribe = this.subscribe();
        this.init(); 
    };
    /**
     * @name subscribe - Subscription on state
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = CheckoutService.getModel();
            vm.screen = UIService.getPaymentCheckoutScreen(vm.screen);
            vm.screen.notifications = NotificationService.getModel(); 
        });
    };


    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
    
    /**
     * @name init - initialization of Credit Card.
     */
    this.init = function () {};

    /**
     * @name save - executes payment after adding a new card
     */
    this.save = function (form) {
        if (!form || form.$invalid || !form.$valid) { throw this.PAYMENT_EXCEPTION; }
        if( typeof this.next === 'function' ){
            return this.next(this.model.checkout);
        }
    };
}
PaymentCreditCardController.$inject = ['CheckoutService', 'NotificationService', 'StateService', 'ActionCreator', 'UIService'];
angular.module('hoogy').component('hgCreditCard', {
    controllerAs: 'vm',
    bindings: {next : '&?'},
    templateUrl: 'js/checkout/view/payment-creditcard.html',
    controller: PaymentCreditCardController
});