'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * @name HoogyCVC
 * @param {$log}  
 */
function HoogyCvc($log) {
    function link($scope, $element, $attrs, $ctrl) {
        $element.on('keyup', function (event) {
            $scope.$apply(function () {
                var isValid = Stripe.card.validateCVC($element.val());
                $log.log('hoogyCvc::isValid', isValid);
                $ctrl.$setValidity('hcvc', isValid);
            });
        });

        /**
         * Cleanup - try to destroy memory leak substible 
         */
        function cleanup() {
            if (angular.isElement(this)) { return $scope.$destroy(); }
            if ($element && typeof $element.off === 'function') {
                $element.off();
            }
             
            if($($element) && typeof $($element).off === 'function'){
                $($element).off();
            }
        }
        $scope.$on('$destroy', cleanup);
        $element.on('$destroy', cleanup);

    }

    return {
        require: 'ngModel',
        link: link
    };
}
HoogyCvc.$inject = ['$log'];
angular.module('hoogy').directive('hoogyCvc', HoogyCvc);