'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
function ContactWidgetController(NotificationService, PeopleService, StateService, ActionCreator) {
    var vm = this;

    /**
     * @name $onInit 
     */
    this.$onInit = function () {
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        this.init();
    };

    /**
     * @name subscribe - Subscribing to Auth Service Changes
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false : StateService.subscribe(function () {
            vm.model = PeopleService.getModel();
            vm.model.notifications = NotificationService.getModel(); 
        });
    };

    /**
     * @name init - Re-initialization 
     */
    this.init = function () {vm.model = PeopleService.getModel();};

    /**
     * @name save - Save Current form.
     * It may be an update|creation|delete
     */
    this.save = function (form) {
        if (!form || !form.$valid || form.$invalid) throw '@ContactWidgetController::save Requires a Valid Form';
        PeopleService.saveContact(vm.model.contact, vm.model.contact.invite)
            .then(function (response) {
                vm.model.contact = response.contact || response;
                vm.model.message = response.message || false; //check if message invitation sent
            }).catch(function (error) {
                NotificationService.report(error);
            });

    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if(typeof this.unsubscribe === 'function') this.unsubscribe(); 
        this.unsubscribe = null; 
    };

}

/**
 * The controller for message directive
 * [ContactWidgetController description]
 */
ContactWidgetController.$inject = ['NotificationService','PeopleService', 'StateService', 'ActionCreator'];

angular.module('hoogy').component('hoogyContactWidget', {
    controllerAs: 'vm',
    controller: ContactWidgetController,
    bindings: {
        screen: '=',
        model: '='
    },
    templateUrl: 'js/common/components/view/contactwidget.html'
});