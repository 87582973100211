'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/ 

function MessengerController($filter, $state, $auth, $log, AuthService, MessengerService, UIService, StateService, ActionCreator, NotificationService) {

    /**
     * [vm description]
     * @type {Object}
     */
    var vm = this;
    
    /**
     * @name $onInit 
     */
    this.$onInit = function(){
        this.model = MessengerService.getModel(); 
        this.screen = UIService.getMessengerScreen({component: 'messenger'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        StateService.dispatch(ActionCreator.showHideRootLoading({value: false}));        
        StateService.dispatch(ActionCreator.showHideSidebar({value: false}));  
        this.unsubscribe = this.subscribe();
        this.init(); 
    };


    /**
     * @name subscribe 
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = MessengerService.getModel(); 
            vm.screen = UIService.getMessengerScreen(vm.screen);
            vm.model.notifications = NotificationService.getModel();                         
        });
    };
 
    /**
     * Initialization - most of initialization has moved to TalkWidget
     * 	- @todo initialize search variables
     * 	- @todo send request for contacts
     * 	- @todo send request for contact[0].latest messages
     * @name
     * @return {Object} 
     */
    this.init = function () {
        this.model = MessengerService.getModel();
    };

    /**
     * Intent can be :
     * 	- Feeback (poll|redirect open feature|blog article | help)
     * 	- Order(order with a problem | return item now | close order | etc)
     * 	- Discussion(Send this message|Transform message into a ticket | make reservation | etc )
     * @name
     * @param  {Object} intent 
     * @return {Object}        
     */
    this.execute = function (intent) {
        //find type of intent, and take proper action to execute
    };

    /**
     * @name isAuthenticated - This function is used to test if user is authenticated or not.
     * It injects this functionality into current scope, so that we can
     * use this test wherever current scope arrives.
     * @returns {*}
     */
    this.isAuthenticated = function () {
        return AuthService.isAuthenticated(true);
    };
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };
}
MessengerController.$inject = ['$filter', '$state', '$auth', '$log', 'AuthService', 'MessengerService', 'UIService', 'StateService', 'ActionCreator', 'NotificationService'];
angular.module('hoogy').component('messenger', {
    controllerAs: 'vm',
    controller: MessengerController,
    templateUrl: 'js/messenger/view/index.html'
});
