/**
 * This filter will be used to capitalize first word in a string
 * @link https://gist.github.com/paulakreuger/b2af1958f3d67f46447e#file-angular-filters-js
 * Usage
 * 	{{astring | capitalize}}
 * produces
 *  Astring
 */
'use strict';
function capitalize() {
    return function(input, scope) {
        return input.substring(0, 1).toUpperCase() + input.substring(1);
    };
}

 angular.module('hoogy').filter('capitalize', capitalize);
