'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/

(function (w) {
    /**
     * Attach configuration parameters to the window object
     * Config.server = "http://localhost:8080" || "https://app.hoo.gy";
     */
    var Config = {};
    var l = window.location;
    Config.env = window.location.protocol === 'https:' ? 'prod' : 'dev';
    Config.server = window.location.origin;
    Config.api = Config.server + '/api';
    Config.WSURL = [l.protocol === 'https:' ? 'wss://' : 'ws://', l.host].join('');
    Config.app = Config.server;
    Config.twitter = { url: '/auth/twitter' };
    Config.facebook = { appId: Config.env === 'dev' ? '810542255675478' : '810541015675602' };
    Config.google = { clientId: Config.env === 'dev' ? '228488494340-rlfuobuqq424rh5u072g7igikd1tn4um.apps.googleusercontent.com' : '228488494340-1fplabl41lgahn494guasrr5t2tp0573.apps.googleusercontent.com' };
    Config.ospry = { key: Config.env === 'dev' ? 'pk-test-pgauzw950yy6m9trqz0p8ipz' : 'pk-prod-or2sfq9thccb325c8lm3ehfe', host: Config.env === 'dev' ? 'crispy-silkworm.ospry.io' : 'static-hoogy.ospry.io' };
    Config.stripe = { key: Config.env === 'dev' ? 'pk_test_EPaWf6wYDFWthUbuzomftxOW' : 'pk_live_7qxO4rpABIImKyDcnyziDHAp' };
    Config.smooch = { appToken: 'aqd829rxld7uuy163ofualga8' };
    w.Config = Config;
    w.AppConfig = {Config: Config};
})(window);

/**
 * hooggy is an angularjs application client for hoo.gy platform
 * Created by murindwaz on 2014-12-23.
 * @uses https://github.com/imsky/holder
 */
var hoogy = angular.module('hoogy', ['ngCookies', 'ngTouch', 'ngSanitize', 'ngMessages', 'ui.router', 'ui.bootstrap', 'satellizer', 'angularytics', 'hoogy-template']);
function HoogyConfig($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider, $authProvider, $controllerProvider, AngularyticsProvider) {

    $httpProvider.defaults.useXDomain = true;
    $locationProvider.html5Mode(true); //disable hashbang mode === app has to run on html5 enabled browsers
    var Config = AppConfig.Config || Config || {};
    $authProvider.baseUrl = Config.server || $authProvider.baseUrl;
    $authProvider.httpInterceptor = true; // Add Authorization header to HTTP request
    $authProvider.loginOnSignup = true;
    $authProvider.tokenPrefix = 'hoogy'; //the name of this app
    $authProvider.facebook({ clientId: Config.facebook.appId, scope: ['email', 'public_profile'] });
    $authProvider.google({ clientId: Config.google.clientId });
    $authProvider.twitter({ url: Config.twitter.url });
    /**
     * Details about  disabling the logging
     * @link http://www.webdeveasy.com/service-providers-in-angularjs-and-logger-implementation/
     * @link http://stackoverflow.com/a/28728380/132610
     */
    $controllerProvider.allowGlobals();
    AngularyticsProvider.setEventHandlers(['Console', 'GoogleUniversal']);
    if(Config.env !== 'prod' && (typeof AngularyticsProvider.disablePageViewTracking === 'function')){
        AngularyticsProvider.disablePageViewTracking();
    }
    /**
     * @link http://stackoverflow.com/questions/22526024/angularjs-ui-router-secured-states
     * redirects to store/ which is the listing section
     */
    $urlRouterProvider.otherwise('/');
    $urlRouterProvider.when('', '/');
    $urlRouterProvider.when('/workspace', '/workspace/profile');
    $stateProvider
        .state('store', { component: 'store' })
        .state('store.list', { url: '/', component: 'list' })
        .state('store.howitworks', { url: '/how-it-works', component: 'howitworks' })
        .state('store.item', { url: '/item/:itemid', component: 'item' })
        .state('store.partners', { url: '/partners', component: 'partners' })
        .state('store.confirmation', { url: '/confirmation/:invitation', component: 'confirmation' })
        .state('store.terms', { url: '/terms', component: 'terms' })
        .state('store.privacy', { url: '/privacy', component: 'privacy' })

        .state('stuff', { url: '/e/item', component: 'stuff', data: { authorization: true } })
        .state('stuff.edit', { url: '/{id}', component: 'stuff', data: { authorization: true } })

        .state('workspace', { url: '/w', component: 'workspace', data: { authorization: true } })
        .state('workspace.admin', { url: '/admin', component: 'admin', data: { authorization: true } })
        .state('workspace.profile', { url: '/:id/profile', component: 'profile', data: { authorization: true } })
        .state('workspace.logout', { url: '/logout', component: 'logout', data: { authorization: true } })
        .state('workspace.discover', { url: '/discover', component: 'discover', data: { authorization: true } })
        .state('workspace.wallet', { url: '/wallet', component: 'wallet', data: { authorization: true } })
        .state('workspace.orders', { url: '/orders', component: 'orders', data: { authorization: true } })
        .state('workspace.order', { url: '/order/:id', component: 'order', data: { authorization: true } })
        .state('workspace.notifications', { url: '/notifications', component: 'notifications', data: { authorization: true } })
        .state('workspace.collection', { url: '/:owner/c', component: 'collection', data: { authorization: true } })
        .state('workspace.rent', { url: '/:recipient/rt', component: 'rent', data: { authorization: true } })
        .state('workspace.notify', { url: '/notify/:itemid', component: 'notify', data: { authorization: true } })

        .state('messenger', { url: '/m', component: 'messenger', data: { authorization: true } })
        .state('messenger.talk', { url: '/:recipient/messages', component: 'messages', data: { authorization: true } })

        .state('checkout', { url: '/checkout', component: 'checkout', data: { authorization: true } })
        .state('checkout.item', { url: '/item/:id', component: 'itemCheckout', data: { authorization: true } })
        .state('checkout.contract', { url: '/:oid/contract', component: 'contractCheckout', data: { authorization: true } })
        .state('checkout.payment', { url: '/:oid/payment', component: 'paymentCheckout', data: { authorization: true } })
        
        .state('settings', { url: '/settings', component: 'settings', data: { authorization: true }})
        .state('settings.profile', { url: '/profile', component: 'profileSettings', data: { authorization: true } })
        .state('settings.photo', { url: '/photo', component: 'photoSettings', data: { authorization: true } })
        .state('settings.password', { url: '/password', component: 'passwordSettings', data: { authorization: true } })
        .state('settings.address', { url: '/address', component: 'addressSettings', data: { authorization: true } })
        .state('settings.wallet', { url: '/wallet', component: 'walletSettings', data: { authorization: true } })
        .state('settings.payment', { url: '/payment', component: 'paymentSettings', data: { authorization: true } })
        .state('settings.contact', { url: '/contact', component: 'contactSettings', data: { authorization: true } })
        .state('settings.contract', { url: '/contract', component: 'contractSettings', data: { authorization: true } })
        ;
}

HoogyConfig.$inject = ['$stateProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$authProvider', '$controllerProvider', 'AngularyticsProvider'];


//, ActionCreator, StateService , 'ActionCreator', 'StateService'
function HoogyAppRunner($window, $state, $transitions, $auth, AuthService, Angularytics, NotificationService) {
    /**
     * Facebook SDK Initialiazation.
     */
    var fbAppId = AppConfig.Config.facebook.appId;

    $window.fbAsyncInit = function () {
        FB.init({ appId: fbAppId, xfbml: true, version: 'v2.11' });
        FB.AppEvents.logPageView();
    };
    (function (doc, script) {
        var js,
            fjs = doc.getElementsByTagName(script)[0],
            add = function (url, id) {
                if (doc.getElementById(id)) { return; }
                js = doc.createElement(script);
                js.src = url;
                id && (js.id = id);
                fjs.parentNode.insertBefore(js, fjs);
            };
        add('//connect.facebook.net/en_US/sdk.js', 'facebook-jssdk');
        add('//platform.twitter.com/widgets.js', 'twitter-wjs');
    }(document, 'script'));

    /**
     * Google analytics tracking
     * @todo change this variable based on development environment.
     * this tracking code belongs to hoo.gy the main website.
     * ga('create', 'UA-57919101-1', 'auto');
     */
    (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date(); a = s.createElement(o),
            m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
    })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
    if(Config.env === 'prod'){
        ga('create', 'UA-57919101-2', 'auto');
        ga('send', 'pageview');
    }
    /**
     * @link http://angular-ui.github.io/ui-router/site/#/api/ui.router.state.$state
     */
    function onStart(transition) {
        var params = transition.$to();
        var $_state = transition.router.stateService;
        var authorize = false;
        if (params.data && params.data['authorization']) { authorize = params.data['authorization']; }
        if ($transitions && typeof $transitions.params === 'function') {
            authorize = $transitions.params('data').authorization || false;
        }
        if (authorize === true) {
            if (!AuthService.isAuthenticated()) {
                NotificationService.unauthorized();
                return $_state.target('store.list');//$state.target
                // //@link https://ui-router.github.io/guide/ng1/migrate-to-1_0
                //return trans.router.stateService.target('store.list');
            }
        }

        var StateService = transition.injector().get('StateService');
        var ActionCreator = transition.injector().get('ActionCreator');
        StateService.dispatch(ActionCreator.showHideSidebar({value: false}));        
        StateService.dispatch(ActionCreator.showHideRootLoading({value: true}));        
    }

    /**
     * @name onSuccess - Function works with transition. 
     * @param {} transition 
     */
    function onSuccess(transition) {
        var StateService = transition.injector().get('StateService');
        var ActionCreator = transition.injector().get('ActionCreator');

        var toState = transition.$to().name;
        var intro = toState === '/' || false;
        var containerClass = toState === '/item/:itemid' ? ' item-details-section-1' : '';
       StateService.dispatch(ActionCreator.showHideRootLoading({value: false}));        
       StateService.dispatch(ActionCreator.showHideSidebar({value: false}));        
       StateService.dispatch(ActionCreator.topScreen({value:{intro: intro, containerClass: containerClass}}));
    }


    /**
     * Error handling with transitions. 
     * @param {*} transition 
     */
    function onError(transition) {
        var message, type;
        var toState = transition.$to().name;
        if(toState !== 'store.list'){
            if (!AuthService.isAuthenticated()) {
                message = 'Not authorized, would you like to login again?';
                type = NotificationService.FAILURE;
                NotificationService.notify({ message: message, type: type });
                NotificationService.unauthorized();
                $state.go('store.list'); //I have no login    
            }
        }
    }

    $transitions.onStart({ to: '*' }, onStart);
    $transitions.onSuccess({ to: '*' }, onSuccess);
    $transitions.onError({}, onError);
    /**
     * Registering Events to $rootScope --- these events are available 
     * Only when Route-UI is available. 
     *   //$rootScope.$on('$stateChangeStart', stateChangeStart);
     *   //$rootScope.$on('$stateChangeSuccess', stateChangeSuccess);
     *   //$rootScope.$on('$stateChangeError', stateChangeError);
     * Starting the Analytics Service
     */
    if(Angularytics && typeof Angularytics.init === 'function') Angularytics.init();
    if(Stripe && typeof Stripe.setPublishableKey === 'function'){
        Stripe.setPublishableKey(AppConfig.Config.stripe.key);
    }
    /**
     * Service Worker Registration.
     */
    if (Modernizr.serviceworker || 'serviceWorker' in navigator){
            navigator.serviceWorker.register('/sw.js').then(function (registration) {
                // Registration was successful
            }).catch(function (error) {
                NotificationService.report(error);
                // registration failed :(
            });
    }
}
HoogyAppRunner.$inject = ['$window', '$state', '$transitions', '$auth', 'AuthService', 'Angularytics', 'NotificationService'];
/**
 * running this application
 * @link http://stackoverflow.com/questions/22526024/angularjs-ui-router-secured-states
 */
angular.module('hoogy').config(HoogyConfig).run(HoogyAppRunner);

