'use strict';

function PaymentSettingsController(HoogyService, PeopleService , UIService, StateService, NotificationService, ActionCreator, Angularytics){
    
    var vm = this;
    /**
     * @name $onInit 
     */
    this.$onInit = function(){
        
        this.model = PeopleService.getMe();
        this.screen = UIService.getSettingsScreen({component: 'payment:settings', active: 'payment'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        this.init(); 
    };
    
    /**
     * @name subscribe - Subscribe to PeopleService
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = PeopleService.getMe();
            vm.model.notifications = NotificationService.getModel();                            
            vm.screen = UIService.getSettingsScreen(vm.screen);
        });
    };
    
    /**
     * @name init - navigation
     * @return {void} 
     */
    this.init = function () {
        Angularytics.trackEvent('Settings Photo', +1);
    };

    /**
     * @name reset - resets a form. 
     * @uses WalletSettingsController::init 
     * @return {void}
     */
    this.reset = function(form) {
        if (!form || typeof form.$setPristine !== 'function') {
            throw 'Reset Requires a Form.';
        }
        Angularytics.trackEvent('Settings Reset', +1);
        form.$setPristine();
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };

}

PaymentSettingsController.$inject = ['HoogyService','PeopleService','UIService', 'StateService', 'NotificationService', 'ActionCreator','Angularytics'];
angular.module('hoogy').component('paymentSettings', {
    controllerAs: 'vm',
    templateUrl: 'js/settings/view/payment.html',
    controller: PaymentSettingsController
});