'use strict';

function StoreItemController($state, HoogyService, AuthService, UIService, StateService, ActionCreator, NotificationService, Angularytics){
    
    var vm = this; 

    /**
     * @name $onInit - Initialization of Store Item.
     */
    this.$onInit = function(){
        this.model = {}; 
        this.screen = UIService.getStoreItemScreen({component: 'store:item'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        this.init($state.params);
    };
    
    /**
     * @name subscribe - Subscribes for Model Updates.
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = AuthService.getModel();
            vm.screen = UIService.getStoreItemScreen(vm.screen);
            vm.model.notifications = NotificationService.getModel();                                                 
        });
    };

    /**
     * @name Init - displays item details.
     * Order - item
     *       - pickup : pickup/shop/item address
     *       - shipping : shipping/renter address
     *       - cost : calculated from starting.thing by ending.thing
     *       - starting : starting date
     *       - ending : Ending date
     * This function corresponds to item/:id url.
     * @todo delegate most of operations to a Service Class.
     * @todo Two addresses are needed: -renter address and Item address
     * @todo anything that this this URL becomes an vm.screen.order instance
     * This function might be deprecated --- it is replaced with tile.js
     * 	Directive's details(), which tries to make a redirection
     * @param {Object} params - Item details
     */
    this.init = function (options) {
        var params = options || $state.params || {};
        params.itemid = params.itemid || params.id;
        this.screen.waiting = true;        
        return HoogyService.getItemDetails(params.itemid).then(function (response) {
            vm.screen.waiting = false;
            vm.screen.order.item = HoogyService.orderItem;
            var item = (vm.screen.order || {}).item || {};
            var _ext = [item.title, item._id || item.id].join(' - ');
                         
            Angularytics.trackEvent('Item Details', _ext);
            return response;
        });
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };

}
StoreItemController.$inject = ['$state','HoogyService', 'AuthService', 'UIService', 'StateService', 'ActionCreator', 'NotificationService', 'Angularytics'];
angular.module('hoogy').component('item', {
    controllerAs: 'vm',
    controller: StoreItemController,
    templateUrl: 'js/store/view/item.html'
});