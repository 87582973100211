'use strict';

/**
 * @name StuffController has the task to add/edit and display item details.
 */
function StuffController($log, $location, $auth, $state, $stateParams, HoogyService, AuthService, NotificationService, Angularytics, UIService, StateService, ActionCreator, StuffService) {


    
    //ViewModel
    var vm = this;
    this.settings = {};
    /**
     * @name $onInit 
     */
    this.$onInit = function(){
        this.status = {};
        this.screen = UIService.getStuffScreen({component: 'stuff'});
        this.settings.list = true;         
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        StateService.dispatch(ActionCreator.showHideRootLoading({value: false}));        
        StateService.dispatch(ActionCreator.showHideSidebar({value: false}));  
        this.model = StuffService.getModel();
        this.unsubscribe = this.subscribe(); 
        this.init();
    };
  
    /**
     * @name init - Makes a request to initialize items
     */
    this.init = function () {
        this.connected = HoogyService.getUser();
        this.gravatar = HoogyService.gravatar;
        Angularytics.trackEvent('Store Item Adder Initialization', +1);
    };

    
    /**
     * @name subscribe - Subscribes for Model Updates.
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = StuffService.getModel();
            vm.screen = UIService.getStuffScreen(vm.screen);
            vm.model.notifications = NotificationService.getModel();                                                 
        });
    };

    /**
     * This function is used to test if user is authenticated or not.
     * It injects this functionality into current scope, so that we can
     * use this test wherever current scope arrives.
     * @returns {Boolean} isAuthenticated - checks if user is authenticted
     */
    this.isAuthenticated = function () {
        return AuthService.isAuthenticated(true);
    };

    /**
     * @deprecated - Moved to StuffEditor Component.
     * @name save - used by the editors to save a product.
     * @todo move details of this code in inheriting children, and do redirection or notification displaying.
     * Works with model.equipment instead of model.item
     * model.equipment is the term used to describe the item being added/edited.
     */
    this.save = function (form) {

        if (!form || !form.$valid || form.$invalid) {
            this.screen.waiting = false;
            throw 'Item form should be valid';
        }

        if (!this.isAuthenticated()) {
            Angularytics.trackEvent('Store Item Adder - Unauthorized', +1);
            throw 'You should be authenticated to save an item';
        }

        //stop double clicks while waiting for response
        if (this.screen.waiting) {
            Angularytics.trackEvent('Store Item Adder - Save Invalid', +1);
            return false;
        }

        this.screen.waiting = true;
        Angularytics.trackEvent('Store Item Adder - Save', +1);
        return HoogyService.saveItem(this.model.item).then(function (response) {
                vm.screen.waiting = false;
                vm.model.item = HoogyService.inventoryItem;
                NotificationService.notify({ message: 'Item created.' });
                Angularytics.trackEvent('Store Item Adder - Save Success', vm.model.item.title);
                                 
                $state.go('workspace.notify', { itemid: vm.model.item._id });
                return response;
            }).catch(this._handleException);
    };

    /**
     * @deprecated - moved to AddStuffController(StuffEditor Component).
     * @deprecated - moved to EditorWidget, should be removed 
     * @name removItem - function used to delete an item.
     * @required {Component} HoogyItem 
     * This function removes cancels, and deletes an item in current edit mode.
     * It Works with model.equipment instead of model.item
     */
    this.removeItem = function () {
        Angularytics.trackEvent('Store Item Adder', +1);
        return HoogyService.removeItem(vm.model.item).then(function (response) {
            var msg = { message: response.message || 'Item removal next!' };
            NotificationService.notify(msg);
            Angularytics.trackEvent('Store Item Adder - Remove', +1);
            return response;
        });
    };



    /**
     * @requires $location 
     * @name closeItemEditor - This function is used to make a cleanup upon edit completion
     */
    this.close = function () {
        this.status.waiting = false;
        this.status.path = this.status.path || '/store';
        this.status.edit = false;
        Angularytics.trackEvent('Exit Item Adder - Close Item Editor');
        $location.path(this.status.path);
    };

    /**
     * @param {Object<Error>} error 
     * @name _handleException - Utility to handle exceptions from server.
     * @return {Promise<Error>}
     */
    this._handleException = function (error) {
        /**@link http://jimhoskins.com/2012/12/17/angularjs-and-apply.html*/
        vm.screen.waiting = false;
        var notice = NotificationService.formatStoreFailureMessage(error);
        NotificationService.report(notice);
        Angularytics.trackEvent('Store Item Adder Exception', notice.message);
        return error;
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}
StuffController.$inject = ['$log','$location', '$auth', '$state', '$stateParams', 'HoogyService',  'AuthService', 'NotificationService', 'Angularytics', 'UIService', 'StateService', 'ActionCreator', 'StuffService'];

angular.module('hoogy').component('stuff', {
    controllerAs: 'vm',
    controller: StuffController,
    template: 
    '<div class="content-area adder-content-section" ng-init="vm.init()">'+
    '   <div class="global-wrap">'+
    '       <div class="container -item-adder">'+
    '           <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-margin-xs tb-padded-8">'+
    '               <button type="button" class="close pull-right" aria-label="Close" title="Exit edit mode" ng-click="vm.close()">'+
    '                   <span class="-close" aria-hidden="true">&times;</span>'+
    '               </button>'+
    '           </div>'+
    '           <items-widget class="col-xs-12 col-sm-12 col-md-6 col-lg-5 no-padding-xs pull-right-md pull-right-lg" settings="vm.settings" screen="vm.screen"></items-widget>'+  
    '           <editor-widget class="col-xs-12 col-sm-12 col-md-6 col-lg-7 no-padding-xs" screen="vm.screen"></editor-widget>'+
    '       </div>'+
    '   </div>'+
    '</div>'
});