'use strict';

/**
 * @name StoreController - The StoreController will give access to following features:
 */
function StoreController($state, HoogyService, AuthService, NotificationService, UIService, StateService, ActionCreator, Angularytics) {
    
    var vm = this;
    
    
    /**
     * @name $onInit 
     */
    this.$onInit = function(){
        this.model = {};//@todo replace screen variables progressively
        this.status = {};
        this.notification = {};
        this.screen = UIService.getStoreScreen({component: 'store'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        StateService.dispatch(ActionCreator.showHideRootLoading({value: false}));        
        StateService.dispatch(ActionCreator.showHideSidebar({value: false}));  
        this.unsubscribe = this.subscribe();
        this.init();
    };
    
    /**
     * @name init - Makes a request to initialize items
     */
    this.init = function () {
        this.user = _.pick(this.screen, 'wallet', 'bank', 'stripe', 'outgoing', 'inventory', 'invites', 'wishlist', 'address', 'profile');
        Angularytics.trackEvent('Store intialization', +1);
    };
 
        /**
         * @name subscribe - Subscribes for Model Updates.
         */
        this.subscribe = function(){
            return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
                vm.model = AuthService.getModel();
                vm.screen = UIService.getStoreScreen(vm.screen);
                vm.model.notifications = NotificationService.getModel();                                                                 
            });
        };

    /**
     * @name auth - Triggers authentication alert box. 
     */
    this.auth = function () {
        return NotificationService.unauthorized();
    };

  

    /**
     * @name isAuthenticated 
     * This function is used to test if user is authenticated or not.
     * It injects this functionality into current scope, so that we can
     * use this test wherever current scope arrives.
     * @returns {*}
     */
    this.isAuthenticated = function () {
        return AuthService.isAuthenticated(true);
    };



    /**
     * @name start - Start checkout process. This can be used by checkout starting from item/:itemid URL
     */
    this.start = function (item) {

        var _log = 'StoreController::reservaton - waiting/no order';
        if (this.screen.waiting) {
            return;
        }

        _log = 'StoreController::reservaton --- sending the order';
        if (!AuthService.isAuthenticated(true)) {
            _log = item.title + ' - ' + item._id;
            Angularytics.trackEvent('Un-Authorized Rent Item', _log);
            return;
        } else {
            this.screen.waiting = true;
            _log = item.title + ' - ' + item._id;
            Angularytics.trackEvent('Authorized Item Rent - Like', _log);
            $state.go('checkout.item', { id: item.id || item._id });
        }
    };
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };

}

StoreController.$inject = ['$state', 'HoogyService', 'AuthService', 'NotificationService', 'UIService', 'StateService', 'ActionCreator', 'Angularytics'];
angular.module('hoogy').component('store', {
    controllerAs: 'vm',
    controller: StoreController,
    templateUrl: 'js/store/view/index.html'
});
