'use strict';

/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * @name HoogyExpiry 
 * @param {$log}  
 */
function HoogyExpiry($log) {
    function link($scope, element, attrs, ctrl) {
        element.on('keyup', function (event) {
            $scope.$apply(function () {
                var my; var year; var month; var isValid;
                /**('02', '2020') MM - YYYY*/
                my = element.val().split('/');
                month = my[0] || '';
                year = ['20', (my[1] || '00')].join('');
                isValid = Stripe.card.validateExpiry(month, year);
                ctrl.$setValidity('hexpiry', isValid);
            });
        });

        /**
         * Cleanup - try to destroy memory leak substible 
         */
        function cleanup() {
            if (angular.isElement(this)) { return $scope.$destroy(); }
            if (element && typeof element.off === 'function') {
                element.off();
            }   
            if($(element) && typeof $(element).off === 'function'){
                $(element).off();
            }
        }
        $scope.$on('$destroy', cleanup);
        element.on('$destroy', cleanup);

    }

    return {
        require: 'ngModel',
        link: link
    };
}
HoogyExpiry.$inject = ['$log'];
angular.module('hoogy').directive('hoogyExpiry', HoogyExpiry);
