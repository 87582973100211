'use strict';
/**
 * Income analytics.
 *  As simpler as in
 *   @link https://craftybase.com/tour/cashflow_reports
 *   @link http://morrisjs.github.io/morris.js/
 *   @link http://jtblin.github.io/angular-chart.js/
 *   @todo create this graph directive and integrate angularjs-chart
 *   @todo create this directive and
 */
function WalletController(HoogyService, DiagnoseService, StripeService, NotificationService, UIService, StateService, ActionCreator, Angularytics) {


    var vm = this;
    
    /**
     * @name $onInit - Initialization Handler.
     */
    this.$onInit = function () {
        this.model = {}; //@todo --- Change This.Screen with This.Model Instead
        this.screen = UIService.getWorkspaceWalletScreen({component: 'workspace:wallet'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));   
        StateService.dispatch(ActionCreator.showHideRootLoading({value: false}));        
        StateService.dispatch(ActionCreator.showHideSidebar({value: false}));  
        this.unsubscribe = this.subscribe();
        this.init();
    };


    /**
     * @name subscribe - Subscribes for Model Updates.
     */
    this.subscribe = function () {
        /**
         * @todo Subscribe on HoogyService instead ... 
         * @todo Move most of computation to Services ... 
         */
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.screen = UIService.getWorkspaceWalletScreen(vm.screen);
            vm.bank = StripeService.getBank() || {};
            vm.wallet = StripeService.getWallet();
            vm.stripe = StripeService.getStripe();
            if (vm.bank.last) {
                vm.bank.last = '*****' + (vm.bank.last).toString();
            }
            vm.screen.results = HoogyService.stats || results.data;
            vm.screen.incomings = 0.00;
            vm.screen.outgoings = 0.00;
            _.each(vm.screen.results, function (rs) {
                vm.screen.incomings += rs.incomings;
                vm.screen.outgoings += rs.outgoings;
            });
            vm.screen.cashflow = (vm.screen.incomings - vm.screen.outgoings) || 0.00;
            vm.model.notifications = NotificationService.getModel();       
            var context = {
                type: DiagnoseService.ADD_WALLET,
                category: 'suggestion',
                context: DiagnoseService.SETTINGS_CONTEXT
            };
            var notification = NotificationService.getNotificationByContext(context);
            //@todo add additional notifications. 
            if(!_.isEmpty(notification)) vm.model.notification = notification;                                           
                                 
        });
    };

    /**
     * @name init - Re-Initialization  this..subscribe
     * @todo --- remove vm.screen.bank model, and use instead vm.bank {}
     * This function is the index of current Controller
     * Most  of the code here Is coming from vm.wallet on WorkspaceController.
     */
    this.init = function () {
        this.omnibox = false; //@todo - shows the omnibox once the #from label/hashtag is enabled.
        this.bank = StripeService.getBank() || {};
        if (this.bank.last) {
            this.bank.last = '*****' + (vm.bank.last).toString();
        }
        this.wallet = StripeService.getWallet();
        this.stripe = StripeService.getStripe();
       
        return HoogyService.walletStats().then(function (results) {
                vm.screen.results = HoogyService.stats || results.data;
                vm.screen.incomings = 0.00;
                vm.screen.outgoings = 0.00;
                _.each(vm.screen.results, function (rs) {
                    vm.screen.incomings += rs.incomings;
                    vm.screen.outgoings += rs.outgoings;
                });
                vm.screen.cashflow = (vm.screen.incomings - vm.screen.outgoings) || 0.00;
                Angularytics.trackEvent('Wallet Initialization - Wallet Stats Arrived', +1);
                return results;
            }).catch(this._handleException);
    };


    /**
     * @name _handleException - Exception handler. 
     * @param {Object} error 
     * @return {Promise<Error>}
     */
    this._handleException = function (error) {
        vm.screen.waiting = false;
        var notice = NotificationService.formatCreateAccountFailureMessage(error);
        NotificationService.report(notice);
        Angularytics.trackEvent('Wallet Initialization - Error', +1);
        return error;
    };
    
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };

}
WalletController.$inject = ['HoogyService', 'DiagnoseService', 'StripeService', 'NotificationService', 'UIService', 'StateService', 'ActionCreator', 'Angularytics'];
angular.module('hoogy').component('wallet', {
    controllerAs: 'vm',
    controller: WalletController,
    templateUrl: 'js/workspace/view/wallet.html'
});