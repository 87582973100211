'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
//@link https://github.com/mattboldt/typed.js
function HoogyTyper($log) {
    /**
     * Directive used to animate words
     * @requires Typed.js
     * @link https://github.com/murindwaz/typed.js
     * [link description]
     * @param  {Object} scope   
     * @param  {Object} element 
     * @param  {Object} attrs   
     * @param  {Object} ctrl    
     * @return {Object}         
     */
    function link($scope, $element, $attrs, $ctrl) {
        var typed = null;
        /**
         * Cleanup - try to destroy memory leak substible 
         */
        function cleanup() {
            if (angular.isElement(this)) { return $scope.$destroy(); }
            if (typed && typeof typed.destroy === 'function') {
                typed.destroy();
                typed = null;
            }
            if ($element && typeof $($element).off === 'function') {
                $($element).off();
            }
        }
        $scope.$on('$destroy', cleanup);
        $element.on('$destroy', cleanup);

        //initialize 
        if (typed === null) {
            typed = $($element).typed({
                strings: ($attrs.strings || '').split(',') || [],
                cursorChar: '|',
                loop: true,
                typeSpeed: 260,
                backDelay: 4000
            });

        }

        $log.log('hoogyTyper - element attached');
    }

    return {
        restrict: 'A',
        link: link
    };
}
HoogyTyper.$inject = ['$log'];
angular.module('hoogy').directive('hoogyTyper', HoogyTyper);