'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * Spinner Directive used to display waiting animation
 * [HoogySpinner description]
 */
function HoogySpinner() {
    return {
        restrict: 'EA',
        scope: { waiting: '=' },
        template: '<div class="spinner" ng-show="waiting" ng-hide="!waiting"></div>'
    };
}
angular.module('hoogy').directive('hoogySpinner', HoogySpinner);