'use strict';

/**
 * @name HoogySidebarController - Sidebar menu directive.
 *      This directive displays quick information about connected user.
 *      It gives him/her quick acces to his/her information.
 */
function HoogySidebarController($state, HoogyService, NotificationService, StateService, ActionCreator, UIService, AuthService) {

    var vm = this;

    /**
     * @name $onInit - initialization hook.
     */
    this.$onInit = function () {
        var values;
        this.model = {};
        this.screen.component = this.screen.component || 'hooggy';
        if($state && $state.current) { 
            this.screen.component = ($state.current.component || this.screen.component).toLowerCase();
            if($state.current.name && $state.current.name.indexOf(".") > 1){
                values  = $state.current.name.split(".");
                this.screen.action = (values[1] || values[0]).toLowerCase();
                this.screen.active = (values[1] || values[0]).toLowerCase();
            };
        }
        StateService.dispatch(ActionCreator.addScreen(this.screen));        
        this.unsubscribe = this.subscribe();
        this.init();
    };

    /**
     * @name subscribe - Subscribe to Changes. 
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            //vm.screen.component = vm.screen.component || 'hooggy';
            vm.screen = UIService.getSidebarScreen(Object.assign({}, {component: 'hooggy'}, vm.screen));
            vm.model.up = vm.screen.up; 
            vm.init();
        });
    };

    /**
     * @name init - initialization of the sidebar.
     */
    this.init = function () {
        this.force = 'poor';
        this.person = HoogyService.connectedFormatted || {};
        if (!this.person.photo) {
            this.person.photo = ['//placehold.it/100x100/E9E9ED/565a5c&text=', this.person.initials].join('');
        }

        if (!_.isEmpty(HoogyService.userSettings.address)) {
            this.address = HoogyService.userSettings.address;
            this.address = [this.address.city || 'Montreal', this.address.prs || 'QC'].join(', ');
        } else {
            this.address = ['Montreal', 'QC'].join(', ');
        }
        this.force = Math.floor((HoogyService.profileStrength / 150) * 100);
        this.force = [this.force, '%'].join();
    };

    /**
     * @todo - this section has to be re-worked it is a copy paste of WorkspaceController::invitationResponseHandler 
     */
    this.doneInvite = function (response) {
        var data = {
            title: 'Invite friends'
        };
        if (!response && HoogyService.invitesError) {
            response = HoogyService.invitesError;
        }

        data.message = (response || {}).message || 'Message details not Available';
        data.type = NotificationService.FAILURE;
        if (response.type === NotificationService.SUCCESS || response.success) {
            data.type = NotificationService.SUCCESS;
        }
        NotificationService.notify(data);
        this.screen.waiting = false;
    };

    /**
     * @name logout - logout on sidebar side 
     * @returns {Promise} 
     */
    this.logout = function () {
        return AuthService.logout().then(function (response) {
            $state.go('store.list');
            return response;
        }).catch(function (error) {
            NotificationService.unauthorized();
            $state.go('store.list');
            return error;
        });
    };

    /**
     * @name destroy - un-register events. 
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}
HoogySidebarController.$inject = ['$state', 'HoogyService', 'NotificationService', 'StateService', 'ActionCreator', 'UIService', 'AuthService'];
angular.module('hoogy').component('hoogySidebar', {
    controllerAs: 'vm',
    bindings: {
        screen: '='
    },
    controller: HoogySidebarController,
    templateUrl: 'js/core/sidebar.html'
});