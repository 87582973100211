'use strict';


function EditStuffController($state, $log, $location, HoogyService, NotificationService, AuthService, StuffService, UIService, StateService, ActionCreator, Angularytics) {

    var vm = this;
    
    /**
     * @name $onInit - initialization of edit stuff component.
     */
    this.$onInit = function(){
        this.screen = UIService.getEditStuffScreen({component: 'store:editstuff'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.model = StuffService.getModel();
        this.unsubscribe = this.subscribe();
        this.init($state.params);
    };


    /**
     * @name subscribe - Subscribes for Model Updates.
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = StuffService.getModel();
            vm.screen = UIService.getEditStuffScreen(vm.screen);
            vm.model.item = HoogyService.inventoryItem || vm.model.item;
            vm.model.notifications = NotificationService.getModel();                                                 
        });
    };

    /**
     * @name isAuthenticated 
     */
    this.isAuthenticated = function () {
        return AuthService.isAuthenticated(true);
    };

    /**
     * @name init - this function is called to initialize form element.
     */
    this.init = function (options) {

        var params = options || $state.params; 
        this.status = {};
        
        Angularytics.trackEvent('Store Item Adder Initialization', +1);
        
        if (!this.isAuthenticated()) {
            Angularytics.trackEvent('Store Item Adder Init - unauthorized', +1);
            return false;
        }
        if (params && !params.itemid && params.id) {
            params.itemid = params.id;
        }
        if (!params || !params.itemid) {
            throw 'Item ID missing exception';
        }
        Angularytics.trackEvent('Store Item Adder - Edit', +1);

        this.model.item = {
            _id: params.itemid
        };
        this.screen.waiting = true;
        return HoogyService
            .getItem(params.itemid)
            .then(function (response) {
                vm.model.item = HoogyService.inventoryItem || {};
                vm.screen.waiting = false;
                StateService.dispatch(ActionCreator.addStuff(HoogyService.inventoryItem));    
                Angularytics.trackEvent('Store Item Adder - Edit', vm.model.item.title);
                return response;
            }).catch(this._handleException);
    };

    /**
     * @todo --- use StuffService.upload().then(initialize/replace item)
     * This function handles data dropped in an area defined by droppableDirective
     * This section couples, and limits the overall usability of this controller.
     * it is a quick fix ( huh ), but should be refactored
     */
    vm.payload = {};
    this.drop = this.select = function (data) {
        $log.log('Drop::Select [data, vm.payload]', data, vm.payload);
        if (!data) { throw '@EditStuff::drop Requires File to be Dropped.'; }
        
        data.owner = HoogyService.getUserId();
        if (this.model.item && this.model.item._id) {
            data.itemid = this.model.item._id || this.model.item.id;
        }
        Angularytics.trackEvent('Store Item Adder -Select/Drop', +1);

        this.screen.waiting = true;
        var notice = { message: 'File uploaded!', type: NotificationService.SUCCESS};
        return HoogyService.sync(data).then(function (response) {
            vm.screen.waiting = false;
            vm.model.item = HoogyService.inventoryItem || response.data || response || {};
            vm.model.item.photo = vm.model.item.photo || data.payload;
            StateService.dispatch(ActionCreator.addStuff(vm.model.item));
            NotificationService.notify(notice);
            Angularytics.trackEvent('Store Item Adder - Uploaded Image', +1);
            return response;
        }).catch(this._handleException);
    };

    /**
     * @name save - Editing Stuff 
     * @throws ItemMissingException
     * @throws UserNotAuthenticatedException
     * @returns {Object<Promise>}
     */
    this.save = function (item) {
        if(!item || _.isEmpty(item)){
            throw 'EditStuff::save - Item should not be empty';
        }

        if (!this.isAuthenticated()) {
            Angularytics.trackEvent('Store Item Adder - Unauthorized', +1);
            throw 'You should be authenticated to save an item';
        }

        //stop double clicks while waiting for response
        if (this.screen.waiting) {
            Angularytics.trackEvent('Store Item Adder - Save Invalid', +1);
            $log.log('Detected another saving operation');
            return false;
        }

        this.screen.waiting = true;
                 
        Angularytics.trackEvent('Store Item Adder - Save', +1);
        return HoogyService.saveItem(item).then(function (response) {
            vm.screen.waiting = false;
            vm.model.item = HoogyService.inventoryItem;
            NotificationService.notify({message: 'Item created.'});
            StateService.dispatch(ActionCreator.addStuff(HoogyService.inventoryItem ));    
            Angularytics.trackEvent('Store Item Adder - Save Success', vm.model.item.title);
            $state.go('workspace.notify', {itemid: vm.model.item._id});
            return response;
        }).catch(this._handleException);
    };

    /**
     * @name doneUpdatingPricing - Callback used by Price editor
     * @param  {Object<Number>} pricing 
     * @return {void}
     */
    this.doneUpdatingPricing = function (pricing) {
        vm.model.item.pricing = pricing;
    };

    this.closeItemEditor = function () {
        this.model.item = {};
        this.status.waiting = false;
        this.screen.waiting = false;
        this.status.path = this.status.path || '/store';
                 
        Angularytics.trackEvent('Exit Item Adder - Close Item Editor');
        $location.path(this.status.path);
    };

    /**
     * @name _handleException  - Utility to handle exceptions from server. 
     * @param {Object<Error>} error 
     * @return {Promise<Error>}
     */
    this._handleException = function (error) {
        vm.screen.waiting = false;
        var notice = NotificationService.formatStoreFailureMessage(error);
        NotificationService.report(notice);      
        Angularytics.trackEvent('Store Item Adder Exception', notice.message);
        return error;
    };
    
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };

}

EditStuffController.$inject = ['$state', '$log', '$location', 'HoogyService', 'NotificationService', 'AuthService','StuffService', 'UIService', 'StateService', 'ActionCreator', 'Angularytics'];
angular.module('hoogy').component('editStuff', {
    controllerAs: 'vm',
    controller: EditStuffController,
    templateUrl: 'js/stuff/view/edit.html'
});