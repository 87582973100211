'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/ 


/**
 * @name HoogyTileController - Displaying Tile for an Item
 */
function HoogyTileController($state, $log, $timeout, HoogyService, StateService, ActionCreator, Angularytics) {
    
    var vm = this;
    this.model = {item: {}};
    this.$onInit = function(){
        this.hover = false; 
        this.isOwner = false;

        this.model.item = this.item;
        if(this.model.item){
            if(!this.model.item.id && this.model.item._id){
                this.model.item.id = this.model.item._id;
            }
        }
        this.evaluateOwner();
        this.css = { active: false };
        $timeout.cancel(this.handler);
        this.handler = $timeout(function(){ vm.css.active = true; }, 50);
    };

    /**
     * @name evaluateOwner - Checks if Current Person is Item Owner.
     */
    this.evaluateOwner = function () {
        this.isOwner = false;
        this.model.item = this.item; 
        var _owner = HoogyService.getUser();
        var _id = _owner.id || _owner._id;
        if (this.model.item && this.model.item.owner && _id) {
            this.isOwner = this.model.item.owner._id === _id;
            if (typeof this.model.item.owner === 'string') {
                this.isOwner = this.model.item.owner === _id;
            }
        }
    };


    /**
    * @todo goto /workspace/item/:id
    * @param {Object<Item>} item
    */
    vm.edit = function(item) {
        vm.evaluateOwner();
        var id = (item || vm.model.item).id || (item || vm.model.item)._id; 
        $log.log('HoogyTile::rentThis [goto] ');
        if(typeof vm.goto !== 'function' || vm.isOwner){
            Angularytics.trackEvent('Tile Edit Inventory - Initialized', +1);
            StateService.dispatch(ActionCreator.addStuff(this.model.item));
            return $state.go('stuff.edit', {id: id});
        }
        return vm.goto({ item: item || vm.model.item });
    };

    /**
     * @todo must be revisited in upcoming design
     * @param {Object<Item>} item
     */
    this.heart = function heart(item) {
        this.model.item = item; 
        this.suggest(this.model.item);
    };

    /**
     * @name share - Lanches Sharer UI
     *             - Owner or Non-Owner can Share the item
     * @param {Object<Item>} item - Optional Item Object
     */
    this.share = function(item){
        this.model.item = item; 
        var payload = Object.assign({},{item: item || vm.model.item});
        StateService.dispatch(ActionCreator.addRentalItem(payload.item));
        StateService.dispatch(ActionCreator.startSharerScreen(payload));
    };
    
    /**
     * @name mouseover - This function is used with  ng-mouseover.
     */
    this.mouseover = function mouseover() {
        this.hover = true;
        this.evaluateOwner();
    };
    /**
     * @name mouseout - This function is used with  ng-mouseleave/mouseout.
     */
    this.mouseout = function mouseout() {
        this.hover = false;
    };

    /**
     * @name $onDestroy - Destroying objets 
     */
    this.$onDestroy = function(){
        this.model.item = {}; 
        this.css = { active: false };
        $timeout.cancel(this.handler);
        this.handler = null;
    };


}
HoogyTileController.$inject = ['$state', '$log', '$timeout', 'HoogyService',  'StateService', 'ActionCreator', 'Angularytics'];
angular.module('hoogy').component('hoogyTile', {
    controllerAs: 'vm',
    controller: HoogyTileController,
    templateUrl: 'js/common/components/view/tile.html',
    bindings: {item: '=', settings:'=?', suggest: '&', goto: '&' }
});