'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * A Utility function used to format SSN - Canadian and American
 */
function Ssn() {
    /**@link http://stackoverflow.com/a/8935649/132610*/
    function link(scope, element, attrs, ctrl) {
        element.on('keyup', function () {
            scope.$apply(function () {
                var isValid = /^\d+$/.test($(element).val()) && $(element).val().length === 4;
                ctrl.$setValidity('ssn', isValid);
            });
        });

        /**
         * Cleanup - try to destroy memory leak substible 
         */
        function cleanup() {
            if (angular.isElement(this)) { return scope.$destroy(); }
            if (typeof angular.element(element).off === 'function') { angular.element(element).off(); }
            if (element && typeof $(element).off === 'function') {
                $(element).off();
            }
            if (element && typeof element.off === 'function') { element.off(); }
        }
        scope.$on('$destroy', cleanup);
        element.on('$destroy', cleanup);
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        link: link
    };

}
angular.module('hoogy').directive('ssn', Ssn);

