'use strict';
/**
 * @name NotificationsController - Displays a collection of notifications. 
 * @param Angularytics 
 */
function NotificationsController(Angularytics, NotificationService, StateService, ActionCreator, UIService) {
    var vm = this;
  
    /**
     * 
     */
    this.$onInit = function(){
        this.screen = UIService.getNotificationsScreen({component: 'workspace:notification'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        StateService.dispatch(ActionCreator.showHideRootLoading({value: false}));        
        StateService.dispatch(ActionCreator.showHideSidebar({value: false}));  
        this.model = NotificationService.getModel();
        this.unsubscribe = this.subscribe();
        this.init();
    };


    /**
     * @name subscribe - Subscribes current for updates on list of notifications. 
     * @returns {Function} unsubscribe 
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = NotificationService.getModel();
            vm.screen = UIService.getNotificationsScreen(vm.screen);
        });
    }
    /**
     * @name init - initialization of notification screen. 
     * @todo  Add more functions, if there are some used within <notifications/>
     * @name notifications - Most of search/pagination functionalities are delegated to NotificationsWidget
     * @return {void}
     */
    this.init = function () {
        this.omnibox = false;//shows the omnibox
        Angularytics.trackEvent('Workspace Notifications - Initialized', +1);
    };
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}
NotificationsController.$inject = ['Angularytics', 'NotificationService', 'StateService', 'ActionCreator', 'UIService'];
angular.module('hoogy').component('notifications', {
    controllerAs: 'vm',
    templateUrl: 'js/workspace/view/notifications.html',
    controller: NotificationsController
});