'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * @name Scroller 
 * Directive used for auto-scroll on message reception.
 * The code is based in part from QA answers at 
 * @link http://stackoverflow.com/a/2664878/132610
 */
function Scroller(MessengerService, StateService) {
    return {
        restrict: 'A',
        link: function ($scope, $element) {
            var size = 0, models;
            var handler = StateService.subscribe(function(){
                models = MessengerService.getModel();
                //same model count doesn't influence scrollUp/Down
                if(size === models.length){ return; }
                size = models.length;
                _.debounce(function () {$($element).scrollTop($($element)[0].scrollHeight);}, 500)();
            });
            /**
             * @name cleanup - Cleanup - try to destroy memory leak substible 
             */
            function cleanup() {
                if (angular.isElement(this)) { return $scope.$destroy(); }
                if ($element && typeof $element.off === 'function') $element.off();
                if ($element && typeof $($element).off === 'function') $($element).off();
                if(typeof handler === 'function') handler();
                handler = null;
            }
            $scope.$on('$destroy', cleanup);
            $element.on('$destroy', cleanup);
            $($element).on('$destroy', cleanup);
        }
    }
}

Scroller.$inject = ['MessengerService', 'StateService'];
angular.module('hoogy').directive('hoogyMessageScroller', Scroller);
