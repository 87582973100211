'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
//Handles Talk Input Keystrokes and, later, file sharing
function HoogyTalk() {
    return {
        scope: { query: '=', next: '&' },
        link: function link($scope, $element, $attrs, $ctrl) {
            $($element).on('keypress', function (event) {
                if (!$scope.query || !($scope.query || '').trim().length) {
                    return;
                }
                if ((event.keyCode || event.which) === 13 && $scope.query.length > 0) {
                    $scope.next({ payload: $scope.query });
                    event.preventDefault();
                }
            });
            /**
             * Cleanup - try to destroy memory leak substible 
             */
            function cleanup() {
                if (angular.isElement(this)) { return $scope.$destroy(); }
                if ($element && typeof $element.off === 'function') {
                    $element.off();
                }
            }
            $scope.$on('$destroy', cleanup);
            $element.on('$destroy', cleanup);
        },
        restrict: 'A'
    };
}
angular.module('hoogy').directive('hoogyTalk', HoogyTalk);
