'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/

function RentalWidgetController($log, $state, MessengerService, HoogyService, StateService, NotificationService, CheckoutService, RentingService) {
  var vm = this;

  /**
   * @name $onInit - initialization handler 
   */
  this.$onInit = function () {
    this.count = 0;
    this.durl = MessengerService.AVATAR;
    this.model = RentingService.getModel();
    this.unsubscribe = this.subscribe();
    this.init($state.params);
  };

  /**
   * @name subscribe - Updates Items + Renting person Details. 
   */
  this.subscribe = function () {
    return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
      vm.model.person = RentingService.getModel().person || vm.model.person;
      vm.model.items = RentingService.getModel().items;
      vm.model.notifications = NotificationService.getModel(); 
    });
  };

  /**
   * @name init - 
   * @return {Object} 
   */
  this.init = function (options) {
    //initialize model to  inherited model
    var params = options || $state.params || {};
    return RentingService.getRecipient(params).then(RentingService.search)
      .then(function(response) {
        vm.model.person = RentingService.getModel().person || vm.model.person;
        vm.model.items = RentingService.getModel().items;
        return response;
      });
  };


  /**
   * @name setItem - Setting Items 
   * @param {Object<Item>} item - Item 
   */
  this.setItem = function (item) {
    this.model.item = Object.assign({},item, {id: item._id || item.id});
    this.model.order = CheckoutService.checkoutItemRequest(this.model.item);
    $log.log("RentalWidget::setItem", this.model.item);
  };

  /**
   * @name rent 
   * @todo verify information + send rent request.
   * @todo a person can rent different items.
   * @todo renting same object, will result in period extension.
   * @name
   * @param  {Object} item 
   * @return {Object}      
   */
  this.rent = function (item) {
    if (!item && _.isEmpty(this.model.item)) {
      throw 'Item is Required to Rent';
    }
    this.model.order.item = item || this.model.item;
    return RentingService.rentItem(this.model.order).then(function (response) {
      //@todo --- show success notificaiton + startover renting[do you want rent more stuff to same user?]
      $log.log("RentalWidget::rent", response.data, vm.model.order);
      return response;
    });
  };

  /**
   * @name more - More Functions 
   * @todo search for more content starting from latest
   */
  this.more = function () {
    //@todo set starting
    var last = this.model.items[this.model.items.length - 1];
    if (last && last._id) {
      last = last._id;
      this.model.search.starting = last;
    }
    return this.search(this.model.search);
  };

  /**
   * @name search - Function to make an omnisearch + re-initialize search values
   * @return {Promise} 
   */
  this.search = function () {
    return RentingService
      .search(this.model.search)
      .then(function (response) {
        vm.model.item = {};
        vm.model.person = RentingService.getModel().person || vm.model.person;
        vm.model.items = RentingService.getModel().items;
        return response;
      });
  };

  /**
   * @name chengeRentalPeriod - After changing 5 days/1week or a month/, recalculate price
   * will be used to change rental period
   * This function is a copy/carbon of CheckoutController::changedRentalPeriod
   * @param {Object<Period>} period
   */
  this.changedRentalPeriod = function (period) {
    //user selects 10 Days, Item charges per day - total time is calculated as following
    HoogyService.checkout.total = HoogyService.calculatePrice(this.model.item, period);
    this.model.payment.cost = HoogyService.checkout.total;
    HoogyService.checkout.period = period;
    $log.log("RentalWidget::changedRentalPeriod", period, this.model.order);
  };

  /**
   * @name $onDestroy - Cleans Objects when Components
   */
  this.$onDestroy = function () {
    if (typeof this.unsubscribe === 'function') {
      this.unsubscribe();
    }
    this.unsubscribe = null;
  };

}
RentalWidgetController.$inject = ['$log', '$state', 'MessengerService', 'HoogyService', 'StateService', 'NotificationService', 'CheckoutService', 'RentingService'];
angular.module('hoogy').component('hoogyRentalWidget', {
  controllerAs: 'vm',
  controller: RentalWidgetController,
  templateUrl: 'js/common/components/view/rentalwidget.html'
});