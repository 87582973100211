'use strict';
/**
 * @name Actions - Object that lists all available Actions. 
 * @return {Object} actions
 * @example
 *  <code>
 *  ActionCreator::addMessage(payload){
 *      return {Actions.ADD_MESSAGE, payload: payload }
 *  }
 * </code>
 */
function Actions() {
    return {

        //Linking/Unlinking/Signup/Signin
        ADD_LINK_ACCOUNT: 'add:link:account',
        ADD_UNLINK_ACCOUNT: 'add:unlink:account',
        ADD_SIGNUP_ACCOUNT: 'add:signup:account',
        ADD_SIGNIN_ACCOUNT: 'add:signin:account',

        //Screen 
        SCREEN_SHOW_SIDEBAR: 'screen:show:hide:sidebar',
        SCREEN_SHOW_USERMENU: 'screen:show:hide:usermenu',
        SCREEN_SHOW_SHARER: 'show:sharer:screen',

        SCREEN_SHOW_HIDE_MENU: 'show:hide:menu',
        SCREEN_SHOW_HIDE_SIDEBAR: 'show:hide:sidebar',
        SCREEN_HIDE_SHARER: 'hide:sharer:screen',

        //Loading => Root Level Loader, quite different from Waiting(Local Equivalent)
        SCREEN_SHOW_HIDE_ROOT_LOADING: 'screen:show:hide:root:loading',
        SCREEN_SHOW_HOMEPAGE: 'screen:root:homepage',
        ADD_SCREEN: 'add:screen',
        REMOVE_SCREEN: 'remove:screen',
        UPDATE_SCREEN: 'update:screen',

        //Auth related actions. 
        LOGOUT: 'logout',
        ADD_AUTH: 'add:auth:token',
        REMOVE_AUTH: 'remove:auth:token',

        //User: Currently Connected User or ME
        ADD_USER: 'add:me',
        ADD_CARDS: 'add:cards',
        ADD_REVIEW: 'add:review',
        ADD_ADDRESS: 'add:address',
        ADD_REVIEWS: 'add:reviews',
        REMOVE_USER: 'remove:me',
        UPDATE_USER: 'update:me',

        //Messenger
        ADD_MESSENGER: 'add:messenger',
        ADD_MESSENGER_INTENTS: 'add:messenger:intents',
        ADD_MESSENGER_MESSAGE: 'add:messenger:message',
        ADD_MESSENGER_MESSAGES: 'add:messenger:messages',
        ADD_CONVERSATIONS: 'add:messenger:conversations',
        ADD_MESSENGER_RECIPIENT: 'add:messenger:message:recipient',
        ADD_MESSENGER_UNREAD_MESSAGES: 'add:messenger:unread:messages',

        //Message
        ADD_MESSAGE: 'add:message',
        REMOVE_MESSAGE: 'remove:message',
        UPDATE_MESSAGE: 'update:message',
        SEARCH_MESSAGE: 'search:message',
        REMOVE_ALL_MESSAGES: 'remove:all:messages',

        //Notification
        ADD_NOTIFICATION: 'add:notification',
        REMOVE_NOTIFICATION: 'remove:notification',
        UPDATE_NOTIFICATION: 'update:notification',
        SEARCH_NOTIFICATION: 'search:notification',
        FIND_NOTIFICATION: 'find:notification',
        REMOVE_ALL_NOTIFICATIONS: 'remove:all:notifications',

        // Diagnosis message
        ADD_INVENTORY_NOTIFICATION: 'add:inventory:notification',
        ADD_MORE_INVENTORY_NOTIFICATION: 'update:inventory:notification',
        INVITE_FRIENDS_NOTIFICATION: 'invite:friends:notification',
        INVITE_MORE_FRIENDS_NOTIFICATION: 'invite:more:friends:notification',
        ADD_CONTRACT_NOTIFICATION: 'add:contract:notification',
        UPDATE_CONTRACT_NOTIFICATION: 'update:contract:notification',
        ADD_PAYMENT_INFO_NOTIFICATION: 'add:payment:notification',
        UPDATE_PAYMENT_INFO_NOTIFICATION: 'update:payment:notification',
        ADD_ADDRESS_NOTIFICATION: 'add:address:notification',
        UPDATE_ADDRESS_NOTIFICATION: 'update:address:notification',
        ADD_SETTINGS_NOTIFICATION: 'update:settings:notification',
        UPDATE_PERSONALINFO_NOTIFICATION: 'update:personal:notification',
        ADD_ORDER_NOTIFICATION: 'add:order:notification',
        ADD_CONTACTS_NOTIFICATION: 'add:contacts:notification',
        ADD_FAVORITE_NOTIFCATION: 'add:favorite:notification',
        ADD_MESSAGES_NOTIFCATION: 'add:messages:notification',
        ADD_FOLLOWERS_NOTIFICATION: 'add:followers:notification',
        //new updates 
        ADD_REVIEW_NOTIFICATION: 'add:review:notification',
        UPDATE_REVIEW_NOTIFICATION: 'update:review:notification',
        ADD_WHISHLIST_NOTIFICATION: 'add:wishilist:notification',
        ADD_WALLET_NOTIFICATION: 'add:wallet:notification',
        ADD_ORDERS_NOTIFICATION: 'add:orders:notification',
        ADD_INVENTORY_NOTIFICATION: 'add:inventory:notification',
        ADD_PROFILE_REVIEW_NOTIFICATION: 'add:profile:review:notification',

        //People+Contact
        ADD_PERSON: 'add:person',
        REMOVE_PERSON: 'remove:person',
        UPDATE_PERSON: 'update:person',

        ADD_PROFILE: 'add:profile',
        UPDATE_PROFILE: 'update:profile',
        REMOVE_PROFILE: 'remove:profile',

        REMOVE_CONTACT: 'remove:contact',

        ADD_PEOPLE: 'add:people',
        UPDATE_PEOPLE: 'update:people',
        SEARCH_PEOPLE: 'search:people',
        REMOVE_PEOPLE: 'remove:people',
        ADD_INVITED_FRIENDS: 'add:invited:friends',

        //Settings @todo => Add :settings on all these settings commands
        UPDATE_GOOGLE: 'update:google',
        REMOVE_GOOGLE: 'remove:google',
        ADD_GOOGLE: 'add:google',

        REMOVE_FACEBOOK: 'remove:facebook',
        UPDATE_FACEBOOK: 'update:facebook',
        ADD_FACEBOOK: 'add:facebook',

        UPDATE_PASSWORD: 'update:password',
        REMOVE_PASSWORD: 'remove:password',
        ADD_PASSWORD: 'add:password',

        UPDATE_PHOTO: 'update:photo',
        REMOVE_PHOTO: 'remove:photo',
        ADD_PHOTO: 'add:photo',

        ADD_WALLET: 'add:wallet',
        UPDATE_WALLET: 'update:wallet',
        REMOVE_WALLET: 'remove:wallet',

        ADD_PAYMENT: 'add:payment',
        REMOVE_PAYMENT: 'remove:payment',
        UPDATE_PAYMENT: 'update:payment',

        UPDATE_LOCALIZATION: 'update:localization',
        REMOVE_LOCALIZATION: 'remove:localization',
        ADD_LOCALIZATION: 'add:localization',

        RECOVER_ACCOUNT: 'recover:account',
        AUTHENTICATE: 'authenticate',
        UNAUTHORIZED: 'unauthorized',

        FIND_ITEM: 'find:item',
        ADD_FAVORITE_ITEM: 'add:favorite:item',
        
        ADD_CONTACT: 'add:contact',
        UPDATE_CONTACT: 'update:contact',
        REMOVE_CONTACT: 'remove:contact',
        SEARCH_CONTACT: 'search:contact',
        REMOVE_ALL_CONTACT: 'remove:all:contact',

        ADD_CONTRACT: 'add:contract',
        UPDATE_CONTRACT: 'update:contract',
        REMOVE_CONTRACT: 'remove:contract',
        SEARCH_CONTRACT: 'search:contract',
        REMOVE_ALL_CONTRACT: 'remove:all:contract',

        //Actitity
        ADD_ACTIVITY: 'add:activity',
        UPDATE_ACTIVITY: 'update:activity',
        REMOVE_ACTIVITY: 'remove:activity',
        SEARCH_ACTIVITY: 'search:activity',
        REMOVE_ALL_ACTIVITIES: 'remove:all:activities',

        //Item
        ADD_ITEM: 'add:item',
        REMOVE_ITEM: 'remove:item',
        UPDATE_ITEM: 'update:item',
        SEARCH_ITEM: 'search:item',
        REMOVE_ALL_ITEMS: 'remove:all:items',

        //Stuff
        ADD_STUFF: 'add:stuff',
        UPDATE_STUFF: 'update:stuff',
        REMOVE_STUFF: 'remove:stuff',
        ADD_OWNER_STUFF: 'add:provider:stuff',
        ADD_PROVIDER_STUFF: 'add:provider:stuff',

        //Order
        ADD_ORDER: 'add:order',
        REMOVE_ORDER: 'remove:order',
        UPDATE_ORDER: 'update:order',
        SEARCH_ORDER: 'search:order',
        REMOVE_ALL_ORDERS: 'remove:all:orders',

        //Rental 
        UPDATE_RENTAL: 'update:rental',
        ADD_RENTAL: 'add:rental',
        REMOVE_RENTAL: 'remove:rental',

        ADD_SEARCH_RENTAL: 'add:rental:search',
        ADD_PAYMENT_RENTAL: 'add:rental:payment',
        ADD_ORDER_RENTAL: 'add:rental:order',
        ADD_PERSON_RENTAL: 'add:rental:person',
        ADD_ITEM_RENTAL: 'add:rental:item',
        ADD_ITEMS_RENTAL: 'add:rental:items',
        ADD_PEOPLE_RENTAL: 'add:rental:people',

        //Checkout 
        ADD_CHECKOUT: 'add:checkout',
        ADD_CHECKOUT_ITEM: 'add:checkout:item',
        REMOVE_CHECKOUT_ITEM: 'remove:checkout:item',
        UPDATE_CHECKOUT_ITEM: 'update:checkout:item',
        ADD_CHECKOUT_ORDER: 'add:checkout:order',
        UPDATE_CHECKOUT_ORDER: 'update:checkout:order',
        ADD_CHECKOUT_PAYMENT: 'add:checkout:payment',
        ADD_CHECKOUT_CONTRACT: 'add:checkout:contract',


        ADD_CHECKOUT_SHIPPING: 'add:checkout:shipping',
        ADD_CHECKOUT_PERIOD: 'add:checkout:period',
        ADD_CHECKOUT_PRICING: 'add:checkout:pricing',

        //Omnisearch
        ADD_OMNISEARCH: 'add:omnisearch:result',
        REMOVE_OMNISEARCH: 'remove:omnisearch:result',
        UPDATE_OMNISEARCH: 'update:omnisearch:result',
        SEARCH_OMNISEARCH: 'search:omnisearch:result'
    };
}



/**
 * @uses Actions 
 * @name ActionCreator - Object to create Actions. 
 */
function ActionCreator(Actions) {
    var service = {

        //Account
        signup: signup,
        signin: signin,
        logout: logout,
        unauthorized: unauthorized,
        authenticate: authenticate,
        linkAccount: linkAccount,
        unlinkAccount: unlinkAccount,


        //Screen
        topScreen: topScreen,
        addScreen: addScreen,
        showHideMenu: showHideMenu,
        showHideSidebar: showHideSidebar,
        showUsermenu: showUsermenu,
        removeScreen: removeScreen,
        updateScreen: updateScreen,
        startSharerScreen: startSharerScreen,
        stopSharerScreen: stopSharerScreen,
        
        showHideRootLoading: showHideRootLoading,

        //USER => ME
        addUser: addUser,
        addCards: addCards,
        addReview: addReview,
        addReviews: addReviews,
        updateUser: updateUser,
        removeUser: removeUser,
        addAddress: addAddress,

        //User::Settings 
        updateContact: updateContact,
        addContact: addContact,
        removeContact: removeContact,
        addContract: addContract,
        updateContract: updateContract,
        removeContract: removeContract,

        addFacebook: addFacebook,
        updateFacebook: updateFacebook,
        removeFacebook: removeFacebook,

        addGoogle: addGoogle,
        removeGoogle: removeGoogle,
        updateGoogle: updateGoogle,

        addLocalization: addLocalization,
        removeLocalization: removeLocalization,
        updateLocalization: updateLocalization,

        addPayment: addPayment,
        removePayment: removePayment,
        updatePayment: updatePayment,

        addWallet: addWallet,
        removeWallet: removeWallet,
        updateWallet: updateWallet,

        addPhoto: addPhoto,
        removePhoto: removePhoto,
        updatePhoto: updatePhoto,

        addPassword: addPassword,
        removePassword: removePassword,
        updatePassword: updatePassword,

        removeProfile: removeProfile,
        updateProfile: updateProfile,
        recoverAccount: recoverAccount,

        //authentication
        addAuth: addAuth,
        removeAuth: removeAuth,

        //Messenger Messages
        onMessage: onMessage,
        addIntents: addIntents,
        addMessenger: addMessenger,
        addConversations: addConversations,
        addMessageRecipient: addMessageRecipient,
        addUnreadMessage: addUnreadMessage,

        //Message
        addMessage: addMessage,
        removeMessage: removeMessage,
        updateMessage: updateMessage,
        searchMessage: searchMessage,
        removeAllMessages: removeAllMessages,

        //Notification
        addNotification: addNotification,
        updateNotification: updateNotification,
        removeNotification: removeNotification,
        findNotification: findNotification,
        searchNotification: searchNotification,
        removeAllNotifications: removeAllNotifications,

        //Person => contact
        addPerson: addPerson,
        removePerson: removePerson,
        updatePerson: updatePerson,

        //People => contacts and everyone
        addPeople: addPeople,
        addProfile: addProfile,
        removePeople: removePeople,
        updatePeople: updatePeople,
        searchPeople: searchPeople,
        //alias to searchPeople
        findPerson: searchPeople,
        removeContact: removeContact,
        addUserInvitedFriends: addUserInvitedFriends,

        //Activities => notifications 
        addActivity: addActivity,
        updateActivity: updateActivity,
        removeActivity: removeActivity,
        findActivity: searchActivity,
        searchActivity: searchActivity,
        removeAllActivities: removeAllActivities,

        //Item
        addItem: addItem,
        removeItem: removeItem,
        updateItem: updateItem,
        searchItem: searchItem,
        removeAllItems: removeAllItems,

        //Stuff
        addStuff: addStuff,
        updateStuff: updateStuff,
        removeStuff: removeStuff,
        findItem: findItem,
        addProvider: addProvider,
        addFavorite: addFavorite,

        //Order
        addOrder: addOrder,
        removeOrder: removeOrder,
        updateOrder: updateOrder,
        searchOrder: searchOrder,

        //Rental
        addRental: addRental,
        removeRental: removeRental,
        removeAllOrders: removeAllOrders,
        updateRental: updateRental,
        addRentalSearch: addRentalSearch,
        addRentalPayment: addRentalPayment,
        addRentalOrder: addRentalOrder,
        addRentalPerson: addRentalPerson,
        addRentalPeople: addRentalPeople,
        addRentalItem: addRentalItem,
        addRentalItems: addRentalItems,

        //Checkout Event
        addCheckout: addCheckout,
        addCheckoutItem: addCheckoutItem,
        addCheckoutOrder: addCheckoutOrder,
        removeCheckoutItem: removeCheckoutItem,
        updateCheckoutOrder: updateCheckoutOrder,
        addCheckoutShipping: addCheckoutShipping,
        addCheckoutPeriod: addCheckoutPeriod,
        addCheckoutContract: addCheckoutContract,
        addCheckoutPayment: addCheckoutPayment,
        addCheckoutPricing: addCheckoutPricing,

        //these variables should be took into account
        diagnoseMe: diagnoseMe,
        diagnoseSettings: diagnoseSettings,
        diagnoseWishlist: diagnoseWishlist,
        diagnoseOrder: diagnoseOrder,
        diagnoseOrders: diagnoseOrders,
        diagnoseItems: diagnoseItems,
        diagnoseInventory: diagnoseItems,
        diagnoseReviews: diagnoseReviews,
        addMoreInventory: addMoreInventory,

        //Diagnose Service.
        diagnoseAddress: diagnoseAddress,
        diagnoseWallet: diagnoseWallet,
        diagnoseContract: diagnoseContract,
        diagnoseInvitations: diagnoseInvitations,
        diagnoseUpdateAddress: diagnoseUpdateAddress,
        diagnoseUpdatePayment: diagnoseUpdatePayment,
        diagnoseUpdateContract: diagnoseUpdateContract,

        inviteMoreFriends: inviteMoreFriends,
        diagnoseContacts: diagnoseContacts,
        diagnoseFollowers: diagnoseFollowers,
        diagnoseFavorites: diagnoseFavorites,
        diagnoseMessages: diagnoseMessages,

        omnisearch: omnisearch

    };

    return service;


    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.logout(payload)
     * )
     * </code>
     * @name logout - Creates Add Favorite Item Payload
     * @param {Object} payload 
     */
    function addFavorite(payload){
        if (!payload) throw 'Payload Not Found @addFavorite';
        return {
            type: Actions.ADD_FAVORITE_ITEM,
            value: payload.value || payload
        };
    }


    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.logout(payload)
     * )
     * </code>
     * @name logout - Creates Find Item Payload
     * @param {Object} payload 
     */
    function logout(payload) {
        if (!payload) throw 'Payload Not Found @logout';
        return {
            type: Actions.LOGOUT,
            value: payload.value || payload
        };
    }

    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.findItem(payload)
     * )
     * </code>
     * @name findItem - Creates Find Item Payload
     * @param {Object} payload 
     */
    function findItem(payload) {
        if (!payload) throw 'Payload Not Found @findItem';
        return {
            type: Actions.FIND_ITEM,
            value: payload.value || payload
        };
    }


    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.showHideRootLoading(payload)
     * )
     * </code>
     * @name showHideRootLoading - Creates Show HideRoot Loading Payload
     * @param {Object} payload 
     */
    function showHideRootLoading(payload) {
        if (!payload) throw 'Payload Not Found @showHideRootLoading';
        if(payload.value && typeof payload.value !== 'boolean') throw '@showHideRootLoading - Expects a Boolean';
        return {
            type: Actions.SCREEN_SHOW_HIDE_ROOT_LOADING,
            value: payload.value
        };
    }


    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.showHideMenu(payload)
     * )
     * </code>
     * @name showHideMenu - Creates Show HideMenu Payload
     * @param {Object} payload 
     */
    function showHideMenu(payload) {
        if (!payload) throw 'Payload Not Found @showHideMenu';
        if(typeof payload.value !== 'boolean') throw '@showHideMenu - Expects a Boolean';
        return {
            type: Actions.SCREEN_SHOW_HIDE_MENU,
            value: payload.value
        };
    }

    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.unauthorized(payload)
     * )
     * </code>
     * @name unauthorized - Creates unauthorized Payload
     * @param {Object} payload 
     */
    function unauthorized(payload) {
        if (!payload) throw 'Payload Not Found @unauthorized';
        return {
            type: Actions.UNAUTHORIZED,
            value: payload.value || payload
        };
    }

    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.authenticate(payload)
     * )
     * </code>
     * @name authenticate - Creates Authenticate Payload
     * @param {Object} payload 
     */
    function authenticate(payload) {
        if (!payload) throw 'Payload Not Found @authenticate';
        return {
            type: Actions.AUTHENTICATE,
            value: payload.value || payload
        };
    }

    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.recoverAccount(payload)
     * )
     * </code>
     * @name recoverAccount - Creates Recover Account Payload
     * @param {Object} payload 
     */
    function recoverAccount(payload) {
        if (!payload) throw 'Payload Not Found @recoverAccount';
        return {
            type: Actions.RECOVER_ACCOUNT,
            value: payload.value || payload
        };
    }

    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addProfile(payload)
     * )
     * </code>
     * @name addProfile - Creates Add Profile Payload
     * @param {Object} payload 
     */
    function addProfile(payload) {
        if (!payload) throw 'Payload Not Found @addProfile';
        return {
            type: Actions.ADD_PROFILE,
            value: payload.value || payload
        };
    }



    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updateProfile(payload)
     * )
     * </code>
     * @name updateProfile - Creates Update Profile Payload
     * @param {Object} payload 
     */
    function updateProfile(payload) {
        if (!payload) throw 'Payload Not Found @updateProfile';
        return {
            type: Actions.UPDATE_PROFILE,
            value: payload.value || payload
        };
    }


    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeProfile(payload)
     * )
     * </code>
     * @name removeProfile - Creates Remove Profile Payload
     * @param {Object} payload 
     */
    function removeProfile(payload) {
        if (!payload) throw 'Payload Not Found @removeProfile';
        return {
            type: Actions.REMOVE_PROFILE,
            value: payload.value || payload
        };
    }


    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addLocalization(payload)
     * )
     * </code>
     * @name addLocalization - Creates Add Localization Payload
     * @param {Object} payload 
     */
    function addLocalization(payload) {
        if (!payload) throw 'Payload Not Found @addLocalization';
        return {
            type: Actions.ADD_LOCALIZATION,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeLocalization(payload)
     * )
     * </code>
     * @name removeLocalization - Creates Remove Localization Payload
     * @param {Object} payload 
     */
    function removeLocalization(payload) {
        if (!payload) throw 'Payload Not Found @removeLocalization';
        return {
            type: Actions.REMOVE_LOCALIZATION,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updateLocalization(payload)
     * )
     * </code>
     * @name updateLocalization - Creates Update Localization Payload
     * @param {Object} payload 
     */
    function updateLocalization(payload) {
        if (!payload) throw 'Payload Not Found @updateLocalization';
        return {
            type: Actions.UPDATE_LOCALIZATION,
            value: payload.value || payload
        };
    }

    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addPayment(payload)
     * )
     * </code>
     * @name addPayment - Creates Add Payment Payload
     * @param {Object} payload 
     */
    function addPayment(payload) {
        if (!payload) throw 'Payload Not Found @addPayment';
        return {
            type: Actions.ADD_PAYMENT,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updateContact(payload)
     * )
     * </code>
     * @name updateContact - Creates Update Contact Payload
     * @param {Object} payload 
     */
    function removePayment(payload) {
        if (!payload) throw 'Payload Not Found @updateContact';
        return {
            type: Actions.REMOVE_PAYMENT,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updatePayment(payload)
     * )
     * </code>
     * @name updatePayment - Creates Update Contact Payload
     * @param {Object} payload 
     */
    function updatePayment(payload) {
        if (!payload) throw 'Payload Not Found @updatePayment';
        return {
            type: Actions.UPDATE_PAYMENT,
            value: payload.value || payload
        };
    }

    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addWallet(payload)
     * )
     * </code>
     * @name addWallet - Creates Add Wallet Payload
     * @param {Object} payload 
     */
    function addWallet(payload) {
        if (!payload) throw 'Payload Not Found @addWallet';
        return {
            type: Actions.ADD_WALLET,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeWallet(payload)
     * )
     * </code>
     * @name removeWallet - Creates Update Contact Payload
     * @param {Object} payload 
     */
    function removeWallet(payload) {
        if (!payload) throw 'Payload Not Found @removeWallet';
        return {
            type: Actions.REMOVE_WALLET,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updateWallet(payload)
     * )
     * </code>
     * @name updateWallet - Creates Update Wallet Payload
     * @param {Object} payload 
     */
    function updateWallet(payload) {
        if (!payload) throw 'Payload Not Found @updateWallet';
        return {
            type: Actions.UPDATE_WALLET,
            value: payload.value || payload
        };
    }

    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addPhoto(payload)
     * )
     * </code>
     * @name addPhoto - Creates Add Photo Payload
     * @param {Object} payload 
     */
    function addPhoto(payload) {
        if (!payload) throw 'Payload Not Found @addPhoto';
        return {
            type: Actions.ADD_PHOTO,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removePhoto(payload)
     * )
     * </code>
     * @name removePhoto - Creates Remove Photo Payload
     * @param {Object} payload 
     */
    function removePhoto(payload) {
        if (!payload) throw 'Payload Not Found @removePhoto';
        return {
            type: Actions.REMOVE_PHOTO,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updatePhoto(payload)
     * )
     * </code>
     * @name updatePhoto - Creates Update Photo Payload
     * @param {Object} payload 
     */
    function updatePhoto(payload) {
        if (!payload) throw 'Payload Not Found @updatePhoto';
        return {
            type: Actions.UPDATE_PHOTO,
            value: payload.value || payload
        };
    }

    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addPassword(payload)
     * )
     * </code>
     * @name addPassword - Creates Add Password Payload
     * @param {Object} payload 
     */
    function addPassword(payload) {
        if (!payload) throw 'Payload Not Found @addPassword';
        return {
            type: Actions.ADD_PASSWORD,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removePassword(payload)
     * )
     * </code>
     * @name removePassword - Creates Remove Password Payload
     * @param {Object} payload 
     */
    function removePassword(payload) {
        if (!payload) throw 'Payload Not Found @removePassword';
        return {
            type: Actions.REMOVE_PASSWORD,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updatePassword(payload)
     * )
     * </code>
     * @name updatePassword - Creates Update Password Payload
     * @param {Object} payload 
     */
    function updatePassword(payload) {
        if (!payload) throw 'Payload Not Found @updatePassword';
        return {
            type: Actions.UPDATE_PASSWORD,
            value: payload.value || payload
        };
    }


    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updateContact(payload)
     * )
     * </code>
     * @name updateContact - Creates Update Contact Payload
     * @param {Object} payload 
     */
    function updateContact(payload) {
        if (!payload) throw 'Payload Not Found @updateContact';
        return {
            type: Actions.UPDATE_CONTACT,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addContact(payload)
     * )
     * </code>
     * @name addContact - Creates Add Contact Payload
     * @param {Object} payload 
     */
    function addContact(payload) {
        if (!payload) throw 'Payload Not Found @addContact';
        return {
            type: Actions.ADD_CONTACT,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeContact(payload)
     * )
     * </code>
     * @name removeContact - Creates Remove Contact Payload
     * @param {Object} payload 
     */
    function removeContact(payload) {
        if (!payload) throw 'Payload Not Found @removeContact';
        return {
            type: Actions.REMOVE_CONTACT,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addContract(payload)
     * )
     * </code>
     * @name addContract - Creates Add Contract Payload
     * @param {Object} payload 
     */
    function addContract(payload) {
        if (!payload) throw 'Payload Not Found @addContract';
        return {
            type: Actions.ADD_CONTRACT,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updateContract(payload)
     * )
     * </code>
     * @name updateContract - Creates Update Contract Payload
     * @param {Object} payload 
     */
    function updateContract(payload) {
        if (!payload) throw 'Payload Not Found @updateContract';
        return {
            type: Actions.UPDATE_CONTRACT,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeContract(payload)
     * )
     * </code>
     * @name removeContract - Creates Remove Contract Payload
     * @param {Object} payload 
     */
    function removeContract(payload) {
        if (!payload) throw 'Payload Not Found @removeContract';
        return {
            type: Actions.REMOVE_CONTRACT,
            value: payload.value || payload
        };
    }

    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addFacebook(payload)
     * )
     * </code>
     * @name addFacebook - Creates Wishilist Payload
     * @param {Object} payload 
     */
    function addFacebook(payload) {
        if (!payload) throw 'Payload Not Found @addFacebook';
        return {
            type: Actions.ADD_FACEBOOK,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updateFacebook(payload)
     * )
     * </code>
     * @name updateFacebook - Creates Update Facebook Payload
     * @param {Object} payload 
     */
    function updateFacebook(payload) {
        if (!payload) throw 'Payload Not Found @updateFacebook';
        return {
            type: Actions.UPDATE_FACEBOOK,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeFacebook(payload)
     * )
     * </code>
     * @name removeFacebook - Creates Remove Facebook Payload
     * @param {Object} payload 
     */
    function removeFacebook(payload) {
        if (!payload) throw 'Payload Not Found @removeFacebook';
        return {
            type: Actions.REMOVE_FACEBOOK,
            value: payload.value || payload
        };
    }

    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addGoogle(payload)
     * )
     * </code>
     * @name addGoogle - Creates Add Google Payload
     * @param {Object} payload 
     */
    function addGoogle(payload) {
        if (!payload) throw 'Payload Not Found @addGoogle';
        return {
            type: Actions.ADD_GOOGLE,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeGoogle(payload)
     * )
     * </code>
     * @name removeGoogle - Creates Remove Google Payload
     * @param {Object} payload 
     */
    function removeGoogle(payload) {
        if (!payload) throw 'Payload Not Found @removeGoogle';
        return {
            type: Actions.REMOVE_GOOGLE,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updateGoogle(payload)
     * )
     * </code>
     * @name updateGoogle - Creates Wishilist Payload
     * @param {Object} payload 
     */
    function updateGoogle(payload) {
        if (!payload) throw 'Payload Not Found @updateGoogle';
        return {
            type: Actions.UPDATE_GOOGLE,
            value: payload.value || payload
        };
    }


    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseWishlist(payload)
     * )
     * </code>
     * @name diagnoseWishlist - Creates Wishilist Payload
     * @param {Object} payload 
     */
    function diagnoseWishlist(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseWishlist';
        return {
            type: Actions.ADD_WHISHLIST_NOTIFICATION,
            value: payload.value || payload
        };
    }

    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseOrders(payload)
     * )
     * </code>
     * @name diagnoseOrders - Creates Remove Contact Payload
     * @param {Object} payload 
     */
    function diagnoseOrders(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseOrders';
        return {
            type: Actions.ADD_ORDERS_NOTIFICATION,
            value: payload.value || payload
        };
    }

    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseReviews(payload)
     * )
     * </code>
     * @name diagnoseReviews - Creates Remove Contact Payload
     * @param {Object} payload 
     */
    function diagnoseReviews(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseReviews';
        return {
            type: Actions.ADD_REVIEW_NOTIFICATION,
            value: payload.value || payload
        };
    }


    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeContact(payload)
     * )
     * </code>
     * @name removeContact - Creates Remove Contact Payload
     * @param {Object} payload 
     */
    function removeContact(payload) {
        if (!payload) throw 'Payload Not Found @removeContact';
        return {
            type: Actions.REMOVE_CONTACT,
            value: payload.value || payload
        };
    }


    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addAddress(payload)
     * )
     * </code>
     * @name addAddress - Creates addAddress Payload
     * @param {Object} payload 
     */
    function addAddress(payload) {
        if (!payload) throw 'Payload Not Found @addAddress';
        return {
            type: Actions.ADD_ADDRESS,
            value: payload.value || payload
        };
    }


    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addProfile(payload)
     * )
     * </code>
     * @name addProfile - Creates Add Profile Payload
     * @param {Object} payload 
     */
    function addProfile(payload) {
        if (!payload) throw 'Payload Not Found @addProfile';
        return {
            type: Actions.ADD_PROFILE,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addOwner(payload)
     * )
     * </code>
     * @name addOwner - Creates addOwner Payload
     * @param {Object} payload 
     */
    function addOwner(payload) {
        if (!payload) throw 'Payload Not Found @addOwner';
        return {
            type: Actions.ADD_OWNER_STUFF,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addProvider(payload)
     * )
     * </code>
     * @name addProvider - Creates addProvider Payload
     * @param {Object} payload 
     */
    function addProvider(payload) {
        if (!payload) throw 'Payload Not Found @addProvider';
        return {
            type: Actions.ADD_PROVIDER_STUFF,
            value: payload.value || payload
        };
    }
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addStuff(payload)
     * )
     * </code>
     * @name addStuff - Creates addStuff Payload
     * @param {Object} payload 
     */
    function addStuff(payload) {
        if (!payload) throw 'Payload Not Found @addStuff';
        return {
            type: Actions.ADD_STUFF,
            value: payload.value || payload
        };
    }    
    
    /**
    * @example
    * <code>
    * var payload = {};#Initialize payload. 
    * StateService.dispatch(
    *      ActionCreator.updateStuff(payload)
    * )
    * </code>
    * @name updateStuff - Creates updateStuff Payload
    * @param {Object} payload 
    */
   function updateStuff(payload) {
       if (!payload) throw 'Payload Not Found @updateStuff';
       return {
           type: Actions.UPDATE_STUFF,
           value: payload.value || payload
       };
   }

    /**
    * @example
    * <code>
    * var payload = {};#Initialize payload. 
    * StateService.dispatch(
    *      ActionCreator.removeStuff(payload)
    * )
    * </code>
    * @name removeStuff - Creates removeStuff Payload
    * @param {Object} payload 
    */
   function removeStuff(payload) {
       return {
           type: Actions.REMOVE_STUFF,
           value: payload.value || payload
       };
   }


   
    /**
     * @example
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.signup(payload)
     * )
     * </code>
     * @name addUserInvitedFriends - Creates addUserInvitedFriends Payload
     * @param {Object} payload 
     */
    function addUserInvitedFriends(payload) {
        if (!payload) throw 'Payload Not Found @addUserInvitedFriends';
        return {
            type: Actions.ADD_INVITED_FRIENDS,
            value: payload.value || payload
        };
    }
    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.signup(payload)
     * )
     * </code>
     * @name signup - Creates signup Payload
     * @param {Object} payload 
     */
    function signup(payload) {
        if (!payload) throw 'Payload Not Found @signup';
        return {
            type: Actions.ADD_SIGNUP_ACCOUNT,
            value: payload.value || payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.signin(payload)
     * )
     * </code>
     * @name signin - Creates signin Payload
     * @param {Object} payload 
     */
    function signin(payload) {
        if (!payload) throw 'Payload Not Found @signin';
        return {
            type: Actions.ADD_SIGNIN_ACCOUNT,
            value: payload.value || payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.linkAccount(payload)
     * )
     * </code>
     * @name linkAccount - Creates linkAccount Payload
     * @param {Object} payload 
     */
    function linkAccount(payload) {
        if (!payload) throw 'Payload Not Found @linkAccount';
        return {
            type: Actions.ADD_LINK_ACCOUNT,
            value: payload.value || payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.unlinkAccount(payload)
     * )
     * </code>
     * @name unlinkAccount - Creates addUser Payload
     * @param {Object} payload 
     */
    function unlinkAccount(payload) {
        if (!payload) throw 'Payload Not Found @unlinkAccount';
        return {
            type: Actions.ADD_UNLINK_ACCOUNT,
            value: payload.value || payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addReviews(payload)
     * )
     * </code>
     * @name addReviews - Creates addReviews Payload
     * @param {Object} payload 
     */
    function addReviews(payload) {
        if (!payload) throw 'Payload Not Found @addReviews';
        return {
            type: Actions.ADD_REVIEWS,
            value: payload.value || payload
        };
    }
    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addReview(payload)
     * )
     * </code>
     * @name addReview - Creates addReview Payload
     * @param {Object} payload 
     */
    function addReview(payload) {
        if (!payload) throw 'Payload Not Found @addReview';
        return {
            type: Actions.ADD_REVIEW,
            value: payload.value || payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addCards(payload)
     * )
     * </code>
     * @name addCards - Creates addCards Payload
     * @param {Object} payload 
     */
    function addCards(payload) {
        if (!payload) throw 'Payload Not Found @addCards';
        return {
            type: Actions.ADD_CARDS,
            value: payload.value || payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addUser(payload)
     * )
     * </code>
     * @name addUser - Creates addUser Payload
     *                   - Function used with Screen to Show/Hide Sidebar
     * @param {Object} payload 
     */
    function addUser(payload) {
        if (!payload) throw 'Payload Not Found @addUser';
        return {
            type: Actions.ADD_USER,
            value: payload.value || payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeUser(payload)
     * )
     * </code>
     * @name removeUser - Creates removeUser Payload
     *                   - Function used with Screen to Show/Hide Sidebar
     * @param {Object} payload 
     */
    function removeUser(payload) {
        if (!payload) throw 'Payload Not Found @removeUser';
        return {
            type: Actions.REMOVE_USER,
            value: payload.value || payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updateUser(payload)
     * )
     * </code>
     * @name updateUser - Creates updateUser Payload
     *                   - Function used with Screen to Show/Hide Sidebar
     * @param {Object} payload 
     */
    function updateUser(payload) {
        if (!payload) throw 'Payload Not Found @updateUser';
        return {
            type: Actions.UPDATE_USER,
            value: payload.value || payload
        };
    }




    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.onMessage(payload)
     * )
     * </code>
     * @name onMessage - Creates onMessage Payload
     *                   - Function used with Messenger to receive new Message From Socket.
     * @param {Object} payload 
     */
    function onMessage(payload) {
        if (!payload) throw 'Payload Not Found @onMessage';
        return {
            type: Actions.ADD_MESSENGER_MESSAGE,
            value: payload.value || payload
        };
    }




    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.startSharerScreen(payload)
     * )
     * </code>
     * @name startSharerScreen - Creates startSharerScreen Payload
     *                   - Function used with Screen to Show/Hide Sharer on Screen
     * @param {Object} payload 
     */
    function startSharerScreen(payload){
        if (!payload) throw 'Item Payload Not Found @startSharerScreen';
        return {
            type: Actions.SCREEN_SHOW_SHARER,
            value: payload.value || payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.stopSharerScreen(payload)
     * )
     * </code>
     * @name startSharerScreen - Creates stopSharerScreen Payload
     *                   - Function used with Screen to Hide Sharer on Screen
     * @param {Object} payload 
     */
    function stopSharerScreen(payload){
        if (!payload) throw 'Payload Not Found @stopSharerScreen';
        
        return {
            type: Actions.SCREEN_HIDE_SHARER,
            value: payload.value || payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.showHideSidebar(payload)
     * )
     * </code>
     * @name showHideSidebar - Creates showHideSidebar Payload
     *                   - Function used with Screen to Show/Hide Sidebar
     * @param {Object} payload 
     */
    function showHideSidebar(payload) {
        if (!payload) throw 'Payload Not Found @showHideSidebar';
        if(typeof payload.value !== 'boolean') throw '@showHideSidebar - Expects a Boolean';
        return {
            type: Actions.SCREEN_SHOW_SIDEBAR,
            value: payload.value
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addScreen(payload)
     * )
     * </code>
     * @name addScreen - Creates addScreen Payload
     *                 - Function used with Screen Instances.
     *                 - Payload can have Type that is not necessarily the type of the payload.
     * @param {Object} payload 
     */
    function addScreen(payload) {
        if (!payload) throw 'Payload Not Found @addScreen';
        return {
            type: Actions.ADD_SCREEN,
            value: payload.value || payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updateScreen(payload)
     * )
     * </code>
     * @name updateScreen - Creates updateScreen Payload
     *                   - Function used with Screen to Show/Hide Sidebar
     * @param {Object} payload 
     */
    function updateScreen(payload) {
        if (!payload) throw 'Payload Not Found @updateScreen';
        return {
            type: Actions.UPDATE_SCREEN,
            value: payload.value || payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeScreen(payload)
     * )
     * </code>
     * @name removeScreen - Creates removeScreen Payload
     *                   - Function used with Screen to Show/Hide Sidebar
     * @param {Object} payload 
     */
    function removeScreen(payload) {
        if (!payload) throw 'Payload Not Found @removeScreen';
        return {
            type: Actions.REMOVE_SCREEN,
            value: payload.value || payload
        };
    }



    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.topScreen(payload)
     * )
     * </code>
     * @name topScreen - Creates topScreen Payload
     *                   - Function used With Home Page Screen to Show/Hide Container Class And Intro Class on Homepage.
     * @param {Object} payload 
     */
    function topScreen(payload) {
        if (!payload) throw 'Payload Not Found @topScreen';
        return {
            type: Actions.SCREEN_SHOW_HOMEPAGE,
            value: payload.value
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.showUsermenu(payload)
     * )
     * </code>
     * @name showUsermenu - Creates showUsermenu Payload
     *                   - Function used with Screen to Show/Hide Usermenu 
     * @param {Object} payload 
     */
    function showUsermenu(payload) {
        if (!payload) throw 'Payload Not Found @showUsermenu';
        if(payload.value && typeof payload.value !== 'boolean') throw '@showUserMenu - Expects a Boolean';
        
        return {
            type: Actions.SCREEN_SHOW_USERMENU,
            value: payload.value
        };
    }

    

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addCheckout(payload)
     * )
     * </code>
     * @name addCheckout - Creates addCheckout Payload
     * @param {Object} payload 
     */
    function addCheckout(payload) {
        if (!payload) throw 'Payload Not Found @addCheckout';
        return {
            type: Actions.ADD_CHECKOUT,
            value: payload.value || payload
        };
    }




    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addCheckoutPricing(payload)
     * )
     * </code>
     * @name addCheckoutPricing - Creates addCheckoutPricing Payload
     * @param {Object} payload 
     */
    function addCheckoutPricing(payload) {
        if (!payload) throw 'Payload Not Found @addCheckoutPricing';
        return {
            type: Actions.ADD_CHECKOUT_PRICING,
            value: payload.value || payload
        };
    }
    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addCheckoutPayment(payload)
     * )
     * </code>
     * @name addCheckoutPayment - Creates addCheckoutPayment Payload
     * @param {Object} payload 
     */
    function addCheckoutPayment(payload) {
        if (!payload) throw 'Payload Not Found @addCheckoutPayment';
        return {
            type: Actions.ADD_CHECKOUT_PAYMENT,
            value: payload.value || payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addCheckoutPeriod(payload)
     * )
     * </code>
     * @name addCheckoutPeriod - Creates addCheckoutPeriod Payload
     * @param {Object} payload 
     */
    function addCheckoutPeriod(payload) {
        if (!payload) throw 'Payload Not Found @addCheckoutPeriod';
        return {
            type: Actions.ADD_CHECKOUT_PERIOD,
            value: payload.value || payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addCheckoutShipping(payload)
     * )
     * </code>
     * @name addCheckoutShipping - Creates addCheckoutShipping Payload
     * @param {Object} payload 
     */
    function addCheckoutShipping(payload) {
        if (!payload) throw 'Payload Not Found @addCheckoutShipping';
        return {
            type: Actions.ADD_CHECKOUT_SHIPPING,
            value: payload.value || payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addCheckoutContract(payload)
     * )
     * </code>
     * @name addCheckoutContract - Creates Add Checkout Contract Payload
     * @param {Object} payload 
     */
    function addCheckoutContract(payload) {
        if (!payload) throw 'Payload Not Found @addCheckoutContract';
        return {
            type: Actions.ADD_CHECKOUT_CONTRACT,
            value: payload.value || payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addCheckoutItem(payload)
     * )
     * </code>
     * @name addCheckoutItem - Creates addCheckoutItem Payload
     * @param {Object} payload 
     */
    function addCheckoutItem(payload) {
        if (!payload) throw 'Payload Not Found @addCheckoutItem';
        return {
            type: Actions.ADD_CHECKOUT_ITEM,
            value: payload.value || payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeCheckoutItem(payload)
     * )
     * </code>
     * @name removeCheckoutItem - Creates removeCheckoutItem Payload
     * @param {Object} payload 
     */
    function removeCheckoutItem(payload) {
        if (!payload) throw 'Payload Not Found @removeCheckoutItem';
        return {
            type: Actions.REMOVE_CHECKOUT_ITEM,
            value: payload.value || payload
        };
    }
    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addCheckoutOrder(payload)
     * )
     * </code>
     * @name addCheckoutOrder - Creates addCheckoutOrder Payload
     * @param {Object} payload 
     */
    function addCheckoutOrder(payload) {
        if (!payload) throw 'Payload Not Found @addCheckoutOrder';
        return {
            type: Actions.ADD_CHECKOUT_ORDER,
            value: payload.value || payload
        };
    }
    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updateCheckoutOrder(payload)
     * )
     * </code>
     * @name updateCheckoutOrder - Creates updateCheckoutOrder Payload
     * @param {Object} payload 
     */
    function updateCheckoutOrder(payload) {
        if (!payload) throw 'Payload Not Found @updateCheckoutOrder';
        return {
            type: Actions.UPDATE_CHECKOUT_ORDER,
            value: payload.value || payload
        };
    }



    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.omnisearch(payload)
     * ); 
     *  # Since omnisearch returns various type of object, Callers may need to dispatch additional targets
     * PeopleComponent::search
     *  => SearchService.omnisearch().then(() => dispatch(addPeople()))
     * </code>
     * @name omnisearch - Creates omnisearch Payload
     * @param {Object} payload 
     */
    function omnisearch(payload) {
        if (!payload) throw 'Payload Not Found @omnisearch';
        if (!payload.haystack) throw 'Payload::Haystack Not Found @omnisearch';
        if (!payload.response && !payload.collection) throw 'Payload::Response Not Found @omnisearch';
        /**
         * @param type is used to find corresponding HayStack see :SearchService.
         */
        var type = {
            message: Actions.SEARCH_MESSAGE,
            notification: Actions.SEARCH_NOTIFICATION,
            people: Actions.SEARCH_PEOPLE,
            activity: Actions.SEARCH_ACTIVITY,
            item: Actions.SEARCH_ITEM,
            order: Actions.SEARCH_ORDER
        };
        return {
            type: Actions.ADD_OMNISEARCH,
            value: payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseMe(payload)
     * )
     * </code>
     * @name diagnoseMe - Creates diagnoseMe Payload
     * @param {Object} payload 
     */
    function diagnoseMe(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseMe';
        return {
            type: Actions.UPDATE_PERSONALINFO_NOTIFICATION,
            value: payload.message || payload.value || payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseSettings(payload)
     * )
     * </code>
     * @name diagnoseSettings - Creates diagnoseSettings Payload
     * @param {Object} payload 
     */
    function diagnoseSettings(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseSettings';
        return {
            type: Actions.ADD_SETTINGS_NOTIFICATION,
            value: payload.message || payload.value || payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseOrder(payload)
     * )
     * </code>
     * @name diagnoseOrder - Creates diagnoseOrder Payload
     * @param {Object} payload 
     */
    function diagnoseOrder(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseOrder';
        return {
            type: Actions.ADD_ORDER_NOTIFICATION,
            value: payload.message || payload.value || payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseItems(payload)
     * )
     * </code>
     * @name diagnoseItems - Creates diagnoseItems Payload
     * @param {Object} payload 
     */
    function diagnoseItems(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseItems';
        return {
            type: Actions.ADD_INVENTORY_NOTIFICATION,
            value: payload.message || payload.value || payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseAddress(payload)
     * )
     * </code>
     * @name diagnoseAddress - Creates diagnoseAddress Payload
     * @param {Object} payload 
     */
    function diagnoseAddress(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseAddress';
        return {
            type: Actions.ADD_ADDRESS_NOTIFICATION,
            value: payload.message || payload.value || payload
        };
    }



    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseUpdateAddress(payload)
     * )
     * </code>
     * @name diagnoseUpdateAddress - Creates diagnoseUpdateAddress Payload
     * @param {Object} payload 
     */
    function diagnoseUpdateAddress(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseUpdateAddress';
        return {
            type: Actions.UPDATE_ADDRESS_NOTIFICATION,
            value: payload.message || payload.value || payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseWallet(payload)
     * )
     * </code>
     * @name diagnoseWallet - Creates diagnoseWallet Payload
     * @param {Object} payload 
     */
    function diagnoseWallet(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseWallet';
        return {
            type: Actions.ADD_PAYMENT_INFO_NOTIFICATION,
            value: payload.message || payload.value || payload
        };
    }
    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseUpdatePayment(payload)
     * )
     * </code>
     * @name diagnoseUpdatePayment - Creates diagnoseUpdatePayment Payload
     * @param {Object} payload 
     */
    function diagnoseUpdatePayment(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseUpdatePayment';
        return {
            type: Actions.UPDATE_PAYMENT_INFO_NOTIFICATION,
            value: payload.message || payload.value || payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.inviteMoreFriends(payload)
     * )
     * </code>
     * @name inviteMoreFriends - Creates inviteMoreFriends Payload
     * @param {Object} payload 
     */
    function inviteMoreFriends(payload) {
        if (!payload) throw 'Payload Not Found @inviteMoreFriends';
        return {
            type: Actions.INVITE_MORE_FRIENDS_NOTIFICATION,
            value: payload.message || payload.value || payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addMoreInventory(payload)
     * )
     * </code>
     * @name addMoreInventory - Creates addMoreInventory Payload
     * @param {Object} payload 
     */
    function addMoreInventory(payload) {
        if (!payload) throw 'Payload Not Found @addMoreInventory';
        return {
            type: Actions.ADD_MORE_INVENTORY_NOTIFICATION,
            value: payload.message || payload.value || payload
        };
    }




    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseInvitations(payload)
     * )
     * </code>
     * @name diagnoseInvitations - Creates diagnoseInvitations Payload
     * @param {Object} payload 
     */
    function diagnoseInvitations(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseInvitations';
        return {
            type: Actions.INVITE_FRIENDS_NOTIFICATION,
            value: payload.message || payload.value || payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseContract(payload)
     * )
     * </code>
     * @name diagnoseContract - Creates diagnoseContract Payload
     * @param {Object} payload 
     */
    function diagnoseContract(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseContract';
        return {
            type: Actions.ADD_CONTRACT_NOTIFICATION,
            value: payload.message || payload.value || payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseUpdateContract(payload)
     * )
     * </code>
     * @name diagnoseUpdateContract - Creates diagnoseUpdateContract Payload
     * @param {Object} payload 
     */
    function diagnoseUpdateContract(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseUpdateContract';
        return {
            type: Actions.UPDATE_CONTRACT_NOTIFICATION,
            value: payload.message || payload.value || payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseContacts(payload)
     * )
     * </code>
     * @name diagnoseContacts - Creates diagnoseContacts Payload
     * @param {Object} payload 
     */
    function diagnoseContacts(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseContacts';
        return {
            type: Actions.ADD_CONTACTS_NOTIFICATION,
            value: payload.message || payload.value || payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseFollowers(payload)
     * )
     * </code>
     * @name diagnoseFollowers - Creates diagnoseFollowers Payload
     * @param {Object} payload 
     */
    function diagnoseFollowers(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseFollowers';
        return {
            type: Actions.ADD_FOLLOWERS_NOTIFICATION,
            value: payload.message || payload.value || payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseFavorites(payload)
     * )
     * </code>
     * @name addItem - Creates diagnoseFavorites Payload
     * @param {Object} payload 
     */
    function diagnoseFavorites(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseFavorites';
        return {
            type: Actions.ADD_FAVORITE_NOTIFCATION,
            value: payload.message || payload.value || payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.diagnoseMessages(payload)
     * )
     * </code>
     * @name diagnoseMessages - Creates Diagnose Messages Payload
     * @param {Object} payload 
     */
    function diagnoseMessages(payload) {
        if (!payload) throw 'Payload Not Found @diagnoseMessages';
        return {
            type: Actions.ADD_MESSAGES_NOTIFCATION,
            value: payload.message || payload.value || payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addItem(payload)
     * )
     * </code>
     * @name addItem - Creates addItem Payload
     * @param {Object} payload 
     */
    function addItem(payload) {
        if (!payload) throw 'Payload Not Found @addItem';
        return {
            type: Actions.ADD_ITEM,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeItem(payload)
     * )
     * </code>
     * @name removeItem - Creates removeItem Payload
     * @param {Object} payload 
     */
    function removeItem(payload) {
        if (!payload) throw 'Payload Not Found @removeItem';
        return {
            type: Actions.REMOVE_ITEM,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updateItem(payload)
     * )
     * </code>
     * @name updateItem - Creates updateItem Payload
     * @param {Object} payload 
     */
    function updateItem(payload) {
        if (!payload) throw 'Payload Not Found @updateItem';
        return {
            type: Actions.UPDATE_ITEM,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.searchItem(payload)
     * )
     * </code>
     * @name searchItem - Creates searchItemPayload
     * @param {Object} payload 
     */
    function searchItem(payload) {
        if (!payload) throw 'Payload Not Found @searchItem';
        return {
            type: Actions.SEARCH_ITEM,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeAllItems(payload)
     * )
     * </code>
     * @name removeAllItems - Creates removeAllItems Payload
     * @param {Object} payload 
     */
    function removeAllItems(payload) {
        if (!payload) throw 'Payload Not Found @removeAllItems';
        return {
            type: Actions.REMOVE_ALL_ITEMS,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addOrder(payload)
     * )
     * </code>
     * @name addOrder - Creates addOrder Payload
     * @param {Object} payload 
     */
    function addOrder(payload) {
        if (!payload) throw 'Payload Not Found @addOrder';
        return {
            type: Actions.ADD_ORDER,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeOrder(payload)
     * )
     * </code>
     * @name removeOrder - Creates removeOrderPayload
     * @param {Object} payload 
     */
    function removeOrder(payload) {
        if (!payload) throw 'Payload Not Found @removeOrder';
        return {
            type: Actions.REMOVE_ORDER,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updateOrder(payload)
     * )
     * </code>
     * @name updateOrder - Creates updateOrder Payload
     * @param {Object} payload 
     */
    function updateOrder(payload) {
        if (!payload) throw 'Payload Not Found @updateOrder';
        return {
            type: Actions.UPDATE_ORDER,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.searchOrder(payload)
     * )
     * </code>
     * @name searchOrder - Creates searchOrderPayload
     * @param {Object} payload 
     */
    function searchOrder(payload) {
        if (!payload) throw 'Payload Not Found @searchOrder';
        return {
            type: Actions.SEARCH_ORDER,
            value: payload
        };
    }



    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addRental(payload)
     * )
     * </code>
     * @name addRental - Creates addRental Payload
     * @param {Object} payload 
     */
    function addRental(payload) {
        if (!payload) throw 'Payload Not Found @addRental';
        return {
            type: Actions.ADD_RENTAL,
            value: payload
        };
    }
    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeRental(payload)
     * )
     * </code>
     * @name removeRental - Creates removeRental Payload
     * @param {Object} payload 
     */
    function removeRental(payload) {
        if (!payload) throw 'Payload Not Found @removeRental';
        return {
            type: Actions.REMOVE_RENTAL,
            value: payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addRentalSearch(payload)
     * )
     * </code>
     * @name addRentalSearch - Creates addRentalSearch Payload
     * @param {Object} payload 
     */
    function addRentalSearch(payload) {
        if (!payload) throw 'Payload Not Found @addRentalSearch';
        return {
            type: Actions.ADD_SEARCH_RENTAL,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addRentalPayment(payload)
     * )
     * </code>
     * @name addRentalPayment - Creates addRentalPayment Payload
     * @param {Object} payload 
     */
    function addRentalPayment(payload) {
        if (!payload) throw 'Payload Not Found @addRentalPayment';
        return {
            type: Actions.ADD_PAYMENT_RENTAL,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addRentalOrder(payload)
     * )
     * </code>
     * @name addRentalOrder - Creates addRentalOrder Payload
     * @param {Object} payload 
     */
    function addRentalOrder(payload) {
        if (!payload) throw 'Payload Not Found @removeRental';
        return {
            type: Actions.ADD_ORDER_RENTAL,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addRentalPerson(payload)
     * )
     * </code>
     * @name addRentalPerson - Creates addRentalPerson Payload
     * @param {Object} payload 
     */
    function addRentalPerson(payload) {
        if (!payload) throw 'Payload Not Found @addRentalPerson';
        return {
            type: Actions.ADD_PERSON_RENTAL,
            value: payload
        };
    }    
    /**
    * @example 
    * <code>
    * var payload = {};#Initialize payload. 
    * StateService.dispatch(
    *      ActionCreator.addRentalPeople(payload)
    * )
    * </code>
    * @name addRentalPeople - Creates addRentalPeople Payload
    * @param {Object} payload 
    */
   function addRentalPeople(payload) {
       if (!payload) throw 'Payload Not Found @addRentalPeople';
       return {
           type: Actions.ADD_PEOPLE_RENTAL,
           value: {people: payload.value || payload}
       };
   }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addRentalItem(payload)
     * )
     * </code>
     * @name addRentalItem - Creates addRentalItem Payload
     * @param {Object} payload 
     */
    function addRentalItem(payload) {
        if (!payload) throw 'Payload Not Found @addRentalItem';
        return {
            type: Actions.ADD_ITEM_RENTAL,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addRentalItems(payload)
     * )
     * </code>
     * @name addRentalItems - Creates addRentalItems Payload
     * @param {Object} payload 
     */
    function addRentalItems(payload) {
        if (!payload) throw 'Payload Not Found @addRentalItems';
        return {
            type: Actions.ADD_ITEMS_RENTAL,
            value: {items: payload}
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updateRental(payload)
     * )
     * </code>
     * @name updateRental - Creates updateRental Payload
     * @param {Object} payload 
     */
    function updateRental(payload) {
        if (!payload) throw 'Payload Not Found @updateRental';
        return {
            type: Actions.UPDATE_RENTAL,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeAllOrders(payload)
     * )
     * </code>
     * @name removeAllOrders - Creates removeAllOrders Payload
     * @param {Object} payload 
     */
    function removeAllOrders(payload) {
        if (!payload) throw 'Payload Not Found @removeAllOrders';
        return {
            type: Actions.REMOVE_ALL_ORDERS,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addPerson(payload)
     * )
     * </code>
     * @name addPerson - Creates Add Person Payload
     * @param {Object} payload 
     */
    function addPerson(payload) {
        if (!payload) throw 'Payload Not Found @addPerson';
        return {
            type: Actions.ADD_PERSON,
            value: payload
        };
    }
    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removePerson(payload)
     * )
     * </code>
     * @name removePerson - Creates Remove Person Payload
     * @param {Object} payload 
     */
    function removePerson(payload) {
        if (!payload) throw 'Payload Not Found @removePerson';
        return {
            type: Actions.REMOVE_PERSON,
            value: payload
        };
    }
    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updatePerson(payload)
     * )
     * </code>
     * @name updatePerson - Creates Update Person Payload
     * @param {Object} payload 
     */
    function updatePerson(payload) {
        if (!payload) throw 'Payload Not Found @updatePerson';
        return {
            type: Actions.UPDATE_PERSON,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addPeople(payload)
     * )
     * </code>
     * @name addPeople - Creates Add People Payload
     * @param {Object} payload 
     */
    function addPeople(payload) {
        if (!payload) throw 'Payload Not Found @addPeople';
        return {
            type: Actions.ADD_PEOPLE,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removePeople(payload)
     * )
     * </code>
     * @name removePeople - Creates Remove People Payload
     * @param {Object} payload 
     */
    function removePeople(payload) {
        if (!payload) throw 'Payload Not Found @removePeople';
        return {
            type: Actions.REMOVE_PEOPLE,
            value: payload
        };
    }
    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updatePeople(payload)
     * )
     * </code>
     * @name updatePeople - Creates Update People Payload
     * @param {Object} payload 
     */
    function updatePeople(payload) {
        if (!payload) throw 'Payload Not Found @updatePeople';
        return {
            type: Actions.UPDATE_PEOPLE,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.searchPeople(payload)
     * )
     * </code>
     * @name searchPeople - Creates Search People Payload
     * @param {Object} payload 
     */
    function searchPeople(payload) {
        if (!payload) throw 'Payload Not Found @searchPeople';
        return {
            type: Actions.SEARCH_PEOPLE,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addActivity(payload)
     * )
     * </code>
     * @name addActivity - Creates Add Activity Payload
     * @param {Object} payload 
     */
    function addActivity(payload) {
        if (!payload) throw 'Payload Not Found @addActivity';
        return {
            type: Actions.ADD_ACTIVITY,
            value: payload
        };
    }
    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updateActivity(payload)
     * )
     * </code>
     * @name updateActivity - Creates Update Activity Payload
     * @param {Object} payload 
     */
    function updateActivity(payload) {
        if (!payload) throw 'Payload Not Found @updateActivity';
        return {
            type: Actions.UPDATE_ACTIVITY,
            value: payload
        };
    }
    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeActivity(payload)
     * )
     * </code>
     * @name removeActivity - Creates Remove Activity Payload
     * @param {Object} payload 
     */
    function removeActivity(payload) {
        if (!payload) throw 'Payload Not Found @removeActivity';
        return {
            type: Actions.REMOVE_ACTIVITY,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.searchActivity(payload)
     * )
     * </code>
     * @name searchActivity - Creates Search Activity Payload
     * @param {Object} payload 
     */
    function searchActivity(payload) {
        if (!payload) throw 'Payload Not Found @searchActivity';
        return {
            type: Actions.SEARCH_ACTIVITY,
            value: payload
        };
    }
    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeAllActivities(payload)
     * )
     * </code>
     * @name removeAllActivities - Creates Remove All Activities Payload
     * @param {Object} payload 
     */
    function removeAllActivities(payload) {
        return {
            type: Actions.REMOVE_ALL_ACTIVITIES,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addNotification(payload)
     * )
     * </code>
     * @name addNotification - Creates Add Notification Payload
     * @param {Object} payload 
     */
    function addNotification(payload) {
        if (!payload) throw 'Payload Not Found @addNotification';
        return {
            type: Actions.ADD_NOTIFICATION,
            value: payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updateNotification(payload)
     * )
     * </code>
     * @name updateNotification - Creates Update Notification Payload
     * @param {Object} payload 
     */
    function updateNotification(payload) {
        if (!payload) throw 'Payload Not Found @updateNotification';
        return {
            type: Actions.UPDATE_NOTIFICATION,
            value: payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeNotification(payload)
     * )
     * </code>
     * @name removeNotification - Creates Remove Notification Payload
     * @param {Object} payload 
     */
    function removeNotification(payload) {
        if (!payload) throw 'Payload Not Found @removeNotification';
        return {
            type: Actions.REMOVE_NOTIFICATION,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.findNotification(payload)
     * )
     * </code>
     * @name findNotification - Creates findNotification Payload
     * @param {Object} payload 
     */
    function findNotification(payload) {
        if (!payload) throw 'Payload Not Found @findNotification';
        return {
            type: Actions.FIND_NOTIFICATION,
            value: payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.searchNotification(payload)
     * )
     * </code>
     * @name searchNotification - Creates Search Notification Payload
     * @param {Object} payload 
     */
    function searchNotification(payload) {
        if (!payload) throw 'Payload Not Found @searchNotification';
        return {
            type: Actions.SEARCH_NOTIFICATION,
            value: payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeAllNotifications(payload)
     * )
     * </code>
     * @name removeAllNotifications - Creates Remove All Notifications Payload
     * @param {Object} payload 
     */
    function removeAllNotifications(payload) {
        return {
            type: Actions.REMOVE_ALL_NOTIFICATIONS,
            value: payload
        };
    }



    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addAuth(payload)
     * )
     * </code>
     * @name addAuth - action creator for adding auth token in centralized data store.
     * @param {Object} payload 
     * @return {Object<Action>} 
     */
    function addAuth(payload) {
        if (!payload) throw 'Payload Not Found @addAuth';
        return {
            type: Actions.ADD_AUTH,
            value: payload
        };
    }

    /**    
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeAuth(payload)
     * )
     * </code>
     * @name removeAuth - action creator for removing auth token in centralized data store.
     * @param {Object} payload 
     * @return {Object<Action>} 
     */
    function removeAuth(payload) {
        return {
            type: Actions.REMOVE_AUTH,
            value: payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addMessenger(payload)
     * )
     * </code>
     * @name addMessenger - Add  Message Payload
     * @param {Object} payload 
     */
    function addMessenger(payload) {
        if (!payload) throw 'Payload Not Found @addMessenger';
        return {
            type: Actions.ADD_MESSENGER,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addIntents(payload)
     * )
     * </code>
     * @name addIntents - add  Message Intents 
     * @param {Object} payload 
     */
    function addIntents(payload) {
        if (!payload) throw 'Payload Not Found @addIntents';
        return {
            type: Actions.ADD_MESSENGER_INTENTS,
            value: payload
        };
    }


    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addUnreadMessage(payload)
     * )
     * </code>
     * @name addUnreadMessage - add Unread Message Recipient 
     * @param {Object} payload 
     */
    function addUnreadMessage(payload) {
        if (!payload) throw 'Payload Not Found @addUnreadMessage';
        return {
            type: Actions.ADD_MESSENGER_UNREAD_MESSAGES,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addMessageRecipient(payload)
     * )
     * </code>
     * @name addMessageRecipient - add Message Recipient 
     * @param {Object} payload 
     */
    function addMessageRecipient(payload) {
        if (!payload) throw 'Payload Not Found @addMessageRecipient';
        return {
            type: Actions.ADD_MESSENGER_RECIPIENT,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addConversations(payload)
     * )
     * </code>
     * @name addConversations - add conversation 
     * @param {Object} payload 
     */
    function addConversations(payload) {
        if (!payload) throw 'Payload Not Found @addConversations';
        return {
            type: Actions.ADD_CONVERSATIONS,
            value: payload
        };
    }

    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.addMessage(payload)
     * )
     * </code>
     * @name addMessage - add new message
     * @param {Object} payload 
     */
    function addMessage(payload) {
        if (!payload) throw 'Payload Not Found @addMessage';
        return {
            type: Actions.ADD_MESSAGE,
            value: payload
        };
    }
    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeMessage(payload)
     * )
     * </code>
     * @name removeMessage - removes a message
     * @param {Object} payload 
     */
    function removeMessage(payload) {
        if (!payload) throw 'Payload Not Found @removeMessage';
        return {
            type: Actions.REMOVE_MESSAGE,
            value: payload
        };
    }
    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.updateMessage(payload)
     * )
     * </code>
     * @name updateMessage - update and existing message
     * @param {Object} payload 
     */
    function updateMessage(payload) {
        if (!payload) throw 'Payload Not Found @updateMessage';
        return {
            type: Actions.UPDATE_MESSAGE,
            value: payload
        };
    }
    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.searchMessage(payload)
     * )
     * </code>
     * @name searchMessage - search a message
     * @param {Object} payload 
     */
    function searchMessage(payload) {
        if (!payload) throw 'Payload Not Found @searchMessage';
        return {
            type: Actions.SEARCH_MESSAGE,
            value: payload
        };
    }
    /**
     * @example 
     * <code>
     * var payload = {};#Initialize payload. 
     * StateService.dispatch(
     *      ActionCreator.removeAllMessages(payload)
     * )
     * </code>
     * @name removeAllMessages - removes all messages
     * @param {Object} payload 
     */
    function removeAllMessages(payload) {
        return {
            type: Actions.REMOVE_ALL_MESSAGES
        };
    }

}
ActionCreator.$inject = ['Actions'];
angular.module('hoogy').factory('Actions', Actions);
angular.module('hoogy').factory('ActionCreator', ActionCreator);