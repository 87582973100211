'use strict';

/**
 * @name StorePartnersController
 * @param {Object} Angularytics 
 */
function StorePartnersController(AuthService, StateService, UIService, NotificationService, Angularytics){
    var vm = this; 
   /**
    * @name init - initialization of Partners List. 
    */
    this.init = function(){this.partners();};
    this.$onInit = function(){
        this.screen = UIService.getStorePartnersScreen({component: 'store:partners'});
        this.unsubscribe = this.subscribe();
        this.init();
    };
    
        /**
         * @name subscribe - Subscribes for Model Updates.
         */
        this.subscribe = function(){
            return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
                vm.model = AuthService.getModel();
                vm.screen = UIService.getStorePartnersScreen(vm.screen);
                vm.model.notifications = NotificationService.getModel();                                                 
            });
        };
    /**
     * @name partners - logging event track to google. 
     */
    this.partners = function () {
        Angularytics.trackEvent('Store Partners', +1);
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}
StorePartnersController.$inject = ['AuthService', 'StateService', 'UIService', 'NotificationService', 'Angularytics'];
angular.module('hoogy').component('partners', {
    controllerAs: 'vm',
    controller: StorePartnersController,
    templateUrl: 'js/store/view/partners.html'
});