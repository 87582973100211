'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * This directive is used to validate an Email.
 * Usage
 * <code>
 * 	<input type='email' validate-email name='email' id='email' ng-model='email' required>
 * </code>
 */
function ValidateEmail() {
    var EMAIL_REGEXP = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
    function link(scope, element, attrs, ctrl) {
        element.on('keyup', _.debounce(function () {
            var isMatchRegex = EMAIL_REGEXP.test(element.val());
            /**
             * Appling Scope.
             */
            scope.$apply(function () {
                if (isMatchRegex && element.hasClass('warning') || element.val() === '') {
                    element.removeClass('warning');
                } else if (isMatchRegex === false && !element.hasClass('warning')) {
                    element.addClass('warning');
                }
            });
        }, 2000));

        /**
         * Cleanup - try to destroy memory leak substible 
         */
        function cleanup() {
            if (angular.isElement(this)) { return scope.$destroy(); }
            if (element && typeof element.off === 'function') {
                element.off();
            }
               if($(element) && typeof $(element).off === 'function'){
                $(element).off();
            }
        }
        scope.$on('$destroy', cleanup);
        element.on('$destroy', cleanup);


    }

    return {
        link: link,
        restrict: 'A',
        require: 'ngModel'
    };
}
angular.module('hoogy').directive('validateEmail', ValidateEmail);

