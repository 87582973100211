/**
 * WebSocketBus plays same role as HoogyService for WebSocket communication.
 * It uses Socket.IO to send, receive and notify other services message handling.
 *
 */
'use strict';
function WebSocketService($http, $filter, $q, $log) {
    
    return {

    };

}
WebSocketService.$inject = ['$http','$filter', '$q', '$log'];
angular.module('hoogy').factory('WebSocketService', WebSocketService);