
/**
 * @todo equiped with vital statistics tools to evaluate the user.
 * @todo requires StatsService -> getStats() or Evaluate Stats takes User Object, 
 *        and returns a couple well formatted metrics. 
 */
function ProfileCardController($state, MessengerService, ActionCreator, StateService) {
    var vm = this;
  
    /**
     * @name $onInit 
     */
    this.$onInit = function () {
      this.model = {person: this.person}; 
      this.unsubscribe = this.subscribe();
      this.init();
    };

  /**
   * @name share - Shares Items with this Recipient.
   *             - Starts Sharer Screen.
   * @param {Object<Person>} person 
   */  
    this.share = function(person){
      var payload = {person: person || vm.model.person || this.person};
      StateService.dispatch(ActionCreator.addRentalPerson(payload.person));
      StateService.dispatch(ActionCreator.startSharerScreen(payload));
    };
    
    /**
     * @name subscribe 
     */
    this.subscribe = function(){
      return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
        vm.model.person = MessengerService.getModel().recipient || vm.model.person; 
        vm.model.stats = vm.model.person.stats || [];//@todo check the statitics 
      });
    };
  
    /**
     * @name init - initialization of Profile
     * @param {Object<Param>} params
     */
    this.init = function (options) {
        var params = options || $state.params;
      return MessengerService.getRecipient(params).then(MessengerService.getIntents).then(function (response) {
        vm.model.person = MessengerService.getModel().recipient;
        vm.model.stats = vm.model.person.stats || [];//@todo check the statitics 
        return response;
      });
    };
    
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
  }

  /**
   * 
      <!-- @todo - Commented Out will be heading to Profile Card Component -->
      <!--<b class='profile-icon' ui-sref='' ng-if='vm.person.stats.out > 0'> &nbsp; {{vm.person.stats.out}} Items.</b>-->
      <!--<b class='profile-icon ion-ios-chatboxes-outline' ng-if='!vm.person.friendship' ng-class='{active : vm.active}' ui-sref='messenger.talk({recipient: vm.person._id||vm.person.id})'>&nbsp; Contact</b> -->
   */
  ProfileCardController.$inject = ['$state', 'MessengerService','ActionCreator', 'StateService'];
  
  /**
   * @todo add the latest order, If connected person has any pending|late order.
   * @todo --- this profile card defers from hoogyPerson in usage area.
   *          It gives a bird view of the profile, with emphasis on Stats.
   *          It keeps ability to follow/Unfollow, Becoming a Contact.
   *          It also shows the City of the user, if verified or not, and other vital information before users actually rent to them.
   *          It may show if user is already a contact or not. If he has pending or missed orders, etc. 
   */
  angular.module('hoogy').component('profileCard', {
    controllerAs: 'vm',
    bindings: {
      person: '=?'
    },
    controller: ProfileCardController,
    template: '<div class="profile-card-container" ng-init="vm.init()">' +
      '  <div class="profile-card-row -top" ui-sref="workspace.profile({id:vm.model.person.id})">' +
      '    <div class="profile-picture -thumbnail" style="background-image : url({{vm.model.person.photo}})"></div>' +
      '  </div>' +
      '  <div class="profile-card-row -bottom">' +
      '    <div class="name-city col-xs-12 col-sm-12">'+
      '       <b ng-bind="vm.model.person.name"></b>'+
      '       <b ng-bind="vm.model.person.address"></b>'+
      '    </div>'+
      '    <div class="stat" ng-repeat="stat in vm.model.stats track by $index">' +
      '        <div class="stat-number top">{{stat.number}}</div>' +
      '        <div class="stat-text details">{{stat.text}}</div>' +
      '    </div>' +
      '    <div class="stat" ng-click="vm.share(vm.model.person)"><div class="stat-text -button orange-border"><i class="icon ion-paper-airplane"></i>Share</div></div>'+
      '    <div class="stat" ui-sref="workspace.collection({owner: vm.model.person._id || vm.model.person.id})"><div class="stat-text -button green-border">Collection</div></div>'+
      '  </div>' +
      '</div>'
  });