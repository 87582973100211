'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * @todo -- if no other editor works, remember :
 * @link https://github.com/daviferreira/medium-editor
 * @see https://github.com/daviferreira/medium-editor
 * @link http://jmcunningham.net/2014/08/09/angularjs-using-setviewvalue-and-render-to-update-models-forms/
 * @link https://github.com/thijsw/angular-medium-editor/blob/master/src/angular-medium-editor.js
 * @link https://docs.angularjs.org/api/ng/type/ngModel.NgModelController#custom-control-example
 */
function HoogyMeditor($log) {
    /**
     * @link http://stackoverflow.com/questions/17289448/angularjs-to-output-plain-text-instead-of-html
     * @param text
     * @returns {string|*}
     */
    function htmlToPlaintext(text) {
        return String(text).replace(/<[^>]+>/gm, '');
    }

    var stext = '';
    return {
        require: '?ngModel',
        restrict: 'A',
        link: function ($scope, element, attrs, ngModel) {
            //prevent any action to be taken if ngModel is not there yet.
            if (!ngModel) { return; }
            var hme = 'hoogy-medium-editor';
            var hmeClass = ['.', hme].join('');

            angular.element(element).addClass(hme);
            var editor = new MediumEditor(element, { cleanPastedHTML: true, imageDragging: false, buttons: ['bold', 'italic', 'quote'] });

            ngModel.$render = function () {
                element.html(ngModel.$isEmpty(ngModel.$viewValue) ? '' : ngModel.$viewValue);
            };

            element.on('input blur', _.debounce(function () {
                stext = htmlToPlaintext(element.html()) || '';
                ngModel.$setViewValue(stext);
            }, 1000));

            /**
            * Cleanup - try to destroy memory leak substible 
            */
            function cleanup() {
                if (angular.isElement(this)) {
                    return $scope.$destroy();
                }
                if (element && typeof $(element).off === 'function') {
                    $(element).off();
                }
                if (element && typeof element.off === 'function') { element.off(); }
                if (angular.element(hmeClass) && typeof angular.element(hmeClass).remove === 'function') {
                    //removing hoogy-medium-editor 
                    angular.element(hmeClass).remove();
                } else if (angular.element(hmeClass) && typeof angular.element(hmeClass).deactivate === 'function') {
                    angular.element(hmeClass).deactivate();
                }

                stext = null;
            }
            $scope.$on('$destroy', cleanup);
            element.on('$destroy', cleanup);


        }
    };
}
HoogyMeditor.$inject = ['$log'];
angular.module('hoogy').directive('hoogyMeditor', HoogyMeditor);