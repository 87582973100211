'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * Search Directive
 * Type and pause events while searching for items.
 * @link http://stackoverflow.com/questions/4220126/run-javascript-function-when-user-finishes-typing-instead-of-on-key-up
 * @uses underscore
 * @see _.debounce
 * @link http://underscorejs.org/#debounce
 * '$scope', '$filter', '$state', '$log', 'HoogyService'
 */
function HoogySearch() {
    var directive = {
        scope: { query: '=', search: '&' },
        link: link,
        restrict: 'A'
    };

    return directive;
    /**
     * Fires 2 seconds after user stops typing in search box
     *  - Debounce : fires only after user stops and waits for response
     *  - Throttle : fires frequently after a certain amount of time ( every 2 seconds )
     * @param $scope
     * @param element
     * @param attrs
     * @param ctrl
     */
    function link($scope, $element) {
        $($element).on('keyup', _.debounce(function () {
            if ($scope.search && typeof $scope.search === 'function') {
                $scope.search();
            }
        }, 2000));

        /**
         * @name cleanup - try to destroy memory leak substible 
         */
        function cleanup() {
            if (angular.isElement(this)) { return $scope.$destroy(); }
            if (typeof angular.element($element).off === 'function') { angular.element($element).off(); }
            if ($element && typeof $($element).off === 'function') {
                $($element).off();
            }
            if ($element && typeof $element.off === 'function') { $element.off(); }
        }
        $scope.$on('$destroy', cleanup);
        $element.on('$destroy', cleanup);
    }
}

angular.module('hoogy').directive('hoogySearch', HoogySearch);