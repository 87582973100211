'use strict';

/**
 * @todo ---- avoid Remove State Mutation. 
 * @name MessengerService - Communication Service. 
 * @param {*}  
 * @param {*}  
 * @param {*}  
 * @param {*}  
 * @param {*} StateService 
 * @param {*} ActionCreator 
 * @param {*} HoogyService 
 */
function MessengerService($http, $filter, $q, $log, StateService, ActionCreator, HoogyService) {

    var UNSENT = 'unsent';
    var SENT = 'sent';
    var FAILS = 'fails';
    var FIRST = 'JD';
    var AVATAR = '//s3.amazonaws.com/uifaces/faces/twitter/_everaldo/128.jpg';
    var Config = AppConfig.Config;

    /**
     * @todo Move Model to State Service.
     */
    var model = {
        contacts: [],
        message: {
            text: ''
        },
        messages: [],
        unread: {},
        expediter: {},
        recipient: {},
        user: {},
        people: [],
        notification: {},
        notification: [],
        conversation: {},
        conversations: [],
        people: [],
        population: [],
        discussions: [],
        feedbacks: [],
        history: [],
        orders: []
    };

    //@todo have to query for unread messages
    //uses reveal Pattern.
    var service = {
        
        add: add,
        send: send,
        format: format,
        receive: receive,
        formatUser: formatUser,
        getRecipient: getRecipient,
        getIntents: getIntents,
        getMessages: getMessages,
        getUnread: getUnread,
        updateUnread: updateUnread,
        formatUnread: formatUnread,
        updateUnreadList: updateUnreadList,
        updateConversationList: updateConversationList,

        formatRecipient: formatRecipient,
        
        markSent: markSent,
        getConversations: getConversations,

        //Flux + Model 
        getModel: getModel,
        resetModel: resetModel,

        //static variables
        AVATAR: AVATAR,
        UNSENT: UNSENT,
        SENT: SENT,
        FAILS: FAILS
    };
    return service;


    /**
     * @param {String|Optional} which - indicates a model to return
     * Helper to access to a specific model. 
     */
    function getModel(which) {
        var messenger = StateService.getState().messenger;
        var state = Object.assign({}, model, {
            contacts: messenger.entities.messages || model.messages,
            message: {
                text: ''
            },
            messages: messenger.entities.messages || model.messages,
            unread: messenger.entities.unread || model.unread,
            expediter: messenger.expediter || model.expediter,
            recipient: messenger.recipient || model.recipient,
            user: messenger.user || model.user,
            people: messenger.entities.people || model.people,
            notification: messenger.notification || model.notification,
            conversation: messenger.conversation || model.conversation,
            conversations: messenger.entities.conversations || model.conversations,
            discussions: messenger.entities.discussions || model.discussions,
            feedbacks: messenger.entities.feedbacks || model.feedbacks,
            history: messenger.history || model.history,
            orders: messenger.entities.orders || model.orders
        });
        return state[which] || state;
    }
    /**
     * @name resetModel - re-initializes model status to initial status.
     * @return {Object} model 
     */
    function resetModel() {
        StateService.dispatch(ActionCreator.removeAllMessages());
        StateService.dispatch(ActionCreator.addMessenger(service.getModel()));       
        return Object.assign({}, model, StateService.getState().messenger);
    }

    /**
     * @name isMessage - Checks Payload for having a Message.
     * @param  {Object<Payload>}  payload 
     * @return {Boolean}
     */
    function isMessage(payload) {
        var _payload = payload || {};
        if (_.isObject(_payload) && !_.isString(_payload)) {
            if (_payload._id || _payload.id || _payload.text || _payload.timeago) {
                return true;
            }
        }
        return false;
    }

    /**
     * @todo --- publish changes to shared store, to allow the central store to update dependencies.
     * @todo --- check if message has been formatted. updateUnread(formatUnread(message))
     * @name updateConversationList Updates message
     * @param {Object<Message>} message 
     * @return {Collection<Conversation>}
     */
    function updateConversationList(message) {
        var transaction = Object.assign({}, service.getModel());
        if (!isMessage(message || {})) {
            return false;
        }
        //@todo check if message exists in conversation.
        var convId = message.conversation;
        if (convId && (convId._id || convId.id)) {
            convId = convId._id || convId.id;
        }
        if (!transaction.conversations[convId]) {
            transaction.conversations[convId] = [];
        }
        var exists = _.find(transaction.conversations[convId], function (msg) {
            return (message.id || message._id) === (msg.id || msg._id);
        });
        if (_.isString(message.expediter)) {
            message.expediter = {
                id: message.expediter
            };
        }
        if (!transaction.user && HoogyService.getUser()) {
            transaction.user = HoogyService.getUser() || {};
        }
        if (!message.expediter) {
            message.expediter = {
                other: true
            };
        }
        message.expediter.other = true;
        if (message.expediter.id || message.expediter._id) {
            if (transaction.user.id || transaction.user._id) {
                if ((message.expediter.id || message.expediter._id) === (transaction.user.id || transaction.user._id)) {
                    message.expediter.other = false;
                }
            }
        }

        if (!exists && convId) {
            transaction.conversations[convId].push(message);
        }

        StateService.dispatch(ActionCreator.addMessage(message));
        StateService.dispatch(ActionCreator.addConversations(transaction));
        return transaction;
    }

    /**
     * @todo check if a message has been added two times.
     * @name add - Adds a new Message to The List of Conversations.
     * @param {Object<Response>} message 
     * @returns {Object<Message>} message
     */
    function add(response) {
        var message = Object.assign({}, response.data || response);
        if (!isMessage(message)) {
            return;
        }

        var reverse = true;
        var expression = 'created';
        var transaction = Object.assign({}, service.getModel());
        var _user = service.formatUser(HoogyService.getUser());
        if (!transaction.messages) {
            transaction.messages = [];
        }

        if (!message.timeago) {
            message.timeago = moment(message.created).fromNow();
        }

        if (_.isString(message.expediter)) {
            if (_user.id === message.expediter) {
                message.expediter = _user;
            }
        }

        try {
            transaction.messages.push(message);
            transaction.messages = $filter('orderBy')(transaction.messages, expression, !reverse);
            service.updateConversationList(message);
        } catch (e) {
            $log.log('MessengerService::add', e, message);
        }
        StateService.dispatch(ActionCreator.addMessage(message));
        return message;
    }

    //testing object used to have a design valid preview
    /**
     * @uses add
     * @todo move the save() function to HoogyService.
     * Function to send a message
     * @return {Promise} - promised response
     */
    function send(payload) {
        var transaction = Object.assign({}, service.getModel());
        var message = Object.assign({}, {
            recipient: payload.recipient || transaction.recipient.user || transaction.recipient,
            expediter: payload.expediter || transaction.user
        }, format(payload));
        if (transaction.recipient && transaction.recipient.recipient) {
            $log.error('Cirucular Dependency found @MessengerService:send');
        }
        return HoogyService.sendMessage(message).then(function (response) {
            var message = service.add(response.data || response);
            StateService.dispatch(ActionCreator.addMessage(message));
            StateService.dispatch(ActionCreator.addConversations(transaction));
            return response;
        });
    }

    /**
     * @todo --- udpate shared repository. 
     * @uses updateUnread
     * @uses formatUnread
     * @uses updateConversationList
     *
     * @name receive - Excpects these two parameters to be available
     * @param {Object} - recipient
     * @param {Object} - user
     * receive - works like add, but for received messages.
     * It has been introduced to format incoming mesage to match standards.
     * It has to re-organize messages in model(queue)
     * @param  {Object} message 
     * @return {Object}         
     */
    function receive(payload) {
        var transaction = Object.assign({}, service.getModel());
        var message = Object.assign({}, service.format(payload));
        var conversation = message.conversation || {};
        if (conversation.id || conversation._id) {
            conversation = conversation.id || conversation._id;
        }

        //initialize conversations for current message
        if (_.isString(conversation) && !transaction.conversations[conversation]) {
            transaction.conversations[conversation] = [];
        }
        var whitelist = [];
        if (transaction.user && transaction.user.id) {
            whitelist.push(transaction.user.id);
        }
        if (transaction.recipient && transaction.recipient.id) {
            whitelist.push(transaction.recipient.id);
        }
        var exists = false;
        var expression = 'created';
        var reverse = true; //messages sent are already sorted in reverse order
        try {
            exists = !!_.find(transaction.conversations[conversation] || [], function (msg) {
                return msg.id === message.id || msg._id === message._id;
            });

            if (!exists) {
                //expediter is the other user
                if (whitelist.indexOf(message.expediter) > -1) {
                    message.recipient = message.expediter === transaction.recipient.id ? transaction.recipient : transaction.user;
                    message.expediter = message.expediter === transaction.user.id ? transaction.user : transaction.recipient;
                    if (typeof message.expediter === 'object' && message.expediter._id) {
                        message.expediter = service.formatUser(message.expediter) || message.expediter;
                    }
                    message.expediter.other = true;
                    if (message.expediter && (message.expediter.id === (transaction.user || {}).id)) {
                        message.expediter.other = false;
                    }
                    transaction.messages.push(message);
                } else {
                    //update missed/unread messages
                    service.updateUnread(service.formatUnread(message));
                }
            }
            transaction.messages = $filter('orderBy')(transaction.messages, expression, !reverse);
        } catch (e) {
            $log.log('Adding::Received::Message - Error', e);
        }
        //updates conversations list.
        service.updateConversationList(message);
        StateService.dispatch(ActionCreator.addMessage(message));
        //In case return values is needed
        return message;
    }

    /**
     * @todo - StateService.dispatch(ActionCreator.markSent(message));
     *  This function changes status of message from unsent to sent.
     * It searches a message based on:
     * 	- either ID
     * 	- or timesent
     * 	- or hash of text
     * @return {Object} 
     */
    function markSent(payload) {
        var transaction = Object.assign({}, service.getModel());
        var message = payload;
        if (!isMessage(message)) {
            message = format(message);
        }

        _.each(transaction.messages, function (msg) {
            if (isSame(msg, message)) {
                msg.status = SENT;
                return true;
            }
        });
    }


    /**
     * Compares two messages.
     * @param  {Object} sample     
     * @param  {Object} comparator 
     * @return {Object}            
     */
    function isSame(sample, comparator) {
        if (new Date(sample.created).getTime() !== new Date(comparator.created).getTime()) {
            return false;
        }

        if (sample._id && comparator._id && sample.id !== comparator._id) {
            return false;
        }

        return true;
    }

    /**
     * @name formatUser - Formats User to Look Like a Recipient.
     * @param  {Object} options 
     * @return {Object<User>}
     */
    function formatUser(options) {
        var user = HoogyService.formatUser(options || HoogyService.getUser());
        return Object.assign({}, user,{
            initials: user.initials || 'JD',
            name: user.first || user.name || 'JD',
            username: ['@', user.first || user.name || 'JD'].join(''),
            url: user.photo || user.picture || AVATAR,
            photo: user.photo || user.picture || AVATAR,
            id: user.id || user._id || options.id || options._id || ''
        });
    }

    //utility function used to format recipient information
    function formatRecipient(payload) {
        var data = payload.data || payload || {};
        if (data[0] && (data[0].facebook || data[0].local || data[0].google)) {
            data = data[0];
        }
        return service.formatUser(data || payload || {});
    }

    /**
     * @name getRecipient - Search information fetches recipient information
     * @param {Object<Options>} options 
     * @return {Promise} - With recipient Object
     */
    function getRecipient(options) {
        if (options && options.recipient && !options.id) {
            options.id = options.recipient;
        }
        return HoogyService.getRecipient(options).then(function (response) {
            StateService.dispatch(ActionCreator.addMessageRecipient(service.formatRecipient(response)));
            return response.data || response;
        });
    }

    /**
     * @name getIntents - Intent is the function called at load time.
     *                  - It cannot be used for search, nor for next/previous messages.
     * @param  {Object} options 
     * @return {Object}         
     */
    function getIntents(options) {
        var transaction = Object.assign({}, service.getModel());
        
        var expression = 'created';
        var reverse = true; //sorted in reverse order
        var params = Object.assign({}, options.data || options || {});
        if (_.isArray(params) && params[0] && params[0]._id) {
            params = params[0];
        }
        var recipient = params._id || params.id || params.recipient;
        params = Object.assign({}, {recipient: recipient});
        return HoogyService.getIntents(params).then(function (response) {
            var _response = response.data || response;
            transaction.discussions = _response.discussions || [];
            transaction.feedback = _response.feedback || [];
            transaction.orders = _response.orders || [];
            transaction.messages = _response.messages || _response || [];
            //currently connected user
            transaction.user = service.formatUser(HoogyService.getUser());
            //arranged by latest date, next on server side.
            _.each(transaction.messages || [], function (message) {
                message.timeago = moment(message.created).fromNow();
                if (transaction.recipient && transaction.recipient.id === message.expediter) {
                    message.expediter = transaction.recipient || {};
                    message.expediter.other = true;
                } else if (transaction.user && transaction.user.id === message.expediter) {
                    //by default it will format ConnectedUser
                    message.expediter = transaction.user || {};
                    message.expediter.other = false;
                }
                service.updateConversationList(message);
            });
            transaction.messages = $filter('orderBy')(transaction.messages, expression, !reverse);
            StateService.dispatch(ActionCreator.addIntents(transaction));
            return response;
        });

    }

    /**
     * @name getUnread - Request for un-read messages. Ready to be chained to other Promise.
     * @param  {Object<Options>} options 
     * @return {Promise}
     */
    function getUnread(options) {
        var user = HoogyService.getUser();
        var transaction = Object.assign({}, service.getModel());

        var params = options || {}; //makes it chainable to promisedThing
        if (params.data) {
            params = params.data;
            if (options.config && options.config.recipient) {
                params.recipient = options.config.recipient;
            }
        }

        if (_.isArray(params) && params[0] && params[0]._id) {
            params = params[0];
        }

        if (params._id || params.id || params.recipient) {
            params.recipient = params._id || params.id || params.recipient;
        }

        if (!params && transaction.user && (transaction.user.id || transaction.user._id)) {
            params.recipient = transaction.user._id || transaction.user.id;
        }
        if (!params.recipient && (user.id || user._id)) {
            params.recipient = user.id || user._id;
        }
        var recipient = {
            recipient: params.recipient
        };
        return HoogyService.getUnread(recipient).then(function (response) {
            service.updateUnreadList(response.data || response);
            StateService.dispatch(ActionCreator.addUnreadMessage(transaction));
            return response;
        });
    }

    /**
     * Used internally to update unread lists.
     */
    function updateUnreadList(messages) {
        var transaction = Object.assign({}, service.getModel());
        //clean the model to receive brand new data
        transaction.unread = transaction.unread || {};
        _.map(messages, function (message) {
            service.updateUnread(message);
            return message; //for the next item
        });
    }

    /**
     * @name updateUnread - Messages from same expediter are stored in same hash
     * @param  {Object} unread 
     * @return {Object}        
     */
    function updateUnread(payload) {
        var unread = Object.assign({}, service.formatUnread(payload));
        var transaction = Object.assign({}, service.getModel());
        transaction.unread = transaction.unread || {};
        var user = unread.user || unread.expediter;
        var index = _.isString(user) ? user : user.id || user._id;
        if (!index) {
            throw 'Message Expediter not found';
        }
        if (!transaction.unread[index]) {
            transaction.unread[index] = unread;
            transaction.unread[index].counter = 1;
        } else {
            transaction.unread[index].counter = transaction.unread[index].counter + 1;
            unread.latest.count++;
        }
        transaction.unread[index].latest = unread.latest;
        return unread; 
    }
    /**
     * @name formatUnread - Function to format unread message
     * @param  {Object} message
     * @return {Object}         
     */
    function formatUnread(message) {
        var expediter = message.expediter || message.user || message.sender || {};

        if (_.isString(expediter)) {
            expediter = {
                id: expediter
            };
        }
        if (expediter._id && !expediter.id) {
            expediter.id = expediter._id;
        }
        if (message.sender && _.isString(message.expediter || '')) {
            expediter = Object.assign({}, expediter, message.sender);
        }
        var sender = service.formatUser(expediter);
        //format time with moment.
        var latest = {
            id: message.id,
            text: message.text,
            read: false,
            timeago: moment(message.created || new Date().getTime()).fromNow(),
            time: message.time || new Date().getTime()
        };
        if (message.latest && message.latest.text) {
            latest.text = message.latest.text;
        }

        var begin = 0; //starting from 0
        latest.text = $filter('limitTo')(latest.text, 80, begin);
        if (_.isString(message)) {
            message = {
                latest: {
                    text: message
                }
            };
        }
        if (!message.counter) {
            message.counter = 0;
        }
        var counter = message.counter++ || 1; //increment once
        var copy = message || {};
        return Object.assign({}, copy, {
            title: latest.text,
            user: sender,
            expediter: sender,
            latest: latest,
            counter: counter,
            processed: false,
            timeago: latest.timeago
        });
    }


    /**
     * @todo Move Messages Format into a testable function 
     * @name getMessages - Function used to padd messages, can be chained to other methods
     * @param  {Array} options [options.recipient - the guy I am communicating with]
     * @return {Object:Promise} 
     */
    function getMessages(options) {
        var conversation = 0;
        var expression = 'created';
        var reverse = true; //sorted in reverse order
        var params = Object.assign({}, options && options.data ? options.data : options || {});
        if (_.isArray(params) && params[0] && params[0]._id) {
            params = params[0];
        }
        params = Object.assign({}, params, {recipient: params._id || params.id || params.recipient});
        var transaction = Object.assign({}, service.getModel());
        return HoogyService.getMessages(params).then(function (response) {
            var _response = response.data || response;

            if (!transaction.messages || !transaction.messages.length) {
                transaction.messages = [];
            }

            //arranged by latest date, next on server side.
            transaction.messages.push(_response.messages || _response);
            _.each(transaction.messages || [], function (message) {
                if (!conversation && message.conversation) {
                    conversation = message.conversation;
                    if (conversation.id || conversation._id) {
                        conversation = conversation.id || conversation._id;
                    }
                }
                message.timeago = moment(message.created).fromNow();
                if (transaction.recipient && transaction.recipient.id === message.sender) {
                    message.expediter = transaction.recipient || {};
                    message.expediter.other = true;
                } else {
                    //by default it will format ConnectedUser
                    message.expediter = transaction.user || {};
                    message.expediter.other = false;
                }

            });

            transaction.messages = $filter('orderBy')(transaction.messages, expression, !reverse);
            //update conversation
            if (conversation) {
                transaction.conversations[conversation] = transaction.messages || [];
            }
            StateService.dispatch(ActionCreator.addConversations(transaction));
            return response;
        });
    }

    /**
     * @name getConversations - Function [getConversations] checks cached messages.
     * Function will be used to check messages within a given conversation
     * If no cached messages exists, a request is sent to server.
     * If cached messages exist, a request is not necessary.
     * @param {Object<Conversation>} conversation
     * @param {Boolean<True|False>} sort 
     * @return {Array|Object} conversations
     */
    function getConversations(conversation, sort) {
        var id = conversation;
        //sorting parameters
        var expression = 'created';
        var reverse = true; //sorted in reverse order
        if (conversation && (conversation._id || conversation.id)) {
            id = conversation._id || conversation.id;
        }
        var transaction = Object.assign({}, service.getModel());
        var ret = transaction.conversations || [];
        if (id && _.isArray(ret[id])) {
            ret = ret[id];
            if (sort === true && _.size(ret) > 0) {
                return $filter('orderBy')(ret, expression, !reverse);
            }
        }
        return ret || [];
    }

    /**
     * @name format - This function formats a message content.
     *  It takes a String or object, and creates a usable message instance.
     * @param {Object} data 
     * @requires moment
     */
    function format(data) {
        if (_.isString(data)) {
            return Object.assign({}, {
                payload: data,
                text: data,
                status: UNSENT,
                created: new Date().getTime(),
                timeago: moment(new Date().getTime()).fromNow()
            });
        } else if (_.isObject(data)) {
            return Object.assign({}, data, {
                payload: data.text,
                status: data.status || UNSENT,
                created: data.created || new Date().getTime(),
                timeago: moment(data.created || new Date().getTime()).fromNow()
            });
        }
        return {};
    }
}
MessengerService.$inject = ['$http', '$filter', '$q', '$log', 'StateService', 'ActionCreator', 'HoogyService'];
angular.module('hoogy').factory('MessengerService', MessengerService);