'use strict';

/**
 * @name HoogyController 
 * @param {Object} $state 
 * @param {Object} $auth 
 * @param {Object} $timeout 
 * @param {Object} $log 
 * @param {Object} DiagnoseService 
 * @param {Object} HoogyService 
 * @param {Object} NotificationService 
 * @param {Object} AuthService 
 * @param {Object} StripeService 
 * @param {Object} UIService 
 * @param {Object} StateService 
 * @param {Object} ActionCreator 
 * @param {Object} MessengerService 
 * @param {Object} SearchService 
 */
function HoogyController($state, $auth, $timeout, $log, DiagnoseService, HoogyService, NotificationService, AuthService, StripeService, UIService, StateService, ActionCreator, MessengerService, SearchService) {


    var vm = this;

    /**
     * @name $onInit - Initialization of Hooggy Component
     */
    this.$onInit = function () {
        
        this.intro = true;
        this.model = {};
        this.model.notification = {};         
        this.screen = UIService.getHooggyScreen({component: 'hooggy'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));                
        this.unsubscribe = this.subscribe();
        //@link --- scroll top upon message reception
        //http://stackoverflow.com/a/36606829/132610
        //@todo --- wrapp this.socket around a Service to make sure we create It once
        //attaching token to every request, allow server to validate and authenticate requests,
        //as the front-end HTTP requests are validated
        //@link https://auth0.com/blog/2014/01/15/auth-with-this.socket-io/
        //listening to messages from webthis.socket
        this.socket = io(AppConfig.Config.WSURL, {
            query: ['token', $auth.getToken()].join('=')
        });
        this.socket.on('message', _.debounce(this.onMessage, 500)); //
        this.socket.on('error', NotificationService.report);
        this.socket.on('connect_error', NotificationService.report);

        this.init();
    };

    /**
     * @name subscribe - Subscribe for new object models. 
     *                 - Screen.Up makes it possible to show/hide Hamburger Menu. 
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.screen = UIService.getHooggyScreen({component: 'hooggy'});
            vm.model = Object.assign({}, vm.model, MessengerService.getModel());
            vm.notifications = NotificationService.getModel();
            vm.screen.notifications = NotificationService.getModel(); 
            
            if(!_.isEmpty(vm.notification)){
                $timeout(function(){vm.notification={};},500);
            }
            

            vm.model.auth = AuthService.getModel();
            if(!vm.model.auth || !vm.model.auth.user){
                vm.handler = $timeout(function () {
                    $log.log('Fires in every Subscribe Change ');
                    $state.go( AuthService.isAuthenticated() ? 'settings.profile': 'store.list');
                }, 1500);
            }
        });
    };


    /**
     * @name _handleException - Generic Exception vm.handler. 
     * @param {Object<Response>} response
     */
    this._handleException = function (error) {
        vm.enableSave = false;
        vm.screen.waiting = false; 
        var notice = NotificationService.formatHooggyComponentFailure(error);
        NotificationService.report(notice);
        return error;
    };

    /**
     * @throws OnMessageRequiresNonEmptyPayloads
     * @name onMessage - callback on a new message.
     * @deprecated use a variation of redux. 
     */
    this.onMessage = function (payload, options) {
        if (_.isEmpty(payload)) throw '@onMessage Requires Non Empty Payloads';
        if (!payload.expediter) throw '@onMessage Payload Requires Expediter';
        payload.expediter = MessengerService.formatUser(payload.expediter) || payload.expediter;
        var message = MessengerService.receive(payload);
        NotificationService.report(message);
        StateService.dispatch(ActionCreator.onMessage({
            value: message
        }));
    };


    /**
     * @uses HoogyService::me
     * @uses StateService::dispatch:add-user
     * @name init - Initialization of 
     */
    this.init = function () {
        return HoogyService.me().then(function (response) {
                var _settings = HoogyService.userSettings;
                if (!_.isString(_settings)) {
                    vm.screen = Object.assign({}, vm.screen, _settings);
                    vm.screen.wallet = StripeService.getWallet();
                    vm.screen.bank = StripeService.getBank();
                    vm.screen.customer = StripeService.getCustomer();
                }
                HoogyService.user = HoogyService.getUser() || response.data;
                StateService.dispatch(ActionCreator.addUser(HoogyService.user)); 
                return response;
            })
            .then(DiagnoseService.diagnose)
            .catch(vm._handleException);
        //waiting for updates as messages arives
    };

    this.isAuthenticated = function () {
        return AuthService.isAuthenticated();
    };

    this._unauthorized = function () {
        HoogyService.showAuthentication = true;
    };

    this._reinit = function (event) {
        var statego = 'settings.profile';
        vm.init();
        vm.handler = $timeout(function () {
            $state.go(statego);
        }, 1500);
    }

    /**
     * @param {Object<Params>}
     * @name close - Callback Closing the Alert Box Redirects to Store.Listing
     */
    this.close = function(params){
        $state.go(AuthService.isAuthenticated() ? 'settings.profile': 'store.list');
    };

    /**
     * @name onUnauthized - Handle the case someone complains about a non authenticated user
     */
    this.onUnauthorized = function (event) {
        AuthService.logout().then(vm._unauthorized).catch(vm._unauthorized);
        $log.log('HoogyController::watching unauthorized');
    };



    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') this.unsubscribe();
        // Releasing Objects 
        this.unsubscribe = null;
        //remove handler
        if (vm.handler) $timeout.cancel(vm.handler);
        vm.handler = null;
    };
}
HoogyController.$inject = ['$state', '$auth', '$timeout', '$log', 'DiagnoseService', 'HoogyService', 'NotificationService', 'AuthService', 'StripeService', 'UIService', 'StateService', 'ActionCreator', 'MessengerService', 'SearchService'];
/**
 * Top level application 
 */
angular.module('hoogy').component('hooggy', {
    controllerAs: 'vm',
    controller: HoogyController,
    template: 
    '<div class="hoogy-app-component" ng-init="vm.init()">' +
    '   <hoogy-auth screen="vm.screen" next="vm.close(params)"> </hoogy-auth>' +
    '   <sharer-widget screen="vm.screen" next="vm.close(params)"> </sharer-widget>' +
    '   <hoogy-header screen="vm.screen"></hoogy-header>' +
    '   <ui-view></ui-view>' +
    '   <hoogy-notification  ng-show="vm.model.notification" notification="vm.model.notification"></hoogy-notification>' +
    '   <br clear="all" />' +
    '   <hoogy-footer></hoogy-footer>' +
    '</div>'
});