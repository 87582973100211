'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/ 

/**
 * This directive price helper.
 * It :
 * 	- Gives an interface to choose Price Per Hour/Day/Week/Month/Semester
 */
function PriceController(HoogyService) {
    var vm = this;
    vm.init = function () {
        vm.cycles = HoogyService.getPricingModel((vm.pricing || {}).price);
        vm.cycle = vm.pricing || HoogyService.getDefaultPricing();
    };

    /**
     * This function makes [OK] button available.
     * @param  {Object} target 
     * @return {Object}        
     */
    vm.activate = function (target) {
        if (typeof target === 'object') {
            _.each(vm.cycles, function (cycle) {
                cycle.active = false;
            });
            target.active = !target.active;
        }
        return false;
    };

    /**
     * This function sets current price.
     * It copies previous price into new price.
     * It communicates the change to the caller, via next({pricing : cycle})
     * @param  {Object} cycle 
     * @return {Object}       
     */
    vm.select = function (cycle) {
        if (vm.cycle && vm.cycle.price && !cycle.price) {
            cycle.price = vm.cycle.price;
        }

        cycle.active = !!cycle.active;
        vm.cycle = cycle;
        vm.pricing = vm.cycle;
        if (typeof vm.next === 'function') {
            vm.next({ pricing: vm.pricing });
        }
    };

    /**
     * @name onPricingChange - used with ng-change="vm.onPricingChange($event)" 
     * @todo test change on this directive.
     */
    vm.onPricingChange = function (current, old) {
        if (current !== old) {
            vm.cycle = current;
        }
    };

    //making pricing and vm.cycle sync.
    this.$onDestroy = function(){
        //@todo - add more Price Destroy 
    };
   
    return vm;
}
PriceController.$inject = ['HoogyService'];


function PriceDirective($log) {

    /**
     * http://stackoverflow.com/a/25089383/132610
     * @param  {Object<$scope>} scope   
     * @param  {Object<Element>} element 
     * @param  {Object<Attrs>} attrs   
     * @param  {Function} ctrl    
     * @return {void}         
     */
    function link($scope, $element, $attrs, $ctrl) {
        var dvalue;

        $($element).on('click', '.menu-item .cycle-label', function (e) {
            e.preventDefault();
            dvalue = parseInt($(this).attr('data-value'));
            _.each($ctrl.cycles, function (cycle) {
                cycle.active = false;
                if (cycle.value === dvalue) {
                    $ctrl.activate(cycle);
                }
            });

            $('.dropdown.price-selector').addClass('open');
            $($element).find('.menu-item.active').removeClass('active');
            $(this).parent('li.menu-item').toggleClass('active');
            return false;
        });

        /**
         * Dropdown for price-selector
         */
        $($element).on('click', '.price-cycle.dropdown-status', function (e) {
            $(this).parent('.dropdown').toggleClass('open');
        });

        $($element).on('click', '.menu-item .ok', function (e) {
            e.preventDefault();
            $('.dropdown').removeClass('open');
            $($element).find('.menu-item.active').removeClass('active');
        });
    }

    return {
        link: link,
        restrict: 'EA',
        controllerAs: 'vm',
        bindToController: true,
        controller: PriceController,
        scope: { pricing: '=', next: '&' },
        templateUrl: 'js/common/components/view/price.html'
    };
}
PriceDirective.$inject = ['$log'];
angular.module('hoogy').directive('hoogyPrice', PriceDirective);