'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/ 
function ReviewService($log, $q, HoogyService, StateService, ActionCreator) {

    var model = {
        error: {},
        reviews: [],
        review: {},
        collection: [],
        reviewee: {},
        reviewer: {}
    };

    var service = {
        AVATAR: HoogyService.AVATAR,
        getModel: getModel,
        getReviews: getReviews,
        getReview: getReview,
        postReview: postReview, 
        
        formatReview: formatReview,
        formatReviewResponse: formatReviewResponse,
        validReviewRequest: validReviewRequest
    };


    return service;


    /**
     * @param {String|Optional} which - indicates a model to return
     * @name getModel - Helper to access to a specific model. 
     */
    function getModel(which) {
        var review = StateService.getState().review;
        var state = Object.assign({}, model, {
            error: review.error || model.error ,
            reviews: review.entities.reviews || model.reviews,
            review: review.review || model.review,
            collection: review.entities.collection || model.collection,
            reviewee: review.reviewee || model.reviewee,
            reviewer: review.reviewer || model.reviewer 
        });  
        return state[which] || state;
    }


    /**
     * @param {Object<Review>}
     * @name formatReviewResponse - Used to format a Review 
     */
    function formatReviewResponse(response) {
        if(!response){ throw 'Review Response is missing'; }
        var review = Object.assign({}, response.data || response);
        if (review && review.item) {
            review.item.photo = HoogyService.normalize(review.item.photo || HoogyService.AVATAR);
        }
        return Object.assign({}, review, {
            reviewer: HoogyService.formatUser(review.reviewer || {}),
            reviewee: HoogyService.formatUser(review.reviewee || {})
        });
    }


    /**
     * @name getReview - Retrieves Review Details. 
     * @param {String|Number} id 
     */
    function getReview(id) {
        if (!id || _.isObject(id)) {
            throw 'Review ID parameter is missing';
        }
        return HoogyService.getReview(id).then(function (response) {
            var review = service.formatReviewResponse(response.data || response);
            StateService.dispatch(ActionCreator.addReview(review));                        
            return response; 
        });
    }



    /**
     * @todo --- should set first Order reviewer has made to reviewer OR vice-versa. 
     * @name getReviews - Initialization of reviewee data. 
     * @param {Object} params - search parameters.
     * @return {Object|Promise} review 
     */
    function getReviews(params) {
        if (!params) { throw 'Get Review parameters missing'; }
        if (!params.owner && !params.id) {
            throw 'Reviewee parameter is missing';
        }
        var user = HoogyService.getUser();
        return HoogyService.getReviews(params).then(function (response) {
            var reviews = _.map(response.data || response || [], function (review) {
                return service.formatReviewResponse(review);
            });
            StateService.dispatch(ActionCreator.addReviews(reviews));                        
            return response;
        });
    }


    /**
     * @name formatReview - Function to normalize Review format. Receives a response instance.
     * @param {Object<Response>} response.
     * @returns {Object} review  
     */
    function formatReview(response) {
        var review = response.data || response;
        if (_.isArray(review)) {
            review = review[0];

        }
        if (review && review.review) {
            review = review.review;//server may add additional data
        }
        return review;
    }


    /**
     * Review Request is valid if all keys in Review Object are whitelisted.
     * @param {Object} review 
     * @returns {Boolean} valid. 
     */
    function validReviewRequest(review) {
        var keys = Object.keys(review) || [];
        var whitelist = ['order', 'reviewee', 'reviewer', 'note'];
        if (review._id) { whitelist.push('_id'); }
        return !(_.difference(whitelist, keys) || []).length;
    }

    /**
     * @name postReview 
     * @param {Object} review - review + additional data to send to server. 
     */
    function postReview(review) {
        if (!review || !service.validReviewRequest(review)) {
            throw 'Review data is not valid';
        }
        return HoogyService.postReview(review).then(function (response) {
            StateService.dispatch(ActionCreator.addReview(service.formatReview(response.data || response)));                        
            return response;
        });
    }
}

ReviewService.$inject = ['$log', '$q', 'HoogyService', 'StateService', 'ActionCreator'];
angular.module('hoogy').factory('ReviewService', ReviewService);