'use strict';
/**
 * @name SettingsMenu - Settings Menu - Allows to have left handside menu and drop menu.
 * @type {Array}
 */
function SettingsMenuController(AuthService, StateService, ActionCreator){
  var vm = this;

  /**
   * @name $onInit 
   */
  this.$onInit = function(){
    this.screen.active = 'profile';
    StateService.dispatch(ActionCreator.addScreen(this.screen));
    this.unsubscribe = this.subscribe();
    this.init();    
  };

  /**
   * @name subscribe 
   */
  this.subscribe = function () {
      return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
          vm.model = AuthService.getModel();
      });
  };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
      if (typeof this.unsubscribe === 'function') {
          this.unsubscribe();
      }
      this.unsubscribe = null;
    };
  
  /**
   * @name init 
   */
  this.init = function(){
  };
}
SettingsMenuController.$inject = ['AuthService', 'StateService', 'ActionCreator']
angular.module('hoogy').component('settingsMenu', {
  controllerAs: 'vm',
  controller: SettingsMenuController,
  bindings:{screen:'=' },
  template: [
    '<div class="pull-right" ng-init="vm.init()" settings-menu-dropdown>',
    '<span class="dropdown-toggle glyphicon glyphicon-cog -settings pulse" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"></span>',
    '<ul class="settings-menu dropdown-menu">',
       '<li class="settings-menu-li"><a ng-class="{active : vm.screen.active === \'profile\'}"  ui-sref="settings.profile">Edit Profile</a></li>',
        '<li class="settings-menu-li"><a ng-class="{active : vm.screen.active === \'photo\'}"  ui-sref="settings.photo">Upload a Photo</a></li>',
        '<li class="settings-menu-li"><a ng-class="{active : vm.screen.active === \'address\'}"  ui-sref="settings.address">Change Address Settings</a></li>',
        '<li class="settings-menu-li"><a ng-class="{active : vm.screen.active === \'password\'}"  ui-sref="settings.password">Change Password</a></li>',
        '<li class="settings-menu-li"><a ng-class="{active : vm.screen.active === \'wallet\'}"  ui-sref="settings.wallet">Accept Payments</a></li>',
        '<li class="settings-menu-li"><a ng-class="{active : vm.screen.active === \'wallet\'}"  ui-sref="settings.payment">Payment Settings</a></li>',
        '<li class="settings-menu-li"><a ng-class="{active : vm.screen.active === \'contact\'}"  ui-sref="settings.contact">Add Contacts</a></li>',
        '<li class="settings-menu-li"><a ng-class="{active : vm.screen.active === \'contract\'}"  ui-sref="settings.contract">Edit Contract</a></li>',
    '</ul></div>'
  ].join('')
});