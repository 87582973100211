'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/ 

/**
 * @name HoogyOrderStatus - order status
 */
function HoogyOrderStatus($log) {

    /**
     * http://stackoverflow.com/a/25089383/132610
     * [link description]
     * @param  {Object} scope   
     * @param  {Object} element 
     * @param  {Object} attrs   
     * @param  {Object} ctrl    
     * @return {Object}         
     */
    function link(scope, element, attrs, ctrl) {
        $(element).on('click', 'li.menu-item .status-label', function(e) {
            e.preventDefault();
            _.each(ctrl.statuses, function(status) {
                status.active = false;
                if (status.value === $(e).attr('data-value')) {
                    ctrl.activate(status);
                }
            });

            $(element).find('.menu-item.active').removeClass('active');
            $(this).parent('li.menu-item').toggleClass('active');
            return false;
        });

        /**
         * Dropdown for Order Status
         */
        $(element).on('click', '.dropdown-status', function(e){
          $(this).parent('.dropdown').toggleClass('open');
        });

        $(element).on('click', 'li.menu-item .ok', function(e) {
            $(element).find('.menu-item.active').removeClass('active');
        });

    }

    return {
        link: link,
        replace: true,
        restrict: 'EA',
        controllerAs: 'vm',
        bindToController: true,
        scope: { order: '=', next:'&' },
        controller: StatusController,
        templateUrl: 'js/common/components/view/orderstatus.html'
    };
}

StatusController.$inject = ['$log', 'dateFilter', 'HoogyService'];
function StatusController($log, dateFilter, HoogyService) {

    var vm = this;
    
    //
    this.CANCEL = HoogyService.CANCEL;
    this.REJECT = HoogyService.REJECT;
    this.FULLFILL = HoogyService.FULLFILL;
    this.SHIP = HoogyService.SHIP;
    this.REFUND = HoogyService.REFUND;
    this.FINALIZE = HoogyService.FINALIZE;
    
    //
    this.WAITING = HoogyService.WAITING;
    this.CANCELLED = HoogyService.CANCELLED;
    this.REJECTED = HoogyService.REJECTED;
    this.FULLFILLED = HoogyService.FULLFILLED;
    this.SHIPPED = HoogyService.SHIPPED;
    this.REFUNDED = HoogyService.REFUNDED;
    this.FINALIZED = HoogyService.FINALIZED;

    //
    this.statuses = [
      { value: HoogyService.CANCEL,  status: HoogyService.CANCELLED, text: 'Cancel order'},
      { value: HoogyService.REJECT, status: HoogyService.REJECTED, text: 'Reject request' },
      { value: HoogyService.FULLFILL, status: HoogyService.FULLFILLED, text: 'Fulfill' },
      { value: HoogyService.SHIP, status: HoogyService.SHIPPED, text: 'Ship Order' },
      { value: HoogyService.REFUND, status: HoogyService.REFUNDED, text: 'Refund Order' },
      { value: HoogyService.FINALIZE,  status: HoogyService.FINALIZED, text: 'Finalize order' }
    ];

    this.$onInit = function(){this.init();};
    
    /**
     * @name init - initialization of Order Status.
     * @return {void}
     */
    this.init = function() {
        this.filter();
        this.availables = _.size(_.filter(this.statuses, {available: true }));
    };

    /**
     * This function confirms selection on active status.
     * @param  {Object} status - status being used.
     * @return {Object}        
     */
    this.select = function(status) {
        if(!status || _.isEmpty(status)) throw 'Status should have value';
        if(!this.order || _.isEmpty(this.order)) throw 'Order should be initialized';
        if (this.order && typeof status === 'object') {
            this.order.status = status.value;
            this.next({order: this.order});
            this.filter();
            this.availables = _.size(_.filter(this.statuses, {available: true }));
        }
    };

    /**
     * This function makes [OK] button appear.
     * Clicking on [OK] button triggers vm.select(status)
     * @name
     * @return {Object} 
     */
    this.activate = function(target) {
        if (typeof target === 'object') {
            target.active = true;
        }
    };

    var cssClasses = {
      waiting: 'alert-info',
      cancelled: 'alert-danger',
      rejected: 'alert-warning',
      accepted: 'alert-success',
      returned: 'alert-danger',
      completed: 'alert-success'
  };

    /**
     * This function takes order as a premice, and decides available statuses
     * @name
     * @return {Object} 
     */
    this.filter = function() {
        //statuses allowerd to renters - user will be able to cancel with these 3 statuses
        var _rwhitelist = [this.WAITING, this.FULLFILLED, this.SHIPPED];
        _.each(vm.statuses, function(status) {
            status.available = false;
            if (vm.order.isOwner) {
                if (vm.order.status === vm.WAITING) {
                    status.available = [vm.REJECT, vm.FULLFILL].indexOf(status.value) > -1 ;
                }else if (vm.order.status === vm.FULLFILLED) {
                    //Fullfilled order, should be ready to be shipped, or rejected.
                    status.available = [vm.SHIP, vm.REJECT].indexOf(status.value) > -1 ;
                }else if (vm.order.status === vm.SHIPPED) {
                    //shipped, will have to be finalized whenever the user returns the item.
                    status.available = [vm.FINALIZE, vm.REFUND].indexOf(status.value) > -1 ;
                }
            }else if (vm.order.isRenter) {
                //@todo --- overdue should also trigger a Cancellation.
                //only Cancel will be available
                if (_rwhitelist.indexOf(vm.order.status) > -1) {
                    status.available = vm.CANCEL === status.value;
                }
            }
        });
    };

}
HoogyOrderStatus.$inject = ['$log'];
angular.module('hoogy').directive('hoogyOrderStatus',  HoogyOrderStatus);
