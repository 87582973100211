'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/

function MessagesController(AuthService, UIService, StateService, ActionCreator, NotificationService, MessengerService) {


    /**
    * @type {Object}
    */
    var vm = this;
    /**
     * @name $onInit 
     */
    this.$onInit = function(){
        this.model = MessengerService.getModel(); 
        this.screen = UIService.getMessengerScreen({component: 'messenger:messages'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        this.init(); 
    };

    /**
     * @name subscribe 
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = MessengerService.getModel(); 
            vm.model.notifications = NotificationService.getModel();                         
        });
    };

    this.init = function () {
        this.expand = true;
        this.screen = UIService.getMessengerScreen({action: 'messages', component: 'messenger:messages'});
        this.model = MessengerService.getModel(); 
    };

    /**
     * Intent can be :
     * 	- Feeback (poll|redirect open feature|blog article | help)
     * 	- Order(order with a problem | return item now | close order | etc)
     * 	- Discussion(Send this message|Transform message into a ticket | make reservation | etc )
     * @name
     * @param  {Object} intent 
     * @return {Object}        
     */
    this.execute = function (intent) {
        //find type of intent, and take proper action to execute
    };
    
    /**
     * @name isAuthenticated - This function is used to test if user is authenticated or not.
     * It injects this functionality into current scope, so that we can
     * use this test wherever current scope arrives.
     * @returns {Promise}
     */
    this.isAuthenticated = function () {
        return AuthService.isAuthenticated(true);
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };
};
MessagesController.$inject = ['AuthService', 'UIService', 'StateService', 'ActionCreator', 'NotificationService', 'MessengerService'];
angular.module('hoogy').component('messages', {
    controllerAs: 'vm',
    controller: MessagesController,
    templateUrl: 'js/messenger/view/messages.html'
});
