'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * This directive allow users to select rental periods
 * hourly rental, 24 Hours will be available
 * daily rentals, 5 days will be available
 * Weekly rentals, 4 weeks will be available
 * Monthly rentals, 12 months will be available
 * Semester rentals only 2 Will be available
 */
angular
    .module('hoogy')
    .directive('hoogyPeriod', PeriodDirective);

PeriodDirective.$inject = ['$log']
function PeriodDirective($log) {

    /**
     * http://stackoverflow.com/a/25089383/132610
     * [link description]
     * @param  {Object} scope   
     * @param  {Object} element 
     * @param  {Object} attrs   
     * @param  {Object} ctrl    
     * @return {Object}         
     */
    function link(scope, element, attrs, ctrl) {
        var dvalue;

        $(element).on('click', '.menu-item .cycle-label', function (e) {
            e.preventDefault();
            dvalue = parseInt($(this).attr('data-value'));
            _.each(ctrl.cycles, function (cycle) {
                cycle.active = false;
                if (cycle.value === dvalue) {
                    ctrl.activate(cycle);
                }
            });

            $('.dropdown.period-selector').addClass('open');
            $(element).find('.menu-item.active').removeClass('active');
            $(this).parent('li.menu-item').toggleClass('active');
            return false;
        });

        /**
         * Dropdown for period
         */
        $(element).on('click', '.period-cycle.dropdown-status', function (e) {
            $(this).parent('.dropdown').toggleClass('open');
        });

        $(element).on('click', '.menu-item .ok', function (e) {
            e.preventDefault();
            $('.dropdown.period-selector').removeClass('open');
            $(element).find('.menu-item.active').removeClass('active');
        });
    }

    return {
        link: link,
        replace: true,
        restrict: 'EA',
        controllerAs: 'vm',
        bindToController: true,
        controller: PeriodController,
        scope: { model: '=', next: '&' },
        templateUrl: 'js/common/components/view/period.html'
    };

}

PeriodController.$inject = ['HoogyService'];
function PeriodController(HoogyService) {

    'use strict';

    var vm = this;
    var hours = [
        { value: 1, text: 'an hour' },
        { value: 2, text: '2 hours' },
        { value: 3, text: '3 hours' },
        { value: 4, text: '4 hours' },
        { value: 5, text: '5 hours' },
        { value: 6, text: '6 hours' },
        { value: 7, text: '7 hours' },
        { value: 8, text: '8 hours' },
        { value: 9, text: '9 hours' },
        { value: 10, text: '10 hours' },
        { value: 11, text: '11 hours' },
        { value: 12, text: '12 hours' },
        { value: 13, text: '13 hours' },
        { value: 14, text: '14 hours' },
        { value: 15, text: '15 hours' },
        { value: 16, text: '16 hours' },
        { value: 17, text: '17 hours' },
        { value: 18, text: '18 hours' },
        { value: 19, text: '19 hours' },
        { value: 20, text: '20 hours' },
        { value: 21, text: '21 hours' },
        { value: 22, text: '22 hours' },
        { value: 23, text: '23 hours' }];
    var days = [
        { value: 1, text: 'a day' },
        { value: 2, text: '2 days' },
        { value: 3, text: '3 days' },
        { value: 4, text: '4 days' },
        { value: 5, text: '5 days' },
        { value: 6, text: '6 days' },
        { value: 7, text: 'a week' }
    ];

    var months = [
        { value: 1, text: 'a month' },
        { value: 2, text: '2 months' },
        { value: 3, text: '3 months' },
        { value: 4, text: '4 months' },
        { value: 5, text: '5 months' },
        { value: 6, text: '6 months' },
        { value: 7, text: '7 months' },
        { value: 8, text: '8 months' },
        { value: 9, text: '9 months' },
        { value: 10, text: '10 months' },
        { value: 11, text: '11 months' },
        { value: 12, text: 'a year' }
    ];

    var semesters = [
        { value: 1, text: 'a semester' },
        { value: 2, text: '2 semesters' }
    ];
    /**
     * @name init - initialize Period Directive 
     */
    vm.init = function () {
        vm.cycles = days;
        vm.cycle = days[0];//two days makes more sense
        if (vm.model && vm.model.pricing) {
            if (vm.model.pricing.value === 1) {
                vm.cycles = hours; vm.cycle = hours[0];
            }

            if (vm.model.pricing.value === 24) {
                vm.cycles = days; vm.cycle = days[0];
            }

            if (vm.model.pricing.value === 168) {
                vm.cycles = weeks; vm.cycle = weeks[0];
            }

            if (vm.model.pricing.value === 672) {
                vm.cycles = months; vm.cycle = months[0];
            }

            if (vm.model.pricing.value === 2016) {
                vm.cycles = semesters; vm.cycle = semesters[0];
            }
        }
    };

    /**
     * This function makes [OK] button available.
     * @param  {Object} target 
     * @return {Boolean<false>}        
     */
    vm.activate = function (target) {
        if (typeof target === 'object') {
            _.each(vm.cycles, function (cycle) {
                cycle.active = false;
            });

            target.active = !target.active;
        }

        return false;
    };

    /**
     * This function sets current price.
     * It copies previous price into new price.
     * It communicates the change to the caller, via next({pricing : cycle})
     * @param  {Object<Cycle{starting, ending, value, text}>} cycle 
     * @return {Object}       
     */
    vm.select = function (cycle) {
        cycle.active = !!cycle.active;
        vm.period = vm.cycle = cycle;
        if (typeof vm.next === 'function') {
            //avoid to pass $hashKey 
            vm.next({ period: {value: cycle.value, text: cycle.text}});
        }
    };
    vm.onPeriodChange = function (current, old) {
        if (current !== old) {
            vm.cycle = current;
        }
    };
    
    return vm;
}