'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 *  This directive will be used to search anything.
 * #tag will be used to search for a particular item.
 * @mention will be used to search for people
 *
 * Special tags like #me #order #profile #item #stuff (later #transaction) will be used to make a proper redirection after search.
 * @uses underscore
 * @see _.debounce
 * @link http://underscorejs.org/#debounce
 */
function HoogyOmnibox(SearchService, StateService, ActionCreator) {

    /**
     * Utility function to parse text and find hashtag
     * @param {Object} text 
     */
    function Parser(text) {
        this.text = text || '';
    }

    Parser.prototype.hashtag = function () {
        return this.text.match(/#\w+/g);
    };
    /**
     * Will remove all hashtaggged words, and remain with empty string
     * Or words without a hashtag
     * @return {Object} 
     */
    Parser.prototype.clean = function (str) {
        if (!str || !str.length) {
            return str;
        }

        return (str.split(' ') || [])
            .map(function (s) {
                $log.log('Parse::Clean - map', s);
                if (s.trim().indexOf('#') < 0) {
                    return s.trim();
                }
            })
            .reduce(function (pre, cur) {
                $log.log('Parse::Clean - reduce', pre, cur);
                if (pre) {
                    return [pre, cur].join(' ');
                }

                return cur;
            });
    };


    /**
     * @name link - Fires 2 seconds after user stops typing in search box
     *              - Debounce : fires only after user stops and waits for response
     *              - Throttle : fires frequently after a certain amount of time ( every 2 seconds )
     * @param $scope
     * @param $element
     * @param $attrs
     * @param $ctrl
     */
    function link($scope, $element, $attrs, $ctrl) {
        var awesomplete = null, 
            unsubscribe = null, 
            list = {};

        if(!awesomplete){ 
            //$($element).find('input[type=text]').get(0)
            awesomplete = new Awesomplete(document.querySelector(".omnibox input[type=text]"), {
                minChars: 1,
                data: (item, input) => ({ label: item.title || item.name, value: item.id || item._id }),
                list: list
            });
        }
        
        //adding reference to un-subscribe 
        unsubscribe = (function(){
            return typeof unsubscribe === 'function' ? false : StateService.subscribe(function () {
                if ($scope.screen.sharable) {
                    if ($scope.screen.sharable.item) {
                        list = SearchService.getModel().people;  
                    }
                    if ($scope.screen.sharable.person) {
                        //from Messenger, sharing my own stuff. 
                        list = SearchService.getModel().inventory;
                    }
                }
                if(list && _.size(list) >= 1 && awesomplete){ 
                    awesomplete.list = Object.values(list);
                }
            });
        })();

        //@todo --- remove these re-initializations and throw exceptions instead
        if (!$scope.screen) {
            $scope.screen = {
                search: {
                    text: ''
                }
            };
        }
        //@todo --- remove these re-initialization and throw exceptions instead 
        if ($scope.screen && !$scope.screen.search) {
            $scope.screen.search = {
                text: ''
            };
        }

        $($element).find('input[type=text]').on('keyup', _.debounce(function (event) {
            if ($scope.search && typeof $scope.search === 'function') {
                $scope.screen.search.tags = new Parser($scope.screen.search.text).hashtag() || [];
                $scope.screen.search.clean = new Parser($scope.screen.search.text).clean() || '';
                $scope.search($scope.screen.search);
            }
        }, 1000));

        //attaching handler for selectcomplete event.
        //$($element).find('input[type=text]').on('awesomplete-selectcomplete', _selected);
        window.addEventListener('awesomplete-selectcomplete', _selected, {capture: true, passive: true});

        //Making Current List Awesomplete Ready 
        //@link https://learn.jquery.com/using-jquery-core/faq/how-do-i-pull-a-native-dom-element-from-a-jquery-object/
        //awesomplete = new Awesomplete($($element).find('input[type=text]').get(0), {list: list, data: data});
        //awesomplete.addEventListener('awesomplete-selectcomplete', _selected);
        
        /**
         * @todo Add StateService.dispatch(rentingPerson|rentingItem) 
         * @name _selected - Sets Selected Element. 
         * @param {Object<Item|Event>} item 
         */
        function _selected(event) {
            var unique, item = event.text || event;
            Object.values(list).map(function(i){
                if([i.id || i._id ,''].join('') === [item.value ,''].join('')){
                    unique = i;    
                }
                return i;
            });
            if (typeof $scope.next === 'function') $scope.next(unique);
            if ($ctrl && typeof $ctrl.setSelected === 'function') $ctrl.setSelected(unique);
            if (unique && $scope.screen.sharable.item)StateService.dispatch(ActionCreator.addRentalPerson(unique));
            if (unique && $scope.screen.sharable.person)StateService.dispatch(ActionCreator.addRentalItem(unique));
            $scope.screen.search.text = '';
        };

        /**
         * @name cleanup - try to destroy memory leaky subsceptible objects.
         */
        function cleanup() {
            if (angular.isElement(this)) {
                return $scope.$destroy();
            }
            if (typeof angular.element($element).off === 'function') {
                angular.element($element).off();
            }
            if ($element && typeof $($element).off === 'function') {
                $($element).off();
            }
            if ($element && typeof $element.off === 'function') {
                $element.off();
            }

            if (awesomplete && typeof awesomplete.destroy === 'function') awesomplete.destroy();
            if (unsubscribe && typeof unsubscribe === 'function') unsubscribe();
            unsubscribe = null;
        }
        $scope.$on('$destroy', cleanup);
        $element.on('$destroy', cleanup);
    }

    return {
        link: link,
        replace: true,
        restrict: 'EA',
        scope: {
            screen: '=',
            search: '&',
            next: '&?'
        },
        template:
            '<div class=\'omnibox\'>' +
            '   <i ng-hide=\'!$scope.screen.waiting\' class=\'ion-load-c waiting\'></i>' +
            '   <input type=\'text\' ng-class=\'{waiting: $scope.screen.waiting}\' class=\'search awesomplete\' ng-model=\'screen.search.text\' placeholder=\'Search anything ... \'/>' +
            '</div>'
    };
}
HoogyOmnibox.$inject = ['SearchService','StateService', 'ActionCreator'];
angular.module('hoogy').directive('hoogyOmnibox', HoogyOmnibox);