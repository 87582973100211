'use strict';
/**
 * @name HoogyDroppable - To make it more independent, this directive uses native drag and drop API.
 * useCapture, the third argument of addEventListener, is set to false
 * @todo follow this implementation and change accrodingly:
 * @tutorial http://html5doctor.com/drag-and-drop-to-server/#dnd
 * @link https://developer.mozilla.org/en-US/docs/Web/API/EventTarget.addEventListener
 * @link https://developer.mozilla.org/en-US/docs/DragDrop/Drag_and_Drop
 * @link http://www.html5rocks.com/en/tutorials/dnd/basics/
 * @link http://blog.parkji.co.uk/2013/08/11/native-drag-and-drop-in-angularjs.html
 * @link http://jsfiddle.net/murindwaz/rJZV6/
 * @link https://developers.google.com/web/fundamentals/media/capturing-images/
 * @link http://buildinternet.com/2013/08/drag-and-drop-file-upload-with-angularjs/
 */
HoogyDroppable.$inject = ['$log'];

function HoogyDroppable($log) {
    var types = {
        'image/png': true,
        'image/jpeg': true,
        'image/jpg': true,
        'image/gif': true
    };

    function link($scope, $element, $attrs, $ctrl) {
        if (!$($element).attr('dropzone')) {
            $($element).attr('dropzone', 'copy');
        }
        //HTMLNode addEventListener
        var _elt = $element[0]; //element to attach events to
        function dragover(e) {
            e.preventDefault();
            //there will be a copy of actual file
            e.dataTransfer.dropEffect = 'copy';
            this.classList.add('dropzone');
            return false;
        }

        function dragenter(e) {
            e.preventDefault();
            return false;
        }

        function dragleave(e) {
            e.preventDefault();
            this.classList.remove('dropzone');
            return false;
        }

        function drop(e) {
            e.preventDefault();
            if (e.stopPropagation) {
                e.stopPropagation();
            }
            //FileList Object
            var file = e.dataTransfer.files[0] || null;
            if (file === null || types[file.type] !== true) {
                //set error to the handler
                var error = ['Photo file or fyle type not supported ', file].join('');
                if (typeof $scope.next === 'function') $scope.next(error, null);
                throw error;
            }

            var formData = new FormData();
            formData.append('file', file);
            var reader = new FileReader();
            reader.onload = function (event) {
                //@todo --- use $ctrl.next() instead. 
                //payload = event.target.result
                $scope.content = {
                    payload: event.target.result,
                    name: file.name,
                    size: file.size,
                    type: file.type
                };
                //calling assigned method to handle make it possible for the controller to process this data
                if (typeof $scope.drop === 'function') {
                    $scope.drop({
                        data: $scope.content,
                        payload: $scope.content
                    });
                }
                if (typeof $scope.next === 'function') {
                    $scope.next(null, {
                        data: $scope.content,
                        payload: $scope.content
                    });
                }
                $($element).attr('style', 'background-image: url(' + event.target.result + '); color: transparent ');

            };

            reader.readAsDataURL(file);
            this.classList.remove('dropzone');
            return false;
        }
        //@link Adding Passive Options https://developers.google.com/web/tools/lighthouse/audits/passive-event-listeners
        //attaching functions to $scope - for testing purposes. 
        // adding listeners current element. 
        _elt.addEventListener('dragover', dragover, {capture: true, passive: true});
        _elt.addEventListener('dragenter', dragenter, {capture: true, passive: true});
        _elt.addEventListener('dragleave', dragleave, {capture: true, passive: true});
        _elt.addEventListener('drop', drop, {capture: true, passive: true});
        _elt.addEventListener('paste', drop, {capture: true, passive: true});



        /**
         * @name cleanup - try to destroy memory leak substible 
         */
        function cleanup() {
            if (angular.isElement(this)) {
                return $scope.$destroy();
            }
            if ($element && typeof $element.off === 'function') $element.off();
            if (_elt && typeof _elt.off === 'function') _elt.off();

            if ($($element) && typeof $($element).off === 'function') {
                $($element).off();
            }
        }
        //
        $scope.$on('$destroy', cleanup);
        $element.on('$destroy', cleanup);
        if (_elt && typeof _elt.on === 'function') _elt.on('$destroy', cleanup);
    }

    return {
        restrict: 'EA',
        scope: {
            drop: '&',
            next: '&?',
            content: '='
        },
        link: link
    };
}
/**
 * @todo return on this directive once the problem has been identified
 * Example of fileupload implementation
 * @link http://blog.brunoscopelliti.com/a-directive-to-manage-file-upload-in-an-angularjs-application
 * @link http://blog-it.hypoport.de/2013/11/06/passing-functions-to-angularjs-directives/
 * @todo adapt code source from this link to this context
 * @link http://stackoverflow.com/questions/750032/reading-file-contents-on-the-client-side-in-javascript-in-various-browsers
 * @link http://buildinternet.com/2013/08/drag-and-drop-file-upload-with-angularjs/
 */
FileSelector.$inject = ['$log'];

function FileSelector($log) {


    /**
     * @param $scope
     * @param elt
     * @param attrs
     * @param ctrl
     */
    function link($scope, elt) {
        var inputElement = '.' + $(elt).attr('class').replace(/\s+/, '.') + ' input[type=\'file\']';


        function onChange(event) {

            //@todo if these types are not used, remove them. Or use data types from app.mimes
            var types = {
                'image/png': true,
                'image/jpeg': true,
                'image/jpg': true,
                'image/gif': true
            };
            var file = this.files[0] || (event.originalEvent || event).target.files[0];
            if (file && (file instanceof File)) {
                var reader = new FileReader();
                reader.onload = function (evt) {
                    //file.type is not always correct - choose the one that apply
                    $scope.$apply(function () {
                        $scope.content = {
                            payload: evt.target.result,
                            name: file.name,
                            size: file.size,
                            type: file.type
                        };
                        //@todo check if this line doesn't make the code execute two simulteneous times
                        $scope.selected({
                            data: $scope.content
                        });
                        if ($('.dropzone-wrapper') && $('.dropzone-wrapper').length > 0) {
                            $('.dropzone-wrapper').attr('style', 'background-image: url(' + evt.target.result + '); color: transparent; ');
                        }
                    });
                };

                reader.onerror = function (evt) {
                    //@todo set error message and trigger the popup text
                    //document.getElementById("fileContents").innerHTML = "error reading file";
                };
                reader.readAsDataURL(file);
                //reader.readAsText(file, "UTF-8");
            }
        }



        /**
         * @name Cleanup - try to destroy memory leak substible 
         */
        function cleanup() {
            if (angular.isElement(this)) {
                return $scope.$destroy();
            }
            if (elt && typeof elt.off === 'function') elt.off();
            if ($(inputElement) && typeof $(inputElement).off === 'function') $(inputElement).off();
            if ($('.dropzone-wrapper') && typeof $('.dropzone-wrapper').off === 'function') $('.dropzone-wrapper').off();
            if ($(elt) && typeof $(elt).off === 'function') {
                $(elt).off();
            }
        }
        $scope.$on('$destroy', cleanup);
        elt.on('$destroy', cleanup);
        //handling events 
        $(inputElement).on('$destroy', cleanup);
        $(inputElement).on('change', onChange);
        
    }

    return {
        restrict: 'EA',
        link: link,
        scope: {
            selected: '&',
            content: '=',
            ngModel: '@'
        },
        template: '   <div class="file-selector-input-wrapper">' +
            '       <input  class="file-selector-input" type="file" tab-index="-1" capture="camera" accept="image/*"/>' +
            '       <div class="-label">Drop Photo, Click to Choose from Hard Drive or Snap(Mobile Devices only)</div>' +
            '   </div>'
    };
}

angular
    .module('hoogy')
    .directive('hoogyDroppable', HoogyDroppable)
    .directive('fileSelector', FileSelector)