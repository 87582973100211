'use strict';


function StoreHowitworksController($state, NotificationService, Angularytics, AuthService, UIService, StateService){
   var vm = this; 
    /**
    * @name init - initialization of HowItWorks Component.
    */
    this.init = function(){this.howitworks();};
    this.$onInit = function(){
        this.screen = UIService.getHowItWorksScreen({component: 'store:howitworks'});
        this.unsubscribe = this.subscribe();
        this.init();
    };
    
        /**
         * @name subscribe - Subscribes for Model Updates.
         */
        this.subscribe = function(){
            return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
                vm.model = AuthService.getModel();
                vm.screen = UIService.getHowItWorksScreen(vm.screen);
                vm.model.notifications = NotificationService.getModel();                                     
            });
        };
    /**
     * @name howitworks
     */
    this.howitworks = function () {
        Angularytics.trackEvent('Store How It Works', +1);
    };
    /**
     * @name auth - Triggers authentication alert box. 
     */
    this.auth = function () {
        return NotificationService.unauthorized();
    };

    /**
     * @name isAuthenticated 
     * This function is used to test if user is authenticated or not.
     * It injects this functionality into current scope, so that we can
     * use this test wherever current scope arrives.
     * @returns {*}
     */
    this.isAuthenticated = function () {
        return AuthService.isAuthenticated(true);
    };

     /**
     * @name startSavingEarning - Start saving and earning
     * @return {Boolean} 
     */
    this.startSavingEarning = function () {
        if (this.isAuthenticated()) {
            Angularytics.trackEvent('Start saving and earning', 1);
            $state.go('stuff.add');
            return true;
        }
        if (typeof this.auth === 'function') {
            this.auth();
        }
        Angularytics.trackEvent('Un-Authorized Start Earning and Saving', 1);
        return false;
    };
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };
}

StoreHowitworksController.$inject = ['$state', 'NotificationService','Angularytics', 'AuthService', 'UIService', 'StateService'];
angular.module('hoogy').component('howitworks', {
    controllerAs: 'vm',
    controller: StoreHowitworksController,
    templateUrl: 'js/store/view/how-it-works.html'
});