'use strict';

/** 
 * @name ProfileController -  Public Profile Component Controller
 * @param {Object} state 
 * @param {Object} PeopleService 
 */
function ProfileController($state, HoogyService, DiagnoseService, NotificationService, UIService, StateService, ActionCreator, PeopleService) {

    var vm = this;

    this.$onInit = function () {
        this.screen = UIService.getWorkspaceProfileScreen({
            component: 'workspace:profile'
        });
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        StateService.dispatch(ActionCreator.showHideRootLoading({value: false}));        
        StateService.dispatch(ActionCreator.showHideSidebar({value: false}));  
        this.model = PeopleService.getModel();
        this.unsubscribe = this.subscribe();
        this.init();
    };

    /**
     * @name subscribe - Subscribes for Model Updates.
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false : StateService.subscribe(function () {
            vm.model = PeopleService.getModel();
            vm.user = HoogyService.getUser();
            vm.screen = UIService.getWorkspaceProfileScreen(vm.screen);
            vm.model.notifications = NotificationService.getModel();
            var context = {
                type: DiagnoseService.ADD_ME,
                category: 'suggestion',
                context: DiagnoseService.SETTINGS_CONTEXT
            };
            var notification = NotificationService.getNotificationByContext(context);
            if(!_.isEmpty(notification)) vm.model.notification = notification; 
        });
    };

    /**
     * @name init - initialization function. 
     * @uses model.review
     * @uses model.person
     * @uses model.reviews
     * @uses model.reviewer
     * @uses model.collections 
     * @param {Object<Params>}
     * @name profile - initialization of profile component. 
     * @requires $state.params:id - profile/person ID
     * @uses {Object} Person: Public profile
     */
    this.init = function (params) {
        this.user = HoogyService.getUser();
        var options = params || $state.params;
        if (!options || !options.id) {
            throw 'Profile requires valid profile ID';
        }
        return PeopleService.getProfile(options).then(function (response) {
            vm.model = PeopleService.getModel() || vm.model;
            return response;
        }).catch(this._handleException);
    };

    /**
     * @name _handleException - Exception handler. 
     * @param {Object} error
     */
    this._handleException = function (error) {
        vm.screen.waiting = false;
        var notice = NotificationService.formatWorkspaceExceptionMessage(error);
        NotificationService.report(notice);
        return error;
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };

}
ProfileController.$inject = ['$state', 'HoogyService', 'DiagnoseService', 'NotificationService', 'UIService', 'StateService', 'ActionCreator', 'PeopleService'];
/**
 * @name ProfileComponent - To display public profile.
 */
angular.module('hoogy').component('profile', {
    controllerAs: 'vm',
    templateUrl: 'js/workspace/view/profile.html',
    controller: ProfileController
});