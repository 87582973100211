'use strict';

function StorePrivacyController(AuthService, StateService, UIService, NotificationService, Angularytics){
    var vm = this; 

    /**
     * @name $onInit - Initalization of Privacy Component.
     */
    this.$onInit = function(){
        this.unsubscribe = this.subscribe();
        this.screen = UIService.geStorePrivacyScreen({component:'store:privacy'});
        this.init();
    };
    
        /**
         * @name subscribe - Subscribes for Model Updates.
         */
        this.subscribe = function(){
            return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
                vm.model = AuthService.getModel();
                vm.screen = UIService.getStorePrivacyScreen(vm.screen);
                vm.model.notifications = NotificationService.getModel();                                                 
            });
        };


    this.init = function () {
        Angularytics.trackEvent('Store Privacy', +1);
    };
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };

}

StorePrivacyController.$inject = ['AuthService', 'StateService', 'UIService', 'NotificationService', 'Angularytics'];
angular.module('hoogy').component('privacy', {
    controllerAs: 'vm',
    controller: StorePrivacyController,
    templateUrl: 'js/store/view/privacy.html'
});
