'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * @name Capitalize 
 * This directive will capitalize input value
 * Based on this one liner
 * @link http://stackoverflow.com/a/4879879/132610
 */

function Capitalize() {
    var _capitalizer = function (str) {
        return str.toLowerCase().replace(/(^| )(\w)/g,
            function (x) {
                return x.toUpperCase();
            });
    };

    function link(scope, element, attrs, ctrl) {
        function capitalize(value) {
            if (value === undefined) { value = ''; }

            var capitalized = _capitalizer(value);
            if (capitalized !== value && typeof ctrl.$setViewValue === 'function') {
                ctrl.$setViewValue(capitalized);
                ctrl.$render();
            }

            return capitalized;
        };

        // capitalize initial value
        ctrl.$parsers.push(capitalize);
        capitalize(scope[attrs.ngModel]);

        /**
         * Cleanup - try to destroy memory leak substible 
         */
        function cleanup() {
            if (angular.isElement(this)) { return scope.$destroy(); }
            if (element && typeof element.off === 'function') {
                element.off();
            }
              if($(element) && typeof $(element).off === 'function'){
                $(element).off();
            }
        }
        scope.$on('$destroy', cleanup);
        element.on('$destroy', cleanup);

    }

    return {
        restrict: 'A',
        require: 'ngModel',
        link: link
    };

}
angular.module('hoogy').directive('capitalize', Capitalize);
