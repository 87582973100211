'use strict';

function OrderController($state, HoogyService, DiagnoseService, NotificationService, UIService, StateService, ActionCreator, SearchService) {

    var vm = this; 
    
    /**
     * @name $onInit - Initialization Handler.
     */
    this.$onInit = function(){
        this.model = {}; 
        this.screen = UIService.getWorkspaceOrderScreen({component: 'workspace:order'}); 
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        StateService.dispatch(ActionCreator.showHideRootLoading({value: false}));        
        StateService.dispatch(ActionCreator.showHideSidebar({value: false}));  
        this.unsubscribe = this.subscribe();        
        this.init($state.params);
    };
    
    /**
     * @name subscribe - Subscribes for Model Updates.
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model.order = SearchService.getModel().order;
            vm.model.person = HoogyService.formatUser(vm.model.order.customer);
            vm.screen.isCustomer = vm.model.person._id === vm.user._id;
            vm.screen = UIService.getWorkspaceOrderScreen(vm.screen);
            vm.model.notifications = NotificationService.getModel();    
            var context = {
                type: DiagnoseService.ADD_ORDER,
                category: 'suggestion',
                context: DiagnoseService.SETTINGS_CONTEXT
            };
            var notification = NotificationService.getNotificationByContext(context);
            //@todo add additional notifications. 
            if(!_.isEmpty(notification)) vm.model.notification = notification;                                              
        });
    };

    /**
     * @name init - Initialization of Order 
     * @todo --- displays one single order.
     * @todo --- incomplete(unpaid) order should have pay|cancel(delete) button (if customer connected)
     * @todo --- incomplete(unpaig) order should have cancel(delete) button if(if owner connected)
     * @todo --- should re-use view(order) from listing orders.
     * @todo --- should have profile of non-connected person.
     * @todo --- should allow to update(dates) :::future
     * @todo --- which is there a dedicated service for this?
     * 
     * @param {Object} params - Initialization of objects.
     * @return {Promise} - allows to chain response after querying order. 
     */
    this.init = function (params) {
        this.omnibox = false;
        var options = params || $state.params || {};;
        var message = NotificationService.formatOrderQueryErrorMessage();
        if (!options.id) {
            NotificationService.notify(message);
            throw message.message;
        }
        this.user = HoogyService.getUser();
        //user is the connected person 
        return SearchService.lookupOrder(options).then(function (response) {
            vm.model.order = SearchService.getModel().order;
            vm.model.person = HoogyService.formatUser(vm.model.order.customer);
            vm.screen.isCustomer = vm.model.person._id === vm.user._id;
            vm.screen.waiting = false;
            return response;
        }).catch(this._handleException);
    };


    /**
     * @name _handleException - Exception handler. 
     * @param {Object} response
     */
    this._handleException = function (error) {
        vm.screen.waiting = false;
        var notice = NotificationService.formatWorkspaceExceptionMessage(error);
        NotificationService.report(notice); 
        return error;
    };
    
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };

}
OrderController.$inject = ['$state','HoogyService', 'DiagnoseService','NotificationService', 'UIService', 'StateService', 'ActionCreator', 'SearchService'];
/**
 * Order Component displays order details.
 */
angular.module('hoogy').component('order', {
    controllerAs: 'vm',
    templateUrl: 'js/workspace/view/order.html',
    controller: OrderController
});