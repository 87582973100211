'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
  * This directive
  * @link http://codepen.io/murindwaz/pen/VLagGZ
  * @link http://stackoverflow.com/questions/21478891/twitter-card-share-button
  * Usage <hoogy-social social="facebook|twitter|email|heart|liked"></hoogy-social>
  */
  function HoogySocial($log) {
    
    
        /**
         * Supported social networks/elements are
         *  - facebook
         *  - heart + full heart.
         *  - twitter
         *  - envelope
         * @param $scope
         * @param element
         * @param attrs
         */
        function link(scope, element, attrs) {
            var components = {
                facebook: 'ion-social-facebook',
                twitter: 'ion-social-twitter',
                email: 'ion-ios-email-outline',
                heart: 'ion-ios-heart-outline',
                liked: 'ion-ios-heart'
            };
            angular.element(element).addClass('hoogy-social-element');
            scope.component = components[attrs.social || 'heart'];
            scope.isTwitter = attrs.social === 'twitter';
            element.mouseenter(function () {
                angular.element(element).addClass('hover');
            }).mouseleave(function () {
                angular.element(element).removeClass('hover');
            });
            /**
             * Cleanup - try to destroy memory leak substible 
             */
            function cleanup() {
                if (angular.isElement(this)) { return scope.$destroy(); }
                if (typeof angular.element(element).off === 'function') { angular.element(element).off(); }
                if (element && typeof $(element).off === 'function') {
                    $(element).off();
                }
                if (element && typeof element.off === 'function') { element.off(); }
            }
            scope.$on('$destroy', cleanup);
            element.on('$destroy', cleanup);
        }
    
        return {
            restrict: 'EA',
            replace: true,
            link: link,
            scope: { click: '&', url: '=?', item: '=?' },
            controller: HoogySocialController,
            bindToController: true,
            controllerAs: 'vm',
            template: '<span class="outer-circle ion-ios-circle-outline" ng-click="vm.handleClick()" ng-init="vm.init()">'+
                        '<i ng-if="!isTwitter" class="social-component" ng-class="component"></i>'+
                        '<a ng-if="isTwitter" class="social-component" ng-class="component" href="https://twitter.com/intent/tweet?url={{vm.url}}" data-url="{{vm.url}}"></a>'+
                    '</span>'
        };
    }
    HoogySocial.$inject = ['$log'];
    
    
    function HoogySocialController() {
        var vm = this;
        vm.init = function () {
            if (vm.item) {
                vm.url = [AppConfig.Config.server, '/item/', vm.item._id || vm.item.id].join('');
            }
        };
        /**
         * https://www.facebook.com/dialog/share?app_id=810542255675478&display=popup&e2e=%7B%7D&href=http%3A%2F%2Flocalhost%3A8080%2Fitem%2F5692fae570bcad85064cc4f4&locale=en_US&mobile_iframe=false&next=http%3A%2F%2Fstaticxx.facebook.com%2Fconnect%2Fxd_arbiter%2Fr%2FuN4_cXtJDGb.js%3Fversion%3D42%23cb%3Df28d185b42ed798%26domain%3Dlocalhost%26origin%3Dhttp%253A%252F%252Flocalhost%253A8080%252Ff25bd9ba9531d58%26relation%3Dopener%26frame%3Df1fdc291885e27c%26result%3D%2522xxRESULTTOKENxx%2522&sdk=joey
         * If there is a need to track this click - eg. AnalyticsService.
         */
        vm.handleClick = function () {
            if (typeof vm.click === 'function') {
                vm.click({ item: vm.item });
            }
        };
    }
    
angular.module('hoogy').directive('hoogySocial', HoogySocial);