'use strict';
/**
 * @name NotifyController 
 * @param HoogyService
 * @param Angularytics
 */
function NotifyController($state, HoogyService, PeopleService, NotificationService, UIService, StateService, ActionCreator, Angularytics) {

    var vm = this;

    /**
     * @name $onInit - Initialization of Invitations 
     */
    this.$onInit = function () {
        this.omnibox = false;
        this.screen = UIService.getNotifyScreen({component: 'workspace:notify'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        StateService.dispatch(ActionCreator.showHideRootLoading({value: false}));        
        StateService.dispatch(ActionCreator.showHideSidebar({value: false}));  
        this.unsubscribe = this.subscribe();
        this.model = PeopleService.getModel();
        this.init($state.params);
    };

    /**
     * @name subscribe - Subscribes for Model Updates.
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.screen.waiting = false;
            vm.screen = UIService.getNotifyScreen(vm.screen);
            vm.model = PeopleService.getModel();
            vm.model.notifications = NotificationService.getModel();    
                                                         
        });
    };

    /**
     * @name removeInvitables - filter people we are allowed to invite. 
     * @param {Object} person - person to remove from the listing. 
     * @todo invite friends via a Messenger + Widget to choose from.
     */
    this.removeInvitable = function (person) {
        this.model.invitables = _.without(this.model.invitables, person);
    };

    /**
     * @uses HoogyService::findInvitedFriends
     * @name init - Initialization of Notification Service.
     * @todo make this feature available in a directive from inside item editor.
     * @param {Object} options - initializes notify settings screen.  
     * @name notify - Function that initialize notify view/feature.
     *              - After saving an item, we let a person invite a couple of friends.
     * @return {Promise}
     */
    this.init = function (options) {
        var params = options || $state.params || {};
        this.model.item._id = params.itemid || params.id;
        Angularytics.trackEvent('Workspace Item Friend Notification - Initialized', +1);
        return PeopleService.findInvitedFriends().then(function (response) {
            vm.model = PeopleService.getModel();
            vm.screen.waiting = false;
            var message = (response || {}).message || false;
            var notice = NotificationService.formatNotifySuccessMessage(message);
                                 
            NotificationService.notify(notice);
            return response;
        }).catch(this._handleException);
    };


    /**
     * @todo --- this is the handler after an invite has been sent. 
     * @todo --- invite should be its own Component. 
     * ========== Function imported from WorkspaceController
     * Function associated to /w/invite.
     * It finds invitations I sent out. It also checks who registered.
     * And who is waiting. Sending a reminder????
     * @todo This feature may change in current Contact implementation
     */
    this.invite = function () {
        this.omnibox = false;
        this.screen.active = 'invite';
        this.screen.waiting = true;
        Angularytics.trackEvent('Workspace Invite - Initialized', +1);
        return HoogyService.findInvitedFriends().then(function (response) {
            vm.screen.waiting = false;
            vm.model.invitations = HoogyService.getInvitedFriends();
                             
            Angularytics.trackEvent('Workspace Invite - Returned Friends', +1);
            return response;
        });
    };

    /**
     * @deprecated - invitation/send invite should be in its own Component.
     * Function will be used to send invitation to a couple of individuals.
     * A personal message may be included.
     * @param  {Object} form 
     */
    this.save = function (form) {
        if (!form || !form.$valid || form.$invalid || !form.$name) {
            throw 'Invitation Form should be Valid';
        }

        var emails = _.map(this.model.invitables, function(inv){ return inv.email; });
        var notification = {
            recipients: emails,
            action: form.$name,
            itemid: this.model.item._id,
            message: this.model.message
        };
        Angularytics.trackEvent('Workspace Send Notification - Initialized', +1);
        return HoogyService
            .sendNotification(notification)
            .then(this._notifyHandler)
            .catch(this._handleException);
    };

    /**
     * @deprecated - Should be moved down to their respective functions.
     * @todo as let user read the response, and redirect to another section
     */
    this._notifyHandler = function(response) {
        var data = {
            title: 'Invite friends'
        };
        data.message = (response || {}).message || 'Message details not Available. But everything worked :-) ';
        NotificationService.notify(NotificationService.formatNotifySuccessMessage((response || {}).message));
        vm.screen.waiting = false;
                         
        return response;
    };

    /**
     * @name _handleException - Exception handler. 
     * @param {Object} response
     * @return {Promise} response
     */
    this._handleException = function (error) {
        vm.screen.waiting = false;
        var notice = NotificationService.formatWorkspaceExceptionMessage(error);
        NotificationService.report(notice);     
        return error;
    };
    
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };

}
NotifyController.$inject = ['$state', 'HoogyService', 'PeopleService', 'NotificationService', 'UIService', 'StateService', 'ActionCreator', 'Angularytics'];
/**
 * Component to show all notificaitons. 
 */
angular.module('hoogy').component('notify', {
    controllerAs: 'vm',
    templateUrl: 'js/workspace/view/notify.html',
    controller: NotifyController
});