'use strict';


/**
 * @link  Accessing Transition Object https://ui-router.github.io/guide/ng1/route-to-component#accessing-transition
 * Used for to ensure authentication
 * @param $filter
 */
function FooterController($filter, $state, AuthService, StateService) {
    var vm = this;
    this.model = {component: ''}; 
    /**
     * @name $onInit - Initialization of FooterComponent
     */
    this.$onInit = function(){
        this.unsubscribe = this.subscribe();
        this.init();
    };


    /**
     * @name init - Re-Initialization 
     */
    this.init = function () {
        this.today = $filter('date')(new Date(), 'yyyy');
    };


    /**
     * @name subscribe - Subscribing to Changes in StateService.
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.model = AuthService.getModel();
            //@link https://ui-router.github.io/ng1/docs/0.3.1/index.html#/api/ui.router.state.$state
            if($state && $state.current && $state.current.component) {
                vm.model.component = $state.current.component.toLowerCase();
            }
        });
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}
FooterController.$inject = ["$filter", "$state", "AuthService", "StateService"];
/**
 * Hoogy Footer Component declaration. 
 */
angular.module('hoogy').component('hoogyFooter', {
    restrict: 'EA',
    controllerAs: 'vm',
    controller: FooterController,
    templateUrl: "js/core/footer.html"
});