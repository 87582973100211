'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/*global Stripe:true*/
/**
* @link https://stripe.com/docs/connect/managed-accounts
* @link http://www.peterbe.com/plog/promises-with-$http
*
* Stripe supports Auth/Capture payments
* @link https://support.stripe.com/questions/does-stripe-support-authorize-and-capture
*/
function StripeService($log, $q, HoogyService, StateService, ActionCreator) {

    /**
    * @todo add Stripe.card.createToken wrapper.
    */
    var model = { cards: [], card:{}, wallet:{} };
    var service = {
        getModel: getModel,
        getWallet: getWallet,
        getBank: getBank,
        createCardToken: createCardToken,
        makePayment: makePayment,
        getCustomer: getCustomer,
        getStripe: getStripe,
        createBankRequest: createBankRequest,
        formatStripe: formatStripe,
        formatCreditCard: formatCreditCard,
        formatBank: formatBank,
        formatCustomer: formatCustomer,
        formatWallet: formatWallet,
        updateStripe: updateStripe,
        updateBank: updateBank,
        updateCustomer: updateCustomer,
        getUserCards: getUserCards,
        editCustomerCard: editCustomerCard,
        verifyIdentity: verifyIdentity,
        bankAccount: bankAccount
    };


    /**
     * @uses StateService.getState
     * @name getModel - Returns Model 
     * @param {String} which 
     */
    function getModel(which) {
        var settings = StateService.getState().me;
        var state = Object.assign({}, model, {cards: settings.cards, card: settings.card, bank: settings.bank, wallet: settings.wallet});
        return state[which] || state;
    }


    /**
    * @todo Format and return HoogyService.userSettings.wallet;
    */
    function getWallet() {
        return HoogyService.userSettings.wallet || { enabled: false };
    }

    function getBank() {
        return formatBank((HoogyService.userSettings || {}).bank || {});
    }

    /**
    * @name
    * @return {Object} 
    */
    function getCustomer() {
        return formatCustomer((HoogyService.getUser() || {}).customer || {});
    }
    /**
    * @name
    * @return {Object} 
    */
    function getStripe() {
        return formatStripe((HoogyService.getUser() || {}).stripe || {});
    }

    /**
    * Utility used to create Stripe Compatible createAccountToken request.
    * @param  {Object} bank 
    * @return {Object}      
    */
    function createBankRequest(bank) {
        return {
            country: 'CA',
            currency: 'CAD',
            routing_number: bank.routing,
            account_number: bank.last
        };

    }

    function formatStripe(data) {

        if (_.isEmpty(data)) { return {}; }

        var _stripe = {};
        if (data.keys && data.keys.secret) {
            _stripe.secretKey = data.keys.secret;
        }

        if (data.keys && data.keys.publishable) {
            _stripe.publicKey = data.keys.publishable;
        }

        if (!_.isEmpty(data.bank) && !_.isString(data.bank)) {
            _stripe.bank = (formatBank(data.bank) || {}).id;
        }

        if (!_.isEmpty(data.customer) && !_.isString(data.customer)) {
            _stripe.customer = (formatCustomer(data.customer) || {}).id;
        }

        return _.isEmpty(_stripe) ? data : _stripe;
    }

    /**
    * @param {Object} card
    * @param {String} card.expires - Expiration date
    * @param {String} card.ccn - Credit Card Number
    * @param {String} card.cvc - CVC Number
    * @return {Object} Card{ number, cvc, exp_month, exp_year }
    */
    function formatCreditCard(card) {
        /**
        * cc.number = card.ccn;
        * cc.cvc =  card.cvc;
        * cc.exp_month = card.month;
        * cc.exp_year = card.year;
        */
        var _expires = [];
        if (card.expires && card.expires.indexOf('/') > -1) {
            _expires = card.expires.split('/');
            card.year = ['20', (_expires[1] || '00')].join('');
            card.month = _expires[0];
        }

        return {
            number: card.ccn,
            cvc: card.cvc,
            exp_month: card.month,
            exp_year: card.year
        };
    }

    /**
     * @private
     * @return {ArrayList}
     * @name _formatViewable - Function used to format Credit Card for public use.
     * @param  {Object<Collection<Card>>} card 
     * @return {Object<Card>}      
     */
    function _formatViewable(cards) {
        var _card, _expires;
        return _.map(_.isArray(cards) ? cards : [cards], function (card) {
            return Object.assign({},{
                brand: card.brand,
                last: card.last || card.last4,
                expires: card.expires || [card.month, card.year].join('/')
            });
        });
    }

    /**
     * @name formatBank 
     * @param {Object} data 
     */
    function formatBank(data) {
        var _bank = {};

        var _data = data.bank || data;
        if (_data.id) { _bank.id = _data.id; }

        if (_data.bank_name) { _bank.name = _data.bank_name; }
        else { _bank.name = 'Name Not Specified'; }

        if (_data.routing_number || _data.routing) {
            _bank.routing = _data.routing_number || _data.routing;
        }

        if (_data.fingerprint) { _bank.fingerprint = _data.fingerprint; }

        if (_data.account) { _bank.account = _data.account; }

        if (_data.currency) { _bank.currency = _data.currency; }

        if (_data.country) { _bank.country = _data.country; }

        if (_data.status) { _bank.status = _data.status; }

        if (_data.last4 || _data.last) { _bank.last = _data.last4 || _data.last; }

        return _.isEmpty(_bank) ? _data : _bank;
    }

    /**
     * @name formatCustomer 
     * @param {Object} data 
     */
    function formatCustomer(data) {
        var _customer = {};
        var _card = {};
        var _data = data.customer || data;
        _customer.delinquent = _data.delinquent || false;
        _customer.livemode = _data.livemode || false;
        if (_data.id) { _customer.id = _data.id; }

        if (_data.created) { _customer.created = _data.created; }

        if (_data.description) { _customer.description = _data.description; }

        if (_data.email) { _customer.email = _data.email; }

        if (_data.sources && _data.sources.data) {
            _card = _.find(_data.sources.data,
                function (card) {
                    return card.id === _data.default_source;
                });

            if (_card && _card.id) {
                _customer.card = _card.id;
                if (_card.last4) { _customer.last = _card.last4; }

                if (_card.brand) { _customer.brand = _card.brand; }

                if (_card.exp_month) { _customer.month = _card.exp_month; }

                if (_card.exp_year) { _customer.year = _card.exp_year; }

                if (_card.fingerprint) {
                    _customer.fingerprint = _card.fingerprint;
                }
            }
        }

        return _customer;
    }

    /**
    * @name formatWallet - @name
    * @param  {Object} data 
    * @return {Object}      
    */
    function formatWallet(data) {
        return {
            enabled: data.enabled || false
        };
    }

    /**
     * @name updateStripe 
     * @param {Object} stripe 
     * @param {Object} data 
     */
    function updateStripe(stripe, data) {
        return Object.assign({},stripe, data);
    }

    /**
     * @name updateBank 
     * @param {Object} bank 
     * @param {Object} data 
     */
    function updateBank(bank, data) {
        return Object.assign({},bank, data);
    }

    /**
     * @name updateCustomer 
     * @param {Object} customer 
     * @param {Object} data 
     */
    function updateCustomer(customer, data) {
        return Object.assign({},customer, data);
    }

    /**
     * @uses Stripe
     * @uses HoogyService:updateSettings
     * @name verifyIdentity - Updated 
     * @link https://support.stripe.com/questions/What-is-the-process-for-verifying-managed
     * @return {Promise<Response>} 
     */
    function verifyIdentity(identity) {

        if (!identity || !identity.dob) {
            throw 'Date of birth missing Exception';
        }
        var _identity = _.clone(identity);
        var date = identity.dob.split('-');

        if (date.length < 3) { throw 'Date format Exception'; }
        _identity.dob = { year: date[2], month: date[1], day: date[0] };

        return HoogyService.updateSettings({ action: 'identity', data: _identity });
    }

    /**
     * @name bankAccount - Updates Bank Account Settings. 
     * @uses Stripe.bankAccount.validateAccountNumber('000123456789', 'US')
     * Creates a Bank Account
     * @todo validate Account Number
     * @link https://stripe.com/docs/stripe.js?#collecting-bank-account-details
     */
    function bankAccount(accountOptions) {
        return _createAccount(accountOptions).then(function (response) {
            var opt = { action: 'bank', data: { stripeToken: response.id } };
            return HoogyService.updateSettings(opt);
        });
    }

    /**
    * @name makePayment - Make Payment function requests credit card token and send payment data to hoo.gy
    * @uses StripeService::createCardToken
    * @uses StripeService::formatCreditCard
    * @param {Object} options.stripeToken - Response from Stripe if credit card is correct.
    * @params {String} options.email - Email used with credit card.
    * @params {String} options.orderId - Order Id being paid for.
    * @depends StripeService::createCardToken - Will be used to get Stripe Token
    */
    function makePayment(options) {
        return createCardToken(formatCreditCard(options)).then(function (response) {
            /**
             * Payment = old payment form.
             * @params {String} payment.stripeToken
             * @params {String} payment.email
             * @params {Boolean|false} payment.pickup
             * @params {Object[Address]} payment.shipping
             * @params {Number|0} payment.delivery
             * @params {Object[Address]} payment.pickup
             * @params {Object[Date]} payment.starting - pickup date
             * @params {Number} payment.cost - Amount paid out.
             * @params {} payment.notice.message - Notice Mesage resulting from Failure of success.
             */
            var data = options.data || {};
            data.stripeToken = response.id;
            var opts = { action: 'payment', data: data };
            return HoogyService.updateCheckout(opts);
        });
    }

    /**
     * @name createCardToken 
     * @todo use checkout to execute payment instead
     * Function to create non-reusable Credit Card token
     * Contact Stripe Server for initial payment, creates a token and initiate Hoogy Side payment.
     * @param  {Options.} options 
     * @return {Primise} - data will be used to initiate server side payment
     */
    function createCardToken(options) {
        var deferred = $q.defer();
        Stripe.card.createToken(options, function (status, response) {
            if (response.error) { deferred.reject(response.error); }
            else { deferred.resolve(response); }
        });
        return deferred.promise;
    }

    /**
     * @name editCustomerCard 
     * @link https://stripe.com/docs/stripe.js#card-createToken
     * @link https://stripe.com/docs/api#card_object
     * Function used to create a card for a customer.
     * It will be used to create/update a credit card.
     * It may also used to remove a card --- remove  =  true
     */
    function editCustomerCard(options) {
        var card = formatCreditCard(options);
        var cards = getModel().cards;
        return createCardToken(card).then(function (response) {
            var d = response.data || response || {};
            var data = { stripeToken: d.id || response.id };
            if (options.remove === true) { data.remove = true; }
            var params = { action: 'card', data: data };
            return HoogyService.updateSettings(params).then(function (response) {
                cards.push(card);
                StateService.dispatch(ActionCreator.addCards(cards));                        
                return response;
            });
        });
    }

    /**
     * @name getUserCards - fetches cards of a person.
     * @usage - retrieve the list of cards a connected user has
     * @return {Promise} List of available cards is set to model.cards variable
     */
    function getUserCards() {
        var deferred = $q.defer();
        HoogyService.getUserCards().then(function (response) {
            var cards = _formatViewable(response.data || response) || [];
            StateService.dispatch(ActionCreator.addCards(cards));                        
            deferred.resolve(response);
        }).catch(function (error) {
            deferred.reject(error);
        });
        return deferred.promise;
    }

    /**
     * @name _createAccount 
     * _createAccount is a utility to create a managed bank account.
     * On success/failure
     * @param  {Object} accountOptions 
     * @return {Promise}
     */
    function _createAccount(options) {
        var deferred = $q.defer();
        Stripe.bankAccount.createToken(options, function (status, response) {
            if (response.error) { deferred.reject(response.error); }
            else { deferred.resolve(response); }
        });

        return deferred.promise;
    }

    return service;
}
StripeService.$inject = ['$log', '$q', 'HoogyService', 'StateService', 'ActionCreator'];
angular.module('hoogy').service('StripeService', StripeService);