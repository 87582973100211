'use strict';

/**
 * @name WalletSettingsController - Settings Wallet Component's Controller.
 * @param {Function} HoogyService 
 * @param {Function} SettingsService 
 * @param {Function} NotificationService 
 * @param {Function} Angularytics 
 * @param {Function} PeopleService 
 * @param {Function} StateService 
 * @param {Function} ActionCreator 
 * @param {Function} UIService 
 */
function WalletSettingsController(HoogyService, SettingsService, StripeService, NotificationService, Angularytics, PeopleService, StateService, ActionCreator, UIService) {

    var vm = this;
    this.settings = {};

    /**
     * @name $onInit Initialization of Wallet Settings Component. 
     */
    this.$onInit = function () {
        this.model = PeopleService.getMe();
        this.screen = UIService.getSettingsScreen({
            component: 'wallet:settings',
            active: 'wallet'
        });
        this.model.bank = this.model.bank || {}; 
        this.model.identity = this.model.identity || {}; 
        this.stripe = SettingsService.getWalletInitStripeModel();
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        Angularytics.trackEvent('Settings Wallet', +1);
    };

    /**
     * @name subscribe - Subscribe to PeopleService
     */
    this.subscribe = function (params) {
        return typeof this.unsubscribe === 'function' ? false : StateService.subscribe(function () {
            vm.model = PeopleService.getMe();
            vm.model.notifications = NotificationService.getModel();
            vm.screen = UIService.getSettingsScreen(vm.screen);

            var settings = SettingsService.getWalletInitModel();
            vm.model = Object.assign({}, vm.model, settings);

            this.stripe = SettingsService.getWalletInitStripeModel();
            vm.model = Object.assign({}, vm.model, this.stripe);
            //@todo ---- update settings.confirmIdentity when Stripe returns an error. 
            vm.settings.confirmIdentity = false; 
        });
    };



    /**
       * @param response 
       * @name _success - Callback on settings update success.
       * @return {Promise}
       */
      this._success = function (response) {
        vm.screen.waiting = false;
        var message = response.data || response || false;
        var notice = NotificationService.formatSettingsSuccessMessage(message.message || message);
        Angularytics.trackEvent(notice.title, +1);
        NotificationService.notify(notice);
        vm.reset();
        return response;
    };

    /**
     * @name _exception - Handling exception around failed request
     */
    this._exception = function (error) {
        vm.screen.waiting = false;
        var notice = NotificationService.formatSettingsFailureMessage(error);
        Angularytics.trackEvent('Settings Exception ', notice.message);
        NotificationService.report(notice);
        return error;
    };


    /**
     * @name saveIdentity - verify identity does identity validation
     * This function will
     * 	- Gather and validate identification data
     * 	- fetch additional information in users data-model
     * 	- Uses Stripe.account.validate
     * 	- Submit validation token to our server.
     * 	- Handle Errors
     * @param  {Object} form - form to remove 
     * @return {Promise}      
     */
    this.saveIdentity = function (form) {
        this.screen.waiting = true;
        Angularytics.trackEvent(['Wallet Request Update Identity Settings - ', form.$name].join(''), +1);
        return StripeService.verifyIdentity(this.model.identity).then(this._success).catch(this._exception);
    };


    /**
     * @param {FormController} form - bank form
     * @name save - saves Bank Account Settings.
     * @return {Promise} 
     */
    this.enableWallet = function (form) {
        this.screen.waiting = true;
        Angularytics.trackEvent(['Wallet Request Update Bank Settings - ', form.$name].join(''), +1);
        var request = StripeService.createBankRequest(this.model.bank);
        return StripeService.bankAccount(request).then(this._success).catch(this._exception);
    };

    /**
     * @todo @name save - Creates an Account if Account doesn't exist for current customer. 
     * @todo @name save - Creates Identity if US citizen is required to receive more than $1000
     * @todo @name save - Updates User Settings if any of above is done 
     * @todo all settings will be sent to server using this function
     * @todo use HoogyService::setSettings([options], done);
     * 
     * @name save - Saves Identity if changed, else Wallet. 
     * @uses saveIdentity - To Add Identity/Update Identity  
     * @uses enableWallet - To Create/Update Banking information 
     * @param {FormController} form 
     */
    this.save = function (form) {
        if (!form || !form.$valid || form.$invalid) {
            throw 'Wallet Settings Form requires Valid Form.';
        }
        var data = Object.assign({}, this.model.bank, this.model.identity);
        var settings = Object.assign({},{
            action: form.$name,
            data: data
        });
        if (!form || !form.$valid) {
            throw 'Wallet Save Requires a Valid Form';
        }
        var isBank =  (form.fullname && form.routing && form.account) && (form.fullname.$dirty || form.routing.$dirty || form.account.$dirty);
        var isIdentity = (form.dob && form.ssn) && (form.dob.$dirty || form.ssn.$dirty);
        if (isBank && !isIdentity) {
            this.enableWallet(form).then(function (response) {
                return HoogyService.updateSettings(settings).then(self._success).catch(self._exception);
            }).catch(this._exception);;
        }
        if (isIdentity && !isBank) {
            this.saveIdentity(form).then(function (response) {
                return HoogyService.updateSettings(settings).then(self._success).catch(self._exception);
            }).catch(this._exception);;
        }
        if (isBank && isIdentity) {
            return this.enableWallet(form).then(function (response) {
                return self.saveIdentity(form).then(function (response) {
                    return HoogyService.updateSettings(settings).then(self._success).catch(self._exception);
                });
            }).catch(this._exception);;
        }

        if(!isBank && !isIdentity) throw 'Bank and Identity Missing Exception';
    };

    /**
     * @name reset - resets a form. 
     * @uses WalletSettingsController::init 
     * @return {void}
     */
    this.reset = function (form) {
        var _form = form || vm.wallet;
        if (!_form || typeof _form.$setPristine !== 'function') {
            throw 'Reset Requires a Form.';
        }
        Angularytics.trackEvent('Settings Reset', +1);
        _form.$setPristine();
    };
    /**
     * @name next - Callback After Save Methods Are Called
     */
    this.next = function (params) {
        this.screen.waiting = false;
        Angularytics.trackEvent('Settings Updated - next', +1);
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}
WalletSettingsController.$inject = ['HoogyService', 'SettingsService', 'StripeService', 'NotificationService', 'Angularytics', 'PeopleService', 'StateService', 'ActionCreator', 'UIService'];
angular.module('hoogy').component('walletSettings', {
    controllerAs: 'vm',
    templateUrl: 'js/settings/view/wallet.html',
    controller: WalletSettingsController
});