'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * @name DropdownProfile - Directive that forces dropdown to run. 
 */
function DropdownProfile(){
    function Link($scope, $element, $attrs, $ctrl){
        $('.dropdown-toggle.-profile').dropdown('toggle');
    }
    
    return {
        restrict: 'A', 
        link: Link
    };
}
angular.module('hoogy').directive('dropdownProfile', DropdownProfile);
