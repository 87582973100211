'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * @name ItemsFilterDropdown - Directive that forces dropdown to run. 
 */
function ItemsFilterDropdown(){
    function Link($scope, $element, $attrs, $ctrl){
        $('.dropdown-toggle.-items-widget').dropdown('toggle');
    }
    return {
        restrict: 'A', 
        link: Link
    };
}
angular.module('hoogy').directive('itemsFilterDropdown', ItemsFilterDropdown);
