'use strict';

/**
 * @name ContractSettingsController - Editing Contract Settings 
 */
function ContractSettingsController(HoogyService, PeopleService, UIService, NotificationService, StateService, ActionCreator, Angularytics) {
    var vm = this;
    
    /**
     * @name $onInit 
     */
    this.$onInit = function () {
        this.omnibox = false; //hides the omnibox
        this.model = PeopleService.getMe();
        this.screen = UIService.getSettingsScreen({component: 'contract:settings', active: 'contract'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        this.init();
    };

    /**
     * @name subscribe - Subscribe to PeopleService
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.model = PeopleService.getMe();
            vm.model.notifications = NotificationService.getModel();                
            vm.screen = UIService.getSettingsScreen(vm.screen);
        });
    };
    
    /**
     * @name save - Validation and saving the form. 
     * @todo all settings will be sent to server using this function
     * @todo use HoogyService::setSettings([options], next);
     */
    this.save = function (form) {
        var settings = {
            action: form.$name,
            data: this.model.contract
        };
        if (form && form.$valid) {
            HoogyService
                .updateSettings(settings)
                .then(this._settingsRequestCallback)
                .catch(this._handleException);
        }
    };

    /**
     * @name reset - resets a form. 
     * @uses WalletSettingsController::init 
     * @return {void}
     */
    this.reset = function (form) {
        if (!form || typeof form.$setPristine !== 'function') {
            throw 'Reset Requires a Form.';
        }
        Angularytics.trackEvent('Settings Reset', +1);
        form.$setPristine();
    };

    /**
     * @param response 
     * @name _settingsRequestCallback - Callback on settings update success.
     * @return {Promise}
     */
    this._settingsRequestCallback = function (response) {
        vm.enableSave = false;
        var message = response.data || response || false;
        var notice = NotificationService.formatSettingsSuccessMessage(message.message || message);
        Angularytics.trackEvent(notice.title, +1);
        NotificationService.notify(notice);
    };



    /**
     * @name initContract - Has to show template to show to customers prior to renting items
     */
    this.init = function () {

        this.screen.waiting = true;
        Angularytics.trackEvent('Settings Contact', +1);

        /**
         * Initiazation of the Contract. 
         */
        HoogyService.getContract().then(function (response) {
                vm.screen.waiting = false;
                vm.model.contract = {};
                /**@link http://stackoverflow.com/questions/19156148/i-want-to-remove-double-quotes-from-a-string */
                if ((typeof response.data === 'string' && _.size(response.data) > 0)) {
                    var _data = response.data.replace(/^"(.+(?="$))"$/, '$1').replace(/\\n|\\r\\n|\\r/g, '<br/>');
                    vm.model.contract = Object.assign({},vm.model.contract, {
                        text: _data
                    });
                } else if (response.data.contract) {
                    var _d = response.data.contract || response.data;
                    vm.model.contract = Object.assign({},vm.model.contract, {
                        text: _d
                    });
                }
                                 
            })
            .catch(this._handleException);
        Angularytics.trackEvent('Workspace Contract - Initialized', +1);
    };
    
    /**
     * @param {Object<Error>} error 
     * @name _handleException - Handling exception around failed request
     * @return {Promise<Error>}
     */
    this._handleException = function (error) {
        vm.enableSave = false;
        vm.screen.waiting = false;
        var notice = NotificationService.formatSettingsFailureMessage(error);
        NotificationService.report(notice);
        Angularytics.trackEvent('Settings Exception ', notice.message);
        return error;
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}
ContractSettingsController.$inject = ['HoogyService', 'PeopleService', 'UIService', 'NotificationService', 'StateService', 'ActionCreator', 'Angularytics'];
/**
 * Contract Settings. 
 */
angular.module('hoogy').component('contractSettings', {
    controllerAs: 'vm',
    templateUrl: 'js/settings/view/contract.html',
    controller: ContractSettingsController
});