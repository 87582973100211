function AuthService($log, $q, $auth, HoogyService, NotificationService, Angularytics, StateService, ActionCreator) {

    var model = {
        signin: {},
        signup: {},
        recover: {}
    };
    
    /**
     * @todo remove model 
     */
    var service = {
        logout: logout,
        recover: recover,
        signin: signin,
        signup: signup,
        getModel: getModel,
        authenticate: authenticate,
        isAuthenticated: isAuthenticated,

        link: link,
        unlink: unlink, 

        unauthorized: unauthorized
    };



    return service;

    /**
     * @name link - linking provider to a 
     * @param {String} provider 
     */
    function link(provider) {
        var deferred = $q.defer();
        $auth.link(provider).then(function (response) {
            StateService.dispatch(ActionCreator.linkAccount({value: response.data || response}));
            return deferred.resolve(response);
        }).catch(function (error) {
            NotificationService.report(error);
            return deferred.reject(error);
        });
        return deferred.promise;
    }

    /**
     * @name unlink - unlinking google/facebook
     * @param {String} provider 
     */
    function unlink(provider) {
        var deferred = $q.defer();
        $auth.unlink(provider).then(function (response) {
            StateService.dispatch(ActionCreator.unlinkAccount({value: response.data || response}));
            return deferred.resolve(response);
        }).catch(function (error) {
            NotificationService.report(error);
            return deferred.reject(error);
        });
        return deferred.promise;
    }


    /**
     * function to initialize auth directive models.
     * @name getModel - initialization of models.
     * @return {Object<Auth>} {signin, signup, recover}
     */
    function getModel(which) {
        var auth = StateService.getState().auth;
        var state = Object.assign({}, model, { 
            signin: auth.signin || model.signin,
            signup: auth.signup || model.signup,
            recover: auth.recover || model.recover,
            user: HoogyService.getUser()
        });
        return state[which] || state; 
    }
    
    /**
     * @name recover - Function to recover Lost password
     * @todo finalize implementation. 
     */
    function recover(options) {
        if (!options) throw 'Sign In parameters are always required';
        if (!options || !options.email) throw 'Recover model is missing';
        return HoogyService.recover(options);
    }

    /**
     * Function used to signin using username+email 
     * @param {String} options.email 
     * @param {String} options.password
     */
    function signin(options) {
        if (!options) throw 'Sign In parameters are always required';
        if (!options.email || !options.password) throw 'Email or Password not found';
        var deferred = $q.defer();
        $auth.login(options).then(function (response) {
            StateService.dispatch(ActionCreator.signin({value: response.data || response}));
            return deferred.resolve(response);
        }).catch(function (error) {
            NotificationService.report(error);
            return deferred.reject(error);
        });
        return deferred.promise;
    }

    /**
     * Function used to signup using username + email. 
     * @param {String} options.name
     * @param {String} options.email
     * @param {String} options.password
     */
    function signup(options) {
        if (!options) throw 'Sign In parameters are always required';
        if (!options.name && options.email) options.name = options.email;
        if (!options.name || !options.email || !options.password) {
            throw 'Name, Email or Password not found';
        }
        var deferred = $q.defer();
        $auth.signup(options).then(function (response) {
            StateService.dispatch(ActionCreator.signup({value: response.data || response}));
            return deferred.resolve(response);
        }).catch(function (error) {
            NotificationService.report(error);
            return deferred.reject(error);
        });
        return deferred.promise;
    }

    /**
     * Function to Authenticate using either Facebook or Google+ 
     */
    function authenticate(provider) {
        if (!provider) throw 'Third party requires provider';
        return $auth.authenticate(provider);
    }

    /**
     * @name isAuthenticated a wrapper around $auth.isAuthenticated
     * @param {Boolean:optional} prompt - default:false, the prompt should alert. 
     */
    function isAuthenticated(prompts) {
        if (!$auth.isAuthenticated()) {
            if (prompts === true) {
                NotificationService.unauthorized();
            }
            return false;
        }
        return true;
    }


    /**
     * @name unauthorized -  This function will be used to prompt a user to login
     * @param scope
     */
    function unauthorized() {
        StateService.dispatch(ActionCreator.removeAuth());
    }

    /**
     * This function takes care of
     * 	- logging out
     * 	- data cleaning,
     * 	- and setting notification message
     * @uses $auth
     * @uses HoogyService
     * @uses NotificationService
     * @name
     * @return {Object} 
     */
    function logout() {
        var deferred = $q.defer();
        $auth.logout().then(function (response) {
            if (($auth.getToken() || '').length) {
                $auth.removeToken();
            }

            HoogyService.reset();
            NotificationService.notify(response.data);
            Angularytics.trackEvent('Logout', 'Success');
            return deferred.resolve(response);
        }).catch(function (error) {
            if (($auth.getToken() || '').length) {
                $auth.removeToken();
            }
            HoogyService.reset();
            NotificationService.report(error);
            Angularytics.trackEvent('Logout', 'Success');
            return deferred.reject(error);
        });

        return deferred.promise;
    }


}
AuthService.$inject = ['$log', '$q', '$auth', 'HoogyService', 'NotificationService', 'Angularytics', 'StateService', 'ActionCreator'];
angular.module('hoogy').service('AuthService', AuthService);