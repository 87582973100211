'use strict';

/**
 * @name LocalizationSettingsController
 * @param {*} HoogyService 
 * @param {*} PeopleService 
 * @param {*} UIService 
 * @param {*} Angularytics 
 */
function LocalizationSettingsController(HoogyService, PeopleService, UIService, StateService, ActionCreator, NotificationService, Angularytics) {
    var vm = this;
    
    /**
     * @name $onInit - Initialization of Localization Settings Screen.
     */
    this.$onInit = function(){
        
        this.model = PeopleService.getMe();
        this.screen = UIService.getSettingsScreen({component: 'localization:settings', active: 'localization'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        this.init(); 
    };

    /**
     * @name subscribe - Subscribe to PeopleService
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = PeopleService.getMe();
            vm.model.notifications = NotificationService.getModel();                
            vm.screen = UIService.getSettingsScreen(vm.screen);
        });
    };
    
    this.init = function () {
        Angularytics.trackEvent('Settings Localization', +1);
    };

    /**
     * @name reset - resets a form. 
     * @uses WalletSettingsController::init 
     * @return {void}
     */
    this.reset = function(form) {
        if (!form || typeof form.$setPristine !== 'function') {
            throw 'Reset Requires a Form.';
        }
        Angularytics.trackEvent('Settings Reset', +1);
        form.$setPristine();
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };

};
LocalizationSettingsController.$inject = ['HoogyService', 'PeopleService', 'UIService', 'StateService', 'ActionCreator', 'NotificationService', 'Angularytics'];
angular.module('hoogy').component('localizationSettings', {
    controllerAs: 'vm',
    templateUrl: 'js/settings/view/localization.html',
    controller: LocalizationSettingsController
});