'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/

/**
 * @param defaultState - Designed to initiallize state that have entities on them. 
 */
var defaultState = {
  entities: {}
};

/**
 * @link http://stackoverflow.com/a/7616484/132610
 * @param {Object} state
 * @param {Object} action 
 * @name NotificationReducer - List of actions allowed to perform on data store.
 */
function NotificationReducer(state = defaultState, action) {
  switch (action.type) {
    case 'invite:friends:notification':
    case 'invite:more:friends:notification':
    case 'add:notification':
    case 'update:notification':
    case 'add:inventory:notification':
    case 'add:contract:notification':
    case 'add:payment:notification':
    case 'add:address:notification':
    case 'add:contacts:notification':
    case 'add:order:notification':
    case 'add:contact:notification':
    case 'add:favorite:notification':
    case 'add:followers:notification':
    case 'add:messages:notification':
    case 'update:inventory:notification':
    case 'update:contract:notification':
    case 'update:payment:notification':
    case 'update:address:notification':
    case 'update:settings:notification':
    case 'update:personal:notification':
      //new elements 
    case 'add:wishilist:notification':
    case 'add:review:notification':
    case 'add:orders:notification':
      return addNotification(state, action);
    case 'remove:notification':
      return removeNotification(state, action);
    case 'remove:all:notifications':
      return removeAllNotifications(state, action);

    case 'search:notification':
    case 'find:notification':
      return findNotification(state, action);
  }

  return Object.assign({}, state, {});



  /**
   * @name addNotification - Adds message in notifications, or increase counter.
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addNotification(state, action) {
    //non existent notification returns initialization
    if (!state && !action.value) {
      return Object.assign({}, {}, {
        entities: {}
      });
    }

    var notice = Object.assign({}, action.value, {
      counter: 0
    });

    var index = notice.id || [notice.type, notice.category, notice.context].join(':').toLowerCase();
    var entities = Object.assign({}, state.entities);
    entities[index] = Object.assign({}, action.value, {
      counter: entities[index] ? entities[index].counter + 1 : 1
    });
    return Object.assign({}, state, {
      entities: entities
    });
  }

  /**
   * @name removeNotification - removes a particular message. 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function removeNotification(state, action) {
    if (!state && !action.value) {
      return Object.assign({}, {}, {
        entities: {}
      });
    }
    var entities = {};
    //removal candidate
    var index = [action.value.id || action.value.index || action.value._id || action.value, ''].join('');
    if (!index) throw '@removeNotification reducer requires Index to be defined';
    var keys = Object.keys(state.entities || {}).filter(function (key) {
      if (key !== index) {
        //copy keys other than index into HashMap
        entities[key] = Object.assign({}, state.entities[key]);
      }
      return key !== index;
    });
    return Object.assign({}, state, {
      entities: entities
    });
  }

  /**
   * @name removeAllNotifications - removes all notifications.
   * @param {Object} state 
   * @param {String} action 
   */
  function removeAllNotifications(state, action) {
    return Object.assign({}, state, {
      entities: {}
    });
  }

  /**
   * @name findNotification
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function findNotification(state, action) {
    if (!state && !action.value) {
      return Object.assign({}, {}, {
        entities: {}
      });
    }
    var index = [action.value.id || action.value.index || action.value._id, ''].join('') || action.value.index || action.value._id;
    return Object.assign({}, state, {
      top: Object.assign({}, state.entities[index])
    });
  }


}



/**
 * @name MessengerReducer - Handles Chat messages. 
 * @param {Object} state 
 * @param {Object} action 
 */
function MessengerReducer(state = defaultState, action) {
  var whitelist = [
    'add:inventory:message',
    'update:inventory:message',
    'invite:friends:message',
    'invite:more:friends:message',
    'add:contract:message',
    'update:contract:message',
    'add:payment:message',
    'update:payment:message',
    'add:address:message',
    'update:address:message'
  ];

  switch (action.type) {
    case 'add:messenger':
      return addMessenger(state, action);
    case 'add:messenger:intents':
      return addIntents(state, action);

    case 'add:messenger:unread:messages':
      return addUnreadMessage(state, action);

    case 'add:messenger:message:recipient':
      return addMessageRecipient(state, action);

    case 'add:messenger:conversations':
      return addConversations(state, action);

    case 'add:message':
      return addMessage(state, action);

    case 'remove:all:messages':
      return removeAllMessages(state, action);

    case 'remove:message':
      return removeMessage(state, action);

    case 'update:message':
      return updateMessage(state, action);

    case 'search:message':
      return findMessage(state, action);

    case 'TransportError':
      return addTransportError(state, action);

  }
  return Object.assign({}, state, {});


  /**
   * @name addMessenger - Adding Messenger
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addMessenger(state, action) {
    return Object.assign({}, state, action.value);
  }

  /**
   * @name addMessage - Adding Message To Global State.
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addMessage(state, action) {
    //non existent notification returns initialization
    if (!state && !action.value) {
      return Object.assign({}, {}, {
        entities: {}
      });
    }

    //message
    var message = Object.assign({}, action.value, {
      counter: 0
    });
    if (typeof message === 'object' && !message.id) {
      //hyper performant when multiple data are being sent to this adder
      message.id = [action.type.toLowerCase(), (window.performance || {}).now() || new Date().getTime()].join('-');
      if (whitelist.indexOf(action.type.toLowerCase()) > -1) {
        message.id = action.type.toLowerCase();
      }
    }

    //update entities
    var index = message.id || [message.type, message.category, message.context].join(':').toLowerCase();
    var entities = Object.assign({}, state.entities);
    entities[index] = Object.assign({}, action.value, {
      counter: entities[index] ? entities[index].counter + 1 : 1
    });

    //return new state
    return Object.assign({}, state, {
      entities: entities
    });
  }

  /**
   * @name removeMessage - Removing Message from Global State. 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function removeMessage(state, action) {
    if (!state && !action.value) {
      return Object.assign({}, {}, {
        entities: {}
      });
    }
    var entities = {};
    var rm = [action.value.id || action.value.index || action.value._id, ''].join('');
    var keys = Object.keys(state.entities || []).filter(function (key) {
      if (key !== rm) {
        entities[key] = Object.assign({}, state.entities[key]);
      }
      return key !== rm;
    });

    return Object.assign({}, state, {
      entities: entities,
      keys: keys
    });
  }

  /**
   * @name removeAllMessages 
   * @param {Object} state 
   * @param {String} action 
   */
  function removeAllMessages(state, action) {
    return Object.assign({}, {}, defaultState);
  }

  /**
   * @name findMessage 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function findMessage(state, action) {
    if (!state && !action.value) {
      return Object.assign({}, {}, {
        entities: {}
      });
    }
    var index = action.value.id || action.value.index || action.value._id;
    var entities = state.entities || {};
    return Object.assign({}, entities[index] || {});
  }

  /**
   * @name updateMessage 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function updateMessage(state, action) {
    return addMessage(state, action);
  }

  /**
   * @name addIntents - Adding Intents to Current Messages Messages. Appending Intents Messages. 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addIntents(state, action) {
    return addMessage(state, action);
  }

  /**
   * @todo To be Implemented. Used with MessengerService::
   * @name addUnreadMessage - Adding Unread Messages 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addUnreadMessage(state, action) {
    return addMessage(state, action);
  }

  /**
   * @todo To be Implemented. Used with MessengerService::
   * Function used to add Conversations + 
   * @name addConversations - Adding Conversation Messages 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addConversations(state, action) {
    return addMessage(state, action);
  }

  /**
   * @name addTransportError 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addTransportError(state, action) {
    return addMessage(state, action);
  }

  /**
   * @name addMessageRecipient - Will be used to change Recipient to filter from. 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addMessageRecipient(state, action) {
    return Object.assign({}, state, {
      recipient: action.value
    });
  }

}



/**
 * @name ItemsReducer - Handles All Item Editing Needs. 
 * @param {Object<State>}
 * @param {Object<Action>}
 */
function ItemsReducer(state = defaultState, action) {
  switch (action.type) {
    case 'add:item':
    case 'update:item':
      return addItem(state, action);

    case 'remove:item':
      return removeItem(state, action);

    case 'search:item':
      return findItems(state, action);

    case 'remove:all:items':
      return removeAllItems(state, action);

  }

  return Object.assign({}, state, {});

  /**
   * @name addItem - Adding Item on Global Object 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addItem(state, action) {
    var entities = Object.assign({}, state.entities || {}, {});
    entities[action.value._id || action.value.id] = action.value;
    return Object.assign({}, state, {
      entities: entities
    });
  }

  /**
   * @link https://stackoverflow.com/a/34429897/132610
   * @name removeItem 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function removeItem(state, action) {
    var entities = {};
    var rm = [action.value.id || action.value.index || action.value._id, ''].join('');
    var keys = Object.keys(state.entities).filter(function (key) {
      if (key !== rm) {
        entities[key] = Object.assign({}, state.entities[key]);
      }
      return key !== rm;
    });
    return Object.assign({}, state, {
      entities: entities
    });
  }

  /**
   * @name removeAllItems
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function removeAllItems(state, action) {
    return Object.assign({}, {}, defaultState);
  }

  /**
   * @name findItems
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function findItems(state, action) {
    if (!state && !action.value) {
      return Object.assign({}, {}, {
        entities: {}
      });
    }
    var index = action.value.id || action.value.index || action.value._id;
    var entities = state.entities || {};
    return Object.assign({}, entities[index] || {});
  }

}


//screen settings - such as events + etc
/**
 * @name UIReducer - Manages State for Screen Objects 
 * @param {Object} state 
 * @param {Object} action 
 */
function UIReducer(state = defaultState, action) {
  switch (action.type) {
    case 'add:screen':
      return addScreen(state, action);

    case 'update:screen':
      return updateScreen(state, action);

    case 'remove:screen':
      return removeScreen(state, action);

      case 'show:hide:usermenu':
      case 'screen:show:hide:usermenu':
      return showHideMenu(state, action);

      case 'show:hide:sidebar':
      case 'screen:show:hide:sidebar':
      return showHideSidebar(state, action);

    case 'screen:root:homepage':
    case 'screen:show:hide:root:loading':
      return showHideRootLoading(state, action);

    case 'show:sharer:screen':
      return startSharerScreen(state, action);
    case 'hide:sharer:screen':
      return stopSharerScreen(state, action);

  }
  //copying the content of state object. 
  return Object.assign({}, state, {});

  /**
   * @name startSharerScreen - All Screen States have to have Sharable + shareup instance
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function startSharerScreen(state, action) {
    var ext = Object.assign({}, {
      sharable: action.value,
      sharerup: true
    });
    var entities = Object.assign({}, state.entities, {});
    //Change Collection while preserving the keys
    Object.keys(entities).map(function(index){ entities[index] = Object.assign({}, entities[index], ext); });
    return Object.assign({}, state, {
      entities: entities
    });
  }
  /**
   * @name stopSharerScreen - Removes Screen State from All Screens.
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function stopSharerScreen(state, action) {
    var entities = Object.assign({}, state.entities, {}); 
    Object.keys(entities).map(function(index){ entities[index] = Object.assign({}, entities[index], {sharerup: false}); });
    return Object.assign({}, state, {
      entities: entities
    });  
  }

  /**
   * @name addScreen - Adds Screen to State
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addScreen(state, action) {
    //non existent notification returns initialization
    if (!state && !action.value) {
      return Object.assign({}, {}, {
        entities: {}
      });
    }
    var index = [action.value.component, action.value.action || action.value.active].join(':');
    var rxidx = index.toLowerCase();
    var entities = Object.assign({}, state.entities, {});
    entities[rxidx] = Object.assign({}, action.value);
    return Object.assign({}, state, {
      entities: entities
    });
  }
  /**
   * @name updateScreen - Updates Screen State
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function updateScreen(state, action) {
    return addScreen(state, action);
  }

  /**
   * @name removeScreen - Remove Screen from State
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function removeScreen(state, action) {
    //non existent notification returns initialization
    if (!state && !action.value) {
      return Object.assign({}, {}, {
        entities: {}
      });
    }
    var entities = {};
    var rm = [action.value.idx || action.value.id || action.value._id, ''].join('');
    var keys = Object.keys(state.entities || {}).filter(function (key) {
      if (key !== rm) {
        entities[key] = Object.assign({}, state.entities[key]);
      }
      return key !== rm;
    });
    return Object.assign({}, state, {
      entities: entities
    });
  }

  /**
   * @name showHideSidebar - Attaches to the Screen.Up 
   * @param {Object<State>}
   * @param {Object<Action>}
   */
  function showHideSidebar(state, action) {
    //@todo check if received is a boolean and add .up instead
    //@todo scan all screen objects + add the up boolean
    var ext = {};
    if (typeof action.value === 'boolean') {
      ext.up = action.value;
    }
    var entities = Object.assign({}, state.entities, {});
    //Change Collection while preserving the keys
    Object.keys(entities).map(function(index){ entities[index] = Object.assign({}, entities[index], ext); });
    return Object.assign({}, state, {
      entities: entities
    });

  }
  /**
   * @name showHideMenu - Attaches Screen.menup 
   * @param {Object} state 
   * @param {Object} action 
   */
  function showHideMenu(state, action) {
    var entities = Object.assign({}, state.entities, {});
    var ext = { menup : typeof action.value === 'boolean' ? action.value : false };
    //Change Collection while preserving the keys
    Object.keys(entities).map(function(index){ entities[index] = Object.assign({}, entities[index], ext); });
    return Object.assign({}, state, {
      entities: entities
    });
  }

  /**
   * @name showHideRootLoading - Displays Top Level Activity Loader
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function showHideRootLoading(state, action) {
    //@todo check if received is a boolean and add .
    return Object.assign({}, state, {waiting: typeof action.value === 'boolean'? action.value: false });
  }

  /**
   * 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function showTopScreen(state, action) {
    //intro: intro, containerClass: containerClass
    return Object.assign({}, state, {});
  }
}
//tracks flows(steps) user are following: analytics purposes. 
function FlowReducer(state = defaultState, action) {
  return Object.assign({}, state, {});
}

/**
 * @name ActivitiyReducer - Activities Reducers  - Should show user things s/he did on site recently.
 * @param {*} state 
 * @param {*} action 
 */
function ActivityReducer(state = defaultState, action) {
  switch (action.type) {
    case 'add:activity':
      return addActivity(state, action);
    case 'update:activity':
      return updateActivity(state, action);
    case 'remove:activity':
      return removeActivity(state, action);
    case 'search:activity':
      return findActivity(state, action);
    case 'remove:all:activities':
      return removeAllActivities(state, action);
  }

  return Object.assign({}, state, {});

  /**
   * @name updateActivity - Updating Activity. 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function updateActivity(state, action) {
    return addActivity(state, action);
  }
  /**
   * @name addActivity 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addActivity(state, action) {
    //non existent notification returns initialization
    if (!state && !action.value) {
      return Object.assign({}, {}, {
        entities: {}
      });
    }
    var index = action.value.index || action.value._id || action.value.id;
    var entities = Object.assign({}, state.entities || {});
    entities[index] = action.value;
    return Object.assign({}, state, {
      entities: entities
    });

  }

  /**
   * @name removeActivity 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function removeActivity(state, action) {
    //non existent notification returns initialization
    if (!state && !action.value) {
      return Object.assign({}, {}, {
        entities: {}
      });
    }

    var entities = {};
    var rm = [action.value.id || action.value.index || action.value._id, ''].join('');
    var keys = Object.keys(state.entities).filter(function (key) {
      if (key !== rm) {
        entities[key] = Object.assign({}, state.entities[key]);
      }
      return key !== rm;
    });
    return Object.assign({}, state, {
      entities: entities
    });
  }

  /**
   * @name findActivity - Searching for an Activity
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function findActivity(state, action) {
    if (!state && !action.value) {
      return Object.assign({}, {}, {
        entities: {}
      });
    }
    var index = action.value.id || action.value.index || action.value._id;
    return Object.assign({}, state, {
      top: Object.assign({}, state.entities[index])
    });
  }

  /**
   * @name removeAllActivities
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function removeAllActivities(state, action) {
    return Object.assign({}, {
      entities: {}
    }, {});
  }

}


function AuthReducer(state, action) {
  switch (action.type) {
    case 'add:auth:token':
      return addAuthToken(action.value);

    case 'remove:auth:token':
      return removeAuthToken(action.value);

  }

  return Object.assign({}, state, {});

  /**
   * @name addAuthToken - adds token resets prompt auth flag
   * @param {Object} value
   */
  function addAuthToken(value) {
    return Object.assign({}, state, {});
  }

  /**
   * @name removeAuthToken - resets promopt auth flag
   * @param {Object} state 
   */
  function removeAuthToken(value) {
    return Object.assign({}, state, {
      authprompt: true
    });
  }

}

/**
 * @todo add addCheckoutPeriod, addCheckoutShipping
 * @name CheckoutReducer - Checkout Reducer Used with Checkout Component and Services.
 * @param {Object} state 
 * @param {Object} action 
 */
function CheckoutReducer(state = defaultState, action) {
  switch (action.type) {
    case 'add:checkout':
    case 'update:checkout':
      return addCheckout(state, action);


    case 'add:checkout:period':
    case 'update:checkout:period':
      return addCheckoutPeriod(state, action);
    case 'add:checkout:shipping':
    case 'update:checkout:shipping':
      return addCheckoutShipping(state, action);
   
   
    case 'add:checkout:item':
    case 'update:checkout:item':
      return addCheckoutItem(state, action);
    case 'add:checkout:contract':
    case 'update:checkout:contract':
      return addCheckoutContract(state, action);
    case 'add:checkout:payment':
    case 'update:checkout:payment':
      return addCheckoutPayment(state, action);
      case 'add:checkout:pricing': 
        return addCheckoutPricing(state, action);
      break;

    case 'add:checkout:order':
    case 'update:checkout:order':
      return addCheckoutOrder(state, action);
    case 'remove:checkout:item':
      return removeCheckoutOrder(state, action);
  }
  return Object.assign({}, state, {});

  /**
   * @name addCheckout 
   * @param {Object<State>} state
   * @param {Object<Action>} action
   */
  function addCheckout(state, action) {
    return Object.assign({}, state, action.value);
  }

  /**
   * @name addCheckoutContract 
   * @param {Object<State>} state
   * @param {Object<Action>} action
   */
  function addCheckoutContract(state, action) {
    return Object.assign({}, state, {
      contract: action.value
    });
  }

  /**
   * @name addCheckoutPeriod 
   * @param {Object<State>} state
   * @param {Object<Action>} action
   */
  function addCheckoutPeriod(state, action) {
    return Object.assign({}, state, {
      period: action.value
    });
  }

  /**
   * @name addCheckoutShipping 
   * @param {Object<State>} state
   * @param {Object<Action>} action
   */
  function addCheckoutShipping(state, action) {
    return Object.assign({}, state, {
      shipping: action.value
    });
  }

  /**
   * @name addCheckoutPricing 
   * @param {Object<State>} state
   * @param {Object<Action>} action
   */
  function addCheckoutPricing(state, action) {
    return Object.assign({}, state, {
      pricing: action.value
    });
  }

  

  /**
   * @name addCheckoutPayment 
   * @param {Object<State>} state
   * @param {Object<Action>} action
   */
  function addCheckoutPayment(state, action) {
    return Object.assign({}, state, {
      payment: action.value
    });
  }


  /**
   * @name addCheckoutItem 
   * @param {Object<State>} state
   * @param {Object<Action>} action
   */
  function addCheckoutItem(state, action) {
    return Object.assign({}, state, {
      item: action.value 
    });
  }

  /**
   * @name removeCheckoutItem
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   * @return {Object}
   */
  function removeCheckoutOrder(state, action) {
    return Object.assign({}, {}, {});
  }

  /**
   * @name addCheckoutOrder
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   * @return {Object} 
   */
  function addCheckoutOrder(state, action) {
    if (!action.value || typeof action.value !== 'object') throw '@addCheckoutOrder requires Action:Value to be Object';
    var order = Object.assign({}, action.value); 
    if(typeof order.order === 'string'){
      if(!order._id || !order.id){ order._id = order.id = order.oid = order.order;  }
    }
     return Object.assign({}, state, action.value);
  }

  /**
   * @name updateCheckoutOrder
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   * @return {Object} 
   */
  function updateCheckoutOrder(state, action) {
    return addCheckoutOrder(state, action);
  }

}



/**
 * @name ReviewReducer 
 * @param {Object<State>}
 * @param {Object<Action>}
 */
function ReviewReducer(state = defaultState, action) {
  switch (action.type) {
    case 'add:review':
    case 'update:review':
      return addReview(state, action);

  }
  return Object.assign({}, state, {});


  /**
   * @name addReviews - Adds reviews to the central store.
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addReviews(state, action) {
    return Object.assign({}, state, {
      entities: action.value
    });
  }

  /**
   * @name addReview
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addReview(state, action) {
    if (!state && !action.value) {
      return Object.assign({}, {}, {
        entities: {}
      });
    }

    var rxidx = [action.value._id || action.value.id, ''].join('');
    var entities = Object.assign({}, state.entities, {});
    entities[rxidx] = Object.assign({}, action.value.response || action.value);
    return Object.assign({}, state, {
      entities: entities
    });
  }
}

/**
 * @name StuffReducer 
 * @param {Object<State>} state 
 * @param {Object<Action>} action 
 */
function StuffReducer(state = defaultState, action) {
  switch (action.type) {
    case 'add:stuff':
      return addStuff(state, action);
    case 'update:stuff':
      return updateStuff(state, action);
    case 'remove:stuff':
    return removeStuff(state, action);
    case 'add:provider:stuff':
      return addProvider(state, action);
    case 'add:owner:stuff':
      return addOwner(state, action);
  }
  return Object.assign({}, state);




  /**
   * @name removeStuff - Clean Slate to Start Adding new Item 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function removeStuff(state, action) {
    return Object.assign({}, state, {item: {}});
  }
  /**
   * @name addStuff
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addStuff(state, action) {
    if (!action.value || !action.value || typeof action.value !== 'object') throw '@addStuff requires Action:Value to be Object';
    return Object.assign({}, state, {
      item: action.value
    });
  }  
  /**
  * @name updateStuff - works like add, except when required
  * @param {Object<State>} state 
  * @param {Object<Action>} action 
  */
 function updateStuff(state, action) {
   if (!action.value || !action.value || typeof action.value !== 'object') throw '@updateStuff requires Action:Value to be Object';
   return addStuff(state, action);
 }

  /**
   * @name addOwner - Adds Owner To currently edited stuff.
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addOwner(state, action) {
    return Object.assign({}, state, {
      owner: action.value
    });
  }

  /**
   * @name addProvider - Adds Provider To currently edited stuff.
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addProvider(state, action) {
    return Object.assign({}, state, {
      provider: action.value
    });
  }
}

/**
 * @name RentingReducer - Used with RentalService to initiate a rental order from Item Owner Perspective.
 * @param {Object<State>} state
 * @param {Object<Action>} action 
 */
function RentingReducer(state = defaultState, action) {
  switch (action.type) {
    case 'add:rental':
    case 'update:rental':
      return addRental(state, action);
    case 'add:rental:search':
      return addRentalSearch(state, action);
    case 'add:rental:payment':
      return addRentalPayment(state, action);
    case 'add:rental:order':
      return addRentalOrder(state, action);
      case 'add:rental:people':
      return addRentalPeople(state, action);
      case 'add:rental:person':
      return addRentalPerson(state, action);
    case 'add:rental:item':
      return addRentalItem(state, action);
    case 'add:rental:items':
      return addRentalItems(state, action);
    case 'remove:rental':
      return removeRental(state, action);
  }
  return Object.assign({}, state);

  /**
   * @name initializes rental instance using all state mutators
   */
  function addRental(state, action) {
    var initialization = Object.assign({}, state, {items:[]});
    return Object.assign({}, state, action ? action.value || initialization: initialization);
  }

  /**
   * @name addRentalSearch
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addRentalSearch(state, action) {
    if(_.isEmpty(state)) addRental(state, {});
    if (!action.value || !action.value.text) return Object.assign({}, state, {});
    return Object.assign({}, state, {
      search: action.value
    });
  }

  /**
   * @name addRentalPayment
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addRentalPayment(state, action) {
    if(_.isEmpty(state)) addRental(state, {});
    if (!action.value || !action.value.token) return Object.assign({}, state, {});
    return Object.assign({}, state, {
      payment: action.value
    });
  }

  /**
   * @name addRentalOrder
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addRentalOrder(state, action) {
    if(_.isEmpty(state)) addRental(state, {});
    //All orders have an Item Object.
    if (!action.value || !action.value.item) return Object.assign({}, state, {});
    return Object.assign({}, state, {
      order: action.value
    });
  }
  
    /**
     * @name addRentalPerson
     * @param {Object<State>} state 
     * @param {Object<Action>} action 
     */
    function addRentalPerson(state, action) {
      if(_.isEmpty(state)) addRental(state, {});
      //All people have a name
      if (_.isEmpty(action.value)) return Object.assign({}, state, {});
      return Object.assign({}, state, {
        person: action.value
      });
    }
    
      /**
       * @name addRentalPeople - COllection of People used while renting.
       * @param {Object<State>} state 
       * @param {Object<Action>} action 
       */
      function addRentalPeople(state, action) {
        var index;
        if(_.isEmpty(state)) addRental(state, {});

        //All rental People Models have a people index
        if (action && !action.value || !action.value.people) return Object.assign({}, state, {});
        var entities = Object.assign({}, state.entities || {people: {}});
        var people  = Object.assign({}, action && action.value && action.value.people ? action.value.people : {});
        _.each(people, function (item) {
          index = item.id || item._id;
          entities.people[index] = Object.assign({}, item, {
            counter: entities.people[index] ? entities.people[index].counter + 1 : 1
          });
        });
        //@should have more objects. 
        return Object.assign({}, state, {
          entities: entities
        });
      }
  
  /**
   * @name addRentalItem
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addRentalItem(state, action) {
    if(_.isEmpty(state)) addRental(state, {});
    if (_.isEmpty(action.value)) return Object.assign({}, state, {});
    return Object.assign({}, state, {
      item: action.value
    });
  }

  /**
   * @name addRental - One Rental is Active at a time.
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addRentalItems(state, action) {
    if(_.isEmpty(state)) addRental(state, {});
    //All rental Models have an Items index
    if (!action.value || !action.value.items) return Object.assign({}, state, {});
    var entities = Object.assign({}, state.entities,  {items: action.value.items || action.value});
    return Object.assign({}, state, {
      entities: entities
    });
  }

  /**
   * @name removeRental 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function removeRental(state, action) {
    return Object.assign({}, defaultState);
  }
}
/**
 * @name OrderReducer  - Works with Order Listing but not with CheckoutReducer.
 * @param {*} state 
 * @param {*} action 
 */
function OrderReducer(state = defaultState, action) {
  switch (action.type) {
    case 'add:order':
    case 'update:order':
    case 'add:rental:recipient':
      return addOrder(state, action);

    case 'remove:all:orders':
      return removeOrder(state, action);

    case 'remove:order':
      return removeOrder(state, action);

    case 'search:rental':
      return findOrder(state, action);
  }
  return Object.assign({}, state, {});

  /**
   * @name addOrder 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addOrder(state, action) {
    if (!state && !action.value) {
      return Object.assign({}, {}, {
        entities: {},
        order: {}
      });
    }

    var rxidx = [action.value.haystack || action.value._id || action.value.id, ''].join('');
    var entities = Object.assign({}, state.entities, {});
    entities[rxidx] = Object.assign({}, action.value.response || action.value.collection || action.value);
    return Object.assign({}, state, {
      entities: entities,
      order: action.value
    });
  }

  /**
   * @name removeOrder 
   * @param {Object<State>}
   * @param {Object<Action>}
   */
  function removeOrder(state, action) {
    if (!state && !action.value) {
      return Object.assign({}, {}, {
        entities: {},
        order: {}
      });
    }

    var entities = {},
      order = Object.assign({}, state.order);
    var rm = [action.value.id || action.value.index || action.value._id, ''].join('');
    var keys = Object.keys(state.entities).filter(function (key) {
      if (key !== rm) {
        entities[key] = Object.assign({}, state.entities[key]);
      }
      if ((order.id || order._id) == rm) {
        order = Object.assign({}, {});
      }
      return key !== rm;
    });
    return Object.assign({}, state, {
      entities: entities,
      order: order
    });

  }

  /**
   * @name removeAllOrders 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function removeAllOrders(state, action) {
    return Object.assign({}, defaultState);
  }

}

/**
 * @name PeopleReducer - Deals with ME + People Objects 
 * @param {Object<State>} state 
 * @param {Object<Action>} action 
 */
function PeopleReducer(state = defaultState, action) {
  switch (action.type) {
    //People+Contact
    case 'add:people':
      return addPeople(state, action);
    case 'add:person':
    case 'update:person':
    case 'update:people':
      return addPerson(state, action);

    case 'add:profile':
      return addProfile(state, action);
    case 'remove:people':
    case 'remove:person':
      return removePerson(state, action);
    case 'search:people':
      return findPerson(state, action);
  }

  return Object.assign({}, state, {});

  /**
   * @name addPeople - Adds People after search results.
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addPeople(state, action) {
    return Object.assign({}, state, {
      entities: action.value
    });
  }


  /**
   * @name findPerson 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function findPerson(state, action) {
    var top = Object.assign({}, state.entities[action.value.id || action.value._id]);
    return Object.assign({}, state, {
      profile: top,
      top: top
    });
  }

  /**
   * @name removePerson 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function removePerson(state, action) {
    var entities = {};
    var rm = action.value.id || action.value._id;
    var keys = Object.keys(state.entities).filter(function (key) {
      if (key !== rm) {
        entities[key] = Object.assign({}, state.entities[key]);
      }
      return key !== rm;
    });
    return Object.assign({}, state, {
      entities: entities
    });
  }

  /**
   * @name addPerson 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addPerson(state, action) {
    return Object.assign({}, state, {
      person: action.value
    });
  }

  /**
   * @name addProfile - Currently Visible Profile(one at a time)
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addProfile(state, action) {
    return Object.assign({}, state, {
      profile: action.value
    });
  }
}

/**
 * @name UserReducer - Deals with ME + People Objects 
 * @param {Object<State>} state 
 * @param {Object<Action>} action 
 */
function UserReducer(state = {}, action) {
  switch (action.type) {
    case 'add:wishlist':
    case 'add:favorite:item':
    case 'update:wishilist':
    case 'update:favorite:item':
      return addWishlist(state, action);
    case 'add:cards':
      return addCards(state, action);
    case 'add:review':
      return addReview(state, action);
    case 'add:reviews':
      return addReviews(state, action);
    case 'add:me':
    case 'add:user':
      return addUser(state, action);
    case 'remove:me':
    case 'remove:user':
      return removeUser(state, action);
    case 'update:me':
    case 'update:user':
      return updateUser(state, action);
    case 'add:invited:friends':
      return addUserInvitedFriends(state, action);
  }
  return Object.assign({}, state, {});

  /**
   * @name addCards - Adds Cards to Current User. Settings Cards has its own handler.
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addCards(state, action) {
    return Object.assign({}, state, {
      cards: action.value
    });
  }

  /**
   * @name addWishlist - Updates Favorites Item
   * @param {Object<State>} state
   * @param {OBject<Action>} action 
   */
  function addWishlist(state, action) {
    var wishlist = Object.assign({}, state.wishlist);
    wishlist[action.value.id || action.value._id] = action.value;
    return Object.assign({}, state, {
      wishlist: wishlist,
      favorites: wishlist
    });
  }
  /**
   * @name addReview - Adds a Review to a public profile
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addReview(state, action) {
    return addReviews(state, action);
  }

  /**
   * @name addReviews - Adds Reviews to a public profile. 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addReviews(state, action) {
    if (!state && !action.value) {
      return Object.assign({}, {}, {
        entities: {},
        review: {},
        reviewer: {},
        reviewee: {}
      });
    }

    var rxidx = [action.value.id, ''].join('');
    var entities = Object.assign({}, state.reviews, {});
    entities[rxidx] = Object.assign({}, action.value);
    return Object.assign({}, state, {
      reviews: entities,
      review: action.value
    });
  }

  /**
   * @name addUserInvitedFriends - Adding User Invited Friends to Central Storage
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addUserInvitedFriends(state, action) {
    var invitations = {
      invitations: action.value
    };
    return Object.assign({}, state, invitations);
  }

  /**
   * @name addUser - Adding User|Me to Central Storage
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addUser(state, action) {
    return Object.assign({}, state, action.value);
  }

  /**
   * @name removeUser - Removing User|Me from Central State
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function removeUser(state, action) {
    return Object.assign({}, {}, {});
  }

  /**
   * @name updateUser - Updating User|Me from Central State
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function updateUser(state, action) {
    return addUser(state, action);
  }

}

/**
 * @name SettingsReducer - Makes Changed Settings Available. 
 */
function SettingsReducer(state, action) {
  switch (action.type) {

    case 'add:settings':
      return addSettings(state, action);
    case 'add:me':
      return addUser(state, action);
    case 'add:cards':
      return addCards(state, action);
    case 'add:review':
      return addReview(state, action);
    case 'add:reviews':
      return addReviews(state, action);
    case 'remove:me':
      return removeUser(state, action);
    case 'update:me':
      return updateUser(state, action);
    case 'add:link:account':
      return linkAccount(state, action);
    case 'add:unlink:account':
      return unlinkAccount(state, action);
    case 'add:signup:account': //ADD_SIGNUP_ACCOUNT: 
    case 'add:signin:account': //ADD_SIGNIN_ACCOUNT: 
    case 'add:invited:friends':
      return addInvitedFriends(state, action);
    case 'add:address':
      return addAddress(state, action);
    case 'add:contact':
      return addContact(state, action);
    case 'add:facebook':
      return addFacebook(state, action);
    case 'add:google':
      return addGoogle(state, action);
    case 'add:credit:card':
      return addCreditCard(state, action);
    case 'add:photo':
      return addPhoto(state, action);
    case 'add:invite':
      return addInvite(state, action);
    case 'add:invites':
      return addInvites(state, action);
  }

  return Object.assign({}, state, {});

  /**
   * @name addSettings
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addSettings(state, action) {
    return Object.assign({}, state, action.value);
  }

  /**
   * @name linkAccount - Linking an Account to current Settings Instance
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function linkAccount(state, action) {
    var account = {};
    if (action.value && action.value.type) {
      account[action.value.type] = action.value;
    }
    return Object.assign({}, state, action);
  }

  /**
   * @name unlinkAccount - Unlinking Account to Current Settings Instancce
   * @param {Object<State>}
   * @param {Object<Action>}
   */
  function unlinkAccount(state, action) {
    var account = {};
    if (action.value && action.value.type) {
      account[action.value.type] = {};
    }
    return Object.assign({}, state, action);
  }

  /**
   * @name addInvitedFriends - Invited Friends 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addInvitedFriends(state, action) {
    return Object.assign({}, state, {
      invitations: action.value
    });
  }


  /**
   * @name addUser - Adding Settings to base Object.
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addUser(state, action) {
    return Object.assign({}, state, action.value);
  }

  /**
   * @name addCards - Adds Cards to Current User. Settings Cards has its own handler.
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addCards(state, action) {
    return Object.assign({}, state, {
      cards: action.value
    });
  }

  /**
   * @name addReview - Adds a Review to a public profile
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addReview(state, action) {
    return addReviews(state, action);
  }

  /**
   * @name addReviews - Adds Reviews to a public profile. 
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addReviews(state, action) {
    return Object.assign({}, state, {
      reviews: action.value
    });
  }

  /**
   * @name addUserInvitedFriends - Adding User Invited Friends to Central Storage
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addUserInvitedFriends(state, action) {
    var invitations = {
      invitations: action.value
    };
    return Object.assign({}, state, invitations);
  }

  /**
   * @name addUser - Adding User|Me to Central Storage
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addUser(state, action) {
    return Object.assign({}, state, action.value);
  }

  /**
   * @name removeUser - Removing User|Me from Central State
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function removeUser(state, action) {
    return Object.assign({}, {}, {});
  }

  /**
   * @name updateUser - Updating User|Me from Central State
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function updateUser(state, action) {
    return addUser(state, action);
  }

  /**
   * @name addCard - Adding a Credit Card on this settings.
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addCard(state, action) {
    return addCards(state, action);
  }
  /**
   * @name addCards - Adding Cards Settings
   * @param {Object<State>} state 
   * @param {Object<Action>} action 
   */
  function addCards(state, action) {
    return Object.assign({}, state, {
      cards: action.value
    });
  }

  /**
   * @name addCreditCard 
   * @param {*} state 
   * @param {*} action 
   */
  function addCreditCard(state, action) {
    //@todo push(action.value) into cards as well. 
    return Object.assign({}, state, {
      card: action.value
    });
  }

  /**
   * @name addAddress - adding address on settings
   * @param {Object} state 
   * @param {Object} action 
   */
  function addAddress(state, action) {
    return Object.assign({}, state, {
      address: action.value
    });
  }
  /**
   * @name addContact - adding contact to the list of contacts
   * @param {*} state 
   * @param {*} action 
   */
  function addContact(state, action) {
    return Object.assign({}, state, {
      contact: action.value
    });
  }
  /**
   * @name addFacebook - adding facebook changes
   * @param {*} state 
   * @param {*} action 
   */
  function addFacebook(state, action) {
    return Object.assign({}, state, {
      facebook: action.value
    });
  }
  /**
   * @name addGoogle - adding google settings. 
   * @param {*} state 
   * @param {*} action 
   */
  function addGoogle(state, action) {
    return Object.assign({}, state, {
      google: action.value
    });
  }

  /**
   * @name addPhoto 
   * @param {*} state 
   * @param {*} action 
   */
  function addPhoto(state, action) {
    var local = Object.assign({}, state.local, {
      picture: action.value,
      photo: action.value
    });
    return Object.assign({}, state, {
      local: local
    });
  }
  /**
   * @name addInvite 
   * @param {*} state 
   * @param {*} action 
   */
  function addInvite(state, action) {
    return Object.assign({}, state, {
      invite: action.value
    });
  }
};


/**
 * @todo ---- There are sub-entities to be acted on. 
 * @name SearchReducer - Handles All search results. 
 * @param {Object<State>} state 
 * @param {Object<Action>} action 
 */
function SearchReducer(state = defaultState, action) {
  switch (action.type) {
    case 'add:omnisearch:result':
    case 'update:omnisearch:result':
      return addResults(state, action);

    case 'remove:omnisearch:result':
      return removeResults(state, action);

    case 'search:omnisearch:result':
      return findResults(state, action);
      break
  }
  return Object.assign({}, state, {});

  /**
   * @name addResults 
   * @param {Object} state 
   * @param {Object} action 
   */
  function addResults(state, action) {
    //@todo IDx may even be type of search type. 
    var rxidx = [action.value.haystack || action.value._id || action.value.id, ''].join('');
    var entities = Object.assign({}, state.entities, {});
    entities[rxidx] = Object.assign({}, action.value.response || action.value.collection || action.value);
    return Object.assign({}, state, {
      entities: entities
    });
  }
  /**
   * @name removeResults
   * @param {Object} state 
   * @param {Object} action 
   */
  function removeResults(state, action) {
    var entities = {};
    var rm = action.value.id || action.value._id;
    var keys = Object.keys(state.entities).filter(function (key) {
      if (key !== rm) {
        entities[key] = Object.assign({}, state.entities[key]);
      }
      return key !== rm;
    });
    return Object.assign({}, state, {
      entities: entities
    });
  }
  /**
   * @name updateResults
   * @param {Object} state 
   * @param {Object} action 
   */
  function updateResults(state, action) {
    return addResults(state, action);
  }
  /**
   * @name removeResults
   * @param {Object} state 
   * @param {Object} action 
   */
  function findResults(state, action) {
    if (!state && !action.value) {
      return Object.assign({}, {}, {
        entities: {}
      });
    }
    var index = action.value.id || action.value.index || action.value._id;
    var entities = state.entities[action.value.haystack] || {};
    return Object.assign({}, entities[index] || {});
  }


}

/**
 * @name HoogyReducer - Merges reducers 
 * @return {Object} reducer 
 * @example 
 * <code></code>
 */
function HoogyReducer() {
  return Redux.combineReducers({

    //User+Settings    
    me: UserReducer,
    user: UserReducer,
    auth: AuthReducer,
    settings: SettingsReducer,

    //Screen 
    screens: UIReducer,
    flow: FlowReducer,
    items: ItemsReducer,
    inventory: ItemsReducer,
    activity: ActivityReducer,
    checkout: CheckoutReducer,

    //
    messenger: MessengerReducer,
    notification: NotificationReducer,

    //
    people: PeopleReducer,
    renting: RentingReducer,
    review: ReviewReducer,
    order: OrderReducer,
    stuff: StuffReducer,
    results: SearchReducer
  });
};


/**
 * Flux - abstraction that makes any Flux implementation usable
 */
function Flux() {
  return Redux;
}
/**
 * @param {Object<HoogyReducer>}
 * @param {Object<Flux>}
 * @name StateService - Shared data Store.
 *  Sharable data is stored in this object.
 *  It may use LocalStorage, or IndexDB in future
 */
StateService.$inject = ['HoogyReducer', 'Flux'];

function StateService(HoogyReducer, Flux) {
  return Flux.createStore(HoogyReducer, {
    me: {},
    flow: {},
    user: {},
    settings: {},
    auth: {
      authprompt: true
    },
    checkout: {},
    renting: {},
    screens: defaultState,
    notification: defaultState,
    errors: defaultState,
    items: defaultState,
    stuff: defaultState,
    inventory: defaultState,
    order: defaultState,
    people: defaultState,
    messenger: defaultState,
    activity: defaultState,
    results: defaultState
  });
}

/*global Redux, angular, Stripe, AppConfig, moment, _ */
angular
  .module('hoogy')
  .service('HoogyReducer', HoogyReducer)
  .factory('StateService', StateService)
  .factory('Flux', Flux);