'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * Common.js is a collection of self contained directives  that are going to be used while refactoring.
 * It will reduce the number of files, while keeping the code clean, clear and easy to use.
 * The code is writen to comply to upcoming Angular 2
 * @name  DropdownNotifications
 */
function DropdownNotifications(){
    function Link($scope, $element, $attrs, $ctrl){
        $('.dropdown-toggle.-notifications').dropdown('toggle');
    }
    return {
        restrict: 'A', 
        link: Link
    };
}
angular.module('hoogy').directive('dropdownNotifications', DropdownNotifications);
