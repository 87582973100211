/*global Redux, angular, Stripe, AppConfig, moment, _ */
'use strict';

/**
 * @name  DiagnoseService - Checks problems after user authenticates
 * @param {Object} StateService 
 * @param {Object} ActionCreator 
 * @param {Object} HoogyService
 */
function DiagnoseService(StateService, ActionCreator, HoogyService) {

  var SETTINGS_CONTEXT = 'settings:context';
  var SUGGESTION_CONTEXT = 'suggestion:context';

  var ADD_INVENTORY = 'add:inventory:notification';
  var ADD_MORE_INVENTORY = 'update:inventory:notification';
  var INVITE_FRIENDS = 'invite:friends:notification';
  var INVITE_MORE_FRIENDS = 'invite:more:friends:notification';
  var ADD_CONTRACT = 'add:contract:notification';
  var UPDATE_CONTRACT = 'update:contract:notification';
  var ADD_PAYMENT_INFO = 'add:payment:notification';
  var UPDATE_PAYMENT_INFO = 'update:payment:notification';
  var ADD_ADDRESS = 'add:address:notification';
  var UPDATE_ADDRESS = 'update:address:notification';
  var UPDATE_PERSONAL_INFO = 'update:personal:notification';
  var ADD_ME = 'add:me:notification';
  var UPDATE_ME = 'update:me:notification';
  var ADD_ORDER = 'add:order:notification';
  var UPDATE_ORDER = 'update:order:notification';
  var ADD_SETTINGS = 'add:settings:notification';
  var UPDATE_SETTINGS = 'update:settings:notification';

  var ADD_WISHLIST = 'add:wishilist:notification';

  var ADD_REVIEW = 'add:review:notification';
  var UPDATE_REVIEW = 'update:review:notification';
  var ADD_WALLET = 'add:wallet:notification';
  var ADD_ORDERS = 'add:orders:notification';
  var UPDATE_ORDERS = 'update:orders:notification';
  var ADD_PROFILE_REVIEW = 'add:profile:review:notification';


  var settings = [{
      type: ADD_ME,
      title: 'Missing profile settings',
      category: 'suggestion',
      context: SETTINGS_CONTEXT,
      text: 'Accurate name/email/photo increase confidence for people rent from you, as well as renting you their stuff',
      description: 'Accurate name/email/photo increase confidence for people rent from you, as well as renting you their stuff',
      details: 'Accurate name/email/photo increase confidence for people rent from you, as well as renting you their stuff',
      actions: [{
        url: 'settings.profile',
        title: 'Edit profile'
      }]
    }, {
      type: UPDATE_ME,
      title: 'Edit Profile',
      category: 'suggestion',
      context: SETTINGS_CONTEXT,
      text: 'Accurate name/email/photo increase confidence for people rent from you, as well as renting you their stuff',
      description: 'Accurate name/email/photo increase confidence for people rent from you, as well as renting you their stuff',
      details: 'Accurate name/email/photo increase confidence for people rent from you, as well as renting you their stuff',
      actions: [{
        url: 'settings.profile',
        title: 'Edit profile'
      }]
    }, {
      type: ADD_SETTINGS,
      category: 'suggestion',
      title: 'Update Settings',
      context: SETTINGS_CONTEXT,
      text: 'Accurate name/email/photo increase confidence for people rent from you, as well as renting you their stuff',
      description: 'Accurate name/email/photo increase confidence for people rent from you, as well as renting you their stuff',
      details: 'Accurate name/email/photo increase confidence for people rent from you, as well as renting you their stuff',
      actions: [{
        url: 'settings.profile',
        title: 'Edit profile'
      }]
    }, {
      type: UPDATE_SETTINGS,
      title: 'Update Settings',
      category: 'suggestion',
      context: SETTINGS_CONTEXT,
      text: 'Accurate name/email/photo increase confidence for people rent from you, as well as renting you their stuff',
      description: 'Accurate name/email/photo increase confidence for people rent from you, as well as renting you their stuff',
      details: 'Accurate name/email/photo increase confidence for people rent from you, as well as renting you their stuff',
      actions: [{
        url: 'settings.profile',
        title: 'Edit profile'
      }]
    }, {
      type: ADD_INVENTORY,
      title: 'Add Inventory',
      category: 'suggestion',
      context: SETTINGS_CONTEXT,
      text: 'Start by adding stuff you don\'t use quite often',
      description: 'Start by adding stuff you don\'t use quite often',
      details: 'Start by adding stuff you don\'t use quite often',
      actions: [{
        url: 'stuff.add',
        title: 'Add stuff'
      }]
    }, {
      type: ADD_MORE_INVENTORY,
      title: 'Add More Items',
      category: 'suggestion',
      context: SETTINGS_CONTEXT,
      text: 'Awesome! You have a few stuff to rent out.',
      description: 'Awesome! You have a few stuff to rent out.',
      details: 'Awesome! You have a few stuff to rent out.',
      actions: [{
        url: 'stuff.add',
        title: 'Add more stuff'
      }]
    }, {
      type: INVITE_FRIENDS,
      title: 'Invite Friends',
      category: 'suggestion',
      context: SETTINGS_CONTEXT,
      text: 'Invite your friend who may need to rent your stuff.',
      description: 'Invite your friend who may need to rent your stuff.',
      details: 'Invite your friend who may need to rent your stuff.',
      actions: [{
        url: 'settings.contact',
        title: 'Add a Friend'
      }]
    }, {
      type: INVITE_MORE_FRIENDS,
      title: 'Invite More Friends',
      category: 'suggestion',
      context: SETTINGS_CONTEXT,
      text: 'You invited, a couple friends! The more the better.',
      description: 'You invited, a couple friends! The more the better.',
      details: 'You invited, a couple friends! The more the better.',
      actions: [{
        url: 'settings.contact',
        title: 'Invite more'
      }]
    }, {
      type: ADD_CONTRACT,
      title: 'Add Contract',
      category: 'suggestion',
      context: SETTINGS_CONTEXT,
      text: 'Customize contract as first step to protect your stuff.',
      description: 'Customize contract as first step to protect your stuff.',
      details: 'Customize contract as first step to protect your stuff.',
      actions: [{
        url: 'workspace.contract',
        title: 'Add Contract'
      }]
    }, {
      type: UPDATE_CONTRACT,
      title: 'Update Contract',
      category: 'suggestion',
      context: SETTINGS_CONTEXT,
      text: 'Your contract is in order. Update anytime!',
      description: 'Your contract is in order. Update anytime!',
      details: 'Your contract is in order. Update anytime!',
      actions: [{
        url: 'workspace.contract',
        title: 'Update Contract'
      }]
    }, {
      type: ADD_PAYMENT_INFO,
      title: 'Add Payment Information',
      category: 'suggestion',
      context: SETTINGS_CONTEXT,
      text: 'We have no idea where to send your money!',
      description: 'We have no idea where to send your money!',
      details: 'We have no idea where to send your money!',
      actions: [{
        url: 'workspace.wallet',
        title: 'Accept payment'
      }]
    }, {
      type: UPDATE_PAYMENT_INFO,
      title: 'Update Payment Information',
      category: 'suggestion',
      context: SETTINGS_CONTEXT,
      text: 'Payouts rolls every 2 days, you cash out every 7 days. Update payment settings .',
      description: 'Payouts rolls every 2 days, you cash out every 7 days. Update payment settings .',
      details: 'Payouts rolls every 2 days, you cash out every 7 days. Update payment settings .',
      actions: [{
        url: 'workspace.wallet',
        title: 'Update payment options'
      }]
    }, {
      type: ADD_ADDRESS,
      title: 'Add Address Settings',
      category: 'suggestion',
      context: SETTINGS_CONTEXT,
      text: 'We cannot find stuff for you, we have no idea where you live!',
      description: 'We cannot find stuff for you, we have no idea where you live!',
      details: 'We cannot find stuff for you, we have no idea where you live!',
      actions: [{
        url: 'settings.address',
        title: 'Add City'
      }]
    }, {
      type: UPDATE_ADDRESS,
      title: 'Update Address',
      category: 'suggestion',
      context: SETTINGS_CONTEXT,
      text: 'Feel free to update your address anytime!',
      description: 'Feel free to update your address anytime!',
      details: 'Feel free to update your address anytime!',
      actions: [{
        url: 'settings.address',
        title: 'Change address'
      }]
    }
    //suggestions ---- are moved to this new diagnose service. 
    , {
      type: ADD_REVIEW,
      title: 'Add Review',
      category: 'suggestion',
      context: SUGGESTION_CONTEXT,
      text: 'If you loved what you rented, help this person to get more renting buddies.',
      description: 'If you loved what you rented, help this person to get more renting buddies.',
      details: 'If you loved what you rented, help this person to get more renting buddies.',
      actions: [{
        //url: 'settings.address',
        //title: 'Change address'
      }]
    }, {
      type: UPDATE_REVIEW,
      title: 'Update Review',
      category: 'suggestion',
      context: SUGGESTION_CONTEXT,
      text: 'If you loved what you rented, help this person to get more renting buddies.',
      description: 'If you loved what you rented, help this person to get more renting buddies.',
      details: 'If you loved what you rented, help this person to get more renting buddies.',
      actions: [{
        //url: 'settings.address',
        //title: 'Change address'
      }]
    }, {
      type: ADD_WISHLIST,
      title: 'Add Items to Wishlist',
      category: 'suggestion',
      context: SUGGESTION_CONTEXT,
      title: 'Start liking things you would like to rent in future',
      text: 'We will remember things you like, so that you can rent them faster in future.\n items that are ready for rent, and click sur heart <b class="heart"> &#9829;</b> to like them',
      description: 'We will remember things you like, so that you can rent them faster in future.\n items that are ready for rent, and click sur heart <b class="heart"> &#9829;</b> to like them',
      details: 'We will remember things you like, so that you can rent them faster in future.\n items that are ready for rent, and click sur heart <b class="heart"> &#9829;</b> to like them',
      actions: [{
        url: 'workspace.discover',
        title: 'Discover'
      }]
    }, {
      type: ADD_WALLET,
      title: 'Configure Wallet Informations',
      category: 'suggestion',
      context: SUGGESTION_CONTEXT,
      text: [
        '- Enable payments to start accepting payouts.</br>',
        '- To receive direct deposits, add your bank account and routing number. We rollout payments 7 days after transaction is completed.</br>',
        '- Only add your SSN if you process more than $1000, and lives in US. Never disclose this information even to our own employees.</br>'
      ],
      description: 'Enable payments to start accepting payouts', 
      details: 'Enable payments to start accepting payouts',
      actions: [{
        url: 'settings.wallet',
        title: 'Start accepting payments'
      }]
    }, {
      type: ADD_ORDERS,
      category: 'suggestion',
      title: 'You have no orders for the moment',
      context: SUGGESTION_CONTEXT,
      text: [
        '- You can share your items on Facebook/Twitter or share via Email.</br>',
        '- You can also rent from your friends! You will be able to see your rental here</br>',
        '- You will get an email when someone books your item.</br>',
        '- You can also add things people tend to rent often.</br>'
      ],
      description: 'Try to share with your friends via messenger, social media or email.', 
      details: 'Try to share with your friends via messenger, social media or email.', 
      actions: [{
        url: 'stuff.add',
        title: 'Add More Items!'
      }]
    }, {
      type: ADD_INVENTORY,
      category: 'suggestion',
      context: SUGGESTION_CONTEXT,
      title: 'Stuff people are willing to rent from you:',
      text: ['* <b>Bike</b>, tourists are always ready to rent this from you',
        '* <b>Textbook</b>, we know students who are ready to save by renting rather than buying!',
        '* <b>Camera</b>, these are always in need. From professional to newbies.',
        '* <b>Kayak</b>, if you have one trailing in your garage, it is time to give it a second chance.'
      ],
      description: 'to start, try to add Bike, Textbook, Camera gear or Kayak', 
      details: 'to start, try to add Bike, Textbook, Camera gear or Kayak', 
      actions: [{
        url: 'stuff.add',
        title: 'Start renting out today!'
      }]
    }, {
      type: ADD_PROFILE_REVIEW,
      category: 'suggestion',
      context: SUGGESTION_CONTEXT,
      title: 'Add Person Review',
      text: 'Recommend to TWO of your friends who may need this item.\n It will strengthen your profile and help your buddies :-)',
      description: 'Recommend to TWO of your friends who may need this item.\n It will strengthen your profile and help your buddies :-)',
      details: 'Recommend to TWO of your friends who may need this item.\n It will strengthen your profile and help your buddies :-)',
      actions: [{
        url: 'settings.address',
        title: 'Recommend'
      }]
    }
  ];

  var service = {
    //contexts
    SETTINGS_CONTEXT: SETTINGS_CONTEXT,
    SUGGESTION_CONTEXT: SUGGESTION_CONTEXT,

    UPDATE_ORDER: UPDATE_ORDER,
    UPDATE_ADDRESS: UPDATE_ADDRESS,
    UPDATE_SETTINGS: UPDATE_SETTINGS,
    UPDATE_CONTRACT: UPDATE_CONTRACT,
    UPDATE_PAYMENT_INFO: UPDATE_PAYMENT_INFO,
    UPDATE_PERSONAL_INFO: UPDATE_PERSONAL_INFO,

    ADD_ME: ADD_ME,
    UPDATE_ME: UPDATE_ME,
    ADD_ORDER: ADD_ORDER,
    ADD_ORDERS: ADD_ORDERS,
    ADD_WALLET: ADD_WALLET,
    ADD_WISHLIST: ADD_WISHLIST,
    UPDATE_ORDERS: UPDATE_ORDERS,
    
    ADD_ADDRESS: ADD_ADDRESS,
    ADD_CONTRACT: ADD_CONTRACT,
    ADD_SETTINGS: ADD_SETTINGS,
    ADD_INVENTORY: ADD_INVENTORY,
    ADD_PAYMENT_INFO: ADD_PAYMENT_INFO,
    ADD_MORE_INVENTORY: ADD_MORE_INVENTORY,

    INVITE_FRIENDS: INVITE_FRIENDS,
    INVITE_MORE_FRIENDS: INVITE_MORE_FRIENDS,


    //running diagnosis 
    diagnose: diagnose,
    diagnoseMe: diagnoseMe,
    diagnoseInventory: diagnoseItems,
    diagnoseItems: diagnoseItems,
    diagnoseWallet: diagnoseWallet,
    diagnoseOrder: diagnoseOrder,
    diagnoseOrders: diagnoseOrders,
    diagnoseReviews: diagnoseReviews,
    diagnoseAddress: diagnoseAddress,
    diagnoseMessages: diagnoseMessages,
    diagnoseContract: diagnoseContract,
    diagnoseSettings: diagnoseSettings,
    diagnoseWishlist: diagnoseWishlist,
    diagnoseContacts: diagnoseContacts,
    diagnoseFollowers: diagnoseFollowers,
    diagnoseMessages: diagnoseMessages,
    diagnoseFavorites: diagnoseFavorites,
    diagnoseInvitations: diagnoseInvitations, 

    //added for testing purposes. 
    getSettingsMessages: getSettingsMessages
  };

  return service;





  /**
   * @example 
   * <code>
   *  Diagnose.diagnose();
   * </code>
   * @name diagnose
   *       - The service will be called after authenticates. 
   *       - The service will be used by AuthComponent
   *       - The service will use New Reducers 
   * Runs general diagnosis on Functions and Data Models.
   * Any incident is recorded as a Notification or System Error in central data store.
   * Can be used in a Promise context
   * @return {Object} response
   */
  function diagnose(response) {
    //these variables should be took into account
    diagnoseMe();
    diagnoseSettings();
    diagnoseOrder();
    diagnoseOrders();
    diagnoseItems();
    diagnoseAddress();
    diagnoseWallet();
    diagnoseInvitations();
    diagnoseContract();
    diagnoseContacts();
    diagnoseFollowers();
    diagnoseFavorites();
    diagnoseMessages(response);
    if (response) {
      return response;
    }
  }



  /**
   * @name diagnoseWishlist - Diagnoses Wishlist Message
   * @return {Object<Message>}
   */
  function diagnoseWishlist(wishlist) {
    var user = HoogyService.getUser();
    var wishlist = wishlist || user.wishlist;
    var ln = _.size(wishlist);
    var type = !_.size(wishlist) ? ADD_WISHLIST : ADD_WISHLIST;
    var message = service.getSettingsMessages(type);
    message.id = [message.type, message.category, message.context].join(':').toLowerCase();
    if (type) StateService.dispatch(ActionCreator.diagnoseWishlist(message));
    return message; 
  }

  /**
   * @name diagnoseOrders 
   * @return {Object<Message>}
   */
  function diagnoseOrders() {
    var user = HoogyService.getUser();
    var orders = user.outgoings || user.outgoing;
    var ln = _.size(orders);
    var type = !_.size(orders) ? ADD_ORDERS : UPDATE_ORDERS;
    var message = service.getSettingsMessages(type);
    message.id = [message.type, message.category, message.context].join(':').toLowerCase();
    if (type) StateService.dispatch(ActionCreator.diagnoseOrders(message));
    return message; 
  }

  /**
   * @name diagnoseReviews - Diagnose Reviews 
   * @param {Object<Collection>} reviews 
   */
  function diagnoseReviews(reviews) {
    var ln = _.size(reviews);
    var type = !_.size(reviews) ? ADD_REVIEW : UPDATE_REVIEW;
    var message = service.getSettingsMessages(type);
    message.id = [message.type, message.category, message.context].join(':').toLowerCase();
    if (type) StateService.dispatch(ActionCreator.diagnoseReviews(message));
    return message; 
  }


  /**
   * @name diagnoseMe - used to diagnose missing Account Missing Variables.
   * @return {Object<Message>} 
   */
  function diagnoseMe() {
    var user = HoogyService.getUser();
    var local = Object.keys(user.local || {});
    var facebook = Object.keys(user.facebook || {});
    var google = Object.keys(user.google || {});

    var email = (user.local || {}).email || (user.facebook || {}).email || (user.google || {}).email;
    var name = (user.local || {}).name || (user.facebook || {}).name || (user.google || {}).name;
    var photo = (user.local || {}).photo || (user.facebook || {}).photo || (user.google || {}).photo;

    var type = !email && !name && !photo ? ADD_ME : UPDATE_ME;
    var message = service.getSettingsMessages(type);
    message.id = [message.type, message.category, message.context].join(':').toLowerCase();
    StateService.dispatch(ActionCreator.diagnoseMe(message));
    return message; 
  }

  /**
   * @name diagnoseSettings - Diagnose Settings 
   * @return {Object<Message>}
   */
  function diagnoseSettings() {
    var user = HoogyService.getUser();
    var local = Object.keys(user.local || {});
    var facebook = Object.keys(user.facebook || {});
    var google = Object.keys(user.google || {});

    var email = (user.local || {}).email || (user.facebook || {}).email || (user.google || {}).email;
    var name = (user.local || {}).name || (user.facebook || {}).name || (user.google || {}).name;
    var photo = (user.local || {}).photo || (user.facebook || {}).photo || (user.google || {}).photo;

    var type = !email && !name && !photo ? ADD_SETTINGS : UPDATE_SETTINGS;
    var message = service.getSettingsMessages(type);
    message.id = [message.type, message.category, message.context].join(':').toLowerCase();
    StateService.dispatch(ActionCreator.diagnoseSettings(message));
    return message;
  }

  /**
   * @name diagnoseOrder - Diagnose Order Settings 
   * @return {Object<Message>}
   */
  function diagnoseOrder() {
    var user = HoogyService.getUser();
    var test = false;
    var type = !test ? ADD_ORDER : UPDATE_ORDER;
    var message = service.getSettingsMessages(type);
    message.id = [message.type, message.category, message.context].join(':').toLowerCase();
    StateService.dispatch(ActionCreator.diagnoseOrder(message));
    return message;
  }

  /**
   * @name diagnoseItems - Diagnose Items Missing Accounts 
   * @return {Object<Message>}
   */
  function diagnoseItems() {
    var user = HoogyService.getUser();
    var test = _.size(user.inventory);
    var type = !test ? ADD_INVENTORY : ADD_MORE_INVENTORY;
    var message = service.getSettingsMessages(type);
    message.id = [message.type, message.category, message.context].join(':').toLowerCase();
    StateService.dispatch(ActionCreator.diagnoseItems(message));
    return message;
  }

  /**
   * @name diagnoseAddress - Diagnose Address Account
   * @return {void}
   */
  function diagnoseAddress() {
    var user = HoogyService.getUser();
    var test = !!(user.address || {}).verified;
    var type = !test ? ADD_ADDRESS : UPDATE_ADDRESS;
    var message = service.getSettingsMessages(type);
    message.id = [message.type, message.category, message.context].join(':').toLowerCase();
    StateService.dispatch(ActionCreator.diagnoseAddress(message));
    return message;
  }

  /**
   * @name diagnoseWallet - Diagnose Wallet Account Settings.
   * @return {Object<Message>}
   */
  function diagnoseWallet() {
    var user = HoogyService.getUser();
    var test = (user.wallet || {}).enabled;
    var type = !test ? ADD_PAYMENT_INFO : UPDATE_PAYMENT_INFO;
    var message = service.getSettingsMessages(type);
    message.id = [message.type, message.category, message.context].join(':').toLowerCase();
    StateService.dispatch(ActionCreator.diagnoseWallet(message));
    return message;
  }

  /**
   * @name diagnoseInvitations - Diagnose Invitations Account 
   * @return {Object<Message>}
   */
  function diagnoseInvitations() {
    var user = HoogyService.getUser();
    var test = _.size(user.invites);
    var type = !test ? INVITE_FRIENDS : INVITE_MORE_FRIENDS;
    var message = service.getSettingsMessages(type);
    message.id = [message.type, message.category, message.context].join(':').toLowerCase();
    StateService.dispatch(ActionCreator.diagnoseInvitations(message));
    return message;
  }

  /**
   * @name diagnoseContract
   * @returns {Object<Message>}
   */
  function diagnoseContract() {
    var user = HoogyService.getUser();
    var test = (user.contract || '').length;
    var type = !test ? ADD_PAYMENT_INFO : UPDATE_PAYMENT_INFO;
    var message = service.getSettingsMessages(type);
    message.id = [message.type, message.category, message.context].join(':').toLowerCase();
    StateService.dispatch(ActionCreator.diagnoseContract(message));
    return message;
  }

  function diagnoseContacts() {
    var user = HoogyService.getUser();
    var test = _.size(user.contact);
    var type = !test ? ADD_CONTRACT : UPDATE_CONTRACT;
    var message = service.getSettingsMessages(type);
    message.id = [message.type, message.category, message.context].join(':').toLowerCase();
    StateService.dispatch(ActionCreator.diagnoseContacts(message));
    return message;
  }

  function diagnoseFollowers() {
    var user = HoogyService.getUser();
    var test = _.size(user.followers);
    var type = !test ? ADD_PAYMENT_INFO : UPDATE_PAYMENT_INFO;
    var message = service.getSettingsMessages(type);
    message.id = [message.type, message.category, message.context].join(':').toLowerCase();
    StateService.dispatch(ActionCreator.diagnoseFollowers(message));
    return message;
  }

  /**
   * @name diagnoseFavorites
   */
  function diagnoseFavorites() {
    var user = HoogyService.getUser();
    var test = _.size(user.favorites);
    var type = !test ? ADD_PAYMENT_INFO : UPDATE_PAYMENT_INFO;
    var message = service.getSettingsMessages(type);
    message.id = [message.type, message.category, message.context].join(':').toLowerCase();
    StateService.dispatch(ActionCreator.diagnoseFavorites(message));
    return message;
  }

  /**
   * @todo --- rethink this function since unread is being handled at MessengerService:updateUnread
   * @name diagnoseMessages - User has unread messages, this function is responsible to report those cases.
   * @return {Object} 
   */
  function diagnoseMessages(response) {

    var unique = [];
    var msg;
    var user = HoogyService.getUser();
    var messages = user.unread || [];
    if (response && !messages.length) {
      messages = response.data || response || [];
      if (!_.isArray(messages)) messages = [messages];
    }

    if (!response && !messages.length) {
      return false;
    }

    _.each(messages || [], function (message) {
      if (!message) {
        return;
      }
      msg = message.conversation;
      if (!_.isString(message.conversation) && (message.conversation || {})._id) {
        msg = message.conversation._id;
      }
      if (unique.indexOf(msg) === -1) {
        unique.push(msg);
      }
    });
    StateService.dispatch(ActionCreator.diagnoseMessages(unique));
    user.unread = unique || [];
    if (response) {
      return response;
    }
    return unique;
  }

  /**
   * @name getSettingsMesasges - utility function to find the right message.
   * Return settings messages.
   * Search similar type, when Options.type is passed
   * @param  {String|Optional} type - search tag when passed
   * @return {Array|Object} settings messages or one message
   */
  function getSettingsMessages(type) {
    if (!type) {
      return settings;
    }
    return settings.filter(function (i) {
      return i.type === type;
    })[0] || {};
  }



}
DiagnoseService.$inject = ['StateService', 'ActionCreator', 'HoogyService'];
angular.module('hoogy').factory('DiagnoseService', DiagnoseService);