'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/ 

    
/**
 * @name ReviewsWidgetController - Listing available + more 
 */
function ReviewsWidgetController($log, $state, Angularytics, HoogyService, ReviewService, StateService, NotificationService) {
    
    var SIZE = 10;//10 elements per page.
    var vm = this;


    /**
     * @name $onInit - Initialization of Review Widget.
     */
    this.$onInit = function(){
        this.model = ReviewService.getModel();
        this.model.review = this.reviewModel;
        this.model.reviews = this.reviews; 
        this.unsubscribe = this.subscribe();
        this.init(); 
    };    

    /**
     * @name subscribe - Subscription to Reviews Service. 
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = ReviewService.getModel();
            vm.model.more = vm.model.reviews.length > SIZE;
            vm.model.notifications = NotificationService.getModel();             
        });
    };

    /**
     * @name _handleException -  Error handler --- display errors. 
     * @param {Object<Error>} error - Error Object
     */
    this._handleException = function(error) {
        var notice = NotificationService.formatReviewsFailureMessage(error);
        NotificationService.report(notice);
        return error; 
    }

    /**
     * @name init - Initialization of ReviewsWidget.
     * @param {Object<Params>} params
     */
    this.init = function (params) {
        var options = params || vm.params || $state.params;
       
        return ReviewService.getReviews(options).then(function (response) {
                vm.model = ReviewService.getModel();
                vm.model.more = vm.model.reviews.length > SIZE;
                Angularytics.trackEvent('Workspace Review - Success', +1);
                $log.log('WorkspaceController---review');
                return response;
            }).catch(this._handleException);
    };

    /**
     * @name more - More Elements. 
     */
    this.more = function () {
        //@todo navigate available reviews and ask for more if end reached.
    };
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };

}
ReviewsWidgetController.$inject = ['$log', '$state', 'Angularytics', 'HoogyService', "ReviewService", "StateService", "NotificationService"];



/**
 * @requires {Object:Review}
 * @param Rieview.reviewee - Person being reviewed
 * @param Review.reviewer - Currently connected person
 * @param Review.Order - Available Only if reviewing an order
 * @param Review.candidates - Available to autocomplete suggestions/recommendations
 * @requires {Array:Reviews} - Previous reviews from other people.
 * @type {Array}
 */
HoogyReviewController.$inject = ['$log', '$auth', 'HoogyService', 'NotificationService', 'Angularytics','StateService', 'ReviewService'];
function HoogyReviewController($log, $auth, HoogyService, NotificationService, Angularytics, StateService, ReviewService) {
    var vm = this;
    var _candidate;
    
    /**
     * @name $onInit 
     */
    this.$onInit = function(){
        this.model = ReviewService.getModel();
        this.model.user = HoogyService.getUser();
        this.unsubscribe = this.subscribe();
        this.init(); 
    };

    /**
     * @name subscribe - Subscription to Reviews Service. 
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = ReviewService.getModel();
            vm.model.user = HoogyService.getUser();
            vm.model.notifications = NotificationService.getModel(); 
        });
    };

    /**
     * @name _handleException - Exception Handler
     * @param {Object<Error>} error  
     */
    this._handleException = function(error) {
        var notice = NotificationService.formatReviewFailureMessage(error);
        NotificationService.report(notice);
        return error; 
    };

    /**
     * @name init - initilization of review 
     */
    this.init = function () {
        this.model.reviews = this.reviews || [];
        this.model.review = this.reviewModel || {};
        this.model.reviewer = (this.model.review || {}).reviewer || HoogyService.getUser();
        this.model.reviewee = (this.model.review || {}).reviewee || {};
        this.model.candidates = (this.model.review || {}).recomendations || [];

        if(!this.model.review) this.model.review = {};
        if(!this.model.order) this.model.order = {};
        if(!this.model.reviews) this.model.reviews = [];
        if(!this.model.candidates) this.model.candidates = [];
        if(!this.model.recommendations) this.model.recommendations = [];
    };

    /**
     * @name save - Save Form Function
     * @param {Object<FormController>} form 
     * @todo --- add recommendations: invite + message???
     * @todo --- change reviewing concept: user doesn't have to invite.
     * This function
     * 	- double check form validation
     * 	- submits the review to the server.
     * 	- on success, it updates the model.
     */
    this.save = function (form) {
        if (!form || !form.$dirty || !form.$valid) {
            $log.log('HoogyReviewController::form::Invalid');
            throw  'Review Form Should be Available and Valid';
        }
        /**
         * - The review.add will be used on server side,
         * 		to determine if we delete or add
         */
        if (this.model && this.model.query) {
            this.model.review.add = this.model.query;
        }
        return ReviewService.postReview(this.model.review).then(function (response) {
                vm.model = ReviewService.getModel();
                $log.log('HoogyReviewController::add');
                return response;
            })
            .catch(this._handleException);
    };
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };
}

angular
    .module('hoogy')
    .component('reviewWidget', {
        bindings: {reviews: '=?', reviewModel: '=', params: '=?'},
        controllerAs: 'vm',
        controller: HoogyReviewController,
        templateUrl: 'js/common/components/view/review.html'
    })
    .component('reviewsWidget', {
        bindings: {reviews: '='},
        controllerAs: 'vm',
        controller: ReviewsWidgetController,
        template: ['<div class="reviews" ng-init="vm.init()">',
            '<review-widget class="review-editor" review-model="vm.model.review"></review-widget>',
            '<div class="review-list">',
            '<hoogy-review class="review-wrapper" model="vm.model.review"  reviews="vm.model.reviews" ></hoogy-review>',
            '<div class="more btn more btn-default" ng-if="vm.model.more" ng-click="vm.more()">more ...</div>',
            '</div>',
            '</div>'].join('')
    })
    .component('hoogyReview', {
        controllerAs: 'vm',
        controller: HoogyReviewController,
        bindings: {reviews: '=?', reviewModel: '='},
        template: [
            '<div class="review">',
            '<hoogy-emprofile class="review-profile" person="vm.model.review.reviewer"></hoogy-emprofile>',
            '<div class="review-note" ng-bind="vm.model.review.note"></div>',
            '</div>'].join('')
    });