'use strict';

function ContactSettingsController(HoogyService, NotificationService, PeopleService, UIService, StateService, ActionCreator, Angularytics) {
    var vm = this;

    
    /**
     * @name $onInit 
     */
    this.$onInit = function () {
        
        this.model = PeopleService.getMe();
        this.model = Object.assign({},this.model || {}, HoogyService.getUser());
        this.screen = UIService.getSettingsScreen({component: 'contact:settings'});
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        this.init();
    };

    /**
     * @name subscribe - Subscribe to PeopleService
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function () {
            vm.model = PeopleService.getMe();
            vm.model.notifications = NotificationService.getModel();                            
            vm.screen = UIService.getSettingsScreen(vm.screen);
        });
    };

    /**
     * @name init 
     */
    this.init = function () {
        //@todo --- Get Contacts associated to current user.
    };

   
    /**
    * @param response 
    * @name _settingsRequestCallback - Callback on settings update success.
    * @return {Promise}
    */
    this._settingsRequestCallback = function (response) {
        vm.enableSave = false;
        var message = response.data || response || false;
        var notice = NotificationService.formatSettingsSuccessMessage(message.message || message);
        Angularytics.trackEvent(notice.title, +1);
        NotificationService.notify(notice);
        return response;
    };


    /**
     * @param {Object<Error>} error 
     * @name _handleException - Handling exception around failed request
     */
    this._handleException = function (error) {
        vm.enableSave = false;
        vm.screen.waiting = false;
        var notice = NotificationService.formatSettingsFailureMessage(error);
        NotificationService.report(notice);
        Angularytics.trackEvent('Settings Exception ', notice.message);
        return error;
    };

    /**
     * @param {Object<FormController>}
     * @name save - Saves a Form 
     * @todo all settings will be sent to server using this function
     * @todo use HoogyService::setSettings([options], next);
     */
    this.save = function (form) {
        var settings = {action: form.$name, data: this.model.contact};
        if (!form || !form.$valid || form.$invalid) {
            throw 'Settings Form Required';
        }
        return HoogyService.updateSettings(settings)
            .then(this._settingsRequestCallback)
            .catch(this._handleException);

    };

    /**
     * @name reset - resets a form. 
     * @uses WalletSettingsController::init 
     * @return {void}
     */
    this.reset = function(form) {
        if (!form || typeof form.$setPristine !== 'function') {
            throw 'Reset Requires a Form.';
        }
        Angularytics.trackEvent('Settings Reset', +1);
        form.$setPristine();
    };

    
    /**
     * @name getGoogleContacts
     */
    this.getGoogleContacts = function () {
        throw 'Import google data is not defined yet';
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };

}
ContactSettingsController.$inject = ['HoogyService', 'NotificationService', 'PeopleService', 'UIService', 'StateService', 'ActionCreator', 'Angularytics'];

/**
 * Contact Settings Editing.
 */
angular.module('hoogy').component('contactSettings', {
    controllerAs: 'vm',
    templateUrl: 'js/settings/view/contact.html',
    controller: ContactSettingsController
});