'use strict';

function AddStuffController($state, $location, HoogyService, NotificationService, AuthService, Angularytics, UIService, StateService, ActionCreator, StuffService) {

    var vm = this;
   
    
    /**
     * @name $onInit 
     */
    this.$onInit = function(){
        this.model = StuffService.getModel();
        this.screen = UIService.getAddStuffScreen({component: 'store:addstuff'}); 
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        this.init();
    };

    /**
     * @name subscribe - Subscribes for Model Updates.
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = StuffService.getModel();
            vm.screen = UIService.getWorkspaceCollectionScreen(vm.screen);
            vm.model.notifications = NotificationService.getModel();                                                 
        });
    };



    this.isAuthenticated = function () {
        return AuthService.isAuthenticated(true);
    };

    /**
     * @name init - this function is called to initialize form element.
     * Editing - Item
     */
    this.init = function (options) {
        var params = options || $state.params;    
        this.status = {}; 
        Angularytics.trackEvent('Store Item Adder Initialization', +1);

        
        if (!this.isAuthenticated()) {
            Angularytics.trackEvent('Store Item Adder Init - unauthorized', +1);
            return false;
        }
        if (params && !params.itemid && params.id) { params.itemid = params.id; }
        if (!params || !params.itemid) { return false;/*throw 'Item ID missing exception'*/ }
        Angularytics.trackEvent('Store Item Adder - Edit', +1);

        this.model.item = { _id: params.itemid };
        this.screen.waiting = true;
        var notice = { message: 'File uploaded!', type: NotificationService.SUCCESS};
        return HoogyService.getItem(params.itemid)
            .then(function (response) {
                vm.screen.waiting = false;     
                vm.model.item = HoogyService.inventoryItem || {};
                StateService.dispatch(ActionCreator.addStuff(vm.model.item));
                NotificationService.notify(notice);
                Angularytics.trackEvent('Store Item Adder - Edit', vm.model.item.title);
                return response;
            }).catch(this._handleException);
    };

    /**
     * @name drop - function that receives and syncs photo with server. 
     * @param {Object} data - payload from Image source(HD or Camera)
     * This function handles data dropped in an area defined by droppableDirective
     * This section couples, and limits the overall usability of this controller.
     * it is a quick fix ( huh ), but should be refactored
     */
    this.payload = {};
    this.drop = this.select = function (data) {
        if (!data) { throw '@AddStuff::drop Requires File to be Dropped.'; }

        data.owner = HoogyService.getUserId();
        if (this.model.item && this.model.item._id) {
            data.itemid = this.model.item._id || this.model.item.id;
        }
        Angularytics.trackEvent('Store Item Adder -Select/Drop', +1);

        this.screen.waiting = true;
        return HoogyService.sync(data).then(function (response) {
            var item = response.data || response;
            vm.screen.waiting = false;
            vm.model.item = HoogyService.inventoryItem || response.data || response || {};
            vm.model.item.photo = vm.model.item.photo || data.payload;
            StateService.dispatch(ActionCreator.addStuff(vm.model.item));            
            NotificationService.notify({ message: 'File uploaded!', type: NotificationService.SUCCESS });
            Angularytics.trackEvent('Store Item Adder - Uploaded Image', +1);
            return response;
        }).catch(this._handleException);
    };

    /**
     * @param {Object<FormController|Item>}
     * @name save - Saving the Add Stuff Form - Can also be used as a callback for Upload.
     */
    this.save = function (form) {
        //Detecting Callback with Item Object. 
        var whitelist = ['_id', 'payload', 'photo', 'pricing', 'price'];
        var markers = Object.keys(form || {}).filter(function(key){ return whitelist.indexOf(key) >= 0;});
        if(vm.model && form && whitelist.length === markers.length) vm.model.item = form;
        if (!form || !form.$valid || form.$invalid) {
            if(_.isEmpty((vm.model || {}).item)){
                vm.screen.waiting = false;
                throw 'Item form should be valid';
            }
        }else if(_.isEmpty((vm.model || {}).item)){
            vm.screen.waiting = false;
            throw 'Item form should be valid';
        }

        if (!vm.isAuthenticated()) {
            Angularytics.trackEvent('Store Item Adder - Unauthorized', +1);
            throw 'You should be authenticated to save an item';
        }

        //stop double clicks while waiting for response
        if (vm.screen.waiting) {
            Angularytics.trackEvent('Store Item Adder - Save Invalid', +1);
            return false;
        }

        vm.screen.waiting = true;
        Angularytics.trackEvent('Store Item Adder - Save', +1);
        return HoogyService.saveItem(vm.model.item)
            .then(function (response) {
                vm.screen.waiting = false;
                vm.model.item = HoogyService.inventoryItem || response.data || response || {};
                StateService.dispatch(ActionCreator.addStuff(vm.model.item));            
                NotificationService.notify({ message: 'Item created.' });              
                Angularytics.trackEvent('Store Item Adder - Save Success', vm.model.item.title);
                $state.go('workspace.notify', { itemid: vm.model.item._id });
                return response;
            }).catch(vm._handleException);
    };



    /**
     * @name remove - function used to delete an item.
     * @required {Component} HoogyItem 
     * This function removes cancels, and deletes an item in current edit mode.
     * It Works with model.equipment instead of model.item
     */
    this.remove = function (item) {
        /**
         * Checking passed in item. 
         */
        if(_.isEmpty(vm.model.item) && item && item.id) {
            vm.model.item = item;
        }
        Angularytics.trackEvent('Store Item Adder', +1);
        return HoogyService.removeItem(vm.model.item).then(function (response) {
            var msg = {
                message: response.message || 'Item removal done!'
            };
            NotificationService.notify(msg);
            Angularytics.trackEvent('Store Item Adder - Remove', +1);
            return response;
        });
    };

    /**
     * @name closeItemEditor - Closing The Item Editor.
     */
    this.closeItemEditor = function () {
        this.model.item = {};
        this.status.waiting = false;
        this.screen.waiting = false;
        this.status.path = vm.status.path || '/store';
        Angularytics.trackEvent('Exit Item Adder - Close Item Editor');
        $location.path(this.status.path);
    };




    //utility to handle exceptions from server.
    /**@link http://jimhoskins.com/2012/12/17/angularjs-and-apply.html*/
    this._handleException = function (error) {
        vm.screen.waiting = false;
        var notice = NotificationService.formatStoreFailureMessage(error);
        NotificationService.report(notice);         
        Angularytics.trackEvent('Store Item Adder Exception', notice.message);
        return error;
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };


}
AddStuffController.$inject = ['$state','$location', 'HoogyService', 'NotificationService', 'AuthService', 'Angularytics', 'UIService', 'StateService', 'ActionCreator', 'StuffService'];
angular.module('hoogy').component('addStuff', {
    controllerAs: 'vm',
    controller: AddStuffController,
    templateUrl: 'js/stuff/view/add.html'
});