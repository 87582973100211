'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/

/**
 * @todo implement all functions to saveItem, upload media etc
 */
function HoogyAdderController($location, $log, HoogyService, NotificationService, CheckoutService, Angularytics) {

    var vm = this;

    /**
     * @name $onInit - Initialization Handler 
     */
    this.$onInit = function () {
        this.model = {};
        this.MISSING_PHOTO_EXCEPTION = 'Missing Photo Payload Exception';
        this.enableSave = false;
        this.gravatar = HoogyService.gravatar;
        this.init();
    };

    

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        vm.dropData = '';
        vm.fileData = {};
    };

    /**
     * @name init - initialization of editable stuff. 
     */
    this.init = function () {
        if (this.item && !this.model.item) {
            this.model.item = this.item;
        }

        if (this.model && (this.model.id || this.model._id)) {
            this.enableSave = true;
        }
    };


    this.close = function () {
        this.status.waiting = false;
        Angularytics.trackEvent('Exit Item Add');
        $location.path(vm.status.path || '/store');
    };
    /**
     * @name save - Uses the parent/inherited function
     *            - The Form is not required for the adder. 
     * @param {Object<FormController>} form - Form Parameter
     */
    this.save = function (form) {
        if (!vm.item) throw '@HoogyAdder::save item Form is Required';
        if (!vm.model.item || !vm.model.item._id) throw '@HoogyAdder::save item is Required';
        return vm.saveItem({
            item: vm.model.item
        });
    };
    /**
     * @name remove - function to delete an item.
     *              - function is not used for the moment. 
     */
    this.remove = function () {
        if (!vm.model.item || !vm.model.item._id) throw '@HoogyAdder::save item is Required';
        return vm.removeItem({
            item: vm.model.item
        });
    };

    /**
     * @property dropData - data used to drop a picture.
     */
    this.dropData = '';

    /**
     * @name dropHandler - This function handles data dropped in an area defined by droppableDirective
     * @returns {Object<Promise>}
     */
    this.dropHandler = function (data) {
        if (!data || !data.payload) throw vm.MISSING_PHOTO_EXCEPTION;
        vm.enableSave = false;
        data.owner = HoogyService.getUserId();
        HoogyService.upload(data).then(function (response) {
            var item = response.data || response;
            vm.model.item = Object.assign({},data, item);
            NotificationService.notify({
                message: 'File uploaded',
                type: NotificationService.SUCCESS
            });
            $log.log(' Adder - received response');
            vm.enableSave = true;
        });
    };

    /**
     * This section couples, and limits the overall usability of this controller.
     * it is a quick fix ( huh ), but should be refactored
     */
    this.fileData = {};
    this.fileSelectorHandler = function (data) {
        if (!data || !data.payload) throw vm.MISSING_PHOTO_EXCEPTION;
        $log.log('vm.select::data');
        if (!(data.payload.length > 0)) throw vm.MISSING_PHOTO_EXCEPTION;
        vm.enableSave = false;
        data.owner = HoogyService.getUserId();
        HoogyService.upload(data).then(function (response) {
            var item = response.data || response; 
            vm.model.item = Object.assign({},data, item);
            NotificationService.notify({
                message: 'File uploaded!',
                type: NotificationService.SUCCESS
            });
            vm.enableSave = true;
        });

    };
    return vm;
}
HoogyAdderController.$inject = ['$location', '$log', 'HoogyService', 'NotificationService', 'CheckoutService', 'Angularytics'];

/**
 * This directive will be used to add a new item.
 * It may also serve to edit a page sometime later
 */
angular.module('hoogy').component('hoogyAdder', {
    controllerAs: 'vm',
    controller: HoogyAdderController,
    templateUrl: 'js/common/components/view/adder.html',
    bindings: {
        item: '=',
        status: '=',
        saveItem: '&',
        removeItem: '&'
    }
});