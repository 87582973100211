'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
//@link https://github.com/mattboldt/typed.js

/**
 * @todo --- refactor this directive to make sure all $scope related parameters are removed
 * The controller does what ???
 * @param $scope
 */
function HoogyDateTimeController(dateFilter) {
    var _date = new Date();
    this.date = { thing: _date, formatted: dateFilter(_date, 'dd MMMM'), calculated: _date };
}
HoogyDateTimeController.$inject = ['dateFilter'];


/**
* This directive is a bridge between jquery.datetimepicker.js and current angularjs application.
* JQuery Plugin documentation is found here:
* @link http://xdsoft.net/jqplugins/datetimepicker/
* @link https://github.com/xdan/datetimepicker
* @link http://stackoverflow.com/questions/18144142/jquery-ui-datepicker-with-angularjs
* dateFilter uses the format described at the following link
* @link https://docs.angularjs.org/api/ng/filter/date
*/
HoogyDatetime.$inject = ['$log', 'dateFilter'];
function HoogyDatetime($log, dateFilter) {
    /**
     * @link http://amsul.ca/pickadate.js/date/#formats
     * @type {string} format - This variable will be used in this program to display the date
     */

    /**
     * @todo onChangeDateTime ratains a big chunk of data in memory. 
     * @todo $scope.$on($destroy);
     * .replace(/\s/g, ".")
     * || ".datepicker"
     * @param $scope
     * @param element
     * @param attrs
     * @param controler
     */
    function link($scope, element, attrs, ctrl) {
        var dtpicker = null;
        function onChange(thing) {
            $scope.$apply(function () {
                ctrl.date = {thing: thing, formatted: dateFilter(thing, 'dd MMMM'), calculated: thing };
                if(typeof ctrl.next === 'function'){ctrl.next({date: ctrl.date});}
                else if(typeof $scope.next === 'function'){$scope.next({date: ctrl.date});}    
                $log.log('Date - Object :: onSet ');
            });
        };

        //element is a jquery object
        if(!dtpicker){
            dtpicker = element.datetimepicker({onChangeDateTime: onChange, onShow: onChange});
        }
       

        /**
         * Cleanup - try to destroy memory leak substible 
         */
        function cleanup() {
            //if (angular.isElement(this)) { return $scope.$destroy(); }
            if(dtpicker && typeof dtpicker.remove === 'function'){dtpicker.remove();  }
            //@link http://xdsoft.net/jqplugins/datetimepicker/#reset
            if (element && element.datetimepicker) { element.datetimepicker('destroy'); }
            if (typeof angular.element(element).off === 'function') { angular.element(element).off(); }
            if (element && typeof $(element).off === 'function') {
                $(element).off();
            }
            if (element && typeof element.off === 'function') { element.off(); }
            //can leave un cleaned element behind.
            dtpicker = null;

        }
        //$scope.$on('$destroy', cleanup);
        //element.on('$destroy', cleanup);
    }

    /**
     * //date: '=', 
     * @todo - remove the template - we don't need any template
     * @param <a href='#'> {{ date || 'Date' }}</a>
     */
    return {
        link: link,
        replace: true,
        restrict: 'EA',
        controllerAs: 'vm',
        bindToController: true,
        scope: { next:'&' },
        controller: HoogyDateTimeController,
        template: '<span class=\'inline-datetime-selector\' ng-bind="vm.date.formatted"></span>'
    };
}
angular.module('hoogy').directive('hoogyDatetime', HoogyDatetime);
