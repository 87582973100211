'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/

/**
 * @todo replace SearchService with PeopleService + Handle search the way PeopleService does it.
* @name HoogyPeersController - Search Directive
* [HoogySearch description] '$filter', '$state', '$log', 'HoogyService'
* The controller for message directive
* [HoogyMessageController description]
*/
HoogyPeersController.$inject = ['$log', 'HoogyService', 'SearchService', 'UIService', 'PeopleService', 'NotificationService', 'StateService', 'ActionCreator', 'Angularytics'];
function HoogyPeersController($log, HoogyService, SearchService, UIService, PeopleService, NotificationService, StateService, ActionCreator, Angularytics) {

    var vm = this;
    this.SIZE = 10;
    
    
    /**
     * @name _omnisearchPeopleHandler
     * @return {Object<Response>} response
     */
    this._omnisearchPeopleHandler = function (response) {
        vm.screen.step = 0; //Re-Initialization of Pagination Page.
        vm.screen.waiting = false;
        //@todo use PeopleService.getModel() instead of SearchService.
        vm.model = SearchService.getModel();
        //vm.model.population = _.chain(vm.model.people).slice(vm.screen.step * vm.SIZE).take(vm.SIZE).value() || vm.model.population;
        var start = this.screen.step * this.SIZE;
        vm.model.population = Object.values(vm.model.people || {}).filter((usr, index) => index >= start && index < start + vm.SIZE);
        //Display the next button. 
        vm.screen.more = _.size(vm.model.people) > _.size(vm.model.population);
        vm.screen.notifications = NotificationService.getModel();       
        if(typeof vm.next === 'function'){
            vm.next({screen: vm.screen});
        }
        return response;
    };
    
    /**
     * @uses this.init 
     * @uses PeopleService.AVATAR
     * @uses UIServicePeers:getPeersScreen
     * @name $onInit - Initialization of HoogyPeersController.
     */
    this.$onInit = function(){
        this.url = PeopleService.AVATAR;
        this.screen = UIService.getPeersScreen({component: 'peers'});

        this.screen.step = 0;
        this.screen.search.contact = false;
        this.unsubscribe = this.subscribe();
        this.init(); 
    };

    /**
     * @name subscribe - subscribes to Search Response. 
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm._omnisearchPeopleHandler(false);            
        });
    };

    /**
     * @name init - initialization of the screen.
     * @uses search - Searching 
     */
    this.init = function () {
        this.model = Object.assign({}, PeopleService.getModel());
        //this.model.population = []; 
        //by default, search people | if 1 => Check Contacts
        this.screen.search.contact = false;
        //Initialization with initial search
        this.search();
    };

    /**
    * @name more - Function used to search from last person|result
    * @param  {Object} last 
    * @return {Object}      
    */
    this.more = function () {
        var _last;
        this.screen.active = 'contact';
        //cannot detect this when used from html
        if(vm.screen && vm.screen.search && !vm.screen.search.contact)  this.screen.active = 'people';
        var starting = this.screen.next || this.SIZE;
            this.screen.next = (this.screen.step * this.SIZE);
        var ending = this.screen.next;

        //Object.values(vm.model.population).slice(starting, ending);
        var start = this.screen.step * this.SIZE;
        this.model.population = Object.values(vm.model.people || {}).filter((usr, index) => index >= start && index < start + this.SIZE );
        this.screen.step = this.screen.step + 1;
        //this.model.population = _(this.model.people).chain().slice(this.screen.step * this.SIZE).take(this.SIZE).value();
        if (!_.size(this.model.population)){
            this.screen.next = 0;
            this.screen.step = 0;
            _last = this.model.population[_.size(this.model.population) - 1] || {};
            this.screen.search.starting = _last._id || _last.id;
            this.search(this.screen.search);
        }
    };

    /**
      * @name invite - Rebranded to send invitations
      * @return {Promise}
      */
    this.invite = function (person) {
        var params = Object.assign({}, person);
        if(params.person){ params = params.person; }
        if (_.isEmpty(params)) throw 'Invite Requires a Person to Send Invitation To.';
        return HoogyService.inviteAlt(params).then(function (response) {
            $log.log('Peers::Invite::Response', response);
            Angularytics.trackEvent('Peers Invite - Sent - Success', +1);
            return response;
        }).catch(function (error) {
                $log.log('Peers::Invite::Response', error);
                NotificationService.report(error);
                Angularytics.trackEvent('Peers Invite - Sent - Error', +1);
                return error;
            });
    };

    /**
     * @todo -- should use PeopleService:search instead of SearchService.omnisearch
      * @todo Search Function has to be adapted to new reality:
      *     - Search for Contacts + People.
      */
    this.search = function (subject) {
        this.screen.waiting = true;
        this.screen.active = 'people';
        this.screen.search.action = 'people';
        this.screen.search.what = 'people';
        if (this.screen.search.contact === true) {
            this.screen.search.action = 'contact';
            this.screen.search.what = 'contact';
            this.screen.active = 'contact';
        }

        SearchService.omnisearch(this.screen.search)
            .then(this._omnisearchPeopleHandler)
            .catch(this._handleException);
    };

    this.removeInvitable = function () { throw 'PeopleController::removeInvitable is not implemented'; };

    /**
    * @name removContact - This function deletes contact from contacts.
    * @param  {Object} contact 
    * @return {Object}         */
    this.removeContact = function (contact) {
        if (_.isEmpty(contact)) throw 'Contact to remove is required';
        return PeopleService.removeContact(contact).then(function (response) {
            // @todo --- use PeopleService.getContacts() + PeopleService.getContact()
            //@todo test if this really removes the contact
            vm.model.contacts = _.without(vm.model.contacts, contact);
            vm.model.contact = response.contact || response;
            // @todo --- use PeopleService.getMessage()  
            vm.model.message = response.message || false;//check if message invitation sent
            return response;
        });
    };



    /**
     * @name _handleException - handles exception upon search error.
     */
    this._handleException = function (error, status) {
        vm.enableSave = false;
        var notice = NotificationService.formatPeersRequestException(error);
        NotificationService.report(notice);
        return error;
    };

    /**
     * @name onSearchContactChange - After checking send a default search.
     * @param {Object<Event>} event 
     */
    this.onSeachContactChange = function (event) {
            if(typeof vm.next === 'function'){
                return vm.next({screen: vm.screen});
            }
            vm.search();
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };

}

/**
* Peers directive is a component that will be used together with messenger module.
* Peers gives ability to search for contacts, load more contacts, and click-to-talk
* @uses hoogy-search directive
* @uses MessengerService
* @uses hoogy-person ( probably )
* @uses SocketIo ( to listen to connected users, etc )
*/

angular.module('hoogy').component('hoogyPeers', {
    controllerAs: 'vm',
    controller: HoogyPeersController,
    bindings: { next: '&?'},
    templateUrl: 'js/common/components/view/peers.html'
});