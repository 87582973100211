'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/ 

/*global Redux, angular, Stripe, AppConfig, moment, _ */
function SearchService($filter, $log, HoogyService, StateService, ActionCreator) {
    /**
     * @param person - capture temporal user. 
     * @param user - currently connected user. 
     * @todo move saerch related functionality to this new service. 
     */
    var model = {
        order: {},
        person: {},
        unread: [],
        items: [],
        people: [],
        orders: [],
        wishlist: [],
        messages: [],
        discover: [],
        cashflow: [],
        contacts: [],
        inventory: [],
        invitables: [],
        collection: [],
        collections: [],
        transactions: [], 

        //population is used to filter current collection of items.
        population: []
    };

    /**
     * Service 
     */
    var service = {
        omnisearch: search,
        getModel: getModel,
        lookupOrder: lookupOrder 
    };

    return service;




    /**
     * @return {Object} model 
     * @name getModel - Model Getter helper 
     */
    function getModel(which) {
        var results = StateService.getState().results;
        var state = Object.assign({}, model, {
            order: results.order || model.order,
            person: results.person || model.person,
            unread: results.entities.unread || model.unread,
            items: results.entities.items || model.items,
            people:  results.entities.people || model.people,
            orders:  results.entities.orders || model.orders,
            wishlist:  results.entities.wishlist || model.wishlist,
            messages:  results.entities.messages || model.messages,
            discover:  results.entities.discover || model.discover,
            cashflow:  results.entities.cashflow || model.cashflow,
            contacts:  results.entities.contacts || model.contacts,
            inventory:  results.entities.inventory || model.inventory,
            invitables:  results.entities.invitables || model.invitables,
            collection:  results.entities.collection || model.collection,
            collections:  results.entities.collections || model.collections,
            transactions:  results.entities.transactions || model.transactions
        });

        return state[which] || state;
    }

    /**
     * @name _formatOrders - Format Orders 
     * Formats Order Listing 
     * Formatted order should be an inplace editing 
     */
    function _formatOrders(collection) {
        var user = HoogyService.getUser();
        var userId = (user._id || user.id || '').toString();
        _.each(collection, function (order) {
            if (!order.item) { order.item = {}; }
            order.item.photo = HoogyService.normalize(order.item.photo || order.item.picture);
            order.isOwner = false;
            order.isRenter = false;
            if (order.renter && order.renter._id) {
                order.isRenter = order.renter._id.toString() === userId;
            }
            if (order.owner && order.owner._id) {
                order.isOwner = order.owner._id.toString() === userId;
            }
        });
        return collection;
    }
    /**
     * @name _formatItems - Format Items 
     */
    function _formatItems(collection) {
        var user = HoogyService.getUser();
        return collection;
    }
    /**
     * @name _formatDiscover - Format Discover 
     */
    function _formatDiscover(collection) {
        var user = HoogyService.getUser();
        return collection;
    }
    /**
     * @name _formatCollection - Formats User collection
     */
    function _formatCollection(collection) {
        var user = HoogyService.getUser();
        var _likes = _.filter(user.wishlist || [], function (w) { return w._id || w; });
        var owner;
        return _.map(collection.inventory || collection || [], function (i) {
            i.photo = HoogyService.normalize(i.photo);
            if (i._id && _likes.indexOf(i._id) > -1) {
                i.loveit = true;
            }
            if ((i.owner || {})._id && !user._id) {
                //@todo -- rebug this and understand what this code does.
                owner = HoogyService.formatUser(Object.assign({}, i.owner,{
                    incoming: (i.owner || {}).incoming,
                    outgoing: (i.owner || {}).outgoing
                }));
            }
            return i;

        }) || collection || [];
    }
    /**
     * @name _formatInventory - Format Inventory 
     */
    function _formatInventory(collection) {
        var user = HoogyService.getUser();
        return _.map(collection.inventory || collection[0].inventory || collection || [],
            function (item) {
                item.photo = HoogyService.normalize(item.photo);
                $log.log('omnisearch::inventory');
                return item;
            }) || collection || [];
    }

    /**
     * @name _formatPeople - Format People 
     */
    function _formatPeople(collection) {
        var user = HoogyService.getUser();
        var _collection = collection.data || collection;
        if( _collection && _collection.contacts){
            _collection = _collection.contacts; 
        }
        return _.map(_collection || [], function (person) {
            person = HoogyService.formatUser(person);
            person.photo = HoogyService.normalize(person.photo || HoogyService.gravatar);
            return person;
        }) || collection || [];
    }

    /**
     * @name _formatContacts - Format Contacts 
     */
    function _formatContacts(collection) {
        var user = HoogyService.getUser();
         var _collection = collection.data || collection;
        if(_collection[0] && _collection[0].contacts){
            _collection = _collection[0].contacts;
        }
        if( _collection && _collection.contacts){
            _collection = _collection.contacts; 
        }
        
        /**resetting Omni-search data structure*/
        return _.map(_collection, function (person) {
            person = HoogyService.formatUser(person);
            person.photo = HoogyService.normalize(person.photo || HoogyService.gravatar);
            return person;
        }) || collection || [];
    }
    /**
     * @name _formatCashflow - Format Cashflow 
     */
    function _formatCashflow(collection) {
        var user = HoogyService.getUser();
        return collection;
    }
    /**
     * @name _formatTransactions - Format Transactions
     */
    function _formatTransactions(collection) {
        var user = HoogyService.getUser();
        return collection;
    }
    /**
     * @name _formatWishlist - Format Wishilist
     */
    function _formatWishlist(collection) {
        var user = HoogyService.getUser();
        var _likes = _.filter(user.wishlist || [], function (w) { return w._id || w; });
        return _.map(collection.wishlist || collection[0].wishlist || collection || [],
            function (item) {
                item.photo = HoogyService.normalize(item.photo);
                return item;
            }) || collection || [];
    }
    /**
     * @name _formatMessages - Format Message
     */
    function _formatMessages(collection) {
        var user = HoogyService.getUser();
        return collection;
    }
    /**
     * @name _formatUnread - Format Unread 
     */
    function _formatUnread(collection) {
        var user = HoogyService.getUser();
        return collection;
    }



    /**
     * Formatting incoming data.
     * @todo apply proper formatting function according to type of haystack
     */
    function format(type, collection) {
        var data;
        switch (type) {
            case 'orders': data = _formatOrders(collection); break;
            case 'items': data = _formatItems(collection); break;
            case 'discover': data = _formatDiscover(collection); break;
            case 'collection': data = _formatCollection(collection); break;
            case 'inventory': data = _formatInventory(collection); break;
            case 'people': data = _formatPeople(collection); break;
            case 'contact': 
            case 'contacts': data = _formatContacts(collection); break;
            case 'cashflow': data = _formatCashflow(collection); break;
            case 'transactions': data = _formatTransactions(collection); break;
            case 'wishlist': data = _formatWishlist(collection); break;
            case 'messages': data = _formatMessages(collection); break;
            case 'unread': data = _formatUnread(collection); break;
            default:  data = collection; break;
        }
        return data;
    }


    /**
     * @name lookupOrder - Lookup one Order based on params.id 
     * @param {String|Number} options.id - Order Id. 
     * @return {Promise} order 
     */
    function lookupOrder(options){
        var params = options || {};
        var id = params.id || params._id || params.order || false;
        if(id.id || id._id){ id = id.id || id._id || id; }
        if(!id || id.length <= 0 ){
            throw 'Lookup Order is not found';
        }
        return HoogyService.lookupOrder({id: id});
    }


    /**
     * @todo identify needle
     * @todo identiy right callback and HoogyService::[variable] to set.
     * @param  {String|Object} params.needle - what to search
     * @return {String|Object} params.haystack - where(collection) to search from
     */
    function search(options) {
        var params = Object.assign({}, options);
        
        if(params && !params.action && params.what){
            params.action = params.what;
        }
        if (!params.text || _.isEmpty(params.text)) {
            params.text = ['#', params.action || params.what].join('');
        }
        
        if (!params.action) {
            throw 'Search action is missing';
        }
        if(!params.text && params.query && _.isString(params.query)){
            params.text = params.query;
        }
        if (!params.needle && params.text) {
            params.needle = params.text;
        }
        if (params.action === 'collection' && !params.owner) {
            throw 'Search for item collections requires an owner';
        }

        var needle = params.needle;
        var haystack = params.action;
        var collection, payload;
        params.tags = params.tags || [];
        return HoogyService.omnisearch(params).then(function (response) {
            collection = model[haystack] = format(haystack, response.data || response);
            HoogyService.model[haystack] = collection;
            payload = {haystack: haystack, collection: collection, response: collection};
            StateService.dispatch(ActionCreator.omnisearch(payload));
            return response;
        });
    }

}
SearchService.$inject = ['$filter', '$log', 'HoogyService', 'StateService', 'ActionCreator'];
angular.module('hoogy').factory('SearchService', SearchService);