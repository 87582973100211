'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * @name Matcher
 * Usage
 *      - Use [form.passwordField.$error.pwdmatch] to track validation matches
 *      - In "matcher", use pwdCheck/for for the current password
 *
 *
 *    <input type="password" id="current" name="current" ng-model="current" ng-required/>
 *    <input type="password" id="verify" name="verify" ng-model="verify" matcher="current" ng-required/>
 *    <span class="error" ng-show="form.$error.pwdmatch"> Error message </span>
 */

function Matcher() {
    function link(scope, element, attrs, ctrl) {
        var current = '#' + (attrs.matcher);
        element.add(current).on('keyup', function () {
            scope.$apply(function () {
                var v = element.val() === $(current).val();
                ctrl.$setValidity('pwdmatch', v);
            });
        });
        /**
         * Cleanup - try to destroy memory leak substible 
         */
        function cleanup() {
            if (angular.isElement(this)) { return scope.$destroy(); }
            if (typeof angular.element(element).off === 'function') { angular.element(element).off(); }
            if (element && typeof $(element).off === 'function') {
                $(element).off();
            }
            if (element && typeof element.off === 'function') { element.off(); }
        }
        scope.$on('$destroy', cleanup);
        element.on('$destroy', cleanup);
    }



    return {
        restrict: 'A',
        require: 'ngModel',
        link: link
    };

}
angular.module('hoogy').directive('matcher', Matcher);
