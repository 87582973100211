'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
function InviteController($log, HoogyService, Angularytics, StateService, ActionCreator) {

    var vm = this;
    this.$onInit = function () { 
        this.model = {};
        StateService.dispatch(ActionCreator.addScreen(this.screen));       
        this.init(); 
    };
    this.init = function () {
        this.model.edit = false;
    };

    this.enableInvite = function () {
        this.model.edit = true;
    };

    this.disableInvite = function () {
        this.model.edit = false;
    };



    this.sendInvite = function (form) {

        if (!form || form.$invalid || !form.$valid) {
            throw 'Invite Requires a Valid Form';
        }
        if (typeof form.$setPristine !== 'function') {
            throw 'Invite Requires Instance of FormController';
        }
        this.model.edit = true;
        Angularytics.trackEvent('Sidebar Invite Clicked ', +1);
        return HoogyService.invite({ email: this.model.email }).then(function (response) {
            form.$setPristine();
            vm.model.email = '';
            vm.model.edit = false;
            Angularytics.trackEvent('Sidebar Invite Succeded ', +1);
            if (typeof vm.next === 'function') {
                vm.next({ response: response.data || response });
            }
            return response;
        }).catch(function (error) {
            form.$setPristine();
            vm.model.edit = false;
            Angularytics.trackEvent('Sidebar Invite Failed ', +1);
            NotificationService.report(error);
            if (typeof vm.next === 'function') {
                vm.next({response: HoogyService.invitesError || error.data});
            }
            return error;
        });
    };


    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
       //@todo --- add anything to re-init variables
    };
}
InviteController.$inject = ['$log', 'HoogyService', 'Angularytics', 'StateService', 'ActionCreator'];

/**
 * @name InvitationsController 
 * @param {Object} $log  
 * @param {Object} HoogyService 
 * @param {Object} Angularytics 
 */
function InvitationsController($log, HoogyService, Angularytics, StateService, ActionCreator) {
    var vm = this; 
    this.model = {};

    /**
     * @name $onInit - Initialization of Invitations
     */
    this.$onInit = function () { 
        this.init(); 
    };

    /**
     * @name init - Re-Initialization 
     */
    this.init = function () {
        this.model.invitations = [];
        this.model.invitation = {};
        this.model.invitations = this.invitations || [];
        if (_.isEmpty(this.model.invitation)) {
            this.model.invitation = this.model.invitations[0] || {};
        }
    };

    /**
     * @name more - Function 
     */
    this.more = function () {
        throw 'InvitationsController::more is not implemented yet';
    };
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        //@todo  - add anything related to re-initialization. 
    };

}
InvitationsController.$inject = ['$log', 'HoogyService', 'Angularytics', 'StateService', 'ActionCreator'];
angular
    .module('hoogy')
    .component('hoogyInvite', {
        controllerAs: 'vm',
        controller: InviteController,
        bindings: { screen: '=', next: '&' },
        templateUrl: 'js/common/components/view/invite.html'
    })
    .component('hoogyInvitation', {
        bindings: { person: '=', invitation: "=" },
        controllerAs: 'vm',
        controller: function () {
            var vm = this;
            this.$onInit = function () { this.init(); };
            this.init = function () {
                this.person = this.person || {};
                this.invitation = this.invitation || {};
            };
            /**
             * @name $onDestroy - cleans objects when components dies.
             */
            this.$onDestroy = function(){
                //@todo --- destroy dangling objects
            };
        },
        template: [
            '<div class="invitation" ng-init="vm.init()">',
            '<i ng-show="vm.invitation.status === \'Accepted\'" class="icon ion-ios-checkmark green"></i>',
            '<span ng-bind="vm.person.email"></span>',
            '<span ng-bind="vm.person.name"></span>',
            '</div>'].join('')
    })
    .component('hoogyInvitations', {
        controllerAs: 'vm',
        bindings: { invitations: '=' },
        controller: InvitationsController,
        template: [
            '<div ng-init="vm.init()" class="invitations-wrapper">',
            '<hoogy-invitation class="invitation-wrapper" ng-repeat="invitation in vm.model.invitations track by $index" person="invitation" invitation="invitation"></hoogy-invitation>',
            '<div class="last-li more-content">',
            '<b class="btn btn-default ion-more" ng-click="vm.more()"></b>',
            '</div>',
            '</div>'].join('')
    })
    .component('hoogySidebarInvite', {
        controllerAs: 'vm',
        controller: InviteController,
        bindings: { screen: '=', next: '&' },
        templateUrl: 'js/common/components/view/sinvite.html'
    });
