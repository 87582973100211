/**
 * @example
 *  <code>
 *  # Used with Discover Component
 *   <discover>
 *      <items-widget screen="vm.screen"/>       
 *   </discover>
 *  
 *  # Used with Collection Component 
 *   <collection> 
 *      <items-widget screen="vm.screen"/>
 *   </collection>   
 *   
 *  # Used with Inventory(ItemEditor) Component
 *  <inventory> 
 *      <item-editor screen="vm.screen"/> 
 *      <items-widget screen="vm.screen"/>
 *  </inventory>   
 *  </code>
 * @name ItemsWidgetController - Used to List Items, It bakes Search + Pagination Components. 
 */
function ItemsWidgetController($state, HoogyService, SearchService, StateService, ActionCreator, NotificationService, Angularytics){
    var vm = this; 
    //configurations parameters for hoogy-tile component
    this.settings = {};
    this.model = {population:[]};
    //displays 10 Items at a time.
    this.SIZE = 10; 

    //variables used with search dropdowns.
    this.DISCOVER = 'discover';
    this.INVENTORY = 'inventory';
    this.WISHLIST = 'wishlist';

    /**
     * @name $onInit - Initialization of Items 
     */
    this.$onInit = function(){
        if(!this.screen) throw '@ItemsWidgetController:$onInit requires Screen Object';
        if(!this.screen.search) throw '@ItemsWidgetController:$onInit requires Search Object';
        this.settings.list = this.screen.list || false;
        this.model = Object.assign({}, SearchService.getModel());
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        this.search(this.screen.search);
    };

    /**
     * @name $onDestroy - Un-registering items.
     */
    this.$onDestroy = function(){
        if(typeof this.unsubscribe === 'function')this.unsubscribe(); 
        this.unsubscribe = null; 
    };

    /**
     * @name _success - Handles Successful Changes on Search.
     */
    this._success = function(response){
        var wishlist = ['inventory','wishlist', 'discover', 'items', 'collection'];

        vm.screen.step = 1;
        vm.screen.waiting = false;
        vm.model = Object.assign({}, SearchService.getModel());
        vm.model.items = Object.values(Object.assign({}, SearchService.getModel(vm.screen.search.what))).filter((i, index) => index >= 0 && index < vm.SIZE );
        
        // Mark Loved Items
        var _likes = Object.values(HoogyService.getUserWishlist() || []).map((w) => w._id || w);
        vm.model.items = vm.model.items.map((it) => Object.assign({}, it, {loveit: _likes.indexOf(it._id) > -1}) );

        //show/hide more button
        vm.screen.more = _.size(SearchService.getModel(vm.screen.search.what)) > vm.SIZE;
        var count = _.size(vm.model.items);
        var message = ['Workspace', vm.screen.action || 'Collection',' - Found Provider (', count, ') Items'].join('');
        Angularytics.trackEvent(message, +1);
                      
        return response;
    };

    /**
     * @name subscribe - Subscribes for Model Updates.
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false : StateService.subscribe(function () {
            vm._success(true);//re-using existing code. 
            vm.model.notifications = NotificationService.getModel();
            vm.model.notification = {};//@todo --- search proper notification and show it.
        });
    };

    /**
     * @example 
     * <code>
     *  <search next="vm.search(params)"/>    
     *  <filter-items next="vm.search(params)"/>
     * </code>
     * @name search - Launches Search
     * @param {Object<Screen.Search>} - Search Object
     */
    this.search = function(params){
        if(this.screen.active && !this.screen.search.what)
            this.screen.search.what = this.screen.active; //default should be orders
        if(_.isEmpty(this.screen)) throw '@ItemsWidget:search requires Screen Object'; 
        if(_.isEmpty(this.screen.search.what)) throw '@ItemsWidget:search requires Action';
        this.screen.waiting = true; 
        
        var options = Object.assign({}, this.screen.search, params);
        return SearchService.omnisearch(options).then(this._success).catch(this._exception);
    };

    /**
     * @name filter - Uses Dropdown to filter 
     * @param {Object<Params>} params - Should have params.what
     */
    this.filter = function(params){
        this.screen.active = params.what; 
        this.screen.active = params.what; 
        this.screen.type = params.what; 
        this.screen.search.what = params.what;
        this.screen.search.query.type = params.what;
        return this.search(this.screen);
    };

    /**
     * @name _exception - Exception Callback 
     * @param {Object<Error>} error 
     */
    this._exception = function(error){
        vm.screen.waiting = false; 
        var notice = NotificationService.formatWorkspaceExceptionMessage(error);
        if(!_.isEmpty(notice)) NotificationService.report(notice);
        return error;
    };
    /**
     * @name next - Supplies what to do after user clicks on Item.
     *            - Function used as a callback for HoogyTile Component
     *            - Redirect to Checkout by default
     * @param {Object<Item>} 
     */
    this.next = function(item){
        if(_.isEmpty(item)) throw 'Item Not Defined Exception @ItemsWidget::next';
        if(!item.id && !item._id) throw 'Item Should Have ID and Title @ItemsWidget::next';
        var _log = item.title + ' - ' + item._id || item.id || item;
        StateService.dispatch(ActionCreator.addCheckoutItem(item));
        Angularytics.trackEvent('Authorized Item Rent - Like', _log);
        $state.go('checkout.item', {id: item.id||item._id||item});
    };

    /**
     * @throws {Exception} ItemNotFound 
     * @param {Object<Item>} params - Item to be liked. 
     * @name suggestion - Adds to Wishlist(Love) indicated Item.
     *                  - Serves as Callback to HoogyTile  
     *                  - This function is used to with StoreController 
     *                  - This function is clicked to bookmark, or add to preferences current item.
     *                  - For next searches, products are going to be similar to those bookmarked/preferences
     * @return {Object<Response>}
     */
    this.suggestion = function(params){
        var item = Object.assign({}, params);
        if (!item || _.isEmpty(item)) {
            throw 'Item Not Defined Exception';
        }
        return HoogyService.addFavorite(item).then(function (response) {
            var data = response.data || response;
            var _message = data.message || 'Suggestion added';
            var type = NotificationService.SUCCESS;
            NotificationService.notify({
                message: _message,
                type: type
            });
            StateService.dispatch(ActionCreator.addFavorite(Object.assign({}, item,{loveit:true})));
            Angularytics.trackEvent('Like', item.title + ' - ' + item._id);
            return response;
        }).catch(this._exception);
    };
    /**
     * @name more - More items
     * @uses search()
     */
    this.more = function(){
        //memorize the last element in existing collection
        var last = this.model.items[_.size(this.model.items) - 1] || {};

        var start = this.screen.step * this.SIZE;
        this.model.items = Object.values(Object.assign({}, SearchService.getModel(this.screen.search.what)) || {}).filter((usr, index) => index >= start && index < start + vm.SIZE );
        this.screen.step = this.screen.step + 1;

        //Mark Loved Items.
        var _likes = Object.values(HoogyService.getUserWishlist() || []).map((w) => w._id || w);
        this.model.items = this.model.items.map((it) => Object.assign({}, it, {loveit: _likes.indexOf(it._id) > -1}) );

        if (_.size(this.model.items) <= 0){
            this.screen.step = 0;//resetting the step
            this.screen.search.starting = last._id || last.id;
            this.search(this.screen.search);
        }
    };
}
ItemsWidgetController.$inject = ['$state','HoogyService','SearchService', 'StateService', 'ActionCreator', 'NotificationService', 'Angularytics'];
/**
 * @name ItemWidget - Replacement for Item Collections.
 *                  - It is used with Collections/Wishlist/Inventory and Items Discovery.
 */
angular.module('hoogy').component('itemsWidget', {
    controllerAs: 'vm',
    bindings: {screen: '='},
    controller: ItemsWidgetController,
    template: 
    '<div class="items-widget-wrapper no-padding-xs col-xs-12 col-sm-12 col-md-12 col-lg-12" ng-init="vm.init()">' +
    '   <div class="search-wrapper  no-padding-xs col-xs-12 col-sm-12 col-md-12 col-lg-12">'+
    '       <div ng-if="vm.omnibox !== false" class="pull-left left col-xs-12 col-sm-6 col-md-10 col-lg-10 no-padding-xs" screen="vm.screen" search="vm.search(subject)" hoogy-omnibox></div>'+
    '       <div class="pull-right  col-xs-12 col-sm-5 col-md-2 col-lg-2 no-padding-xs" ng-hide="vm.screen.noFilter" items-filter-dropdown>'+
    '           <div class="dropdown-toggle -items-widget" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false">'+
    '               <span class="icon ion ion-arrow-swap rotate-90"></span> Sort By'+
    '           </div>'+
    '           <ul class="menu dropdown-menu -items-widget">'+
    '               <li class="menu-li" data-value="discover" ng-click="vm.filter({what: vm.DISCOVER})">Discover</li>'+
    '               <li class="menu-li" data-value="wishlist" ng-click="vm.filter({what: vm.WISHLIST})">My Wishlist</li>'+
    '               <li class="menu-li" data-value="inventory" ng-click="vm.filter({what: vm.INVENTORY})">My Inventory</li>'+
    '           </ul>'+    
    '       </div>'+
    '   </div>'+
    '   <div class="item-listing  tb-padded">'+
    '       <hoogy-tile  ng-repeat="item in vm.model.items track by $index" item="item" settings="vm.settings" suggest="vm.suggestion(item)" goto="vm.next(item)"></hoogy-tile>'+
    '       <div class="navigation-wrapper  col-xs-12 col-sm-12 col-md-12 col-lg-12">'+
    '           <div class="btn btn-default more" ng-click="vm.more()"  ng-show="vm.screen.more"> more ... </div>'+
    '       </div>'+
    '   </div>'+
    '</div>'
});