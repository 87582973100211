/**
 * @uses HoogyService
 * @uses StripeService
 * @uses Angularytics
 * @uses NotificationService
 *
 * @type {Array}
 */
function CheckoutService($log, $q, $auth, StripeService, HoogyService, NotificationService, StateService, ActionCreator, Angularytics) {


    /**
     * Data Modelling working with CheckoutService.
     * This Model is actually the Order. 
     */
    var model = {
        id: '', 
        _id: '', 
        oid: '',
        item: {},
        //app related models
        //among available pricings, this keeps the latest selected 
        pricing: {},
        shipping:{},
        //starting + ending data 
        period: {},
        //collects credit card information --- 
        checkout: {},
        contract: {},
        payment: {},
        cards: [],
        card: {},
        error: {},
        renter: {},
        owner: {}, 
        customer: ''
    };

    var service = {
        //helpers 
        fetchItem: fetchItem,
        fetchOrder: fetchOrder,

        //Hydrating the checkout from Order
        setOrder: setOrder,
        
        setOrderItem: setOrderItem,
        setOrderShipping: setOrderShipping,
        setOrderPricing: setOrderPricing,
          
        setPaymentFromOrder: setPaymentFromOrder,
        setContractFromOrder: setContractFromOrder,
        setItemFromOrder: setItemFromOrder,
        setShippingFromOrder: setShippingFromOrder,
        setPricingFromOrder: setPricingFromOrder,
        setOrderErrors: setOrderErrors,

        //getModel 
        getModel: getModel,
        getReuseCardModel: getReuseCardModel,
        getOrderTotal: getOrderTotal,
        calculatePrice: calculatePrice,
        updateOrderPricing: updateOrderPricing,
        updateOrderPeriod: updateOrderPeriod,
        updateOrderId: updateOrderId,

        //
        checkCodeError: checkCodeError,
        checkZoneError: checkZoneError,
        checkOwnerError: checkOwnerError,

        //request - formatting 
        checkoutItemRequest: checkoutItemRequest,
        checkoutPaymentRequest: checkoutPaymentRequest,
        checkoutContractRequest: checkoutContractRequest,
        //checkout steps 
        checkoutItem: checkoutItem,
        checkoutContract: checkoutContract,
        //payment 
        checkout: checkout
    };
    return service;


    /**
     *@name getReuseCardModel - initialization of reuse card model
     *@return {} 
     */
    function getReuseCardModel() {
        return {

        };
    }

    /**
     * @uses StateService:getState() 
     * @name getModel - Utitity designed to access model indirectly. 
     * @return {Object} model
     */
    function getModel(which) {
        var checkout = StateService.getState().checkout;
        var state = Object.assign({}, checkout, model, {
            item: checkout.item || model.item,
            card: checkout.card || model.card,
            error: checkout.error || model.error,
            cards: checkout.cards || model.cards,
            period: checkout.period || model.period,
            pricing: checkout.pricing || model.pricing,
            payment: checkout.payment || model.payment,
            shipping: checkout.shipping || model.shipping,
            checkout: checkout.checkout || model.checkout,
            contract: checkout.contract || model.checkout,
            customer: checkout.customer || model.customer
        });
        return state[which] || state;
    }

    /**
     * @uses underscore 
     * @requires Item 
     * @requires Order 
     * @requires Payment 
     * @uses  StateService.dispatch(ActionCreator.addCheckoutPricing(item));
     * 
     * @name updateOrderPricing - updating ordering pricing. 
     * @param {Object} params - Period object
     * @param {Object} params.cost - pricing 
     * @return {Object<Model>} model - has {payment, order}
     */
    function updateOrderPricing(params) {
        if(!params) throw '@updateOrderPricing - Parameter Is Required Exception';
        var transaction = Object.assign({}, service.getModel());
        if (!transaction.payment) throw '@updateOrderPricing requires Payment Object';
        if (_.isEmpty(transaction.item)) throw '@updateOrderPricing requires Order Item';
        
        var period = Object.assign({}, transaction.period, transaction.item.pricing, params);
        if(!period.cost) period.cost = transaction.item.cost || transaction.item.price;
        if (!period.cost) throw '@updateOrderPricing requires Period:Cost to be available';
        
        transaction.payment.amount = period.cost || params.amount || transaction.payment.amount;
        transaction.payment.cost = period.cost || transaction.payment.cost;
        transaction.payment = Object.assign({}, transaction.payment);
        StateService.dispatch(ActionCreator.addCheckoutPricing(period));
        return transaction;
    }

    

    /**
     * @param {Object<Period>}
     * @uses  StateService.dispatch(ActionCreator.addCheckoutPeriod(item));
     * @name updateOrderPeriod - Order Period = Starting + Ending dates. 
     * @returns {Object<Order>}
     */
    function updateOrderPeriod(period) {
        var transaction = Object.assign({}, service.getModel());
        StateService.dispatch(ActionCreator.addCheckoutPeriod(Object.assign({}, transaction.period, period)));
        return transaction;
    }


    /**
     * @param {Object<Options>} options - Updated
     * @uses  StateService.dispatch(ActionCreator.addCheckoutOrder(item));
     * @name updateOrderId - Updates order::id from response data. 
     * @returns {Object<Order>}
     */
    function updateOrderId(options) {
        var transaction = Object.assign({}, service.getModel());
        if (options.order && _.isString(options.order)) {
            transaction._id = transaction.id = options.order;
        }
        if (options.order && _.isString(options.order)) {
            transaction._id = transaction.id = options.order;
        }
        if (options && (options._id || options.id)) {
            transaction._id = transaction.id = options._id || options.id;
        }
        StateService.dispatch(ActionCreator.addCheckoutOrder(transaction));
        return transaction;
    }

    /**
     * @name calculatePrice - Calculates Price of an Item and Initializes Payment Object.
     * @param {Object<Item>}
     * @param {Object<Period<Text, Value>>}
     * @uses  StateService.dispatch(ActionCreator.addCheckoutPayment(item));
     * @uses  StateService.dispatch(ActionCreator.addCheckoutPeriod(item));
     * @todo --- Calculates Pricing based on new period(starting|ending dates objects)
     * @return {Number} price - total amount of money to pay for a period of time. 
     */
    function calculatePrice(item, period) {
        var transaction = Object.assign({}, service.getModel());
        var howLong = typeof period === 'number' ? period : 1;
        if(period && period.value) howLong = period.value || howLong; 
        if (item && !transaction.item) {
            transaction.item = item;
        }
        if (!transaction.item) throw '@CheckoutService:calculatePrice requires an item';
        var starting = service.getModel().period;
        if(starting && starting.starting) starting = starting.starting;
        var period = Object.assign({}, transaction.period, HoogyService.calculateReturnDate(transaction.item, howLong, starting));
        var amount = HoogyService.calculatePrice(transaction.item, howLong, starting);
        var payment = Object.assign({}, transaction.payment, {amount: amount});
        var pricing = Object.assign({}, service.getModel().pricing);
        service.updateOrderPricing(pricing);
        StateService.dispatch(ActionCreator.addCheckoutPayment(payment)); 
        StateService.dispatch(ActionCreator.addCheckoutPeriod(period));
        return amount;
    }



    /**
     * @name getOrderTotal - Replacement of HoogyService.checkout.total.This function returns value calculated in current transaction.
     * @param {Order} - Optional order 
     * @returns {NUmber} - total amount of   
     */
    function getOrderTotal(order) {
        var transaction = Object.assign({}, service.getModel());
        var total = transaction.checkout.total || 0;
        if (order && order.payment && order.payment.amount) {
            total = order.payment.amount || total;
        }
        if (transaction.payment && (transaction.payment.amount || transaction.payment.cost)) {
            total = transaction.payment.amount || transaction.payment.cost || total;
        }
        return total;
    }


    /**
     * @name setOrderItem - Initialize Order Item data. 
     * @param {Object<Item>} item - Item Being Rented. 
     * @return {Object<Order>}
     */
    function setOrderItem(item) {
        var transaction = Object.assign({},{item: item}, service.getModel());
        StateService.dispatch(ActionCreator.addCheckoutOrder(transaction));
        return transaction;
    }

    /**
     * @name setOrderShipping - Updates Shipping Information.
     * @param {Object} meta 
     */
    function setOrderShipping(meta) {
        var transaction = Object.assign({}, service.getModel());
        //address|date|time|person 
        var pickup = {};
        var dropoff = {};
        //if item is associated to a business address.
        if (transaction.item && transaction.item.business && _.isEmpty(transaction.item.address) && !_.isEmpty(transaction.item.business.address)) {
            pickup = transaction.item.business.address || {};
            pickup.name = transaction.item.business.name;
            pickup.telephone = transaction.item.business.telephone;
        }
        var user = HoogyService.getUser();
        if (_.isEmpty(user)) throw '@CheckoutService:setOrderShipping requires User Object';

        if (!_.isEmpty(user.address)) {
            dropoff = user.address;
        }
        var shipping = Object.assign({},{
            dropoff: dropoff,
            pickup: pickup
        });
        if (!transaction.shipping) {
            transaction.shipping = [shipping];
        } else {
            transaction.shipping[0] = shipping;
        }
        StateService.dispatch(ActionCreator.addCheckoutOrder(transaction));
        return transaction;
    }

    /**
     * @name setOrderPeriod - Updated Order Period.
     * @todo --- should re-calculate Pricing after each Period Update.  
     * @todo --- should re-calculate Payment after each Period Update.
     */
    function setOrderPeriod(period){
        throw 'Not Yet Implemented';
    }

    /**
     * @param {Object<Item>}
     * @name setOrderPricing - Initializes order item pricing. 
     * @returns {Object<Order>}
     */
    function setOrderPricing(item) {
        var transaction = Object.assign({}, service.getModel());
        var _item = item || {};
        item.price = item.price || 0.00;
        if (!item.pricing) {
            item.pricing = HoogyService.getDefaultPricing(item.price);
        }
        //dates 
        var starting = HoogyService.getDateModel(new Date());
        var ending = HoogyService.getDateModel(new Date());
        transaction.period = {
            starting: starting,
            ending: ending
        };
        //calculate costs @uses HoogyService::calculatePrice()
        transaction.payment = {
            amount: 0.00,
            cost: 0.00
        };
        StateService.dispatch(ActionCreator.addCheckoutOrder(transaction));
        return transaction;
    }



    /**
     * @param {Object<Item>}
     * @name checkZoneError - Checks if users are in same area
     * @returns {Object<Order>}
     * @todo use Bayesian(Long/Lat) distance
     */
    function checkZoneError(item) {
        var transaction = Object.assign({}, service.getModel());
        var user = HoogyService.getUser();
        if (_.isEmpty(user)) throw '@CheckoutService:checkZoneError requires User Object';

        var _address = user.address || {};
        var _oaddress = (item.owner || {}).address || {};
        if (_oaddress.city !== _address.city) {
            _address.prs = _address.state || _address.prs;
            _oaddress.prs = _oaddress.state || _oaddress.prs;
            if (_address.prs !== _address.prs) {
                transaction.checkout.error.zones = 500;
                StateService.dispatch(ActionCreator.addCheckoutOrder(transaction));
            }
        }
        return transaction;
    }
    /**
     * @name checkoutOrderError - Checks if renter is the item owner. 
     * @param {Object} item 
     */
    function checkOwnerError(item) {
        var transaction = Object.assign({}, service.getModel());
        var user = HoogyService.getUser();
        if (_.isEmpty(user)) throw '@CheckoutService:checkOwnerError requires User Object';
        if (item.owner && (item.owner._id === user._id)) {
            transaction.error.owner = 500;
            StateService.dispatch(ActionCreator.addCheckoutOrder(transaction));
        }
        return transaction;
    }
    /**
     * @param {Object<Item>}
     * @name checkoutCodeError - Checks Owner strength. 
     * @returns {Object<Order>}
     */
    function checkCodeError(item) {
        var transaction = Object.assign({}, service.getModel());
        if (!item.owner || (item.owner.strength < 150)) {
            transaction.error.code = 500;
            StateService.dispatch(ActionCreator.addCheckoutOrder(transaction));
        }
        return transaction;
    }

    /**
     * @name setOrderErrors - Sets Errors When Item/Owner/Zone are Missing.
     * @param {Object<Item>} 
     * @returns {Object<Model|State>}
     */
    function setOrderErrors(item) {
        service.checkZoneError(item);
        service.checkOwnerError(item);
        service.checkCodeError(item);
        return Object.assign({}, service.getModel());
    }




    /**
     * @throws OrderIdNotFound
     * @name setPaymentFromOrder - Is more of hydration than a setter.
     * @param {Object<Params>}
     * @returns {Object<Payment>}
     */
    function setPaymentFromOrder(params) {
        var transaction = Object.assign({}, service.getModel());
        if (_.isEmpty(params)) throw '@CheckoutService:setPaymentFromOrder requires a valid Order';
        if (!params.id && !params._id) throw '@CheckoutService:setPaymentFromOrder requires a valid Order ID';
        return Object.assign({}, params.payment || transaction.payment);
    }

    /**
     * @throws OrderIdNotFound
     * @name setContractFromOrder - Hydrates Contract from Order
     * @param {Object<Params>} params 
     * @returns {Object<Contract>}
     */
    function setContractFromOrder(params) {
        var transaction = Object.assign({}, service.getModel());
        if (_.isEmpty(params)) throw '@CheckoutService:setContractFromOrder requires a valid Order';
        if (!params.id && !params._id) throw '@CheckoutService:setContractFromOrder requires a valid Order ID';
        
        var contract = params.contract || transaction.contract;
        if(typeof contract === 'string') contract = { text: contract };
        return Object.assign({}, contract);
    }

    /**
     * @throws OrderIdNotFound
     * @name setItemFromOrder - Hydrates Item From Order
     * @param {Object<Params>} params
     * @returns {Object<Item>}
     */
    function setItemFromOrder(params) {
        var transaction = Object.assign({}, service.getModel());
        if (_.isEmpty(params)) throw '@CheckoutService:setItemFromOrder requires a valid Order';
        if (!params.id && !params._id) throw '@CheckoutService:setItemFromOrder requires a valid Order ID';
        return Object.assign({}, params.item || transaction.item);
    }

    /**
     * @throws OrderIdNotFound 
     * @name setShippingFromOrder - Hydrates Shipping from Order
     * @param {Object<Shipping>}
     * @returns {Object<Shipping>}
     */
    function setShippingFromOrder(params) {
        var transaction = Object.assign({}, service.getModel());
        if (_.isEmpty(params)) throw '@CheckoutService:setShippingFromOrder requires a valid Order';
        if (!params.id && !params._id) throw '@CheckoutService:setShippingFromOrder requires a valid Order ID';
        return Object.assign({}, params.payment || transaction.payment);
    }

    /**
     * @throws OrderIdNotFound 
     * @name setPricingFromOrder - Hydrates Pricing from Order
     * @param {Object<Shipping>}
     * @returns {Object<Shipping>}
     */
    function setPricingFromOrder(params) {
        var transaction = Object.assign({}, service.getModel());
        if (_.isEmpty(params)) throw '@CheckoutService:setPricingFromOrder requires a valid Order';
        if (!params.id && !params._id) throw '@CheckoutService:setPricingFromOrder requires a valid Order ID';
        return Object.assign({}, params.pricing || transaction.pricing);
    }

    /**
     * @throws OrderIdNotFound 
     * @uses setOrderErrors 
     * @name setOrderErrors - Hydrates Order Erros from Order
     * @param {Object<Shipping>}
     * @returns {Object<Shipping>}
     */
    function setOrderErrorsFromOrder(params) {
        if (_.isEmpty(params)) throw '@CheckoutService:setOrderErrors requires a valid Order';
        if (!params.id && !params._id) throw '@CheckoutService:setOrderErrors requires a valid Order ID';
        service.setOrderErrors(params.item);
        var transaction = Object.assign({}, service.getModel());
        return Object.assign({}, transaction.errors);
    }




    /**
     * @name setOrder - Set Order and returns Order (Factory)
     * @uses setPaymentFromOrder 
     * @uses setContractFromOrder
     * @uses setItemFromOrder
     * @uses setShippingFromOrder
     * @uses setPricingFromOrder 
     * @uses setOrderErrors
     * @param {Object<Params>} params 
     * @returns {Object<Order>}
     */
    function setOrder(params) {
        if (_.isEmpty(params)) throw '@CheckoutService:setOrder requires a valid Order Response';
        var order = Object.assign({}, params.data || params, {
            id: params.id || params._id || 0,
            payment: service.setPaymentFromOrder(params),
            contract: service.setContractFromOrder(params),
            item: service.setItemFromOrder(params),
            shipping: service.setShippingFromOrder(params),
            pricing: service.setPricingFromOrder(params),
            errors: service.setOrderErrors(params)
        });
        StateService.dispatch(ActionCreator.addCheckoutOrder(order));
        return order;
    }

    /**
     * @uses setOrder
     * @uses HoogyService.fetchOrder
     * @uses StateService.dispatch
     * @uses ActionCreator.updateCheckout
     * @throws OrderIdNotFoundError
     * @name fetchOrder - Fetching the Order. 
     *                  - Do fetch Order, before Signing contract
     *                  - Do fetch Order, before making Payment.
     * @param {Object<Params>} params 
     */
    function fetchOrder(params) {
        if (!params) throw '@CheckoutService:fetchOrder requires Parameters';
        if(!params.id && !params._id && !params.oid) throw '@CheckoutService:fetchOrder requires Order ID';
        var options = params.id || params._id || params.oid;
        return HoogyService.fetchOrder(options).then(function (response) {
            var order = Object.assign({}, service.setOrder(response.data || response));
            StateService.dispatch(ActionCreator.addCheckoutOrder(order));
            return response;
        });
    }


    /**
     * @name fetchItem - Fetches Item and re-hydrate Checkout Object 
     * This function will be used by item/:id url.
     * The outcome is initialization of order.
     *   Order - item
     *       - pickup : pickup/shop/item address
     *       - shipping : shipping/renter address
     *       - cost : calculated from starting.thing by ending.thing
     *       - starting : starting date
     *       - ending : Ending date
     * @param {Object} params - search data. 
     * @param {Object} params.itemid || params.id - should contain item ID. 
     * Receives a $state || Object and fetches correspoding Item Details Object.
     */
    function fetchItem(params) {
        /**
         * - initialize Order, if not exists 
         * - initalize Pickup, if not exists 
         * - initialize Dropup, if not exits 
         * - initialize Pricing, if not exists 
         * - should update model parameters. 
         * - data available in current HoogyService.getUser() should be used. 
         * - data missing will be updated by other parts of the application. 
         */
        if(!params || _.isEmpty(params)) throw '@CheckoutService:fetchItem requires Item ID';
        
        var id = params.itemid || params.item || params.id;
        return HoogyService.fetchItem(id).then(function (response) {
            var item = Object.assign({}, response.data || response);
            service.setOrderItem(item); //initialize order item. 
            service.setOrderShipping(item); //attachs shipping information
            service.setOrderPricing(item); //attachs pricing information
            service.setOrderErrors(item); //checks if there is any errors to be displayed. 
            StateService.dispatch(ActionCreator.addCheckoutItem(item));
            return response;
        });
    }

    /**
     * @param {Object<Options>}
     * @name checkoutPaymentRequest - Checkout Payment Request prepares the request to send to server. 
     * @returns {Object<Order>}
     */
    function checkoutPaymentRequest(options) {
        var rtn = {};
        var transaction = Object.assign({}, service.getModel());
        rtn.order = options.order || (transaction.id || transaction._id);
        if (options && options.stripeToken) {
            rtn.stripeToken = options.stripeToken || '';
            if (options.email) {
                rtn.email = options.email;
            }
        }
        //@todo --- throw Exception for not finding the customer option
        if (options.customerId || options.customer) {
            rtn.customerId = options.customerId || options.customer;
        }

        if (!rtn.customerId && transaction.customer) {
            rtn.customerId = transaction.customer;
        }
        if(['string', 'number'].indexOf(typeof rtn.order) < 0) throw '@CheckoutService:checkoutPaymentRequest - Should return a String or Number as ID';
        return rtn;
    }

    /**
     * @name checkoutContractRequest - Formats Contract Request to be sent to server. 
     * @param {Object} options 
     * @returns {Object<order<String>, signature<String>>}
     */
    function checkoutContractRequest(options) {
        var transaction = Object.assign({}, service.getModel());
        var id = transaction._id || transaction.id || transaction.oid || transaction.order;
        var rtn = {order: id, signature: options.name || transaction.contract.name};
        if(['string', 'number'].indexOf(typeof options.order) > -1) {
            rtn.order = options.order;
        }
        if (!rtn.order && options.order) {
            rtn.order = options._id || options.id || options.oid || options.order;
        }
        if (!rtn.order || !rtn.signature) {
            throw '@CheckoutService::checkoutContractRequest - Checkout - Orer ID and Contract signature are always required';
        }

        if(['string', 'number'].indexOf(typeof rtn.order) < 0) throw '@CheckoutService:checkoutContractRequest - Should return a String or Number as ID';
        return rtn;
    }

    /** 
     * @name checkoutContract - Is used to send contract signature in the second step in checkout process. 
     * @param {Object} options - 
     * @return {Promise} checkout 
     */
    function checkoutContract(options) {
        if (!options || _.isEmpty(options)) {
            throw 'Contract Requires Action, Order ID and Signature(name)';
        }
        if (!options.action) {
            options.action = 'contract';
        }
        return HoogyService.updateCheckout(Object.assign({}, options, {
            data: service.checkoutContractRequest(options.data || options)
        })).then(function (response) {
            StateService.dispatch(ActionCreator.addCheckoutContract(response.data));
            return response;
        });
    }


    /**
     * @todo move this function to Service.
     * @param {Object<Options>} options
     * @name checkoutItemRequest - Returns request object to initiate a checkout process
     */
    function checkoutItemRequest(options) {
        var rtn = Object.assign({}, (options || {}).data || {});
        var transaction = Object.assign({}, service.getModel());
        rtn.item = transaction.item._id || transaction.item.id;
        //there are more chances that this will not happen 
        if (!rtn.item && _.isString(options.item)) {
            rtn.item = options.item;
        }
        var howLong = (transaction.period || {}).value || 1;
        var startEnding = HoogyService.calculateReturnDate(transaction.item, howLong);
        transaction.period = Object.assign({}, transaction.period, startEnding);
        rtn.starting = transaction.period.starting;
        if (rtn.starting.thing) {
            rtn.starting = rtn.starting.thing;
        }
        rtn.ending = transaction.period.ending;
        if (rtn.ending.thing) {
            rtn.ending = rtn.ending.thing;
        }
        rtn.total = HoogyService.calculatePrice(transaction.item, howLong);
        return rtn;
    }

    /**
     * @name checkoutItem - Formatting data sent to server to book an item. 
     *                      It is the first step in checkout process. 
     * @param {String} options.action - action defines which part to send the request to.
     * @param {Object} options.data  - or options 
     * @return {Promise<Response<data:{contract[String], order[String]}>>} checkout
     */
    function checkoutItem(options) {
        if (!options) throw 'Checkout Item expects Item parameters';
        return HoogyService.updateCheckout(Object.assign({}, {
            action: options.action || 'item',
            data: service.checkoutItemRequest(options.data || options)
        })).then(function (response) {

            service.updateOrderId(response.data);
            StateService.dispatch(ActionCreator.addCheckoutOrder(response.data));
            return response;
        });
    }


    /**
     * @todo gather variables needed to checkout
     * @todo format right data to send to hoogy/payment
     * @todo send proper notification - 
     * @name checkout - Allow customer to make payment. If customer has no payment method, create customer along the way. 
     * @param {Object} options.stripeToken
     * @uses StripeService::makePayment
     * @returns {Promise} - StripeService::makePayment().then(function(response){});
     */
    function checkout(options) {
        // 1 - case where user has new credit card + has no customerID
        return StripeService.makePayment(options).then(function (response) {
            StateService.dispatch(ActionCreator.addCheckoutPayment(response.data));
            return response; //add response to current order. 
        });
        // 2 - case where user has new credit card + customerID. 
        // 3 - case where user has re-uses default credit card + has customerID 
    }
}
CheckoutService.$inject = ['$log', '$q', '$auth', 'StripeService', 'HoogyService', 'NotificationService', 'StateService', 'ActionCreator', 'Angularytics'];
angular.module('hoogy').factory('CheckoutService', CheckoutService);