'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * Service will be used to Create/Update/Search stuff/items
 */
function SettingsService($http, $filter, $q, $log, HoogyService, StateService, ActionCreator) {
    /**
     * Should provide a default item/stuff image
     * @type {Object}
     */
    var AVATAR = HoogyService.AVATAR;//
    var model = {};
    var service = {
        me: me,

        getWalletInitModel: getWalletInitModel,
        getWalletInitStripeModel: getWalletInitStripeModel,
        getModel: getModel,

        getSettings: getSettings,
        updateSettings: updateSettings,
        getSettingsScreenModel: getSettingsScreenModel
    };

    /**
     * @name getSettingsScreenModel - Used to reduce initialiations in Controllers.
     */
    function getSettingsScreenModel() {
        return {
            user: {},
            address: {},
            bank: {},
            wallet: {},
            profile: {},
            card: {},
            cards: [],
            invites: []
        };
    }




    //using the reveal design pattern
    return service;

    /**
     * @name getWalletInitStripeModel - returns the stripe default model.
     * @return {Object} Stripe - default Stripe model. 
     */
    function getWalletInitStripeModel() {
        return {

        };
    };
    /**
     * @name getWallletInitModel - initalizes Wallet Editing UI.
     * @return { wallet, bank, stripe }
     */
    function getWalletInitModel() {
        return {
            bank: { routing: '', last: '' },
            wallet: { enabled: false },
            identity: { ssn: '', dob: '' },
            stripe: { verification: {} }
        };
    };


    /**
     * @param {String|Optional} which - indicates a model to return
     * Helper to access to a specific model. 
     */
    function getModel(which) {
        var settings = StateService.getState().settings;
        var state = Object.assign({}, model, settings);
        return state[which] || state;
    }

    /**
     * @todo fetch settings + initialize settings + formatt to be used across the application. 
     */
    function getSettings() {
        throw 'Not Implemented yet';
    }
    /**
     * @todo replaces initializations available in @HoogyService::me 
     */
    function me() {
        throw 'Not Implemented yet';
    }

    function updateSettings(options) {
        return HoogyService.updateSettings(options);
    }
}
SettingsService.$inject = ['$http', '$filter', '$q', '$log', 'HoogyService', 'StateService', 'ActionCreator'];
angular.module('hoogy').factory('SettingsService', SettingsService);