'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * @name SettingsMenuDropdown 
 * @usage Manually triggers SettingsMenu Dropdown menu to open/close. 
 * <code>
 *  <settings-menu>
 *      <div class="container" settings-menu-dropdown/>
 *  </settings-menu>
 * </code>
 */
function SettingsMenuDropdown(){
    return {
      link: function($scope, $element, $attrs, $ctrl){
        $($element).on('click', '.dropdown-toggle', function(e){
          $(this).dropdown('toggle');
        });
        $scope.$on('$destroy', function(){$($element).off();});
  
      }
    };
  }
angular.module('hoogy').directive('settingsMenuDropdown', SettingsMenuDropdown);

