'use strict';
/**
 * @name StoreTermsController - Displays terms and Conditions.
 * @param {Object} Angularytics 
 */
function StoreTermsController(Angularytics, AuthService, UIService, NotificationService, StateService){
    
    var vm = this;


    /**
     * @name $onInit 
     */
    this.$onInit = function(){
        this.unsubscribe = this.subscribe();
        this.screen = UIService.getStoreTermsScreen({component: 'store:terms'});
        this.init();
    };

    /**
     * @name subscribe - Subscribes for Model Updates.
     * //StateService.subscribe
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model = AuthService.getModel();
            vm.screen = UIService.getStoreTermsScreen(vm.screen);
            vm.model.notifications = NotificationService.getModel();                                                 
        });
    };

    /**
     * @name init - function to initialize terms.  displays + tracks event associated terms.
     */
    this.init = function () {
        Angularytics.trackEvent('Store Terms', +1);
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };

}
StoreTermsController.$inject = ['Angularytics', 'AuthService', 'UIService', 'NotificationService', 'StateService'];
angular.module('hoogy').component('terms', {
    controllerAs: 'vm',
    controller: StoreTermsController,
    templateUrl: 'js/store/view/terms.html'
});