'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * Service will be used to Create/Update/Search stuff/items
 */
function StuffService($http, $filter, $q, $log, HoogyService, StateService, ActionCreator) {
    /**
     * Should provide a default item/stuff image
     * @type {Object}
     */
    var model = {
        items: [],
        item: {},
        provider: {},
        owner: {}
    };

    var service = {
        AVATAR: HoogyService.AVATAR,
        getModel: getModel,
        setOwner: setOwner,
        setProvider: setProvider,
        getProviderCollection: getProviderCollection
    };

    //using the reveal design pattern
    return service;


    /**
     * @param {String|Optional} which - indicates a model to return
     * @name getModel - Helper to access to a specific model. 
     */
    function getModel(which) {
        var stuff = StateService.getState().stuff;
        var items = model.items; 
        if(stuff && stuff.entities) items = stuff.entities; 
        var state = Object.assign({}, model, {
            items: items,
            item: stuff.item || model.item,
            provider: stuff.provider || model.provider,
            owner: stuff.owner || model.owner
        });

        return state[which] || state;
    }

    /**
     * @deprecated will be replaced with shared storage mechanism
     * @todo - Acts more like a Hydration Function, more than a setter
     * @name setProvider - Function will be used to set Item::owner|provider.
     * @param {Object} owner Description
     * @return {Object<Owner>}
     */
    function setProvider(owner) {
        //check if product is formatted
        var provider = Object.assign({}, owner);
        StateService.dispatch(ActionCreator.addProvider(provider));
        return provider;
    }

    /**
     * @deprecated will be replaced with shared storage mechanism
     * @todo - Acts more like a Hydration Function, more than a setter
     * @name setProvider - Function will be used to set Item::owner|provider.
     * @param {Object} owner Description
     * @return {Object<Owner>}
     */
    function setOwner(owner) {
        //check if product is formatted
        var provider = Object.assign({}, owner);
        StateService.dispatch(ActionCreator.addOwner(provider));
        return provider;
    }

    /**
     * @name Function used to collect provider items.
     * @uses HoogyService::search
     * @return {Object} 
     */
    function getProviderCollection(params) {

        var options = params || {};
        options.what = 'collection';
        options.text = '#collection';
        options.tags = ['#collection'];

        /**
         * Setting Owner Options settings.
         */
        if (!options.owner) {
            options.owner = service.getModel().owner.id;
        }

        return HoogyService.omnisearch(options).then(function (response) {
            var _response = response.data || response || [];
            var _person = {};
            var _likes = _.filter(HoogyService.getUser().wishlist || [], function (w) {
                return w._id || w;
            });
            _.each(_response.inventory || _response || [], function (i) {
                i.photo = HoogyService.normalize(i.photo);
                i.loveit = (i._id && _likes.indexOf(i._id) > -1);
                if ((i.owner || {})._id === options.owner && !_person._id) {
                    _person = i.owner;
                    _person.incoming = (i.owner || {}).incoming;
                    _person.outgoing = (i.owner || {}).outgoing;
                }
            });
            _person = HoogyService.formatUser(_person);
            StateService.dispatch(ActionCreator.addStuff(model));
            service.setProvider(_person);
            service.setOwner(_person);
            return response;
        });
    }
}

StuffService.$inject = ['$http', '$filter', '$q', '$log', 'HoogyService', 'StateService', 'ActionCreator'];
angular.module('hoogy').factory('StuffService', StuffService);