/*global Redux, angular, Stripe, AppConfig, moment, _ */
'use strict';

function HoogyService($http, $auth, $filter, $log, $q, Angularytics, NotificationService) {
    //used once for item image 
    var _AVATAR = '//www.gravatar.com/avatar/00000000000000000000000000000000?s=520&r=pg';

    //data type to hold reference to all data.
    var model = {
        reviews: [],
        review: {}
    };
    var user = {}; 

    var service = {
        
        model: model,
        user: user,

        walletStats: walletStats,
        apply: apply,
        getPricingModel: getPricingModel,
        getDefaultPricing: getDefaultPricing,
        getDateModel: getDateModel,
        calculateReturnDate: calculateReturnDate,
        getProfile: getProfile,
        calculatePrice: calculatePrice,
        checkoutContract: checkoutContract,
        updateCheckout: updateCheckout,
        rentOutItem: rentOutItem,
        createAliasFromEmail: createAliasFromEmail,
        normalize: normalize,
        formatUser: _formatUser,
        _formatProfile: _formatProfile,
        _nameAndEmail: _nameAndEmail,
        
        //currently connected user 
        me: me,
        getUser: getUser,
        settings: settings,

        contract: contract,
        updateSettings: updateSettings,
        getContract: getContract,
        getUserCards: getUserCards,
        recover: recover,
        recoverAlt: recoverAlt,
        invite: invite,
        inviteAlt: inviteAlt,
        confirmation: confirmation,
        findInvitedFriends: findInvitedFriends,
        getInvitedFriends: getInvitedFriends,

        formatNotification: formatNotification,
        sendNotification: sendNotification,
        sendMessage: sendMessage,
        announce: announce,
        initItems: initItems,

        //used to seach anything 
        omnisearch: omnisearch,

        saveAddress: saveAddress,
        saveItem: saveItem,

        getItem: getItem,
        fetchItem: fetchItem,
        getItemDetails: getItemDetails,

        removeContact: removeContact,
        contact: contact,
        getRecipient: getRecipient,
        getIntents: getIntents,
        getMessages: getMessages,
        getUnread: getUnread,
        removeItem: removeItem,
        upload: upload,
        sync: sync,
        removePhoto: removePhoto,
        getReview: getReview,
        getReviews: getReviews,
        postReview: postReview,
        addFavorite: addFavorite,
        lookupItems: lookupItems,
        //Alias to Lookup Order.
        fetchOrder: lookupOrder,
        lookupOrder: lookupOrder,
        lookupOrders: lookupOrders,
        orderStatus: orderStatus,
        cancelOrder: cancelOrder,
        acceptOrder: acceptOrder,
        rejectOrder: rejectOrder,
        formatOrder: formatOrder,
        reset: reset,

        //sharing with facebook, twitter and email ....
        share: share,
        tweet: tweet,
        suggest: addFavorite,
        checkEmail: checkEmail, 
        
        getUserId: getUserId,
        getUserWishlist: getUserWishlist
    };

    /**
     * @throws {String} - User Not Authenticated.
     * @name getUserId - connected user ID. 
     * @return {String} id - user id. 
     */
    function getUserId() {
        if (!service.user) throw 'User not authenticated';
        return service.user._id || service.user.id;
    }
    /**
     * Getter of Connected User.
     * Connected User is replaced by User. 
     * Should not send a request to server --- getMe does that. 
     */
    function getUser() {
        var person = service.connectedUser || service.connectedFormatted || service.user;
        if (!person) throw '@HoogySerice:getUser - User not authenticated';
        if (person && !person.photo) {
            person.photo = ['//placehold.it/100x100/E9E9ED/565a5c&text=', person.initials].join('');
        }
        return Object.assign({}, person);
    }

    /**
     * @name getUserWishlist - returns the user whishlist. 
     * @return {Array} whishlist - array of whichlist from User instance.
     */
    function getUserWishlist() {
        return getUser().wishlist || [];
    }

    var intermediate = {};
    var Config = AppConfig.Config;
    service.showAuthentication = false;

    //@todo remove after migrating code to Reveal pattern
    service.intermediate = intermediate;

    //alternate these two avatars based on availability
    service.AVATAR = '//s3.amazonaws.com/uifaces/faces/twitter/_everaldo/128.jpg';
    service.gravatar = '//gravatar.com/avatar/00000000000000000000000000000000?s=444&r=pg';
    service.gravatar = '//s3.amazonaws.com/uifaces/faces/twitter/_everaldo/128.jpg';
    /**
     * @todo - check if user
     */
    service.stats = [];


    /**
     * @link https://developers.facebook.com/docs/sharing/reference/share-dialog
     *   * @link http://jasonwatmore.com/post/2014/08/01/AngularJS-directives-for-social-sharing-buttons-Facebook-Like-GooglePlus-Twitter-and-Pinterest.aspx
     * @link http://www.michaelbromley.co.uk/blog/171/enable-rich-social-sharing-in-your-angularjs-app
     * @todo share current item on facebook.
     * @todo --- -check orientation. 
        name: vm.screen.item.title,
        link: url,
        picture: vm.screen.item.photo || vm.screen.item.picture || '',
        caption: vm.screen.item.title  || '',
        description: vm.screen.item.description || '',
        message: 'Check this item for rental out!',

     * Makes it possible to share things on facebook. 
     * @uses Facebook JS SDK 
     * @todo use promise to return Chainable thing. 
     */
    function share(item) {
        var deferred = $q.defer();
        var url = [AppConfig.Config.server, 'item', item._id || item.id].join('/');
        var appId = AppConfig.Config.facebook.appId;
        Angularytics.trackEvent('Facebook share - init', item.title + ' - ' + item._id);
        if (!FB || !FB.ui) {
            throw 'Facebook SDK is not loaded.';
        }
        FB.ui(Object.assign({},{
            method: 'share',
            app_id: appId,
            display: 'popup',
            href: url
        }), function (response) {
            Angularytics.trackEvent('Facebook share - finalize', item.title + ' - ' + item._id);
            $log.log(response);
            if (response && response.error_message) {
                deferred.reject(response.error_message);
            } else {
                deferred.resolve(response);
            }
        });
        return deferred.promise;

    }

    /**
     * Makes it possible to share things on tweeter 
     */
    function tweet() {
        throw 'Tweet function is not implemented yet';
    }

    /**
     * @alias suggest
     * @name addFavorite - Makes it possible to share things on hoogy. Function used to add Liked Item. 
     * @param {Object<Item>} item - Item Id or _Id  
     * @return {Promise} HTTP request 
     */
    function addFavorite(item) {
        var user = service.getUser();
        var itemid = item.id || item._id || item;
        if(!user || _.isEmpty(user)) throw '@HoogyService:addFavorite requires Authenticated Users';
        if(!itemid || ['number', 'string'].indexOf(typeof itemid) < 0) throw '@HoogyService:addFavorite requires Item ID';
        var id = user._id || user.id;
        var requestor = {id: id, _id: id};
        var request = { requestor: requestor};
        return $http.post(Config.api + '/favorite/' + itemid, request);
    }



    function walletStats() {
        return $http.get(Config.api + '/order/stats').then(function (stats) {
            service.stats = stats;
            return stats;
        });
    }


    /**
     * @deprecated Redux is used instead of $scope bound variables. 
     * @name apply - Utility used to re-enforce scope digest
     * @param  {Object}   scope 
     * @param  {Function} fn    
     * @return {Object}         
     */
    function apply(scope, fn) {
        if(true) throw 'Scope Apply Have Been Deprecated @HoogyService::apply'
        
        if (!scope || !fn) {;
            return;
        }

        return (scope.$$phase || scope.$root.$$phase) ? fn() : scope.$apply(fn);
    }

    /**
     * @name getProfile - Wrapper used to fetch public profile
     * Function used by PeopleService to fetch profile data.
     * Caller will be responsible to handle exceptions + format returned data.
     * @params {String} options.id - profile ID
     * @return {Promise}
     */
    function getProfile(options) {
        var _endpoint = [Config.api, 'settings/profile', options.id].join('/');
        return $http.get(_endpoint, options);
    }

    /**
     * @name getUserCards - Returns a list of credit cards
     * @return {Promise}
     */
    function getUserCards() {
        return $http.get([Config.api, 'settings/cards'].join('/'));
    }



    /**
     * This function harmonizes all dates.
     * @param {Date} date - date to be formatted 
     * @returns {Object{thing, formatted, calculated}} - date used by application wide calculations. 
     */
    function getDateModel(date) {
        var _date = _.isNumber(date) ? new Date(parseInt(date)) : date;
        return Object.assign({}, {
            date: date,
            thing: _date || new Date(),
            formatted: $filter('date')(_date || new Date(), 'dd MMMM'),
            calculated: _date || new Date(),
            time: (_date || new Date()).getTime()
        });
    }

    /**
     * Collection of pricing schemes
     * It is used to generate a pricing model, while displaying an item. 
     * @name
     * @param {Number} price - Default(optional) monetary price to initialize to
     * @return {[Array]} - array of pricing model. 
     */
    function getPricingModel(price) {
        return [{
                value: 1,
                text: 'Per Hour',
                price: price || 0.00
            },
            {
                value: 24,
                text: 'Per Day',
                price: price || 0.00
            },
            /**168 Hours a week */
            {
                value: 168,
                text: 'A Week',
                price: price || 0.00
            },
            /**168 Hours a week, times 4 */
            {
                value: 672,
                text: 'A Month',
                price: price || 0.00
            },
            /**168 Hours a week, times 12 Weeks */
            {
                value: 2016,
                text: 'Semester',
                price: price || 0.00
            }
        ];
    }

    /**
     * For initialization purposes on items that don't have any pricing 
     * @return {Object} 
     */
    function getDefaultPricing(price) {
        return getPricingModel(price)[1];
    }

    /**
     * @deprecated - object now moved to CheckoutService::model 
     *  Checkout object contains information related to checkout operations
     * @type {Object}
     */
    service.checkout = {};

    /**
     * @todo use to pass starting time + ending time use period parameter 
     * @deprecated - this function looks irrelevant to price calculation. 
     * This function calculates and validates return date.
     * @uses item.pricing.
     * @param  {Object} item - Item representation of Object. 
     * @param {Period} starting ending - number of hours users will keep the item for. 
     * @param {Period}  - number of hours users will keep the item for. 
     * @return {Period} Period - pricing instance with additional models. 
     */
    function calculateReturnDate(item, howLong, period) {
        //not relevant 
        var _period = Object.assign({}, period || {});
        //period, howLong
        var pricing = item.pricing || getDefaultPricing(item.price);
        var _howLong = howLong || 1;
        if (!item.pricing && item.price) {
            item.pricing = getDefaultPricing(item.price);
        }
        var _starting = new Date();
        var _ending = new Date();
        if (_period.starting && _period.starting.thing && _period.starting.thing.getTime()) {
            _period.starting = getDateModel(_period.starting.thing.getTime());
            _ending = new Date(_period.starting.thing.getTime());
        } else {
            _period.starting = getDateModel(new Date().getTime());
            _ending = new Date(_period.starting.thing.getTime());
        }

        //item.pricing.value - may cause inconsistency in date calculation
        _ending.setHours(_ending.getHours() + (pricing.value * _howLong));
        var ending = getDateModel(_ending.getTime());


        return Object.assign({}, {
            starting: getDateModel(_starting.getTime()),
            ending: ending,
            reason: 'Renting - item'
        });
    }


    /**
     * @todo --- move this function to CheckoutService 
     * Calculates how much to pay, given a period of time. 
     * @param  {Object} item   - Item serves for pricing.
     * @param  {Number} howLong - howLong(starting, ending) 
     * @return {Number} price  - total amount to pay
     */
    function calculatePrice(item, howLong) {
        var _howLong = howLong;
        if (_.isObject(howLong) && howLong.howLong) {
            _howLong = howLong.howLong;
        }
        //@todo un-comment + retest -- all section of the application
        //if(!_howLong) throw '@HoogyService:calculatePrice requires HowLong to be defined';
        if (!item.pricing || !item.pricing.price) {
            //make sure every product has a pricing scheme 
            item.pricing = getDefaultPricing(item.price);
        }
        //@todo un-comment + retest -- all section of the application
        //if(!item || !item.pricing || !item.pricing.price) throw '@HoogyService:calculatePrice requires Item:Pricing:Price to Be Defined';
        
        if (!item.price && item.pricing) {
            item.price = item.pricing.price;
        }

        service.calculateReturnDate(item, _howLong);
        return parseInt(_howLong || 1) * item.price;
    }


    /**
     * @deprecated --- replaced with CheckoutService::checkoutContractRequest 
     * @todo --- exstend testing of these functions 
     */
    function checkoutContract(data) {
        return Object.assign({}, {
            order: data.order, 
            signature: data.name
        });
    }


    /**
     * @todo split this function into three Checkout functions:
     *  - input output should be clear 
     * @todo - Check where the user is sending the checkout.
     * @todo - Format response, and return promise
     */
    function updateCheckout(options) {
        if (!options || !options.data) {
            throw 'Checkout options and options.data are required';
        }
        if (options.action && options.action.indexOf('vm.') > -1) {
            options.action = options.action.split('vm.')[1];
        }
        var _endpoint = [Config.api, '/checkout/', options.action || 'item'].join('');
        return $http.post(_endpoint, options.data);
    }

    /**
     * @name rentOutItem - Function used by item owner, renting out to a friend or contact.
     * @uses checkoutItem - Function that Formats Rent Out Request 
     * @param {Object<Options>} params
     * @returns {Object<Promise>}
     */
    function rentOutItem(params) {
        if(!params) throw '@HoogyService:rentOutItem - Params Required Exception';
        //if order._id provided, this will be treated as an existing order
        var recipient = params.recipient || params.user || params.receiver || params.person;
        if(recipient && (recipient.id || recipient._id)){
            recipient = recipient.id || recipient._id;
        }
        var options = Object.assign({}, params, {recipient: recipient});        
        if(!options.item) throw '@HoogyService:rentOutItem - Item Not Found Exception'; 
        if(!options.owner) throw '@HoogyService:rentOutItem - Owner Not Found Exception';
        if(!options.starting) throw '@HoogyService:rentOutItem - Starting Date Not Found Exception';
        if(!options.ending) throw '@HoogyService:rentOutItem - Ending Date Not Found Exception';
        //We cannot re-use this endpoint, since we are reting out, rather than reting our selves.
        if (!recipient) {throw '@HoogyService:rentOutItem - Rent Out Item requires a Recipient';}
        var _endpoint = [Config.api, 'order', recipient, 'rent'].join('/');
        return $http.put(_endpoint, options);
    }

    /**
     * This function derives valid model for password change
     * @param person
     * @returns {{hash: *, current: string, old: string, verify: string}}
     * @private
     */
    function _formatPassword(person) {
        return Object.assign({},{
            hash: person && person.local ? person.local.password : '',
            current: '',
            old: '',
            verify: ''
        });
    }
    /**
     * @todo strip the telephone from the address and display it here.
     * @name _formatUser - Function to extract the renter|Owner object
     * @param  {Object<Renter>} user 
     * @return {Object<User>}        
     */
    function _formatUser(payload) {
        var user = Object.assign({}, payload);
        if (!user || !_.isObject(user) || _.isEmpty(user)) {
            return {
                _id: ''
            };
        }

        var _user = user.local || user.facebook || user.google || user || {};
        if (user.local && user.local.email) {
            _user.email = user.local.email;
        } else if (user.google && user.google.email) {
            _user.email = user.google.email;
        } else if (user.facebook && user.facebook.email) {
            _user.email = user.facebook.email;
        }

        if (user.local && user.local.name) {
            _user.name = user.local.name;
           
        } else if (user.google && user.google.name) {
            _user.name = user.google.name;
        } else if (user.facebook && user.facebook.name) {
            _user.name = user.facebook.name;
        }

        if (user.local && user.local.picture) {
            _user.photo = user.local.picture;
        } else if (user.google && user.google.picture) {
            _user.photo = user.google.picture;
        } else if (user.facebook && user.facebook.picture) {
            _user.photo = user.facebook.picture;
        }


        _user.initials = 'JD';
        if (_user.name) {
            if(_user.name.indexOf('@') > -1) _user.name = service.createAliasFromEmail(_user.name);
            _user.initials = _user.name.replace(/\W*(\w)\w*/g, '$1').toUpperCase();
            _user.first = (_user.name.trim().split(' ')[0]);
        }
        _user.stats = { 
            in: _.size(user.incoming),
            out: _.size(user.outgoing)
        };
        /**
         * @todo normalize users profile photos
         */
        if (_user.photo) {
            _user.photo = service.normalize(_user.photo);
        }

        if(_user.name === _user.email || (_user.name && _user.name.indexOf('@') > -1)){
            _user.name = service.createAliasFromEmail(_user.name);
        }
       return Object.assign({}, {
            name: _user.name || service.createAliasFromEmail(_user.email),
            email: _user.email,
            photo: _user.photo,
            telephone: '',
            id: user._id || user.id,
            _id: user._id || user.id,
            initials: _user.initials,
            stats: _user.stats
        }, _user);
    }

    /** 
     * @name checkEmail - Checking Email 
     * @param email{String} 
     * @returns {Boolean} 
     */
    function checkEmail(email){
        if(!email) return false; 
        //@link https://stackoverflow.com/a/46181/132610
        var regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return regex.test(email.toLowerCase());
    }

    /**
     * @name createAliasFromEmail - Function to create an Alias Name from an Email. 
     * @param {String} email 
     */
    function createAliasFromEmail(email){
        if(typeof email === 'undefined' || !email) return email; 
        var alias = email.trim().split('@')[0] || email; 
        if(alias.indexOf('.') >= 0 ){ alias = alias.split('.')[0] || alias;}
        if(alias.indexOf('_') >= 0 ){ alias = alias.split('_')[0] || alias;}
        return alias.indexOf('@') >= 0 ? alias : ['@', alias].join(''); 
    }

    /**
     * @todo person can have only facebook and facebook, with no local setup!
     * @param  {Object[User]} person
     * @return {Object[name, email]}
     */
    function _formatProfile(person) {
        var _profile = person.local || {};
        if (!_profile.name) {
            _profile = _formatUser(person);
        }

        return _.pick(_profile, 'name', 'email');
    }

    /**
     * This function is used with
     * @param data
     * @returns {*}
     * @private
     */
    function _nameAndEmail(data) {
        if (data && data.profile) {
            return Object.assign({}, {
                name: data.profile.name,
                email: data.profile.email
            });
        }

        return Object.assign({}, data);
    }
    /**
     * This function is used to format address to be sent to the server
     * @param data
     * @returns {data.address|*|{}}
     * @private
     */
    function _address(data) {
        return Object.assign({}, data.address || data || {});
    }

    /**
     * Model to be used with settings Management.
     */
    service.userSettings = {};
    service.settingsError = {};
    service.profileStrength = 0;

    /**
     * @name _settingsCallback - Called to Format User Data
     * @param {Object<Response>} me 
     * @param {Number} status 
     * @param {Object<Headers>} headers 
     * @param {Object<Config>} config 
     */
    function _settingsCallback(me, status, headers, config) {

        if (_.isString(me) || status !== 200) {
            return false;
        }
        /**
         * @todo re-model this section as soon as
         *       the server is ready to provide with content.
         */
        service.profileStrength = me.strength || 0; //re-initialize the counter in case of multiple calls
        service.userSettings = Object.assign({},service.userSettings, me);
        service.userSettings.password = _formatPassword(me);
        service.userSettings.profile = _formatProfile(me);
        service.userSettings.address = Object.assign({}, me.address);
        service.userSettings.notifications = {};
        return me;
    }
    /**
     * @name settings - User Details For Public Settings
     * @uses _settingsCallback - Formatting Settings to Be Used Here.
     * @returns {*}
     */
    function settings() {
        var _me = Object.assign({}, service.connectedUser);
        return $http
            .get(Config.api + '/settings/details')
            .then(_settingsCallback);
    }

    service.connectedUser = service.connectedUser || {};
    service.connectedFormatted = service.connectedFormatted || {};
    service.meError = {};


    /**
     * @name me() - Retrieves Connected User Details. 
     * @uses _formatUser - Formats
     * @uses _settingCallback - Formats User Settings Data.
     * @returns {Promise}
     */
    function me() {
        service.meError = {};
        return $http.get(Config.api + '/me').then(function (response, status, headers, config) {
            var me = response.data || response || {};
            if (_.isString(me)) {
                return response;
            } 
            //throw 'Exception - endoint has problems';
            service.connectedUser = Object.assign({},service.connectedUser, me);
            service.connectedFormatted = _formatUser(service.connectedUser);
            service.connectedUser.authenticated = true;
            //makes this object available accross the application
            service.user = service.connectedFormatted;
            _settingsCallback(response, status, headers, config);
            if (!service.connectedUser.address) {
                service.connectedUser.address = {
                    query: ''
                };
            }

            $log.log('HoogyService::connectedUser - received');
            return response;
        });
    }

    /**
     * @name contract - This function updates the contract.
     * From the date this contract is set, renters will be using it.
     * It will be appended to orders, to make it clear that any user who borrows things agree with the terms written in the contract.
     * It can be updated with the owner at any time, but the agreed revision will remain intact on order.
     * @param {Object} options - data including contract to be used
     * @returns {Promise}
     */
    function contract(options) {
        return $http.put(Config.api + '/contract', options);
    }

    /**
     * @todo simplify this function. 
     * @todo - make sure that updates are sent to server.
     * @param options
     */
    function updateSettings(options) {

        if (options && options.action && options.action.indexOf('vm.') > -1) {
            options.action = options.action.split('vm.')[1];
        }

        //@todo details should not be here as it is
        if (!options.action) {
            options.action = 'details';
        }

        if (options.action === 'profile') {
            options.data = _nameAndEmail(options.data);
        }

        if (options.action === 'address') {
            options.data = _address(options.data);
        }

        var _endpoint = [Config.api, 'settings', options.action].join('/');

        return $http
            .put(_endpoint, options.data).then(function (response) {
                if (!service.updatedSettings) {
                    service.updatedSettings = {};
                }
                service.updatedSettings[options.action] = (response || {}).data || response;
                $log.log('HoogyService::updateSettings -- success');
                return response || {};
            });
    }

    /**
     * This utility function will take two parameters.
     * The first paramter indicates a photo we received.
     * The second  optional parameter, indicates, if different, where to find shit.
     * This  function respects protocol less images (eg://s3.amazon.com/[path]/photo.ext)
     * @param  {Object} photo    
     * @param  {Object} location 
     * @return {Object}          
     */
    function normalize(photo, location) {
        if (photo && photo.trim().indexOf('//') === 0) {
            return photo;
        }
        if (!location) {
            location = '/photo/600x300/';
        }

        return photo && photo.indexOf('http') < 0 ? [Config.app, location, photo].join('') : photo;
    }

    /**
     * This function will be used to get current Contract
     * @param {String} owner - the user who is looking for his contract
     * @returns {*}
     */
    function getContract() {
        var owner = service.connectedUser._id;
        var _endpoint = [Config.api, 'contract'];
        if (owner) {
            _endpoint.push(owner);
        }

        return $http.get(_endpoint.join('/'));
    }

    /**
     *
     * @param options.email
     * @returns {*}
     */
    function recover(options) {
        return $http.put(Config.api + '/recover', options);
    }

    /**
     * Options is optional in case the user is already logged in
     * @param options
     * @returns {*}
     */
    function recoverAlt(options) {
        var data = {};
        if (service.connectedUser && service.connectedUser.local) {
            data.email = service.connectedUser.local.email;
        }

        if (!data.email && options && options.email) {
            data.email = options.email;
        }

        return $http
            .put(Config.api + '/recover', data)
            .then(function (data) {
                service.recoverResponse = Object.assign({}, data);
                return data;
            });
    }

    service.invitation = {};
    service.invitationError = {};
    service.invitesError = {};
    service.invitedFriends = [];
    /**
     * @name getInvitedFriends - Getter for InvitedFriends.
     */
    function getInvitedFriends(){
        return service.invitedFriends || [];
    }
    /**
     * Sending invitation if user still has invitations to send
     * @param options
     */
    function invite(options) {
        if (!options) {
            throw 'HoogyService::invite - email is required';
        }
        return $http
            .post(Config.api + '/invite', options)
            .then(function (response) {
                service.invitedFriend = Object.assign({},options, response);
                service.invitedFriends.push(service.invitedFriend);
                return response;
            });
    }



    /**
     * @name inviteAlt 
     * @param {Object<Params>}
     * @todo supports both authenticated and non-authenticated requests
     * This function will eventurally replace service.invite
     * This function sends a manageable invitation.
     * It was introduced to be chained with service.contact
     * @return {Object} 
     */
    function inviteAlt(params) {
        var options = params.data || params; //in case we use response instance
        if (_.isArray(options)) {
            options = options[0];
        }

        if (_.isString(options)) {
            options = JSON.parse(options);
        }

        var _endpoint = [Config.api, !options.claim ? 'invite' : 'claim'].join('/');
        $log.log('HoogyService::inviteAlt', options);
        if (!options || !options.email) {
            throw 'HoogyService::inviteAlt - email is required';
        }

        return $http.post(_endpoint, options);
    }

    /**
     * @todo - From an Invitation Key, get email and other invitaiton details.
     * @todo - confirmInvitation will post/to create a new account.
     * @todo - it will behave like create account/auth.create() - except the name won't be available.
     */
    function confirmation(options) {
        $log.log('HoogyService::getInvitation [options]');
        if (!options && !options.key && !options.invitation) {
            throw 'HoogyService::invitation - invitation required';
        }

        options.key = options.invitation || options.key;
        var endpoint = [Config.api, '/confirmation/', options.key].join('');
        return $http
            .get(endpoint)
            .then(function (response) {
                $log.log('HoogyService::getInvitation - response [response]');
                service.invitation = Object.assign({},options, response.data || response);
                return response;
            });
    }

    /**
     * @name findInvitedFriends This function retrieves invitations sent out.
     *  The endpoint is supposed to crunch data about who signed up, and who didn't
     * @todo test it on my own friends, and see how it works.
     * @return {Promise<Response>} 
     */
    function findInvitedFriends() {
        return $http.get(Config.api + '/invitations').then(function (response) {
            var invitations = response.data || response || [];
            service.invitedFriends = invitations || service.invitedFriends;
            return response;
        });
    }

    /**
     * @todo - Should throw an exception if users or text or item are missing. 
     * @name sendNotification - Email friends about just posted item.
     * @param {Object} params.users - IDs of friends to be notified
     * @param {Object} params.text - Custom message
     * @param {Object|String|Number} params.id - Item ID
     */
    function sendNotification(options) {
        var params = service.formatNotification(options);
        if(!params) throw '@HoogyService:sendNotification requires Parameters'; 
        if(!params.recipients) throw '@HoogyService:sendNotification requires Recipients Users Parameter';
        if(!params.itemid) throw '@HoogyService:sendNotification requires Item ID';
        if(!params.text) throw '@HoogyService:sendNotification requires a Custom Messages';
        return $http.post(Config.api + '/notify', params).then(function (response) {
                service.notification = Object.assign({}, {message: response.message || response});
                return response;
            });
    }

    /**
     * @param {Params.person} - Person who is sending the message.
     * @param {Params.owner} - Owner, not required 
     * @param {Params.item} - Object to suggest to people
     * @param {Object<Params>} params - Params 
     * @returns {Object<ItemId, PersonId, Text>}
     */
    function formatNotification(notification){
        var params = Object.assign({}, notification);
        if(!params) throw '@HoogyService:formatNotification requires Params'; 
        if(!params.person && !params.invitables && !params.recipients) throw '@HogyService.formatNotification requires Params.Person';
        if(!params.item && !params.itemid && !params.id) throw '@HoogyService.formatNotification requires Params.Item';
        
        var person = params.person;
        if(person && (person.id || params.person._id)) person = person.id || person._id;
        
        var id = params.itemid || params.id || params.item;
        if(id && (id._id || id.id)) id = id.id || id._id;
        
        var users = params.invitables || params.recipients || [person];
        if(person !== service.getUserId()) users.push(service.getUserId());
        var text = 'Happy to help with this information';
        return Object.assign({}, {users: users, text: text, itemid: id, recipients: users, invitables: users});
    };


    /**
     * Sending a message
     * Endpoint expects:
     * 	- text
     * 	- expeditor(Optional - can be found in session)
     * 	- receiver(required)
     * Response expects:
     * 	- status
     * 	- error message.
     */
    function sendMessage(message) {
        return $http.post([Config.api, '/message'].join(''), message);
    }

    /**
     * Sending announcement if user still have not found items he is looking for!
     * @param options
     */
    function announce(options) {
        return $http.post(Config.api + '/announce', options);
    }



    service.siteItems = [];
    /**
     * @deprecated - this function will not be used as searching items is next via OmniSearching
     * @todo check whenever this function is used and correct it accordingly.
     * Initializes Items to be displayed whenever a user connects
     * @returns {Promise}
     */
    function initItems(options) {
        var _likes = _.filter(service.connectedUser.wishlist || [], function (w) {
            return w._id || w;
        });
        return $http.get(Config.api + '/items')
            .then(function (items) {
                _.each(items, function (item) {
                    if (item._id && _likes.indexOf(item._id) > -1) {
                        item.loveit = true;
                    }
                });
                service.siteItems = items || service.siteItems;
                return items;
            });
    }




    /**
     * @param {String} id - Item ID. 
     * @throws {Error} 'Item ID is required to fetch Item Details' - for missing item id
     * @return {Promise} promise - Promised $get object
     */
    function fetchItem(id) {
        if (!id) throw 'Item ID is required to fetch Item Details';
        var _endpoint = [Config.api, 'item', id].join('/');
        return $http.get(_endpoint);
    }

    /**
     * #@name getItemDetails - retrieves and formats item details for public use.
     * @param {String} id - item ID 
     */
    function getItemDetails(id) {
        return fetchItem(id);
    };


    /**
     * This function will gradullay replace service.omnisearch.
     * @param {Array} options.tags - the list of searchable tags
     * @param {String} options.text - text to search for
     * @params {String} options.what - required to target an endpoint
     * @prams {String} options.owner - item Provider/Owner ID.
     * The omni-search is a search for everything
     * @name
     * @param  {Object<Query>} query 
     * @return {Object<Promise>}       
     */
    function omnisearch(options) {

        if (options && options.action && options.action.indexOf('vm.') > -1) {
            options.action = options.action.split('vm.')[1];
        }
        var action = (options.action || options.what || 'items');
        if(action.indexOf('#') > -1) action = action.split('#')[1];

        $log.log('HoogyService::omnisearch');
        var _endpoint = [Config.api, 'omnisearch', action].join('/');
        return $http.post(_endpoint, options);
    }

    function saveAddress(address) {
        return $http.put(Config.api + '/address', address);
    }

    /**
     * This model will be used as a reference to currently being edited Inventory Item
     * @type {{}}
     */

    service.inventoryItem = {};

    /**
     * @name saveItem - saves an item.
     * @param item
     * @returns {*}
     */
    function saveItem(item) {
        if (!item || _.isEmpty(item)) throw 'HoogyService::SaveItem Requires Valid Item';
        if (item.tags && typeof item.tags === 'string' && item.tags.length > 0) {
            item.tags = item.tags.split(',');
        }
        var endpoint = [Config.api, 'item', item._id || item.id].join('/');
        return $http.put(endpoint, item).then(function (response) {
            var item = response.data || response || {};
            /**items has tags has to be displayed as a single entity.*/
            if (item.tags && _.isArray(item.tags)) {
                item.tags = item.tags.join(', ');
            }

            service.inventoryItem = Object.assign({},service.inventoryItem, item);
            return response;
        });
    }

    /**
     * @deprecated -- getItem is not fetched from system, but a classic item. 
     *                We test owner vs user to detect if user can actually  edit. 
     * this function should return an item from inventory.
     * It will be only used while editing an item, from item owner perspective
     */
    function getItem(item) {
        var itemid = item;
        if (_.isObject(item) && item.id) {
            itemid = item.id;
        }
        /**re-initializes the item to be able to work with item edit function*/
        service.inventoryItem = {
            cost: 0.00,
            owner: {},
            address: {
                query: ''
            },
            delivery: -1,
            starting: {
                thing: new Date()
            },
            ending: {
                thing: new Date()
            }
        };

        
        var _photo = _AVATAR;
        var endpoint = [Config.api, 'item', itemid].join('/');
        
        return $http.get(endpoint).then(function (response) {
            var item = response.data || response;
            item.photo = service.normalize(item.photo || _photo);
            if (item.tags && _.isArray(item.tags)) {
                item.tags = item.tags.join(', ');
            }

            service.inventoryItem = Object.assign({},service.inventoryItem, item);
            return response;
        });
    }

    /**
     * Deletes an Contact
     * @param item
     * @returns {*}
     */
    function removeContact(contact) {
        return $http.delete(Config.api + '/contact/' + (contact._id || contact.id), contact);
    }

    /**
     * Function used by PeopleService to Create/Update a contact
     */
    function contact(options) {
        if (!options) {
            throw ' Contact Editing has to have contact details ';
        }

        if (options._id) {
            return $http.put(Config.api + '/contact/' + options._id, options);
        }

        return $http.post(Config.api + '/contact', options);
    }

    /**
     * Recipient is person who receives messages
     * For protection purposes, server will check if I have access to this information
     * @param  {Object} options 
     * @return {Object}         
     */
    function getRecipient(options) {
        if (!options) {
            throw ' Recipient information is missing';
        }

        var id = _.isString(options) ? options : false;
        if (_.isObject(options) && options.id) {
            id = options.id;
        } else if (_.isObject(options) && options.recipient) {
            id = options.recipient;
        }

        if (!id) {
            throw 'Recipient id is required ';
        }

        if (!options.what) {
            options.what = 'message';
        }

        if (options.where) {
            options.what = options.where;
        }

        var _endpoint = [Config.api, options.what, id, 'recipient'].join('/');

        return $http.get(_endpoint);
    }

    function getIntents(options) {
        if (!options) {
            throw ' Recipient information is missing';
        }
        var id = _.isString(options) ? options : options;
        if (_.isObject(id) && id.id) {
            id = id;
        } else if (_.isObject(id) && id.recipient) {
            id = id.recipient;
        }

        if (!id) {
            throw ' Intent information is missing';
        }
        var endpoint = [Config.api, 'message', id, 'intents'].join('/');
        return $http.get(endpoint);
    }

    function getMessages(options) {
        var params = options || {};
        if (_.isObject(options) && options.recipient) {
            params.recipient = options.recipient;
        }

        if (_.isObject(options) && options.starting) {
            params.starting = options.starting;
        }

        $log.log('MessengerService::getMessages - options', options);
        return $http.get(Config.api + '/message/' + params.recipient + '/history', params);
    }
    /**
     * Feching un-read messages.
     * Used to show missed messages notification.
     * @param  {Object} options 
     * @return {Object}         
     */
    function getUnread(options) {
        var params = options || {};
        if (_.isObject(options) && options.recipient) {
            params.recipient = options.recipient;
        }

        if (_.isObject(options) && options.starting) {
            params.starting = options.starting;
        }
        var id = params.recipient || service.connectedUser._id || '';
        $log.log('MessengerService::getUnread - options');
        var endpoint = [Config.api, 'message', id, 'unread'].join('/');
        if (!params.recipient && !service.connectedUser._id) {
            endpoint = [Config.api, 'message', 'unread'].join('/'); //allowed
        }

        return $http.get(endpoint, params);
    }

    /**
     * Deletes an Item
     * @param item
     * @returns {*}
     */
    function removeItem(item) {
        return $http.delete(Config.api + '/item/' + item._id, item);
    }

    /**
     * @deprecated - this function has to be merged and replaced with sync.
     * Uploads the image
     * @param image
     * @returns {*}
     */
    function upload(image) {
        return $http.post(Config.api + '/upload', image);
    }

    /**
     * @see upload - upload also uploads a picture. 
     * @name sync - syncrhonizes image from local computer with the server. 
     * This function is used to upload a picture.
     * The result is used with Inventory Item, but Can also be used with Profile Picture.
     * It needs a refactoring.
     */
    function sync(image) {
        var endpoint = [Config.api, 'upload'];
        if (image && image.itemid) {
            endpoint.push(image.itemid);
        }
        endpoint = endpoint.join('/');
        return $http.post(endpoint, image).then(function (response) {
            service.inventoryItem = Object.assign({},service.inventoryItem || {}, response.data || response);
            //@todo normalize image if it is not normalized already
            return response;
        });
    }

    function removePhoto(image) {
        return $http.delete(Config.api + '/upload', image);
    }

    /**
     * Used with owner being the requestor 
     * The reponse from server is an Owner and a List of Reviewable instances
     * @param  {String} id - owner id of rented item
     * @return {Promise}
     */
    function getReview(id) {
        var user = service.getUser();
        var owner = user.id || user._id;
        var _endpoint = [Config.api, 'review', owner, id].join('/');
        return $http.get(_endpoint);
    }

    /**
     * The reponse from server is an Owner and a List of Reviewable instances
     * @param  {Object|String} params - owner id of rented item
     * @return {Promise}
     */
    function getReviews(params) {
        var _endpoint = [Config.api, 'reviews', params.owner || params.id].join('/');
        return $http.get(_endpoint);
    }

    /**
     * Post Review -
     * @param  {Object} review 
     * @return {Object}        
     */
    function postReview(review) {
        var endpoint = [Config.api, 'review'].join('/');
        return $http.post(endpoint, review);
    }




    /**
     * @deprecated - system/items API has been retired. Therefore all System related items are not usable.
     * Function to lookup items - it uses system's API instead of normal API.
     * @todo query.requestor is not initialized, when the link is sent with no
     *       requestor set!. ( eg: type the address and hit enter in the browser )
     * @todo change this, by changing the order in which the lookupItems gets called
     */
    function lookupItems(query) {
        var q = (query && query.itext) ? query.itext : '';
        if (service.connectedUser._id && !query.requestor) {
            query.requestor = service.connectedUser._id;
        }

        var endpoint = [Config.api, 'system/items', q].join('/');
        if (query && query.type === 'inventory') {
            if (query.business) {
                endpoint = [Config.api, 'system/business', query.business, 'inventory', q].join('/');
            } else if (query.requestor) {
                endpoint = [Config.api, 'system/user', query.requestor, 'inventory', q].join('/');
            }
        }

        return $http.get(endpoint);
    }

    /**
     * This function fetches orders from /incoming|/outgoing
     * @param query
     */
    function lookupOrders(query) {
        return $http.get(Config.api + '/incoming');
    }

    /**
     * @name lookupOrder - Fetches one single order.
     * This order is can be used by customer or item owner in various scenario.
     *  - update | cancel | change date | complete payment etc.
     * @param {Object<Options>}
     */
    function lookupOrder(options) {
        var id = typeof options === 'string'? options : options.id || options._id || options.oid || options;
        return $http.get([Config.api, 'order', id].join('/'));
    }



    /**
     * Possible order status updates
     * [CANCEL description]
     * @type {String}
     */
    service.CANCEL = 'cancel';
    service.REJECT = 'reject';
    service.FULLFILL = 'fullfill';
    service.SHIP = 'ship';
    service.REFUND = 'refund';
    service.FINALIZE = 'finalize';

    service.WAITING = 'Waiting'; //default status
    service.CANCELLED = 'Cancelled';
    service.REJECTED = 'Rejected';
    service.FULLFILLED = 'Fullfilled';
    service.SHIPPED = 'Shipped';
    service.REFUNDED = 'Refunded';
    service.FINALIZED = 'Finalized';

    /**
     * Model to be used to collect returned order/data
     * @type {{}}
     */
    service.changedOrder = {};

    /**
     * This function asks the server to cancel the order.
     * The only person who can cancels the order, is the requestor/renter.
     * The order cannot be cancelled two times.
     */
    function orderStatus(data) {
        if (!data || !_.isObject(data)) {
            throw 'Cancel Request Parameters not found';
        }

        //@todo get rid of requestor, we will use connected user on server side.
        data.requestor = service.connectedUser._id;
        data.status = data.status || 'cancel';
        return $http
            .post(Config.api + '/order/' + data.status, data)
            .then(function (data) {
                service.changedOrder = Object.assign({}, data);
                return data;
            });
    }

    /**
     * This function asks the server to cancel the order.
     * The only person who can cancels the order, is the requestor/renter.
     * The order cannot be cancelled two times.
     * @deprecated - replace with orderStatus
     */
    function cancelOrder(data) {
        if (!data || !_.isObject(data)) {
            throw 'Cancel Request Parameters not found';
        }

        data.requestor = service.connectedUser._id;
        return $http
            .post(Config.api + '/order/cancel')
            .then(function (data) {
                service.changedOrder = Object.assign({}, data);
                return data;
            });
    }

    /**
     * This function asks the server to accept the order.
     * The only person who can accept the order, is the item owner.
     * It is a good practice to accept the order, if can be processed.
     * @deprecated - replaced by orderStatus
     */
    function acceptOrder(data) {
        if (!data || !_.isObject(data)) {
            throw 'Cancel Request Parameters not found';
        }

        data.requestor = service.connectedUser._id;
        return $http
            .post(Config.api + '/order/accept', data)
            .then(function (data) {
                service.changedOrder = Object.assign({}, data);
                return data;
            });
    }

    /**
     * Model to be used when collecting data as response.
     * @type {{}}
     */
    service.rejectedOrder = {};

    /**
     * This is the equivalent to cancel the order, but it is next by the item owner.
     * This action should be next once for all.
     * @deprecated - replaced by order status
     */
    function rejectOrder(data) {
        if (!data || !_.isObject(data)) {
            throw 'Cancel Request Parameters not found';
        }

        data.requestor = service.connectedUser._id;
        return $http
            .post(Config.api + '/order/reject', data)
            .then(function (data) {
                service.rejectedOrder = Object.assign({}, data);
                return data;
            });

    }

    /**
     * @todo calculate the sum, show the status, order by most recent order.
     * @todo only allow one item to be displayed at a time.
     * This order is formatted to be displayed on Order Details.
     * The first Client to use this funciton is hoogyOrder Directive at hoogyOrder::init
     * @param order
     *  Allowed statuses ( Waiting, Declined, Completed (xMoney Paid)
     */
    function formatOrder(order) {


        if (!order) {
            throw 'It looks like there is no Order to format!';
        }

        var _copy = {};
        _copy.period = {};
        _copy.shipping = {};
        _copy.payment = {};
        _copy.renter = {};
        _copy.owner = {};

        /** Possible values: or alert-success alert-info */
        _copy.statusClass = 'alert-warning';
        /**
         * will be used to display order summary
         * @type {string}
         */
        _copy.first = 'A customer';
        /**
         * will be used to display item name details
         * @todo add either a URL to the product, or a popover with item details + availability.
         * @type {string}
         */
        _copy.smallTitle = 'Item';
        /**
         * _copy
         * will be used to display the number of days renter is willing to rent the item.
         * @type {number}
         */
        _copy.period = {
            upto: {
                date: new Date()
            },
            from: {
                date: new Date()
            }
        };
        if (order.period && order.period[0] && order.period[0].upto && order.period[0].from) {
            _copy.period = order.period[0];
        }
        _copy.days = moment(_copy.period.upto.date).diff(moment(_copy.period.from.date), 'days') || 1;
        //progressively removing the [from,upto] notation
        if (_copy.period.starting && _copy.period.ending) {
            _copy.days = moment(_copy.period.starting.date).diff(moment(_copy.period.ending.date), 'days') || 1;
        }
        if(order && order.shipping && order.shipping[0])_copy.shipping = order.shipping[0] || {};
        if(order && order.payment && order.payment[0])_copy.payment = order.payments[0] || {};
        if (typeof _copy.period.from.date === 'string') {
            _copy.period.from.date = new Date(_copy.period.from.date);
        }

        if (typeof _copy.period.upto.date === 'string') {
            _copy.period.upto.date = new Date(_copy.period.upto.date);
        }

        var _cuser = service.connectedUser._id;
        if (_cuser && _cuser.length) {
            _cuser = _cuser.toString();
        }

        _copy.renter = _formatUser(order.renter) || {
            _id: ''
        };
        _copy.owner = _formatUser(order.owner) || {
            _id: ''
        };
        _copy.isRenter = _copy.renter._id.toString() === _cuser;
        _copy.isOwner = _copy.owner._id.toString() === _cuser;

        //to avoid un-set variables
        _copy.renter.first = (_copy.renter.name || '').split(' ')[0] || _copy.first;
        if(order && order.item) order.item.smallTitle = ((order.item || {}).title || (order.item || {}).name || '').substr(0, 100);

        return Object.assign({}, order, _copy);
    }

    /**
     * This function re-initialize all data models, after logout
     * @name
     * @return {Object} 
     */
    function reset() {
        service.stats = [];
        service.checkout = {};
        service.userSettings = {};
        service.connectedUser = {};
        service.connectedFormatted = {};
        service.invitation = {};
        service.invitationError = {};
        service.invitesError = {};
        service.invitedFriends = [];

    }


    return service;
}
HoogyService.$inject = ['$http', '$auth', '$filter', '$log', '$q', 'Angularytics', 'NotificationService'];
angular.module('hoogy').service('HoogyService', HoogyService);