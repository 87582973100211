'use strict';

/**
 * @name CheckoutItemController - CreditCard Checkout 
 */
function CheckoutItemController($state, $timeout, HoogyService, CheckoutService, UIService, NotificationService, StateService, ActionCreator, Angularytics) {
    var timeoutId;
    var vm = this; //required to be linked in forms 

    /**
     * @name $onInit - Initialization Handler.
     */
    this.$onInit = function () {
        this.screen = UIService.getItemCheckoutScreen({
            component: 'checkout'
        });
        StateService.dispatch(ActionCreator.addScreen(this.screen));        
        this.model = CheckoutService.getModel(); //model related to data being used 
        this.unsubscribe = this.subscribe();
        this.init($state.params);
    };

    /**
     * @name subscribe - Subscription on state
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false : StateService.subscribe(function () {
            vm.model = CheckoutService.getModel();
            vm.screen = UIService.getItemCheckoutScreen(vm.screen);
            vm.screen.notifications = NotificationService.getModel(); 
        });
    };


    /**
     * @deprecated - move this function to itemScreen Component. 
     * @todo move more calculations in CheckoutService::fetchItem(params)
     * Function to initialize Item Screen.
     * This function makes a double check of item availability.
     */
    this.init = function (options) {
        var params = options || $state.params;
        return CheckoutService.fetchItem(params).then(function (response) {
            vm.model = CheckoutService.getModel();
            vm.screen.url = [AppConfig.Config.server, '/item/', vm.model.item._id || vm.model.item.id].join('');
            Angularytics.trackEvent('Checkout - Complete Item Details Screen', +1);
            return response;
        }).catch(function (error) {
            NotificationService.report(error);
            Angularytics.trackEvent('Checkout - Failed Item Details Screen - Error', +1);
            timeoutId = $timeout(function () {
                $state.go('workspace.discover');
            }, 500);
            return error;
        });
    };


    /**
     * @param {FormController} form
     */
    this.save = function (form) {
        if (!form || form.$invalid) {
            throw 'Item form should be valid.';
        }
        this.next(form);
    };


    /**
     * @todo replace this methods with simple functions.
     * @todo support previous step.
     * Save and go to next step next
     */
    this.next = function (form) {
        //errors are being displayed in-form
        if (!form || !form.$name || form.$invalid || form.$hasErrors) {
            throw 'Checkout steps require a valid form';
        }
        if (this.screen.waiting) {
            return false;
        }
        this.screen.waiting = true;
        var options = {
            action: form.$name,
            data: CheckoutService.getModel().item
        };
        return CheckoutService.checkoutItem(options).then(this._saveCheckoutHandler).catch(this._handleException);
    };
    

    /**
     * @name _saveCheckoutHandler - Handles Save Response.
     */
    this._saveCheckoutHandler = function (response) {
        vm.screen.active = 'contract';
        vm.screen.waiting = false;
        var order = CheckoutService.getModel();
        var oid  = typeof order.order === 'string' ? order.order : order.id || order._id;
        Angularytics.trackEvent('Checkout - Completed Date Step', +1);
        $state.go('checkout.contract', {oid: oid});
        return response;
    };
    /**
     * @name _handleException - handles exceptions when server sends error code.
     * @param {Object} error - object passed along from request promise 
     */
    this._handleException = function (error) {
        vm.screen.waiting = false;
        var notice = NotificationService.formatCheckoutFailureMessage(error);
        NotificationService.report(notice);
        Angularytics.trackEvent('Checkout - Exception', notice.message);
        return error;
    };
    /**
     * @name changedRentalPeriod - Utility for changing the rental period. 
     * @param {Object<Period{starting, ending, value, text}>} period 
     * Callback to hoogy-price directive.
     * It calculates price based on hours/days/weeks/months or semesters.
        //user selects 10 Days, Item charges per day - total time is calculated as following
     */
    this.changedRentalPeriod = function (period) {
        if(!period) throw '@changedRentalPeriod Period is Required Exception';
        var item = Object.assign({}, CheckoutService.getModel().item);
        var total = CheckoutService.calculatePrice(item, period);
        this.screen.waiting = false;
    };

    /**
     * @example 
     *  <code>
     *     <hoogy-datetime next="vm.period(date)"/>
     *  </code>
     * @name period - used with Date Helper Callback. It Sets Starting Date.
     * @uses CheckoutItemController::changeRentalPeriod 
     * @param {Object<Date{calculated, date, formatted, thing, time}>}
     */
    this.period = function (date) {
        if (!date) throw 'Date is Requierd @Item:period';
        var elements = Object.keys(date);
        if (elements.indexOf('formatted') < 0 ||
            elements.indexOf('thing') < 0 ||
            elements.indexOf('calculated') < 0) {
            throw 'Date Not Well Formatted [' + elements.join(',') + '] @Item:Period'
        }
        var period = Object.assign({}, {starting: date}, CheckoutService.getModel().period);
        this.changedRentalPeriod(period);
    };


    /**
     * @name suggestion - making a suggestion
     * @param {Object} item - Item to be suggested
     * @todo - vm.suggestion should be moved to a Service :: (ItemService||HoogyService)
     * Local Likes(Heart/Preference)
     * @return {Promise}
     */
    this.suggestion = function (item) {
        if (!item || _.isEmpty(item)) throw 'Item not Found Exception';
        return HoogyService.addFavorite(item)
            .then(function (response) {
                var data = response.data || response;
                var _message = data.message || 'Suggestion added';
                var type = NotificationService.FAILURE;
                NotificationService.notify({
                    message: _message,
                    type: type
                });
                Angularytics.trackEvent('Checkout Item Like', item.title + ' - ' + item._id);
                return response;
            }).catch(this._handleException);
    };


    /**
     * Callback to share a link. 
     */
    this.share = function () {
        return HoogyService.share(vm.model.item);
    };

    /**
     * @todo - share the url, when the Card is approved, people will only need to share the card.
     * Which has to include Photo and Text of current Card.
     * @link http://stackoverflow.com/questions/21478891/twitter-card-share-button
     */
    this.tweet = function () {
        Angularytics.trackEvent('Twitter share - init', CheckoutService.getModel().item.title + ' - ' + CheckoutService.getModel().item._id);
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
            this.unsubscribe = null;
        }
        if (timeoutId) $timeout.cancel(timeoutId);
        timeoutId = null;
    };


}
CheckoutItemController.$inject = ['$state', '$timeout', 'HoogyService', 'CheckoutService', 'UIService', 'NotificationService', 'StateService', 'ActionCreator', 'Angularytics'];
angular.module('hoogy').component('itemCheckout', {
    controllerAs: 'vm',
    controller: CheckoutItemController,
    templateUrl: 'js/checkout/view/item.html'
});