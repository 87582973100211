'use strict';

/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/  
/**
 * @name NotificationsWidgetController 
 * @param {$filter}  
 * @param {DiagnoseService} DiagnoseService 
 * @param {NotificationService} NotificationService 
 * @param {HoogyService} HoogyService 
 * @param {StateService} StateService 
 * @param {ActionCreator} ActionCreator 
 * @param {MessengerService} MessengerService 
 */
function NotificationsWidgetController($filter, DiagnoseService, NotificationService, HoogyService, StateService, ActionCreator, MessengerService) {

    var vm = this;
    /**
     * @name $onInit - Widget Initializations.
     */
    this.$onInit = function(){
        this.count = 0;
        this.durl = HoogyService.AVATAR;
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.unsubscribe = this.subscribe();
        this.init(); 
    };

    /**
     * @name subscribe - Subscribes to Notifications Updates.
     */
    this.subscribe = function(){
        return typeof this.unsubscribe === 'function' ? false: StateService.subscribe(function(){
            vm.model.notifications = (Object.values(NotificationService.getNotifications()) || []).filter((n) => n.type && n.type.length > 0);
        });
    };

    /**
     * @name init - Function used to initialize chatt
     * @return {Promise} 
     */
    this.init = function () {
        if(!this.screen) throw '@NotificationsWidgetController:init requires Screen';
        this.model = {unread: [],dropdown: false, limit: 10, notifications: []};
        return MessengerService.getUnread().then(vm.notificationCallback);//should die silenty
    };

    /**
     * @name notificationCallback - Used with both Subscribe and Reponse from Server.
     * @param {Object} - state|response
     */
    this.notificationCallback = function(state) {
        vm.count = 0;
        vm.model.notifications = (Object.values(NotificationService.getNotifications()) || []).filter((n) => n.type && n.type.length > 3);
        vm.model.unread = MessengerService.getModel().unread || model.unread || {};
        if (!vm.model.unread && state && state.data) {
            //for some reasons the test were not passing
            MessengerService.updateUnreadList(state.data);
            vm.model.unread = MessengerService.getModel().unread;
            DiagnoseService.diagnoseMessages(vm.model.unread);
        }

        vm.model.unread = vm.model.notifications || vm.model.unread;
        vm.count = _.size(vm.model.notifications);

        //displays notifications depending on context
        if (!vm.screen || vm.screen.action === 'notifications') {
            vm.model.notifications = vm.model.unread;
            vm.model.dropdown = true; //disable dropdown
            vm.model.limit = 5;
            vm.model.notifications = $filter('limitTo')(vm.model.notifications, 5, 0);
        } else if (vm.screen && vm.screen.action === 'messages') {
            vm.model.notifications = vm.model.unread;
            vm.model.dropdown = true; //disable dropdown
            vm.model.limit = 10;
        }

        return state;
    }

    /**
     * @name more - More Items for listing. 
     */
    this.more = function() {
        vm.model.more = !vm.model.more ? vm.model.limit : (vm.model.more + vm.model.limit);
        if (vm.model.more > _.size(vm.model.notifications)) {
            vm.model.more = 0;
        }
        vm.model.notifications = $filter('limitTo')(vm.model.notifications, vm.model.limit, vm.model.more);
    };

  
    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };
}

NotificationsWidgetController.$inject = ['$filter', 'DiagnoseService', 'NotificationService', 'HoogyService', 'StateService', 'ActionCreator', 'MessengerService'];

/**
 * @name NotificationWidgetController - A Widget that will serve to display multiple Notifications.
 * @link https://trello.com/c/ygdQmvsv
 */
function NotificationWidgetController($state, $sce) {
    var vm = this;

    /**
     * @name $onInit - On Initialization 
     */
    this.$onInit = function(){
        this.description = "";
        this.init();
    };

    this.init = function(){
        this.action = {};//default action model
        this.alt = {};//alternative action model
        if(!this.notification) throw '@NotificationWidgetController:init require Binding Notification';
        if (this.notification.actions) {
            this.action = this.notification.actions[0] || this.action;
        }
        if (this.notification.created) {
            this.notification.created = moment(this.notification.created).fromNow();
        }
        //
        if(this.notification.description){
            this.description = $sce.trustAsHtml(this.notification.description);
        }
    };

    /**
     * @name takeAction - Allows notification to use
     * @return {void} 
     */
    this.takeAction = function takeAction() {
        if (this.action && this.action.url) {
            if (typeof this.action.url === 'object' && this.action.url.id) {
                $state.go(this.action.url.text, { recipient: this.action.url.id });
            } else {
                $state.go(this.action.url);
            }
        }
    };

    /**
     * @name altAction - Alternative action such as Cancel, Delete, Etc.
     * @return {void}
     */
    this.altAction = function altAction() {
        if (this.alt && this.alt.url) {
            $state.go(this.alt.url);
        }
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        this.action = {};//default action model
        this.alt = {};//alternative action model
      
    };
}
NotificationWidgetController.$inject = ['$state', '$sce'];

/**
* @name NotificationController - ngMessages may be an alternative to this notification implementation
* @link https://docs.angularjs.org/api/ngMessages
*/
function NotificationController() {

    var vm = this;
    var kinds = ['positive', 'success', 'info', 'warning', 'negative','error'];
    /**
     * @name $onInit - Initialization 
     */
    this.$onInit = function(){
        this.init(); 
    };

    /**
     * @name init - Initialization Function 
     */
    this.init = function () {
        this.kind = '';
        //this.notification is bidirectionally bound. 
        if(!this.notification) throw '@NotificationWidgetController:init require Binding Notification';
        if (this.notification.positive) this.kind = 'positive';
        if (this.notification.success) this.kind = 'success';
        if (this.notification.info) this.kind = 'info';
        if (this.notification.warning) this.kind = 'warning';
        if (this.notification.negative) this.kind = 'negative';
        if (this.notification.error) this.kind = 'error';
        this.visibility = 'visible';
    };

    /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function(){
        this.kind = '';   
    };
}
//NotificationController.$inject = [''];


angular
    .module('hoogy')
    .component('hoogyNotification', {
        bindings: { notification: '=' },
        controller: NotificationController,
        controllerAs: 'vm',
        templateUrl: 'js/common/components/view/notification.html'
    })
    .component('notificationWidget', {
        controllerAs: 'vm',
        bindings: { notification: '=' },
        controller: NotificationWidgetController,
        templateUrl: 'js/common/components/view/notificationwidget.html'
    })
    .component('notificationsWidget', {
        controllerAs: 'vm',
        bindings: { screen: '=' },
        controller: NotificationsWidgetController,
        templateUrl: 'js/common/components/view/notificationswidget.html'
    });