'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/  
/**
 * This library is used to collect bank information
 * 	+ identification validation
 * Bank needed is Owner Full name.
 * Bank [Transit Number[5 digits]] - [Institution Number[4 digits]]
 * 		- [Account Number[7 digits]]
 *
 * Verification Information
 * - Scan of Identification
 * - SIN of individual who owns this account(valid representative)
 */
function BankController(StateService, ActionCreator) {

  var vm = this; 
  
  /**
   * @name $onInit - Initialization of Bank Component.
   */
  this.$onInit = function(){ 
    StateService.dispatch(ActionCreator.addScreen(this.screen));    
  };
  
  /**
   * @name reset - Resetting Form
   */
  this.reset = function (form) {
    if (form && typeof form.$setPristine === 'function') {
      form.$setPristine();
    }
  };

  /**
   * @name save - Save Form
   * @link
   * https://stripe.com/docs/stripe.js#collecting-bank-account-details
   */
  this.save = function (form) {

    if(!form || !form.$valid || form.$invalid){
      throw 'Bank form should be valid';
    }
    
    this.next({form: form});
  };
}
BankController.$inject = ['StateService', 'ActionCreator'];
/**
 * Hoogy Bank Directive 
 */
angular.module('hoogy').component('hoogyBank', {
    bindings: {screen: '=', next: '&'},
    controllerAs: 'vm',
    controller: BankController,
    templateUrl: 'js/common/components/view/bank.html'
  });
