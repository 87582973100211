'use strict';
/**
 * @name DiscoverController 
 * @param {$state}  
 * @param {Object} HoogyService 
 * @param {Object} StateService 
 * @param {Object} ActionCreator 
 * @param {Object} RentingService 
 * @param {Object} SearchService 
 * @param {Object} NotificationService 
 * @param {Object} DiagnoseService
 * @param {Object} UIService 
 * @param {Object} Angularytics 
 */
function DiscoverController($state, HoogyService, StateService, ActionCreator, RentingService, SearchService, NotificationService, DiagnoseService, UIService, Angularytics) {
    var vm = this;

    /**
     * @name $onInit - Initialized Discover Screen.
     */
    this.$onInit = function () {
        this.model = SearchService.getModel();
        this.model.user = HoogyService.getUser();
        this.model.notification = {}; 
        this.screen = UIService.getDiscoverWorkspaceScreen({
            component: 'workspace:discover'
        });
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        StateService.dispatch(ActionCreator.showHideRootLoading({value: false}));        
        StateService.dispatch(ActionCreator.showHideSidebar({value: false}));  
        this.unsubscribe = this.subscribe();
        this.omnibox = true; //shows the omnibox
        Angularytics.trackEvent('Workspace Discover - Initialized', +1);
    };

    /**
     * @name subscribe - Subscribes for Model Updates.
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false : StateService.subscribe(function () {
            vm.model.items = SearchService.getModel().items;
            vm.screen = UIService.getDiscoverWorkspaceScreen(vm.screen);
            vm.model.notifications = NotificationService.getModel();

            vm.model.notification = {}; 
            var context = {
                type: DiagnoseService.ADD_INVENTORY,
                category: 'suggestion',
                context: DiagnoseService.SETTINGS_CONTEXT
            };
            var notification = NotificationService.getNotificationByContext(context);
            //@todo add additional notifications. 
            if(!_.isEmpty(notification)) vm.model.notification = notification;  
            //this.screen = UIService.getWorkspaceInventoryScreen({component: 'workspace:inventory'});
            //vm.model.items = SearchService.getModel().inventory;
            //vm.screen = UIService.getWorkspaceInventoryScreen(vm.screen);
            
            //vm.model.items = SearchService.getModel().wishlist || vm.model.items || [];
            //this.screen = UIService.getWishlistScreen({component: 'workspace:wishlist'});
            //vm.model.items = SearchService.getModel().wishlist;
        });
    };
   /**
     * @name $onDestroy - cleans objects when components dies.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };


    


}
DiscoverController.$inject = ['$state', 'HoogyService', 'StateService', 'ActionCreator', 'RentingService', 'SearchService', 'NotificationService', 'DiagnoseService', 'UIService', 'Angularytics'];
/**
 * @name discover - A Component to discover available stuff on website. 
 */
angular.module('hoogy').component('discover', {
    controllerAs: 'vm',
    templateUrl: 'js/workspace/view/discover.html',
    controller: DiscoverController
});