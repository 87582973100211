'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/


/**
 * @name PeopleService - Used with Peers Components and alike
 * @todo ---- Add Search Function to be used while using PeesWidget and alike.
 * @todo ---- Search Function uses SearchService.omnisearch
 * @param {*}  
 * @param {*}  
 * @param {*} HoogyService 
 * @param {*} DiagnoseService 
 * @param {*} StateService 
 * @param {*} ActionCreator 
 */
function PeopleService($log, $q, HoogyService, SearchService, DiagnoseService, StateService, ActionCreator) {


    var me = {};


    var model = {
        item: {},
        invitations: [],
        invitables: [],
        reviews: [],
        reviewer: {},
        review: {},
        wishlist:[],
        collections: [],
        person: {},
        population: [],
        people: [],
        contacts: [], 
        contact: {}
    };

    var service = {

        omnisearch: omnisearch,

        getModel: getModel,
        findInvitedFriends: findInvitedFriends,
        
        getMe: getMe,
        getProfile: getProfile,
        
        claimInvite: claimInvite,
        formatAddress: formatAddress,
        formatContact: formatContact,
        formatInvite : formatInvite,

        saveContact: saveContact,
        removeContact: removeContact,
        AVATAR: HoogyService.AVATAR,


        // Suppsedly to to be priavate
        _successContact: _successContact,
        _errorContact: _errorContact

    };

    return service;


    /**
     * @name omnisearch - Works People Related Search.
     *                  - In addition to Results, it Adds Additional Objects such as User/etc. 
     * @uses SearchService:omnisearch
     * @param {Object<Options>} options 
     */
    function omnisearch(options){
        var params = Object.assign({}, options);
        return SearchService.omnisearch(params).then(function(response){
            //@todo merge ServiceModel with Current model
            return response;
        });
    }   


    /**
     * @name findInvitedFriends - Wrapper around HoogyService::findInvitedFriends
     *      Finds User Invitations Sent Out to Invite Friends To Join 
     * @uses HoogyService::findInvitedFriends
     * @uses StateService::dispatch
     * @uses ActionCreator::AddUserInvitedFriends
     * @param {Object<Parameters>} params 
     * @returns {Promise<Response>}
     */
    function findInvitedFriends(params) {
        var transaction = Object.assign({}, service.getModel());
        return HoogyService.findInvitedFriends().then(function (response) {
            transaction.invitations = HoogyService.getInvitedFriends() || [];
            transaction.invitables = Object.values(transaction.invitations).slice(0, 10);
            StateService.dispatch(ActionCreator.addUserInvitedFriends(transaction));
            return response;
        });
    };


    /**
     * @name getModel - Accessor to People Model data structure. 
     * @param {String|Optional} which - indicates a model to return
     *                                - Helper to access to a specific model. 
     * @return {Object} model 
     */
    function getModel(which) {
        var people = StateService.getState().people;
        var state = Object.assign({}, model, {
            item: people.item || model.item ,
            invitations: people.invitations || model.invitations,
            invitables: people.entities.invitables || model.invitables,
            reviews: people.entities.reviews || model.reviews,
            reviewer: people.reviewer || model.reviewer,
            review: people.review || model.review,
            collections: people.collections || model.collections,
            person: people.person || model.person,
            population: people.entities.population || model.population,
            people: people.entities.people || model.people,
            contacts: people.entities.contacts || model.contacts, 
            contact: people.contact || model.contact
        });

        return state[which] || state;
    }

    /**
     * @name _findReview - Identifies reviews belonging to currently connected reviewer
     * @param {Collection{Object<Review>}} reviews 
     */
    function _findReview(reviews) {
        var reviewer = HoogyService.getUser()._id;
        var review = {
            reviewer: reviewer
        };
        return _.each(reviews || [], function (r) {
            if (r.reviewer && r.reviewer._id === reviewer) {
                review = r;
                return review;
            }
        }) || review;
    }

    /**
     * @name getMe - Formats Editable Connected User Data
     * @uses HoogyService.getMe
     * @return {Object<Me>} Instance of Connected Ediatable User Settings.
     */
    function getMe() {
        //initialization 
        var state = StateService.getState().me;
        return Object.assign({}, state, HoogyService.getUser(), {
            local: state.local || {}, 
            profile: state.profile || state.local || {},
            wallet: state.wallet || {},
            facebook: state.facebook || {},
            google: state.google || {},
            contact: getContract(state.contract),
            password: state.password,
            wishlist: state.wishlist || [],
            invites: state.invites || [],
            bank: state.bank || {},
            photo : state.photo || {},
            card: state.card || {},
            cards: state.cards || [],
            identity: state.identity || {},
            payment: state.payment || {},
            address: state.address || {}
        });
        /**
         * @name getContract - Formats and Returns a contract Object.
         * @param {String|Object} contract 
         */
        function getContract(contract){
            return _.isString(contract) ? {
                text: contract
            } : {text: '', enabled: false};
        }
    }

    /**
     * @name getProfile - Function used to query profile for public use
     * @param {Object<Params>}
     * @uses StateService::dispatch 
     * @uses ActionCreator::addProfile
     * @param  params.id - User profile.
     * @return {Promise} - chainable promise.
     */
    function getProfile(params) {
        var _user = HoogyService.getUser();
        var transaction = Object.assign({}, service.getModel());
        

        var options = {
            id: params.id
        };
        return HoogyService.getProfile(options).then(function (response) {
            var _response = response.data;
            if (!_response && response) {
                _response = response;
            }
            var profile = Object.assign({}, model, {
                person: HoogyService.formatUser(_response.person || _response.user || _response),
                collection: _response.collection || _response.items,
                reviews: _response.reviews,
                review: _findReview(transaction.reviews)
            });
            

            DiagnoseService.diagnoseReviews(profile.reviews);  
            StateService.dispatch(ActionCreator.addProfile(profile));            
            return response;
        });
    }

    //private function to call after successfull response
    function _successContact(response) {
        var transaction = Object.assign({}, service.getModel());
        if (!transaction.contacts) {
            transaction.contacts = [];
        }

        transaction.contacts = response.data || response;
        $log.log('HoogyService::saveContact -- success');
        return response; //in case we need to chain to another promise
    }

    function _errorContact(error, status, headers, config) {
        var transaction = Object.assign({}, service.getModel());
        transaction.contacts = {};
        transaction.contactsError = {
            message: error.message || error,
            status: status
        };
        NotificationService.report(error);
        return error; //to be chainable to another promise
    }

    /**
     * @name saveContact - Function serving for creating a new contact.
     * It also serves to update an existing contact.
     * @param {Object<Contact>} contact
     * @param {Object<Boolean>} invite 
     */
    function saveContact(contact, invite) {
        var transaction = Object.assign({}, service.getModel());
        var _contact = formatContact(contact);
        if (!!invite) {
            return HoogyService.contact(_contact).then(HoogyService.inviteAlt).then(service._successContact);
        }   
        return HoogyService.contact(_contact).then(service._successContact);
    }

    /**
     * @name claimInvite - ClaimInvite is different in a sense that
     * 	User will not be required to be authenticated.
     * @param  {Object<Params>} params 
     * @return {Promise}        
     */
    function claimInvite(params) {
        return HoogyService.inviteAlt(params).then(function (response) {
                //@todo check if there is something to assign here.
                return response;
            });
    }

    /**
     * @name removeContact - Function will be used to delete contact.
     * @param {Object<Contact>}
     * @return {Promise} 
     */
    function removeContact(contact) {
        var _contact = service.formatContact(contact);
        var transation = service.getModel('contacts');
        return HoogyService.removeContact(contact).then(function (response) {
                if (!transation.contacts) {
                    transation.contacts = [];
                }
                var contacts = transation.contacts;
                transation.contacts = _.reject(contacts, function (p) {
                    return p._id === contact._id;
                });
                StateService.dispatch(ActionCreator.removeContact(transation));            
                return response;
            });
    }

    /**
     * @name formatAddress - Format a default address
     * @param {Object<Contact>} contact - Object representing a contact
     */
    function formatAddress(contact) {
        contact = contact || {};
        var _whitelist = ['street', 'civic', 'suite', 'city', 'prs', 'state',
            'country', 'zip', 'latitude', 'longitude', 'verified'
        ];
        var address = _.pick(contact.address || contact, _whitelist) || {};
        return Object.assign({}, {
            zip: '',
            civic: '',
            suite: '',
            street: '',
            latitude: '',
            longitude: '',
            country: 'Canada',
            city: 'Montreal',
            prs: this.state || 'QC',
            state: this.prs || 'QC',
            verified: false
        }, address);
    }

    /**
     * @name formatInvite
     * Params
     * 	- invitation : will be created on server side
     * 	- Final status : will be determined on server, but default is Issued
     * 	- type: String, default: 'Issued'
     * @param {Object<Contact>}
     */
    function formatInvite(contact) {
        var _whitelist = ['email', 'invitation', 'status'];
        var invite = {
            email: contact.email,
            status: contact.invite ? contact.invite.status : 'Issued'
        };
        if (contact.invite && contact.invite.invitation) {
            invite.invitation = contact.invite.invitation;
        }

        return invite;
    }

    function isValid(address) {
        var _valid = false;
        if (address.name && (address.email || address.telephone)) {
            _valid = true;
        }

        return _valid;
    }

    /**
     * @name
     * @param  {Object} contact 
     * @return {Object}         
     */
    function formatContact(candidate, invite) {
        //me: currently connected individual
        //friend: populated other person is on hoogy
        var _whitelist = [
            'name', 'email', 'telephone', 'source', 'address',
            'username', 'me', 'friend', 'friendship', 'invite'
        ];
        var contact = _.pick(candidate || {}, _whitelist) || {};
        contact.address = service.formatAddress(contact);
        if (!contact.source) {
            contact.source = 'Manual';
        }

        if (!contact.friendship) {
            contact.friendship = 'Contact';
        }

        if (!contact.username) {
            contact.username = contact.email;
        }

        //@todo add invite only if save+invite === true
        contact.member = !!candidate.friend;
        if (invite === true) {
            contact.invite = service.formatInvite(contact);
        }

        return contact;
    }
}
PeopleService.$inject = ['$log', '$q', 'HoogyService', 'SearchService', 'DiagnoseService', 'StateService', 'ActionCreator'];
angular.module('hoogy').factory('PeopleService', PeopleService);