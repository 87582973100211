'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/
/**
 * @name Routing 
 * Utility to format Stripe routing number
 */
function Routing() {
    function link(scope, element, attrs, ctrl) {
        var country = attrs.country === 'CA' ? 'CA' : 'US';
        element.on('keyup', function () {
            scope.$apply(function () {
                //Canadian routing numbers have '11111-111', US don't
                var v = Stripe.bankAccount.validateRoutingNumber(element.val(), country);
                ctrl.$setValidity('validrouting', v);
            });
        });

        /**
         * Cleanup - try to destroy memory leak substible 
         */
        function cleanup() {
            if (angular.isElement(this)) { return scope.$destroy(); }
            if (element && typeof element.off === 'function') {
                element.off();
            }
            if($(element) && typeof $(element).off === 'function'){
                $(element).off();
            }
        }
        scope.$on('$destroy', cleanup);
        element.on('$destroy', cleanup);
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        link: link
    };

}
angular.module('hoogy').directive('routing', Routing);
