'use strict';
/**
 * Used for to ensure authentication
 */
function HoogyHeaderController($state, $auth, HoogyService, AuthService, PeopleService, StateService, ActionCreator, NotificationService) {

    var vm = this;

    /**
     * @name $onInit 
     */
    this.$onInit = function () {
        this.model = AuthService.getModel();
        this.unsubscribe = this.subscribe();
        this.model = Object.assign({}, {
            user: HoogyService.getUser()
        }, PeopleService.getMe());
        StateService.dispatch(ActionCreator.addScreen(this.screen));
        this.init();
    };


    /**
     * @name subscribe - used with notification subscriptions.
     */
    this.subscribe = function () {
        return typeof this.unsubscribe === 'function' ? false : StateService.subscribe(function () {
            vm.model = AuthService.getModel();
            vm.count = _.size(NotificationService.getNotifications());
            if (vm.count > 10) {
                vm.count = '10+';
            }
            vm.model = Object.assign({}, {
                user: HoogyService.getUser()
            }, PeopleService.getMe());
            vm.model.notifications = NotificationService.getModel();
            vm.model.notification = {};
        });
    };

    /**
     * @name init 
     */
    this.init = function () {
        this.model.user = HoogyService.getUser();
    };

    /**
     * @name hamburger - Function that Emits Event to show/Hide sidebar. 
     */
    this.hamburger = function () {
        //up or down 
        var sidebar = {value: !this.screen.up};
        var usermenu = {value: false};
        StateService.dispatch(ActionCreator.showHideSidebar(sidebar));
        StateService.dispatch(ActionCreator.showUsermenu(usermenu));
        if (!this.isAuthenticated()) {
            return AuthService.unauthorized(true);
        }
    };


    /**
     * @name usermenu - Function that Emits Event to Show/Hide usermenu.
     * Redirects to Connected customer Settings+profile
     */
    this.usermenu = function () {
        if (!this.isAuthenticated()) {
            return AuthService.unauthorized(true);
        }
        var sidebar = {value: false};
        var usermenu = {value: !this.screen.menup};
        StateService.dispatch(ActionCreator.showUsermenu(usermenu));
        StateService.dispatch(ActionCreator.showHideSidebar(sidebar));        
        $state.go('settings.profile');
    };

    /**
     * @name isAuthenticated - Checks if user is authenticated. 
     */
    this.isAuthenticated = function () {
        return AuthService.isAuthenticated();
    };

    /**
     * @name auth - forces user to Authenticate
     */
    this.auth = function () {
        NotificationService.unauthorized();
        return;
    };

    /**
     * @name logout - Forces user to logout.
     */
    this.logout = function () {
        AuthService.logout().then(function () {
            $state.go('store.list');
        }).catch(function () {
            NotificationService.unauthorized();
            $state.go('store.list');
        });
    };

    this.listYourStuff = function () {
        var payload = {};
        StateService.dispatch(ActionCreator.removeStuff(payload));
        $state.go('stuff');
    };

    /**
     * @name $onDestroy - used to unregister subscriptions.
     */
    this.$onDestroy = function () {
        if (typeof this.unsubscribe === 'function') {
            this.unsubscribe();
        }
        this.unsubscribe = null;
    };

}
HoogyHeaderController.$inject = ['$state', '$auth', 'HoogyService', 'AuthService', 'PeopleService', 'StateService', 'ActionCreator', 'NotificationService'];

angular
    .module('hoogy')
    .component('hoogyHeader', {
        controllerAs: 'vm',
        bindings: {
            screen: '='
        },
        controller: HoogyHeaderController,
        templateUrl: 'js/core/header.html'
    });