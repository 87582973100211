'use strict';
/*global _:true*/
/*global angular:true*/
/*global moment:true*/
/*global Stripe:true*/
/*global AppConfig:true*/  
/**
 * @name HoogyOrderController - Order Controller. 
 * @param HoogyService - Object HoogyService
 * E Tile stands for Editable Tile.
 * The reason of having two tiles is to isolate editing functionality when the user hovers the tile.
 * @link http://www.bennadel.com/blog/2595-using-scope-digest-as-a-performance-optimization-in-angularjs.htm
 * HoogyService.formatOrder
 */
function HoogyOrderController($timeout, HoogyService) {
    var vm = this;
    
    /**
     * This function has to format the order in a such a way
     * 	it becomes easy to display
     */
    var cssClasses = {
        waiting: 'alert-info',
        cancelled: 'alert-danger',
        rejected: 'alert-warning',
        accepted: 'alert-success',
        returned: 'alert-danger',
        completed: 'alert-success'
    };

    

    /**
     * @name $onInit - Initialization Order.
     */
    this.$onInit = function(){
        this.cost = false;//cost
        this.hover = false;//mouseove+mouseout 
        this.more = false; 

        this.model = {order : {}};
        this.CANCEL = HoogyService.CANCEL;
        this.REJECT = HoogyService.REJECT;
        this.FULLFILL = HoogyService.FULLFILL;
        this.SHIP = HoogyService.SHIP;
        this.REFUND = HoogyService.REFUND;
        this.FINALIZE = HoogyService.FINALIZE;
    
        this.CANCELLED = HoogyService.CANCELLED;
        this.REJECTED = HoogyService.REJECTED;
        this.FULLFILLED = HoogyService.FULLFILLED;
        this.SHIPPED = HoogyService.SHIPPED;
        this.REFUNDED = HoogyService.REFUNDED;
        this.FINALIZED = HoogyService.FINALIZED;
    
        //
        this.statuses = [
            HoogyService.CANCEL, HoogyService.REJECT,
            HoogyService.FULLFILL, HoogyService.SHIP,
            HoogyService.REFUND, HoogyService.FINALIZED
        ];

        // Initialization. 
        this.init();
    };

    this.init = function() {
        var _status;
        this.more = true;
        this.model.order = HoogyService.formatOrder(this.order);
        if(this.model.order.payment){ 
            this.cost = this.model.order.payment.amount || false; 
        }
        if(this.model && this.model.order){
            _status = (this.model.order.status || 'waiting').toLowerCase();
            this.statusClass = cssClasses[_status];
        }

        this.css = { active: false };
        $timeout.cancel(this.handler);
        this.handler = $timeout(function(){ vm.css.active = true; }, 50);
    
    };

    /**
     * This function is used with  ng-mouseover.
     */
    this.mouseover = function mouseover() {
        this.hover = true;
    };

    /**
     * This function is used with  ng-mouseleave/mouseout.
     */
    this.mouseout = function mouseout() {
        this.hover = false;
    };

    /**
     *  This function expands and hides Order details.
     */
    this.showHide = function showHide() {
        this.more = !this.more;
        var _status = 'waiting';

        //try to force order update
        if (this.model.order) {
            this.model.order = vm.model.order;
            if (this.model.order.status) {
                _status = (this.model.order.status).toLowerCase();
            }

            this.statusClass = cssClasses[ _status ];
        }
    };

    /**
     * @name save - This function will replace cancel/[reject=order cancelled by owner]/fullfill/shipped/refund
     *            - A refund is initiated by anyone, but confirmed by the system administrator
     */
    this.save = function(order) {
        var options =  { orderId: vm.model.order._id || order._id };
            options.status =  order.status || HoogyService.CANCEL;
        return HoogyService.orderStatus(options).then(function(response) {
          vm.waiting = false;
          if (HoogyService.changedOrder._id === vm.model.order._id) {
              //@todo change the status model based on selected status.
              vm.model.order.status = HoogyService.changedOrder.status || vm.model.order.status;
              vm.waiting = false;
          }
          return response;
      });
    };

    this.$onDestroy = function(){
        this.css = { active: false };
        $timeout.cancel(this.handler);
        this.handler = null;
    };
}
HoogyOrderController.$inject = ['$timeout', 'HoogyService'];
angular.module('hoogy').component('hoogyOrder', {
    controllerAs: 'vm',
    controller: HoogyOrderController,
    bindings: {order: '='},
    templateUrl: 'js/common/components/view/order.html'
});