'use strict';
/**
 * @name UIService - UI Service is used with Events 
 */
function UIService(StateService, AuthService) {

    /**
     * Service References 
     * @param service 
     */
    var service = {
        getHooggyScreen: getHooggyScreen,

        getRentScreen: getRentScreen,
        getPeersScreen: getPeersScreen,
        getSettingsScreen: getSettingsScreen,
        getMessengerScreen: getMessengerScreen,
        getCheckoutContractScreen: getCheckoutContractScreen,
        getPaymentCheckoutScreen: getPaymentCheckoutScreen,
        getItemCheckoutScreen: getItemCheckoutScreen,

        getPeopleScreen: getPeopleScreen,
        getCheckoutScreen: getCheckoutScreen,
        getWorkspaceScreen: getWorkspaceScreen,
        getWorkspaceOrderScreen: getWorkspaceOrderScreen,
        getWorkspaceOrdersScreen: getWorkspaceOrdersScreen,
        getWorkspaceWalletScreen: getWorkspaceWalletScreen,
        getWorkspaceProfileScreen: getWorkspaceProfileScreen,
        getDiscoverWorkspaceScreen: getDiscoverWorkspaceScreen,
        getWorkspaceInventoryScreen: getWorkspaceInventoryScreen,
        getWorkspaceCollectionScreen: getWorkspaceCollectionScreen,
        
        getStuffScreen: getStuffScreen,
        getAddStuffScreen: getAddStuffScreen,
        getEditStuffScreen: getEditStuffScreen,

        getStoreScreen: getStoreScreen,
        getStoreTermsScreen: getStoreScreen, 
        geStorePrivacyScreen: getStoreScreen, 
        getStorePartnersScreen: getStoreScreen,
        getStoreListScreen: getStoreListScreen,
        getStoreItemScreen: getStoreItemScreen,
        getHowItWorksScreen: getStoreScreen,
        getStorePrivacyScreen: getStoreScreen, 
        getStoreConfirmationScreen: getStoreConfirmationScreen,

        getCardScreen: getCardScreen,
        getCardWidgetScreen: getCardWidgetScreen,
        getCardsWidgetScreen: getCardsWidgetScreen,
        
        getNotifyScreen: getNotifyScreen,
        getInviteScreen: getInviteScreen,
        getAnnounceScreen: getAnnounceScreen,
        getNotificationsScreen: getNotificationsScreen,
        getWishlistScreen: getWishlistScreen, 


        getAuthScreen: getAuthScreen, 
        getSigninAuthScreen: getAuthScreen, 
        getSignupAuthScreen: getAuthScreen, 
        getRecoverAuthScreen: getAuthScreen,

        getSharerScreen: getSharerScreen,
        getSidebarScreen: getSidebarScreen 
    };

    return service;




    /**
     * @name getSidebarrScreen - Initializes Sidebar Screen.
     * @param {Object<Settings>} settings 
     */
    function getSidebarScreen(settings) {
        if(!settings.component) throw 'Component is Required @getSidebarScreen';
        var action = settings.action || 'sidebar';
        var index = [settings.component, action].join(':');
        var entities = StateService.getState().screens.entities || {};
        var screen = entities? entities[index] || {} : {};
        
       return Object.assign({}, {
            active:  screen.active || settings.active || 'sidebar',
            action:  screen.action || settings.action || 'sidebar',
            up:  screen.up || settings.up || false,
            waiting:  screen.waiting || settings.waiting || false,
            sharerup: screen.sharerup || settings.sharerup || false,
            authprompt: !AuthService.isAuthenticated(),
        });
    }

    /**
     * @todo - Change the Searh Object, as it will depend on various parameters.
     * @name getSharerScreen - Initializes Sharer Screen.
     * @param {Object<Settings>} settings 
     */
    function getSharerScreen(settings) {
        if(!settings.component) throw 'Component is Required @getSharerScreen';
        var action = 'signin';
        var index = [settings.component, action].join(':');
        var entities = StateService.getState().screens.entities || {};
        var screen = entities? entities[index] || {} : {};
        var search = {query:{type:'inventory'}, type:'inventory', what: 'inventory', text: '#inventory', tags:['#inventory']};
        
       return Object.assign({}, {
            active:  screen.active || settings.active || 'signin',
            action:  screen.action || settings.action || 'signin',
            waiting:  screen.waiting || settings.waiting || false,
            search:  screen.search || settings.search || search,
            sharerup: screen.sharerup || settings.sharerup || false,
            authprompt: !AuthService.isAuthenticated(),
        });
    }
    
        /**
         * @name getAuthScreen - Initializes Auth Screen.
         * @param {Object<Settings>} settings 
         */
        function getAuthScreen(settings) {
            return Object.assign({},{
                active: 'signin',
                action: 'signin',
                waiting: false,
                sharerup: false,
                authprompt: !AuthService.isAuthenticated(),
            }, settings);
        }

    /**
     * @name getCheckoutScreen - Initializes Checkout Screen.
     * @param {Object<Settings>} settings 
     */
    function getCheckoutScreen(settings) {
        if(!settings) throw '@getCheckoutScreen - Settings Options is required';
        if(settings && !settings.action) throw 'Action is Required @getCheckoutScreen';
        if(settings && !settings.component) throw 'Component is Required @getCheckoutScreen';
        var index = [settings.component, settings.action].join(':');
        var entities = StateService.getState().screens.entities || {};
        var screen = entities? entities[index] || {} : {};
        return Object.assign({},{
            active: 'checkout',
            waiting: false,
            sharerup: false,
            up: screen.up || settings.up || false, 
            authprompt: !AuthService.isAuthenticated(),
        }, settings);
    }

    /**
     * @name getWorkspaceOrdersScreen - Used with Workspace Controller.
     * @param {Object<Settings>} settings 
     */
    function getWorkspaceOrdersScreen(settings){
        var search = {what: 'orders', tags:['#incoming'], text:'#incoming', type: 'incoming'};
         var config = Object.assign({},{
             step: 0, 
             more: true,             
            active: 'orders', 
            type: 'orders',
            search: search,
            waiting: true,
            sharerup: false,
            authprompt: !AuthService.isAuthenticated()
        }, settings || {});
       
        //
        config.INCOMING = '#incoming';
        config.OUTGOING = '#outgoing';
        
        //returned configuration.
        return config;
    }


    /**
     * @name getDiscoverWorkspaceScreen - Initialized Discover Workspace Screen
     * @param {Object<Settings>} settings 
     */
    function getDiscoverWorkspaceScreen(settings){
        var search = {what: 'discover', tags:['#items'], text:'', query: {type: 'discover'}};
        return Object.assign({},{
            active: 'discover', 
            type: 'discover',
            search: search,
            step: 0, 
            more: true,
            waiting: true,
            sharerup: false,
            authprompt: !AuthService.isAuthenticated()
        }, settings || {});
    }


    /**
     * @name getStoreListScreen - Initialization of Store List Screen.
     * @param {Object<Settings>} settings 
     */
    function getStoreListScreen(settings){
        var rtn = Object.assign({},{
            message: false,
            success: false,
            waiting: false,
            active: 'list',
            sharerup: false,
            authprompt: !AuthService.isAuthenticated()
        }, settings || {});

        rtn.BIKE = 'bike';
        rtn.DRONE = 'drone';
        rtn.SEGWAY = 'segway';
        rtn.SKATES = 'skates';
        rtn.CAMERA = 'camera';
        rtn.HOVERBOARD = 'hoverboard';
        rtn.THREE_D_PRINTER = '3d-printer';
        rtn.OCULUS_LIFT = 'oculus-lift';
        rtn.TEXTBOOK = 'textbook';

        return rtn;
    }

    /**
     * @name getCardsWidgetScreen - Initializes Cards Widget Screen
     * @param {Objet<Settings>} settings 
     */
    function getCardWidgetScreen(settings){
        return Object.assign({},{waiting: false, active: 'card:widget', sharerup: false, authprompt: !AuthService.isAuthenticated()}, settings || {});
    }

    /**
     * @name getCardsWidgetScreen - Initializes Cards Widget Screen
     * @param {Objet<Settings>} settings 
     */
    function getCardsWidgetScreen(settings){
        return Object.assign({},{waiting: false,  active: 'cards:widget', sharerup: false, authprompt: !AuthService.isAuthenticated()}, settings || {});
    }

    /**
     * @name getCardScreen - Used to Initialize Card Screen on Cards Widget.
     * @param {Objet<Settings>} settings 
     */
    function getCardScreen(settings){
        return Object.assign({},{waiting: false, confirm: false,  active: 'card:screen', sharerup: false, authprompt: !AuthService.isAuthenticated()}, settings || {});
    }


    /**
     * @name getStoreScreen - Store Screen Initialization
     * @param {Object<Settings>} settings 
     */
    function getStoreScreen(settings){
        var search = {text: ''};
        return Object.assign({},{
            active: 'store', 
            search: search,
            waiting: true, 
            email: '', 
            user: {}, 
            equipment: {}, 
            item: {text: ''}, 
            invitation: {},
            sharerup: false,
            authprompt: !AuthService.isAuthenticated()
        }, settings || {});
    }

    /**
     * @name getWorkspaceInventoryScreen
     * @param {Object} settings 
     */
    function getWorkspaceInventoryScreen(settings){
        var search = {query:{type:'inventory'}, type:'inventory', what: 'inventory', text: '#inventory', tags:['#inventory']};
        return Object.assign({},{
            active: 'inventory', 
            search: search,
            more: true,
            step: 0,             
            waiting: true,
            sharerup: false,
            authprompt: !AuthService.isAuthenticated()
        }, settings || {});
    }

    /**
     * @name getWorkspaceCollectionScreen - Initialization of Workspace COlleciton Screen
     * @param {Object<Settings>} settings 
     */
    function getWorkspaceCollectionScreen(settings){
        var search = {query:{type:'collection'}, type:'collection', what: 'collection', text: '#collection', tags:['#collection']};
        return Object.assign({},{
                active: 'collection', 
                search: search,
                step: 0,
                more: true,
                waiting: true,
                sharerup: false,
                authprompt: !AuthService.isAuthenticated()
            }, settings || {});
    }

    /**
     * @name getWorkspaceWalletScreen - Initialization of Workspace Wallet Screen 
     * @param {Object<Settings>} settings 
     */
    function getWorkspaceWalletScreen(settings){
        return Object.assign({},{
                active: 'wallet', 
                results: [],
                incomings: 0.00,
                outgoings: 0.00,
                cashflow: 0.00,
                waiting: false,
                sharerup: false,
                authprompt: !AuthService.isAuthenticated()

            }, settings || {});
    }

    /**
     * @name getInviteScreen - Initialization Screen
     * @param {Object<Settings>} settings 
     * @returns {Object}
     */
    function getInviteScreen(settings){
        return Object.assign({},{
                active: 'invite',
                waiting: false,
                sharerup: false,
                authprompt: !AuthService.isAuthenticated()
            }, settings || {});
    }

    /**
     * @name getNotifyScreen
     * @param {Object<Settings>} settings 
     */
    function getNotifyScreen(settings){
        return Object.assign({},{
                active: 'notify',
                search: {query :{}},
                waiting: false,
                sharerup: false,
                authprompt: !AuthService.isAuthenticated()
            }, settings || {});
    }

        /**
     * @name getNotificationsScreen - Initialization of Notifications Directive
     * @param {Object<Settings>} settings 
     */
    function getNotificationsScreen(settings){
        return Object.assign({},{
                active: 'notifications',
                step: 0, 
                more: true,
                search: {query :{}},
                sharerup: false,
                authprompt: !AuthService.isAuthenticated()
            }, settings || {});
    }


    /**
     * @returns {Object}
     * @name getWishlistScreen
     * @param {Object<Settings>} settings 
     */
    function getWishlistScreen(settings){
        return Object.assign({},{
            active: 'wishlist',
            save: false,
            edit: false, 
            step: 0,
            more: true,
            sharerup: false,
            authprompt: !AuthService.isAuthenticated(),
            search:{
                what: 'wishlist',
                query: {type: 'wishlist'}, 
                text: '#wishlist', 
                tags: ['#wishlist']
            },
        }, settings || {});
    }

    /**
     * @name getAnnounceScreen - Initialization of Announcement Directive
     * @param {Object<Settings>} settings 
     */
    function getAnnounceScreen(settings){
        return Object.assign({},{
                active: 'stuff',
                save: false,
                edit: false,
                sharerup: false,
                authprompt: !AuthService.isAuthenticated()
            }, settings || {});
    }

    /**
     * @name getStuffScreen - Initiallization of Screen Object on Adder. 
     * @param {Object<Settings>} settings 
     */
    function getStuffScreen(settings) {
        var search = {
                what: 'inventory',
                query: {type: 'inventory'}, 
                text: '#inventory', 
                tags: ['#inventory']
            };
        return Object.assign({},{
            active: 'stuff',
            search: search,
            noFilter: true, 
            list: true,
            sharerup: false,
            authprompt: !AuthService.isAuthenticated(),
            waiting: false,
            pickup: false
        }, settings || {});
    }

    /**
     * @name getEditStuffScreen - Initiallization of Screen Object on Adder. 
     * @param {Object<Settings>} settings 
     */
    function getEditStuffScreen(settings) {
        return Object.assign({},{
            active: 'adder',
            pickup: false,
            waiting: false,
            sharerup: false,
            authprompt: !AuthService.isAuthenticated()
        }, settings || {});
    }

    /**
     * @name getAddStuffScreen - Initiallization of Screen Object on Adder. 
     * @param {Object<Settings>} settings 
     */
    function getAddStuffScreen(settings) {
        return Object.assign({},{
            active: 'adder',
            waiting: false,
            pickup: false,
            sharerup: false,
            authprompt: !AuthService.isAuthenticated()
        }, settings || {});
    }



    /**
     * @name getStoreConfirmationScreen - Initialization of Store Screen 
     * @param {Object<Settings>} settings 
     */
    function getStoreConfirmationScreen(settings) {
        return Object.assign({},{
            active: 'confirmation',
            waiting: true,
            sharerup: false,
            authprompt: !AuthService.isAuthenticated()
        }, settings || {});
    }


    /**
     * @name getStoreItemScreen - Initialization of Item on Store Screen.
     * @param {Object} settings 
     */
    function getStoreItemScreen(settings) {
        return Object.assign({},{
            active: 'item',
            step: 0,
            more: true,
            waiting: true,
            order: {},
            sharerup: false,
            authprompt: !AuthService.isAuthenticated()
        }, settings || {});
    }

    /**
     * @name getWorkspaceOrderScreen - Used to initialize Workspace::Order
     */
    function getWorkspaceProfileScreen(settings) {
        return Object.assign({},{
            active: 'profile',
            waiting: false,
            sharerup: false,
            authprompt: !AuthService.isAuthenticated()
        }, settings || {});
    }

    /**
     * @name getWorkspaceOrderScreen - Used to initialize Workspace::Order
     */
    function getWorkspaceOrderScreen(settings) {
        return Object.assign({},{
            active: 'order',
            step: 0,
            more: true,
            waiting: true,
            isCustomer: false,
            sharerup: false,
            authprompt: !AuthService.isAuthenticated()
        }, settings || {});
    }


    /**
     * @name getRentScreen - initializes Rent Screen 
     * @param {Object<Settings>} 
     * @returns {Object}
     */
    function getRentScreen(settings) {
        var search = {
            tags: ['#inventory'],
            query: {
                type: 'inventory'
            },
            type: 'inventory',
            text: '#inventory',
            what: 'rent'
        };
        return Object.assign({},{
            user: {},
            active: 'rent',
            contract: {},
            waiting: true,
            sharerup: false,
            search: search,
            authprompt: !AuthService.isAuthenticated(),
            items: []
        }, settings);
    }


    /**
     * @name getWorkspaceScreen - initializes Workspace Screen Object
     * @param {Object} settings 
     */
    function getWorkspaceScreen(settings) {
        var search = {
            query: {
                type: 'inventory', 
                requestor: {}
            },
            text: ''
        };
        return Object.assign({},{
            user: {},
            active: 'profile',
            contract: {},
            step: 0,
            more: true,
            waiting: true,
            search: search,
            up: false,
            sharerup: false,
            authprompt: !AuthService.isAuthenticated(),
            invitables: [],
            items: []
        }, settings);
    }




    /**
     * @name getPeersScreen - initialization of Peers Component Screen. 
     * @param {Object} settings - optional initialization parameters 
     */
    function getPeersScreen(settings) {
        var search = {
            tags: ['#people'],
            text: '#people',
            type: 'people',
            what: 'people'
        };
        return Object.assign({},{
            step: 0, 
            forward: 0,
            backward: 0,
            more: true,
            hasTitle: true,
            active: 'people',
            contact: true,
            search: search,
            sharerup: false,
            authprompt: !AuthService.isAuthenticated(),
            waiting: false
        }, settings || {});
    }

    
    /**
     * @name getPeopleScreen - used to initialize People Component Screen.
     * @param {Object} settings - optional configuration object.
     */
    function getPeopleScreen(settings) {
        var search = {
            tags: ['#people'],
            text: '#people',
            type: 'people',
            what: 'people'
        };
        return Object.assign({},{
            step: 0,
            more: true,
            waiting: false,
            active: 'people',
            sharerup: false,
            authprompt: !AuthService.isAuthenticated(),
            search: search
        }, settings || {});
    };

    /**
     * @name getHooggyScreen
     * @param {Object} settings 
     */
    function getHooggyScreen(settings) {
        if(!settings.component) throw 'Component is Required @getHooggyScreen';
        var active = 'search';//indexer users action|active
        var index = [settings.component, active].join(':');
        var entities = StateService.getState().screens.entities || {};
        var screen = entities? entities[index] || {} : {};

        return Object.assign({}, settings, screen, {
            component: screen.component || settings.component,
            active:  screen.active || settings.active || 'search',
            waiting:  screen.waiting || settings.waiting || false,
            up: screen.up || settings.up || false,
            menup: screen.menup || settings.menup || false,
            sharerup: screen.sharerup || settings.sharerup || false,
            authprompt: !AuthService.isAuthenticated()
        });
    }

    /**
     * @name getPaymentCheckoutScreen - View Checkout Screen. 
     * @param {Object} settings 
     */
    function getPaymentCheckoutScreen(settings) {
        return Object.assign({},{
            active: 'payment',
            waiting: false,
            sharerup: false,
            authprompt: !AuthService.isAuthenticated()
        }, settings || {});
    }

    /**
     * @name getItemCheckoutScreen - View Item Checkout Screen.
     * @param {Object<Settings>} settings 
     */
    function getItemCheckoutScreen(settings) {
        return Object.assign({},{
            active: 'item',
            waiting: false,
            sharerup: false,            
            authprompt: !AuthService.isAuthenticated()
        }, settings || {});
    }

    /**
     * @name getMessengerScreen - View Messenger Screen. 
     * @param {Object} settings
     * @return {Object} messenger 
     */
    function getMessengerScreen(settings) {
        return Object.assign({},{
            active: 'messenger',
            more: true,//for listing items 
            waiting: false,
            sharerup: false,            
            authprompt: !AuthService.isAuthenticated()
        }, settings || {});
    }

    /**
     * @name getCheckoutContractScreen - Initialization of Checkout Contract UI screens.
     */
    function getCheckoutContractScreen(settings) {
        return Object.assign({},{
            hasTitle: true,
            waiting: false,
            up: false,                        
            sharerup: false,                        
            active: 'Contract',
            authprompt: !AuthService.isAuthenticated()
        }, settings || {});
    }


    /**
     * @uses _.extend 
     * @param {Object} settings - Optional additional settings
     * @name getSettingsScreen - destined for settings Screen Object initialization.
     * @return {Object}
     */
    function getSettingsScreen(settings) {
        return Object.assign({},{
            hasTitle: true,
            active: 'Settings',
            waiting: false,
            up: false,                        
            sharerup: false,                        
            authprompt: !AuthService.isAuthenticated()
        }, settings || {});
    }
}
UIService.$inject = ['StateService', 'AuthService'];
angular.module('hoogy').factory('UIService', UIService);